import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_thongtu } from "../../../models/response/danh-muc/sis_thongtu";
import { eThongTuActionTypeIds, IThongTuChangeSelectedIds, IThongTuCloseConfirm, IThongTuCloseDetail, IThongTuDeleteErr, IThongTuDeleteStart, IThongTuDeleteSuccess, IThongTuLoadErr, IThongTuLoadStart, IThongTuLoadSuccess, IThongTuSaveErr, IThongTuSaveStart, IThongTuSaveSuccess, IThongTuShowConfirm, IThongTuShowDetail } from "../../action-types/danh-muc/IThongTuActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const thongTuActions = {
    loadStart: (): IThongTuLoadStart => BaseAction(undefined, eThongTuActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_thongtu[]): IThongTuLoadSuccess => BaseAction(respone, eThongTuActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IThongTuLoadErr => BaseAction(messege, eThongTuActionTypeIds.LOAD_ERROR),

    showDetail: (data?: sis_thongtu): IThongTuShowDetail => BaseAction(data, eThongTuActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IThongTuCloseDetail => BaseAction(undefined, eThongTuActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IThongTuShowConfirm => BaseAction(undefined, eThongTuActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IThongTuCloseConfirm => BaseAction(undefined, eThongTuActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IThongTuChangeSelectedIds => BaseAction(ids, eThongTuActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IThongTuDeleteStart => BaseAction(ids, eThongTuActionTypeIds.DELETE_START),
    deleteSuccess: (): IThongTuDeleteSuccess => BaseAction(undefined, eThongTuActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IThongTuDeleteErr => BaseAction(message, eThongTuActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_thongtu): IThongTuSaveStart => BaseAction(data, eThongTuActionTypeIds.SAVE_START),
    saveSuccess: ():IThongTuSaveSuccess => BaseAction(undefined, eThongTuActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IThongTuSaveErr =>  BaseAction(message, eThongTuActionTypeIds.SAVE_ERR)
}