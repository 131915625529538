import { INamHocTruongKhoiHeFilter } from "../../../models/INamHocTruongKhoiHeFilter";
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { ILopChangeHocSinhClassRequest } from "../../../models/request/dm-lop/ILopChangeHocSinhClassRequest";
import { ILopChangeThongTuRequest } from "../../../models/request/dm-lop/ILopChangeThongTuRequest";
import { ILopChangeThuTuHocSinhRequest } from "../../../models/request/dm-lop/ILopChangeThuTuHocSinhRequest";
import { ILopChuNhiemRequest } from "../../../models/request/dm-lop/ILopChuNhiemRequest";
import { ILopLockParentAppHocSinhRequest } from "../../../models/request/dm-lop/ILopLockParentAppHocSinhRequest";
import { ILopRemoveHocSinhClassRequest } from "../../../models/request/dm-lop/ILopRemoveHocSinhClassRequest";
import { ILopSelectHocSinhRequest } from "../../../models/request/dm-lop/ILopSelectHocSinhRequest";
import { ILopImportPhanLopRequest } from "../../../models/request/dm-lop/LopImportPhanLopRequest";
import { IHocSinhSelectRequest } from "../../../models/request/hoc-sinh/IHocSinhSelectRequest";
import { ILopImportPhanLopResponse } from "../../../models/response/dm-lop/LopImportPhanLopResponse";
import { ILopImportDsLopRequest } from "../../../models/request/dm-lop/LopImportDsLopRequest";
import { ILopImportDsLopResponse } from "../../../models/response/dm-lop/LopImportDsLopResponse";
import { ILopTreeViewItemResponse } from "../../../models/response/dm-lop/LopTreeViewItem";
import { dm_lop, dm_lop_viewmodel } from "../../../models/response/dm-lop/dm_lop";
import { IHocSinhChuaPhanLopResponse } from "../../../models/response/hoc-sinh/IHocSinhChuaPhanLopResponse";
import { IHocSinhSelectResponeItem } from "../../../models/response/hoc-sinh/IHocSinhSelectRespone";
import { IPhuHuynhItemRespone } from "../../../models/response/phu-huynh/IPhuHuynhItemRespone";
import { IActionTypeBase } from "../IActionTypeBase";
import { ILopSelectRequest } from './../../../models/request/dm-lop/ILopSelectRequest';

export enum eLopActionTypeIds {
    LOAD_ALL_START = "LOP_LOAD_ALL_START",
    LOAD_ALL_SUCCESS = "LOP_LOAD_ALL_SUCCESS",
    LOAD_ALL_ERROR = "LOP_LOAD_ALL_ERROR",

    LOAD_START = "LOP_LOAD_START",
    LOAD_SUCCESS = "LOP_LOAD_SUCCESS",
    LOAD_ERROR = "LOP_LOAD_ERROR",

    LOAD_TREE_CTE_START = "LOP_LOAD_TREE_CTE_START",
    LOAD_TREE_CTE_SUCCESS = "LOP_LOAD_TREE_CTE_SUCCESS",
    LOAD_TREE_CTE_ERROR = "LOP_LOAD_TREE_CTE_ERROR",

    SELECT_LOPCHUNHIEM_START = "LOP_SELECT_LOPCHUNHIEM_START",
    SELECT_LOPCHUNHIEM_SUCCESS = "LOP_SELECT_LOPCHUNHIEM_SUCCESS",
    SELECT_LOPCHUNHIEM_ERROR = "LOP_SELECT_LOPCHUNHIEM_ERROR",

    SELECT_START = "LOP_SELECT_START",
    SELECT_SUCCESS = "LOP_SELECT_SUCCESS",
    SELECT_ERROR = "LOP_SELECT_ERROR",

    SELECT_DETAIL_START = "LOP_SELECT_DETAIL_START",
    SELECT_DETAIL_SUCCESS = "LOP_SELECT_DETAIL_SUCCESS",
    SELECT_DETAIL_ERROR = "LOP_SELECT_DETAIL_ERROR",

    SHOW_DETAIL = "LOP_SHOW_DETAIL",
    CLOSE_DETAIL = "LOP_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "LOP_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "LOP_SHOW_CONFIRM",
    CLOSE_CONFIRM = "LOP_CLOSE_CONFIRM",

    DELETE_START = "LOP_DELETE_START",
    DELETE_SUCCESS = "LOP_DELETE_SUCCESS",
    DELETE_ERROR = "LOP_DELETE_ERROR",

    SAVE_START = "LOP_SAVE_START",
    SAVE_SUCCESS = "LOP_SAVE_SUCCESS",
    SAVE_ERR = "LOP_SAVE_ERR",

    CHANGE_THONGTU_START = "LOP_CHANGE_THONGTU_START",
    CHANGE_THONGTU_SUCCESS = "LOP_CHANGE_THONGTU_SUCCESS",
    CHANGE_THONGTU_ERROR = "LOP_CHANGE_THONGTU_ERROR",

    SELECT_HOCSINH_START = "LOP_SELECT_HOCSINH_START",
    SELECT_HOCSINH_SUCCESS = "LOP_SELECT_HOCSINH_SUCCESS",
    SELECT_HOCSINH_ERROR = "LOP_SELECT_HOCSINH_ERROR",

    SELECT_HOCSINH_CHUAPHANLOP_START = "LOP_SELECT_HOCSINH_CHUAPHANLOP_START",
    SELECT_HOCSINH_CHUAPHANLOP_SUCCESS = "LOP_SELECT_HOCSINH_CHUAPHANLOP_SUCCESS",
    SELECT_HOCSINH_CHUAPHANLOP_ERROR = "LOP_SELECT_HOCSINH_CHUAPHANLOP_ERROR",

    CHANGE_HOCSINH_THUTU_START = "LOP_CHANGE_HOCSINH_THUTU_START",
    CHANGE_HOCSINH_THUTU_SUCCESS = "LOP_CHANGE_HOCSINH_THUTU_SUCCESS",
    CHANGE_HOCSINH_THUTU_ERROR = "LOP_CHANGE_HOCSINH_THUTU_ERROR",

    CHANGE_THUTU_MACDINH_START = "LOP_CHANGE_THUTU_MACDINH_START",
    CHANGE_THUTU_MACDINH_SUCCESS = "LOP_CHANGE_THUTU_MACDINH_SUCCESS",
    CHANGE_THUTU_MACDINH_ERROR = "LOP_CHANGE_THUTU_MACDINH_ERROR",

    CHANGE_HOCSINH_START = "LOP_CHANGE_HOCSINH_START",
    CHANGE_HOCSINH_SUCCESS = "LOP_CHANGE_HOCSINH_SUCCESS",
    CHANGE_HOCSINH_ERROR = "LOP_CHANGE_HOCSINH_ERROR",

    REMOVE_HOCSINH_START = "LOP_REMOVE_HOCSINH_START",
    REMOVE_HOCSINH_SUCCESS = "LOP_REMOVE_HOCSINH_SUCCESS",
    REMOVE_HOCSINH_ERROR = "LOP_REMOVE_HOCSINH_ERROR",

    UPLOAD_HOCSINH_EXCEL_START = "LOP_UPLOAD_HOCSINH_EXCEL_START",
    UPLOAD_HOCSINH_EXCEL_SUCCESS = "LOP_UPLOAD_HOCSINH_EXCEL_SUCCESS",
    UPLOAD_HOCSINH_EXCEL_ERROR = "LOP_UPLOAD_HOCSINH_EXCEL_ERROR",

    SAVE_HOCSINH_EXCEL_START = "LOP_SAVE_HOCSINH_EXCEL_START",
    SAVE_HOCSINH_EXCEL_SUCCESS = "LOP_SAVE_HOCSINH_EXCEL_SUCCESS",
    SAVE_HOCSINH_EXCEL_ERROR = "LOP_SAVE_HOCSINH_EXCEL_ERROR",

    LOCK_PARENT_APP_START = "LOP_LOCK_PARENT_APP_START",
    LOCK_PARENT_APP_SUCCESS = "LOP_LOCK_PARENT_APP_SUCCESS",
    LOCK_PARENT_APP_ERROR = "LOP_LOCK_PARENT_APP_ERROR",

    LOAD_PHUHUYNH_START = "LOP_LOAD_PHUHUYNH_START",
    LOAD_PHUHUYNH_SUCCESS = "LOP_LOAD_PHUHUYNH_SUCCESS",
    LOAD_PHUHUYNH_ERROR = "LOP_LOAD_PHUHUYNH_ERROR",

    UPLOAD_LOP_EXCEL_START = "LOP_UPLOAD_LOP_EXCEL_START",
    UPLOAD_LOP_EXCEL_SUCCESS = "LOP_UPLOAD_LOP_EXCEL_SUCCESS",
    UPLOAD_LOP_EXCEL_ERROR = "LOP_UPLOAD_LOP_EXCEL_ERROR",

    SAVE_LOP_EXCEL_START = "LOP_SAVE_LOP_EXCEL_START",
    SAVE_LOP_EXCEL_SUCCESS = "LOP_SAVE_LOP_EXCEL_SUCCESS",
    SAVE_LOP_EXCEL_ERROR = "LOP_SAVE_LOP_EXCEL_ERROR",
}

export interface ILopLoadAllStart extends IActionTypeBase<eLopActionTypeIds.LOAD_ALL_START, undefined> { }
export interface ILopLoadAllSuccess extends IActionTypeBase<eLopActionTypeIds.LOAD_ALL_SUCCESS, dm_lop_viewmodel[]> { }
export interface ILopLoadAllError extends IActionTypeBase<eLopActionTypeIds.LOAD_ALL_ERROR, string> { }

export interface ILopLoadStart extends IActionTypeBase<eLopActionTypeIds.LOAD_START, ILopSelectRequest> { }
export interface ILopLoadSuccess extends IActionTypeBase<eLopActionTypeIds.LOAD_SUCCESS, dm_lop_viewmodel[]> { }
export interface ILopLoadError extends IActionTypeBase<eLopActionTypeIds.LOAD_ERROR, string> { }

export interface ILopTreeCteLoadStart extends IActionTypeBase<eLopActionTypeIds.LOAD_TREE_CTE_START, INamHocTruongKhoiHeFilter> { }
export interface ILopTreeCteLoadSuccess extends IActionTypeBase<eLopActionTypeIds.LOAD_TREE_CTE_SUCCESS, ILopTreeViewItemResponse[]> { }
export interface ILopTreeCteLoadError extends IActionTypeBase<eLopActionTypeIds.LOAD_TREE_CTE_ERROR, string> { }

export interface ILopSelectLopChuNhiemStart extends IActionTypeBase<eLopActionTypeIds.SELECT_LOPCHUNHIEM_START, ILopChuNhiemRequest> { }
export interface ILopSelectLopChuNhiemSuccess extends IActionTypeBase<eLopActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS, dm_lop_viewmodel> { }
export interface ILopSelectLopChuNhiemError extends IActionTypeBase<eLopActionTypeIds.SELECT_LOPCHUNHIEM_ERROR, string> { }

export interface ILopSelectStart extends IActionTypeBase<eLopActionTypeIds.SELECT_START, INamHocTruongKhoiHeFilter> { }
export interface ILopSelectSuccess extends IActionTypeBase<eLopActionTypeIds.SELECT_SUCCESS, dm_lop_viewmodel[]> { }
export interface ILopSelectError extends IActionTypeBase<eLopActionTypeIds.SELECT_ERROR, string> { }

export interface ILopSelectDetailStart extends IActionTypeBase<eLopActionTypeIds.SELECT_DETAIL_START, number> { }
export interface ILopSelectDetailSuccess extends IActionTypeBase<eLopActionTypeIds.SELECT_DETAIL_SUCCESS, dm_lop | undefined> { }
export interface ILopSelectDetailError extends IActionTypeBase<eLopActionTypeIds.SELECT_DETAIL_ERROR, string> { }

export interface ILopShowDetail extends IActionTypeBase<eLopActionTypeIds.SHOW_DETAIL, dm_lop_viewmodel | undefined> { }
export interface ILopCloseDetail extends IActionTypeBase<eLopActionTypeIds.CLOSE_DETAIL, undefined> { }
export interface ILopChangeSelectedIds extends IActionTypeBase<eLopActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface ILopShowConfirm extends IActionTypeBase<eLopActionTypeIds.SHOW_CONFIRM, undefined> { }
export interface ILopCloseConfirm extends IActionTypeBase<eLopActionTypeIds.CLOSE_CONFIRM, undefined> { }

export interface ILopDeleteStart extends IActionTypeBase<eLopActionTypeIds.DELETE_START, IDeleteMultipleBaseRequest> { }
export interface ILopDeleteSuccess extends IActionTypeBase<eLopActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface ILopDeleteErr extends IActionTypeBase<eLopActionTypeIds.DELETE_ERROR, string> { }
export interface ILopSaveStart extends IActionTypeBase<eLopActionTypeIds.SAVE_START, dm_lop> { }
export interface ILopSaveSuccess extends IActionTypeBase<eLopActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface ILopSaveErr extends IActionTypeBase<eLopActionTypeIds.SAVE_ERR, string> { }

export interface ILopChangeThongTuStart extends IActionTypeBase<eLopActionTypeIds.CHANGE_THONGTU_START, ILopChangeThongTuRequest> { }
export interface ILopChangeThongTuSuccess extends IActionTypeBase<eLopActionTypeIds.CHANGE_THONGTU_SUCCESS, undefined> { }
export interface ILopChangeThongTuError extends IActionTypeBase<eLopActionTypeIds.CHANGE_THONGTU_ERROR, string> { }

export interface ILopSelectHocSinhStart extends IActionTypeBase<eLopActionTypeIds.SELECT_HOCSINH_START, ILopSelectHocSinhRequest> { }
export interface ILopSelectHocSinhSuccess extends IActionTypeBase<eLopActionTypeIds.SELECT_HOCSINH_SUCCESS, IHocSinhSelectResponeItem[]> { }
export interface ILopSelectHocSinhError extends IActionTypeBase<eLopActionTypeIds.SELECT_HOCSINH_ERROR, string> { }

export interface ILopSelectHocSinhChuaPhanLopStart extends IActionTypeBase<eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_START, IHocSinhSelectRequest> { }
export interface ILopSelectHocSinhChuaPhanLopSuccess extends IActionTypeBase<eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_SUCCESS, IHocSinhChuaPhanLopResponse[]> { }
export interface ILopSelectHocSinhChuaPhanLopError extends IActionTypeBase<eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_ERROR, string> { }

export interface ILopChangeHocSinhStart extends IActionTypeBase<eLopActionTypeIds.CHANGE_HOCSINH_START, ILopChangeHocSinhClassRequest> { }
export interface ILopChangeHocSinhSuccess extends IActionTypeBase<eLopActionTypeIds.CHANGE_HOCSINH_SUCCESS, undefined> { }
export interface ILopChangeHocSinhError extends IActionTypeBase<eLopActionTypeIds.CHANGE_HOCSINH_ERROR, string> { }

export interface ILopChangeHocSinhThuTuStart extends IActionTypeBase<eLopActionTypeIds.CHANGE_HOCSINH_THUTU_START, ILopChangeThuTuHocSinhRequest> { }
export interface ILopChangeHocSinhThuTuSuccess extends IActionTypeBase<eLopActionTypeIds.CHANGE_HOCSINH_THUTU_SUCCESS, undefined> { }
export interface ILopChangeHocSinhThuTuError extends IActionTypeBase<eLopActionTypeIds.CHANGE_HOCSINH_THUTU_ERROR, string> { }

export interface ILopChangeThuTuMacDinhStart extends IActionTypeBase<eLopActionTypeIds.CHANGE_THUTU_MACDINH_START, number> { }
export interface ILopChangeThuTuMacDinhSuccess extends IActionTypeBase<eLopActionTypeIds.CHANGE_THUTU_MACDINH_SUCCESS, undefined> { }
export interface ILopChangeThuTuMacDinhError extends IActionTypeBase<eLopActionTypeIds.CHANGE_THUTU_MACDINH_ERROR, string> { }

export interface ILopRemoveHocSinhStart extends IActionTypeBase<eLopActionTypeIds.REMOVE_HOCSINH_START, ILopRemoveHocSinhClassRequest> { }
export interface ILopRemoveHocSinhSuccess extends IActionTypeBase<eLopActionTypeIds.REMOVE_HOCSINH_SUCCESS, undefined> { }
export interface ILopRemoveHocSinhError extends IActionTypeBase<eLopActionTypeIds.REMOVE_HOCSINH_ERROR, string> { }


export interface ILopUploadExcelStartAction extends IActionTypeBase<eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_START, FormData> { }
export interface ILopUploadExcelSuccessAction extends IActionTypeBase<eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_SUCCESS, ILopImportPhanLopResponse> { }
export interface ILopUploadExcelErrorAction extends IActionTypeBase<eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_ERROR, string> { }

export interface ILopSaveExcelStartAction extends IActionTypeBase<eLopActionTypeIds.SAVE_HOCSINH_EXCEL_START, ILopImportPhanLopRequest> { }
export interface ILopSaveExcelSuccessAction extends IActionTypeBase<eLopActionTypeIds.SAVE_HOCSINH_EXCEL_SUCCESS, ILopImportPhanLopResponse> { }
export interface ILopSaveExcelErrorAction extends IActionTypeBase<eLopActionTypeIds.SAVE_HOCSINH_EXCEL_ERROR, string> { }

export interface ILopLockParentAppStudentStart extends IActionTypeBase<eLopActionTypeIds.LOCK_PARENT_APP_START, ILopLockParentAppHocSinhRequest> { }
export interface ILopLockParentAppStudentSuccess extends IActionTypeBase<eLopActionTypeIds.LOCK_PARENT_APP_SUCCESS, undefined> { }
export interface ILopLockParentAppStudentError extends IActionTypeBase<eLopActionTypeIds.LOCK_PARENT_APP_ERROR, string> { }

export interface ILopLoadPhuHuynhStart extends IActionTypeBase<eLopActionTypeIds.LOAD_PHUHUYNH_START, ILopSelectHocSinhRequest> { }
export interface ILopLoadPhuHuynhSuccess extends IActionTypeBase<eLopActionTypeIds.LOAD_PHUHUYNH_SUCCESS, IPhuHuynhItemRespone[]> { }
export interface ILopLoadPhuHuynhError extends IActionTypeBase<eLopActionTypeIds.LOAD_PHUHUYNH_ERROR, string> { }

export interface ILopUploadLopExcelStartAction extends IActionTypeBase<eLopActionTypeIds.UPLOAD_LOP_EXCEL_START, FormData> { }
export interface ILopUploadLopExcelSuccessAction extends IActionTypeBase<eLopActionTypeIds.UPLOAD_LOP_EXCEL_SUCCESS, ILopImportDsLopResponse> { }
export interface ILopUploadLopExcelErrorAction extends IActionTypeBase<eLopActionTypeIds.UPLOAD_LOP_EXCEL_ERROR, string> { }

export interface ILopSaveLopExcelStartAction extends IActionTypeBase<eLopActionTypeIds.SAVE_LOP_EXCEL_START, ILopImportDsLopRequest> { }
export interface ILopSaveLopExcelSuccessAction extends IActionTypeBase<eLopActionTypeIds.SAVE_LOP_EXCEL_SUCCESS, ILopImportDsLopResponse> { }
export interface ILopSaveLopExcelErrorAction extends IActionTypeBase<eLopActionTypeIds.SAVE_LOP_EXCEL_ERROR, string> { }

export type ILopActionTypes = ILopLoadAllStart | ILopLoadAllSuccess | ILopLoadAllError | ILopLoadStart | ILopLoadSuccess | ILopLoadError |
    ILopTreeCteLoadStart | ILopTreeCteLoadSuccess | ILopTreeCteLoadError |
    ILopSelectLopChuNhiemStart | ILopSelectLopChuNhiemSuccess | ILopSelectLopChuNhiemError |
    ILopSelectStart | ILopSelectSuccess | ILopSelectError |
    ILopSelectDetailStart | ILopSelectDetailSuccess | ILopSelectDetailError |
    ILopShowDetail | ILopCloseDetail | ILopChangeSelectedIds | ILopShowConfirm | ILopCloseConfirm |
    ILopDeleteStart | ILopDeleteSuccess | ILopDeleteErr | ILopSaveStart | ILopSaveSuccess | ILopSaveErr |
    ILopChangeThongTuStart | ILopChangeThongTuSuccess | ILopChangeThongTuError |
    ILopSelectHocSinhStart | ILopSelectHocSinhSuccess | ILopSelectHocSinhError |
    ILopSelectHocSinhChuaPhanLopStart | ILopSelectHocSinhChuaPhanLopSuccess | ILopSelectHocSinhChuaPhanLopError |
    ILopChangeHocSinhStart | ILopChangeHocSinhSuccess | ILopChangeHocSinhError |
    ILopChangeHocSinhThuTuStart | ILopChangeHocSinhThuTuSuccess | ILopChangeHocSinhThuTuError |
    ILopChangeThuTuMacDinhStart | ILopChangeThuTuMacDinhSuccess | ILopChangeThuTuMacDinhError |
    ILopRemoveHocSinhStart | ILopRemoveHocSinhSuccess | ILopRemoveHocSinhError |
    ILopUploadExcelStartAction | ILopUploadExcelSuccessAction | ILopUploadExcelErrorAction |
    ILopSaveExcelStartAction | ILopSaveExcelSuccessAction | ILopSaveExcelErrorAction |
    ILopUploadLopExcelStartAction | ILopUploadLopExcelSuccessAction | ILopUploadLopExcelErrorAction |
    ILopSaveLopExcelStartAction | ILopSaveLopExcelSuccessAction | ILopSaveLopExcelErrorAction |
    ILopLockParentAppStudentStart | ILopLockParentAppStudentSuccess | ILopLockParentAppStudentError |
    ILopLoadPhuHuynhStart | ILopLoadPhuHuynhSuccess | ILopLoadPhuHuynhError