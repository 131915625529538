import { baiTapKetQuaReducer } from './baiTapKetQuaReducer';
import { baiTapReducer } from './baiTapReducer';
import { combineReducers } from 'redux';
import { baiTapTypeReducer } from './baiTapTypeReducer';
import { baiTapKetQuaLopReducer } from './baiTapKetQuaLopReducer';
export const baiTapReducerWrapper = combineReducers({
    type: baiTapTypeReducer,
    baiTap: baiTapReducer,
    baiTapKetQuaLop: baiTapKetQuaLopReducer,
    baiTapKetQua:baiTapKetQuaReducer
})