import { NotifyHelper, showNotify } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eGiaoVienActionTypeIds, IGiaoVienActionTypes } from "../../action-types/IGiaoVienActionTypes";
import { IGiaoVienPageStateModel } from "../../page-state-model/IGiaoVienPageState";

const initialState: IGiaoVienPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_giaoviens: [],
    is_show_delete_confirm: false,
    sis_giaovien_selected_ids: [],
    sis_giaoviens_notin_truong: [],
    sis_giaovien_editing: undefined,
    is_show_detail_modal: false,
    is_show_add_modal: false,    
    data_from_excel: undefined,
    upload_status: ePageBaseStatus.is_not_initialization,
}
export const giaoVienReducer = (state: IGiaoVienPageStateModel = initialState, action: IGiaoVienActionTypes): IGiaoVienPageStateModel => {
    switch (action.type) {
        case eGiaoVienActionTypeIds.LOAD_START:
        case eGiaoVienActionTypeIds.LOAD_BYTRUONG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eGiaoVienActionTypeIds.LOAD_SUCCESS:
        case eGiaoVienActionTypeIds.LOAD_BYTRUONG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_giaoviens: action.payload
            }
        case eGiaoVienActionTypeIds.LOAD_ERROR:
        case eGiaoVienActionTypeIds.LOAD_BYTRUONG_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_giaoviens: []
            }
        case eGiaoVienActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_giaovien_editing: action.payload
            }
        case eGiaoVienActionTypeIds.SHOW_ADD_MODAL:
            return {
                ...state,
                is_show_add_modal: action.payload,
                sis_giaovien_selected_ids: []
            }
        case eGiaoVienActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_giaovien_editing: undefined
            }
        case eGiaoVienActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_giaovien_selected_ids: action.payload
            }
        case eGiaoVienActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eGiaoVienActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eGiaoVienActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eGiaoVienActionTypeIds.DELETE_SUCCESS:
            showNotify({ type: "success", message: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eGiaoVienActionTypeIds.DELETE_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eGiaoVienActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eGiaoVienActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eGiaoVienActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        case eGiaoVienActionTypeIds.CLEAR_STATUS:
            return {
                ...initialState
            }
        case eGiaoVienActionTypeIds.UPLOAD_EXCEL_START:
            return {
                ...state,
                upload_status: ePageBaseStatus.is_loading
            }
        case eGiaoVienActionTypeIds.UPLOAD_EXCEL_SUCCESS:
            NotifyHelper.Success("Load file excel thành công.")
            return {
                ...state,
                upload_status: ePageBaseStatus.is_completed,
                data_from_excel: action.payload
            }
        case eGiaoVienActionTypeIds.UPLOAD_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                upload_status: ePageBaseStatus.is_completed
            }
        case eGiaoVienActionTypeIds.SAVE_EXCEL_START:
            return {
                ...state,
                upload_status: ePageBaseStatus.is_saving
            }
        case eGiaoVienActionTypeIds.SAVE_EXCEL_SUCCESS:
            NotifyHelper.Success("Đã xử lý Import dữ liệu thành công. Vui lòng xem kết quả import ở cột Trạng thái từng dòng")
            return {
                ...state,
                upload_status: ePageBaseStatus.is_need_reload,
                status: ePageBaseStatus.is_need_reload,
                data_from_excel: action.payload
            }
        case eGiaoVienActionTypeIds.SAVE_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                upload_status: ePageBaseStatus.is_completed
            }
        default:
            return state;
    }

}
