import { IThaiDoHocTapSelectRequest } from "../models/request/nhan-xet/IThaiDoHocTapSelectRequest";
import { sis_thaidohoctap } from "../models/response/nhan-xet/sis_thaidohoctap";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_THAIDOHOCTAP_PATH = "thai-do-hoc-tap"
class ThaiDoHocTapApi extends BaseCRUDApi<sis_thaidohoctap> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    Select(request: IThaiDoHocTapSelectRequest){
        return apiClient.post(`${this._path}/select`, request)
    }

}
export const thaiDoHocTapApi = new ThaiDoHocTapApi({ path: API_THAIDOHOCTAP_PATH });