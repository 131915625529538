import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { ePLNamHocActionTypeIds, IPLNamHocActionTypes } from "../../action-types/danh-muc/IPLNamHocActionTypes"
import { IPLNamHocPageState } from "../../page-state-model/danh-muc/IPLNamHocPagaState"

const initialState: IPLNamHocPageState = {
    status: ePageBaseStatus.is_not_initialization,
    pl_namhocs: [],
    pl_namhoc_selected_ids: [],
    pl_namhoc_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const namhocReducer = (state: IPLNamHocPageState = initialState, action: IPLNamHocActionTypes): IPLNamHocPageState => {
    switch (action.type) {
        case ePLNamHocActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case ePLNamHocActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_namhocs: action.payload
            }
        case ePLNamHocActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_namhocs: []
            }
        case ePLNamHocActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                pl_namhoc_editing: action.payload
            }
        case ePLNamHocActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                pl_namhoc_editing: undefined
            }
        case ePLNamHocActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                pl_namhoc_selected_ids: action.payload
            }
        case ePLNamHocActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case ePLNamHocActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        
            case ePLNamHocActionTypeIds.DELETE_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_deleting
                }
            case ePLNamHocActionTypeIds.DELETE_SUCCESS:
                NotifyHelper.Success("Xoá dữ liệu thành công.")
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }
            case ePLNamHocActionTypeIds.DELETE_ERROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }

        
            case ePLNamHocActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case ePLNamHocActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }
        case ePLNamHocActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }

        default:
            return state;
    }

}