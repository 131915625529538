import { NotifyHelper } from './../../../helpers/toast';
import { eNhomThanhPhanDiemActionTypeIds, INhomThanhPhanDiemActionTypes } from './../../action-types/so-diem/INhomThanhPhanDiemActions';
import { INhomThanhPhanDiemStateModel } from "../../page-state-model/so-diem/INhomThanhPhanDiemStateModel";
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';

const iniState: INhomThanhPhanDiemStateModel = {
    nhomThanhPhanDiems: [],
    status: ePageBaseStatus.is_not_initialization
}

export const nhomThanhPhanDiemReducer =
    (state: INhomThanhPhanDiemStateModel = iniState, action: INhomThanhPhanDiemActionTypes): INhomThanhPhanDiemStateModel => {
        switch (action.type) {
            case eNhomThanhPhanDiemActionTypeIds.LOAD_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_loading
                }
            case eNhomThanhPhanDiemActionTypeIds.LOAD_SUCCESS:
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    nhomThanhPhanDiems: action.payload
                }
            case eNhomThanhPhanDiemActionTypeIds.LOAD_ERRROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                }
            default:
                return {
                    ...state
                }
        }
    }