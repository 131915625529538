import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { IBaseResponeModel } from "../../models/response/base-response";
import { eMonHocActionTypeIds, IMonHocDeleteStart, IMonHocLoadStart, IMonHocSaveStart } from "../action-types/IMonHocActionTypes";
import { actions } from './../actions/actionsWrapper';

const monHocApi = apiWrapper.monHoc;

export function* monHocSaga(): any {
    yield takeLatest(eMonHocActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eMonHocActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eMonHocActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(action: IMonHocLoadStart): any {

    const res: IBaseResponeModel = yield call([monHocApi, monHocApi.SelectByTruong], action.payload)
    if (res.is_success) {
        yield put(actions.monHoc.loadMonHocSuccess(res.data))
    } else {
        yield put(actions.monHoc.loadMonHocError(res.message || "Không tải được môn học"))
    }
}
function* deleteWorker(action: IMonHocDeleteStart): any {
    const res: IBaseResponeModel = yield call([monHocApi, monHocApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.monHoc.deleteMonHocSuccess())
    } else {
        yield put(actions.monHoc.deleteMonHocErr(res.message || "Không tải được môn học"))
    }
}
function* saveWorker(action: IMonHocSaveStart): any {
    console.log(action);
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([monHocApi, monHocApi.Update], action.payload);
    } else {
        res = yield call([monHocApi, monHocApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.monHoc.saveMonHocSuccess())
    } else {
        yield put(actions.monHoc.loadMonHocError(res.message || "Không lưu được môn học"))
    }
}
