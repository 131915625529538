import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { ChamTietCaNhanSelectRequest } from "../../../models/request/cham-tiet/ChamTietCaNhanSelectRequest";
import { ChamTietCaNhanUpdateRequest } from "../../../models/request/cham-tiet/ChamTietCaNhanUpdateRequest";
import { ChamTietDuyetRequest } from "../../../models/request/cham-tiet/ChamTietDuyetRequest";
import { ChamTietQuanLySelectRequest } from "../../../models/request/cham-tiet/ChamTietQuanLySelectRequest";
import { ChamTietItemResponse, sis_chamtiet } from "../../../models/response/cham-tiet/sis_chamtiet";
import { sis_chamtiet_trangthai_duyet } from "../../../models/response/cham-tiet/sis_chamtiet_trangthai_duyet";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eChamTietActionTypeIds {
    SELECT_TRANGTHAIDUYET_START = "ChamTiet_SELECT_TRANGTHAIDUYET_START",
    SELECT_TRANGTHAIDUYET_SUCCESS = "ChamTiet_SELECT_TRANGTHAIDUYET_SUCCESS",
    SELECT_TRANGTHAIDUYET_ERROR = "ChamTiet_SELECT_TRANGTHAIDUYET_ERROR",

    SELECT_CANHAN_START = "ChamTiet_SELECT_CANHAN_START",
    SELECT_CANHAN_SUCCESS = "ChamTiet_SELECT_CANHAN_SUCCESS",
    SELECT_CANHAN_ERROR = "ChamTiet_SELECT_CANHAN_ERROR",

    SELECT_DUYET_START = "ChamTiet_SELECT_DUYET_START",
    SELECT_DUYET_SUCCESS = "ChamTiet_SELECT_DUYET_SUCCESS",
    SELECT_DUYET_ERROR = "ChamTiet_SELECT_DUYET_ERROR",

    SELECT_QUANLY_START = "ChamTiet_SELECT_QUANLY_START",
    SELECT_QUANLY_SUCCESS = "ChamTiet_SELECT_QUANLY_SUCCESS",
    SELECT_QUANLY_ERROR = "ChamTiet_SELECT_QUANLY_ERROR",

    CHANGE_SELECTED_IDS = "ChamTiet_CHANGE_SELECTED_IDS",
    CHANGE_EDITING_DATA = "ChamTiet_CHANGE_EDITING_DATA",
    SHOW_DELETE_CONFIRM = "ChamTiet_SHOW_CONFIRM",

    DELETE_START = "ChamTiet_DELETE_START",
    DELETE_SUCCESS = "ChamTiet_DELETE_SUCCESS",
    DELETE_ERROR = "ChamTiet_DELETE_ERROR",

    SHOW_DETAIL_MODAL = "ChamTiet_SHOW_DETAIL_MODAL",

    SAVE_START = "ChamTiet_SAVE_START",
    SAVE_SUCCESS = "ChamTiet_SAVE_SUCCESS",
    SAVE_ERROR = "ChamTiet_SAVE_ERROR",

    CHANGE_TRANGTHAI_START = "ChamTiet_CHANGE_TRANGTHAI_START",
    CHANGE_TRANGTHAI_SUCCESS = "ChamTiet_CHANGE_TRANGTHAI_SUCCESS",
    CHANGE_TRANGTHAI_ERROR = "ChamTiet_CHANGE_TRANGTHAI_ERROR",

    CHAMLAI_START = "ChamTiet_CHAMLAI_START",
    CHAMLAI_SUCCESS = "ChamTiet_CHAMLAI_SUCCESS",
    CHAMLAI_ERROR = "ChamTiet_CHAMLAI_ERROR",

    DUYET_START = "ChamTiet_DUYET_START",
    DUYET_SUCCESS = "ChamTiet_DUYET_SUCCESS",
    DUYET_ERROR = "ChamTiet_DUYET_ERROR",
}

export interface IChamTiet_SELECT_TRANGTHAIDUYET_START extends IActionTypeBase<eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_START, undefined> { };
export interface IChamTiet_SELECT_TRANGTHAIDUYET_SUCCESS extends IActionTypeBase<eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_SUCCESS, sis_chamtiet_trangthai_duyet[]> { };
export interface IChamTiet_SELECT_TRANGTHAIDUYET_ERROR extends IActionTypeBase<eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_ERROR, string> { };

export interface IChamTiet_SELECT_CANHAN_START extends IActionTypeBase<eChamTietActionTypeIds.SELECT_CANHAN_START, ChamTietCaNhanSelectRequest> { };
export interface IChamTiet_SELECT_CANHAN_SUCCESS extends IActionTypeBase<eChamTietActionTypeIds.SELECT_CANHAN_SUCCESS, ChamTietItemResponse[]> { };
export interface IChamTiet_SELECT_CANHAN_ERROR extends IActionTypeBase<eChamTietActionTypeIds.SELECT_CANHAN_ERROR, string> { };

export interface IChamTiet_SELECT_DUYET_START extends IActionTypeBase<eChamTietActionTypeIds.SELECT_DUYET_START, ChamTietQuanLySelectRequest> { };
export interface IChamTiet_SELECT_DUYET_SUCCESS extends IActionTypeBase<eChamTietActionTypeIds.SELECT_DUYET_SUCCESS, ChamTietItemResponse[]> { };
export interface IChamTiet_SELECT_DUYET_ERROR extends IActionTypeBase<eChamTietActionTypeIds.SELECT_DUYET_ERROR, string> { };

export interface IChamTiet_SELECT_QUANLY_START extends IActionTypeBase<eChamTietActionTypeIds.SELECT_QUANLY_START, ChamTietQuanLySelectRequest> { };
export interface IChamTiet_SELECT_QUANLY_SUCCESS extends IActionTypeBase<eChamTietActionTypeIds.SELECT_QUANLY_SUCCESS, ChamTietItemResponse[]> { };
export interface IChamTiet_SELECT_QUANLY_ERROR extends IActionTypeBase<eChamTietActionTypeIds.SELECT_QUANLY_ERROR, string> { };

export interface IChamTiet_CHANGE_SELECTED_IDS extends IActionTypeBase<eChamTietActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface IChamTiet_CHANGE_EDITING_DATA extends IActionTypeBase<eChamTietActionTypeIds.CHANGE_EDITING_DATA, sis_chamtiet> { };
export interface IChamTiet_SHOW_DELETE_CONFIRM extends IActionTypeBase<eChamTietActionTypeIds.SHOW_DELETE_CONFIRM, boolean> { };

export interface IChamTiet_DELETE_START extends IActionTypeBase<eChamTietActionTypeIds.DELETE_START, IDeleteMultipleBaseRequest> { };
export interface IChamTiet_DELETE_SUCCESS extends IActionTypeBase<eChamTietActionTypeIds.DELETE_SUCCESS, undefined> { };
export interface IChamTiet_DELETE_ERROR extends IActionTypeBase<eChamTietActionTypeIds.DELETE_ERROR, string> { };

export interface IChamTiet_SHOW_DETAIL_MODAL extends IActionTypeBase<eChamTietActionTypeIds.SHOW_DETAIL_MODAL, boolean> { };

export interface IChamTiet_SAVE_START extends IActionTypeBase<eChamTietActionTypeIds.SAVE_START, sis_chamtiet> { };
export interface IChamTiet_SAVE_SUCCESS extends IActionTypeBase<eChamTietActionTypeIds.SAVE_SUCCESS, undefined> { };
export interface IChamTiet_SAVE_ERROR extends IActionTypeBase<eChamTietActionTypeIds.SAVE_ERROR, string> { };

export interface IChamTiet_CHANGE_TRANGTHAI_START extends IActionTypeBase<eChamTietActionTypeIds.CHANGE_TRANGTHAI_START, ChamTietCaNhanUpdateRequest> { };
export interface IChamTiet_CHANGE_TRANGTHAI_SUCCESS extends IActionTypeBase<eChamTietActionTypeIds.CHANGE_TRANGTHAI_SUCCESS, undefined> { };
export interface IChamTiet_CHANGE_TRANGTHAI_ERROR extends IActionTypeBase<eChamTietActionTypeIds.CHANGE_TRANGTHAI_ERROR, string> { };

export interface IChamTiet_CHAMLAI_START extends IActionTypeBase<eChamTietActionTypeIds.CHAMLAI_START, string> { };
export interface IChamTiet_CHAMLAI_SUCCESS extends IActionTypeBase<eChamTietActionTypeIds.CHAMLAI_SUCCESS, undefined> { };
export interface IChamTiet_CHAMLAI_ERROR extends IActionTypeBase<eChamTietActionTypeIds.CHAMLAI_ERROR, string> { };

export interface IChamTiet_DUYET_START extends IActionTypeBase<eChamTietActionTypeIds.DUYET_START, ChamTietDuyetRequest> { };
export interface IChamTiet_DUYET_SUCCESS extends IActionTypeBase<eChamTietActionTypeIds.DUYET_SUCCESS, undefined> { };
export interface IChamTiet_DUYET_ERROR extends IActionTypeBase<eChamTietActionTypeIds.DUYET_ERROR, string> { };

export type IChamTietActionTypes = 
    IChamTiet_SELECT_TRANGTHAIDUYET_START | IChamTiet_SELECT_TRANGTHAIDUYET_SUCCESS | IChamTiet_SELECT_TRANGTHAIDUYET_ERROR |
    IChamTiet_SELECT_CANHAN_START | IChamTiet_SELECT_CANHAN_SUCCESS | IChamTiet_SELECT_CANHAN_ERROR |
    IChamTiet_SELECT_DUYET_START | IChamTiet_SELECT_DUYET_SUCCESS | IChamTiet_SELECT_DUYET_ERROR |
    IChamTiet_SELECT_QUANLY_START | IChamTiet_SELECT_QUANLY_SUCCESS | IChamTiet_SELECT_QUANLY_ERROR |
    IChamTiet_CHANGE_EDITING_DATA | IChamTiet_CHANGE_SELECTED_IDS |
    IChamTiet_SHOW_DELETE_CONFIRM |
    IChamTiet_DELETE_START | IChamTiet_DELETE_SUCCESS | IChamTiet_DELETE_ERROR |
    IChamTiet_SHOW_DETAIL_MODAL |
    IChamTiet_SAVE_START | IChamTiet_SAVE_SUCCESS | IChamTiet_SAVE_ERROR |
    IChamTiet_CHANGE_TRANGTHAI_START | IChamTiet_CHANGE_TRANGTHAI_SUCCESS | IChamTiet_CHANGE_TRANGTHAI_ERROR |
    IChamTiet_CHAMLAI_START | IChamTiet_CHAMLAI_SUCCESS | IChamTiet_CHAMLAI_ERROR |
    IChamTiet_DUYET_START | IChamTiet_DUYET_SUCCESS | IChamTiet_DUYET_ERROR     