import { NotifyHelper } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { IChamTietActionTypes, eChamTietActionTypeIds } from "../../action-types/cham-tiet/IChamTietActionTypes";
import { IChamTietState } from "../../page-state-model/cham-tiet/IChamTietState";

const initialState: IChamTietState = {
    status: ePageBaseStatus.is_not_initialization,
    isShowDeleteConfirm: false,
    isShowDetailModal: false,
    sis_chamtiet_canhan_all: [],
    sis_chamtiet_duyet_all: [],
    sis_chamtiet_quanly_all: [],
    sis_chamtiet_selected_ids: [],
    sis_chamtiet_trangthai_duyet_all: [],
    editing_detail: undefined
}
export const chamTietReducer = (state: IChamTietState = initialState, action: IChamTietActionTypes): IChamTietState => {
    switch (action.type) {
        case eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_trangthai_duyet_all: action.payload
            }
        case eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_trangthai_duyet_all: []
            }
        case eChamTietActionTypeIds.SELECT_CANHAN_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eChamTietActionTypeIds.SELECT_CANHAN_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_canhan_all: action.payload
            }
        case eChamTietActionTypeIds.SELECT_CANHAN_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_canhan_all: []
            }
        case eChamTietActionTypeIds.SELECT_DUYET_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eChamTietActionTypeIds.SELECT_DUYET_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_duyet_all: action.payload
            }
        case eChamTietActionTypeIds.SELECT_DUYET_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_duyet_all: []
            }
        case eChamTietActionTypeIds.SELECT_QUANLY_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eChamTietActionTypeIds.SELECT_QUANLY_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_quanly_all: action.payload
            }
        case eChamTietActionTypeIds.SELECT_QUANLY_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_chamtiet_quanly_all: []
            }
        case eChamTietActionTypeIds.CHANGE_EDITING_DATA:
            return {
                ...state,
                editing_detail: action.payload
            }
        case eChamTietActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_chamtiet_selected_ids: action.payload
            }
        case eChamTietActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirm: action.payload || false
            }
        case eChamTietActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eChamTietActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Delete success")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eChamTietActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eChamTietActionTypeIds.SHOW_DETAIL_MODAL:
            return {
                ...state,
                isShowDetailModal: action.payload || false
            }
        case eChamTietActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eChamTietActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Save success")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eChamTietActionTypeIds.SAVE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }

        case eChamTietActionTypeIds.CHANGE_TRANGTHAI_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eChamTietActionTypeIds.CHANGE_TRANGTHAI_SUCCESS:
            NotifyHelper.Success("Save success")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eChamTietActionTypeIds.CHANGE_TRANGTHAI_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eChamTietActionTypeIds.CHAMLAI_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eChamTietActionTypeIds.CHAMLAI_SUCCESS:
            NotifyHelper.Success("Save success")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eChamTietActionTypeIds.CHAMLAI_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eChamTietActionTypeIds.DUYET_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eChamTietActionTypeIds.DUYET_SUCCESS:
            NotifyHelper.Success("Save success")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eChamTietActionTypeIds.DUYET_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        default:
            return state;
    }

}