import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eThaiDoHocTapTieuChiActionTypeIds, IThaiDoHocTapTieuChiActionTypes } from "../../action-types/nhan-xet/IThaiDoHocTapTieuChiActionTypes"
import { IThaiDoHocTapTieuChiState } from "../../page-state-model/nhan-xet/IThaiDoHocTapTieuChiState"

const initialState: IThaiDoHocTapTieuChiState = {
    status: ePageBaseStatus.is_not_initialization,
    sis_thaidohoctap_tieuchis: [],
    sis_thaidohoctap_tieuchi_selected_ids: [],
    sis_thaidohoctap_tieuchi_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const thaiDoHocTapTieuChiReducer = (state: IThaiDoHocTapTieuChiState = initialState, action: IThaiDoHocTapTieuChiActionTypes): IThaiDoHocTapTieuChiState => {
    switch (action.type) {
        case eThaiDoHocTapTieuChiActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eThaiDoHocTapTieuChiActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thaidohoctap_tieuchis: action.payload
            }
        case eThaiDoHocTapTieuChiActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thaidohoctap_tieuchis: []
            }
        case eThaiDoHocTapTieuChiActionTypeIds.SELECT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eThaiDoHocTapTieuChiActionTypeIds.SELECT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thaidohoctap_tieuchis: action.payload
            }
        case eThaiDoHocTapTieuChiActionTypeIds.SELECT_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thaidohoctap_tieuchis: []
            }
        case eThaiDoHocTapTieuChiActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_thaidohoctap_tieuchi_editing: action.payload
            }
        case eThaiDoHocTapTieuChiActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_thaidohoctap_tieuchi_editing: undefined
            }
        case eThaiDoHocTapTieuChiActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_thaidohoctap_tieuchi_selected_ids: action.payload
            }
        case eThaiDoHocTapTieuChiActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eThaiDoHocTapTieuChiActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        
            case eThaiDoHocTapTieuChiActionTypeIds.DELETE_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_deleting
                }
            case eThaiDoHocTapTieuChiActionTypeIds.DELETE_SUCCESS:
                NotifyHelper.Success("Xoá dữ liệu thành công.")
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }
            case eThaiDoHocTapTieuChiActionTypeIds.DELETE_ERROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }

        
            case eThaiDoHocTapTieuChiActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eThaiDoHocTapTieuChiActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }
        case eThaiDoHocTapTieuChiActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }

        default:
            return state;
    }

}