import { NotifyHelper } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eSoNhanXetItemActionTypeIds, ISoNhanXetItemActionTypes } from '../../action-types/so-diem-tieu-hoc/ISoNhanXetItemActionTypes';

import { ISoNhanXetItemPageStateModel } from '../../page-state-model/so-diem-tieu-hoc/ISoNhanXetItemPageStateModel';

const initialState: ISoNhanXetItemPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_dgdk_dm_nlpcs: [],
    is_show_delete_confirm: false,
    sis_dgdk_dm_nlpc_selected_ids: [],
    sis_dgdk_dm_nlpc_editing: undefined,
    is_show_detail_modal: false
}
export const soNhanXetItemReducer = (state: ISoNhanXetItemPageStateModel = initialState, action: ISoNhanXetItemActionTypes): ISoNhanXetItemPageStateModel => {
    switch (action.type) {
        case eSoNhanXetItemActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eSoNhanXetItemActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_dgdk_dm_nlpcs: action.payload
            }
        case eSoNhanXetItemActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_dgdk_dm_nlpcs: []
            }
        case eSoNhanXetItemActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_dgdk_dm_nlpc_editing: action.payload
            }
        case eSoNhanXetItemActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_dgdk_dm_nlpc_editing: undefined
            }
        case eSoNhanXetItemActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_dgdk_dm_nlpc_selected_ids: action.payload
            }
        case eSoNhanXetItemActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eSoNhanXetItemActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eSoNhanXetItemActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eSoNhanXetItemActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eSoNhanXetItemActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eSoNhanXetItemActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eSoNhanXetItemActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eSoNhanXetItemActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}
