import { NotifyHelper } from '../../../helpers/toast';
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eTuanActionTypeIds, ITuanActionTypes } from '../../action-types/danh-muc/ITuanActionTypes';
import { ITuanPageState } from '../../page-state-model/danh-muc/ITuanPageState';

const initialState: ITuanPageState = {
	status: ePageBaseStatus.is_not_initialization,
	pl_tuans: [],
	pl_tuan_selected_ids: [],
	pl_tuan_editing: undefined,
	is_show_delete_confirm: false,
	is_show_detail_modal: false,
};

export const tuanReducer = (state: ITuanPageState = initialState, action: ITuanActionTypes): ITuanPageState => {
	switch (action.type) {
		case eTuanActionTypeIds.LOAD_START:
			return {
				...state,
				status: ePageBaseStatus.is_loading,
			};
		case eTuanActionTypeIds.LOAD_SUCCESS:
			return {
				...state,
				status: ePageBaseStatus.is_completed,
				pl_tuans: action.payload,
			};
		case eTuanActionTypeIds.LOAD_ERROR:
			NotifyHelper.Error(action.payload);
			return {
				...state,
				status: ePageBaseStatus.is_completed,
				pl_tuans: [],
			};
		case eTuanActionTypeIds.SELECT_START:
			return {
				...state,
				status: ePageBaseStatus.is_loading,
			};
		case eTuanActionTypeIds.SELECT_SUCCESS:
			return {
				...state,
				status: ePageBaseStatus.is_completed,
				pl_tuans: action.payload,
			};
		case eTuanActionTypeIds.SELECT_ERROR:
			NotifyHelper.Error(action.payload);
			return {
				...state,
				status: ePageBaseStatus.is_completed,
				pl_tuans: [],
			};
		case eTuanActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				is_show_detail_modal: true,
				pl_tuan_editing: action.payload,
			};
		case eTuanActionTypeIds.CLOSE_DETAIL:
			return {
				...state,
				is_show_detail_modal: false,
				pl_tuan_editing: undefined,
			};
		case eTuanActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				pl_tuan_selected_ids: action.payload,
			};
		case eTuanActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				is_show_delete_confirm: true,
			};
		case eTuanActionTypeIds.CLOSE_CONFIRM:
			return {
				...state,
				is_show_delete_confirm: false,
			};

		case eTuanActionTypeIds.DELETE_START:
			return {
				...state,
				status: ePageBaseStatus.is_deleting,
			};
		case eTuanActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success('Xoá dữ liệu thành công.');
			return {
				...state,
				status: ePageBaseStatus.is_need_reload,
				is_show_delete_confirm: false,
			};
		case eTuanActionTypeIds.DELETE_ERROR:
			NotifyHelper.Error(action.payload);
			return {
				...state,
				status: ePageBaseStatus.is_completed,
				is_show_delete_confirm: false,
			};
		case eTuanActionTypeIds.SAVE_START:
			return {
				...state,
				status: ePageBaseStatus.is_saving,
			};
		case eTuanActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success('Thay đổi dữ liệu thành công.');
			return {
				...state,
				status: ePageBaseStatus.is_need_reload,
				is_show_detail_modal: false,
			};
		case eTuanActionTypeIds.SAVE_ERR:
			NotifyHelper.Error(action.payload);
			return {
				...state,
				status: ePageBaseStatus.is_completed,
				is_show_detail_modal: false,
			};
		case eTuanActionTypeIds.GENERATE_START:
			return {
				...state,
				status: ePageBaseStatus.is_saving,
			};
		case eTuanActionTypeIds.GENERATE_SUCCESS:
			return {
				...state,
				status: ePageBaseStatus.is_need_reload,
			};
		case eTuanActionTypeIds.GENERATE_ERROR:
			return {
				...state,
				status: ePageBaseStatus.is_completed,
			};
		default:
			return state;
	}
};
