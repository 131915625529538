import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";
import { sis_chamtiet_loaitiet } from "../models/response/cham-tiet/sis_chamtiet_loaitiet";

export const API_CHAMTIET_LOAITIET_PATH = "cham-tiet-loai-tiet";
export const chamTietLoaiTietApi = new class ChamTietLoaiTietApi extends BaseCRUDApi<sis_chamtiet_loaitiet> {
    constructor(path: string) {
        super(path);
    }
    SelectAllAsync(dm_truong_id: number) {
        return apiClient.get(`${this._path}/truong/${dm_truong_id}/select-all`);
    }
}(API_CHAMTIET_LOAITIET_PATH);
