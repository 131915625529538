import { IUpdateDgdkNlpcRequest } from './../../../../models/request/danh-gia/dinh-ky/IUpdateDgdkNlpcRequest';
import { IHocSinhSelectRequest } from './../../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { IHocSinhSelectResponeItem } from '../../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { INamHocHocKyTruongKhoiLopGiaiDoanFilter } from './../../../../models/INamHocTruongKhoiLopGiaiDoanFilter';
import { IActionTypeBase } from './../../IActionTypeBase';
import { ISoNhanXetItem } from '../../../../models/response/dgdk-dm/ISoNhanXetItem';
import { ISelectDgdkNlpcRequest } from '../../../../models/request/danh-gia/dinh-ky/ISelectDgdkNlpcRequest';
import { IDgdkKetQuaNlpc } from '../../../../models/response/danh-gia/dinh-ky/IDgdkKetQuaNlpc';
import { ImportDgdkNlpcRequest } from '../../../../models/request/danh-gia/dinh-ky/IImportDgdkNlpcRequest';
import { ICheckLockDgdkNlpcRequest } from '../../../../models/request/danh-gia/dinh-ky/ICheckLockDgdkNlpcRequest';
import { sis_dgdk_lock } from '../../../../models/response/danh-gia/dinh-ky/sis_dgdk_lock';
import { IDgdkLockDetailSelectByLopBoMonRequest, IDgdkLockDetailSelectRequest } from '../../../../models/request/danh-gia/dinh-ky/IDgdkLockSelectRequest';
//IDgdkNlpc
//DGDK_NLPC_
export enum eDgdkNlpcActionTypeIds {
    CHANGE_FILTER = "DGDK_NLPC_CHANGE_FILTER",

    HOCSINH_LOAD_START = "DGDK_NLPC_HOCSINH_LOAD_START",
    HOCSINH_LOAD_SUCCESS = "DGDK_NLPC_HOCSINH_LOAD_SUCCESS",
    HOCSINH_LOAD_ERROR = "DGDK_NLPC_HOCSINH_LOAD_ERROR",

    DM_NLPC_LOAD_START = "DM_NLPC_LOAD_START",
    DM_NLPC_LOAD_SUCCESS = "DM_NLPC_LOAD_SUCCESS",
    DM_NLPC_LOAD_ERROR = "DM_NLPC_LOAD_ERROR",

    KETQUA_LOAD_START = "DGDK_NLPC_KETQUA_LOAD_START",
    KETQUA_LOAD_SUCCESS = "DGDK_NLPC_KETQUA_LOAD_SUCCESS",
    KETQUA_LOAD_ERROR = "DGDK_NLPC_KETQUA_LOAD_ERROR",

    KETQUA_SAVE_START = "DGDK_NLPC_KETQUA_SAVE_START",
    KETQUA_SAVE_SUCCESS = "DGDK_NLPC_KETQUA_SAVE_SUCCESS",
    KETQUA_SAVE_ERROR = "DGDK_NLPC_KETQUA_SAVE_ERROR",

    VALIDATE_IMPORT_START = "DGDK_NLPC_VALIDATE_IMPORT_START",
    VALIDATE_IMPORT_SUCCESS = "DGDK_NLPC_VALIDATE_IMPORT_SUCCESS",
    VALIDATE_IMPORT_ERRROR = "DGDK_NLPC_VALIDATE_IMPORT_ERRROR",

    IMPORT_START = "DGDK_NLPC_IMPORT_START",
    IMPORT_SUCCESS = "DGDK_NLPC_IMPORT_SUCCESS",
    IMPORT_ERRROR = "DGDK_NLPC_IMPORT_ERRROR",

    LOCK_CHECK_START = "DGDK_NLPC_LOCK_START",
    LOCK_CHECK_SUCCESS = "DGDK_NLPC_LOCK_SUCCESS",
    LOCK_CHECK_ERRROR = "DGDK_NLPC_LOCK_ERRROR",

    LOCKLOPBOMON_CHECK_START = "DGDK_NLPC_LOCKLOPBOMON_START",
    LOCKLOPBOMON_CHECK_SUCCESS = "DGDK_NLPC_LOCKLOPBOMON_SUCCESS",
    LOCKLOPBOMON_CHECK_ERRROR = "DGDK_NLPC_LOCKLOPBOMON_ERRROR",
}
export interface IDgdkNlpcChangeFilter extends IActionTypeBase<eDgdkNlpcActionTypeIds.CHANGE_FILTER, INamHocHocKyTruongKhoiLopGiaiDoanFilter> { }

export interface IDgdkNlpcHocSinhLoadStart extends IActionTypeBase<eDgdkNlpcActionTypeIds.HOCSINH_LOAD_START, IHocSinhSelectRequest> { }
export interface IDgdkNlpcHocSinhLoadSuccess extends IActionTypeBase<eDgdkNlpcActionTypeIds.HOCSINH_LOAD_SUCCESS, IHocSinhSelectResponeItem[]> { }
export interface IDgdkNlpcHocSinhLoadError extends IActionTypeBase<eDgdkNlpcActionTypeIds.HOCSINH_LOAD_ERROR, string> { }

export interface IDgdkNlpcDmLoadStart extends IActionTypeBase<eDgdkNlpcActionTypeIds.DM_NLPC_LOAD_START, number> { }
export interface IDgdkNlpcDmLoadSuccess extends IActionTypeBase<eDgdkNlpcActionTypeIds.DM_NLPC_LOAD_SUCCESS, ISoNhanXetItem[]> { }
export interface IDgdkNlpcDmLoadError extends IActionTypeBase<eDgdkNlpcActionTypeIds.DM_NLPC_LOAD_ERROR, string> { }

export interface IDgdkNlpcLoadKetQuaStart extends IActionTypeBase<eDgdkNlpcActionTypeIds.KETQUA_LOAD_START, ISelectDgdkNlpcRequest> { }
export interface IDgdkNlpcLoadKetQuaSuccess extends IActionTypeBase<eDgdkNlpcActionTypeIds.KETQUA_LOAD_SUCCESS, IDgdkKetQuaNlpc[]> { }
export interface IDgdkNlpcLoadKetQuaError extends IActionTypeBase<eDgdkNlpcActionTypeIds.KETQUA_LOAD_ERROR, string> { }

export interface IDgdkNlpcSaveKetQuaStart extends IActionTypeBase<eDgdkNlpcActionTypeIds.KETQUA_SAVE_START, IUpdateDgdkNlpcRequest> { }
export interface IDgdkNlpcSaveKetQuaSuccess extends IActionTypeBase<eDgdkNlpcActionTypeIds.KETQUA_SAVE_SUCCESS, IDgdkKetQuaNlpc> { }
export interface IDgdkNlpcSaveKetQuaError extends IActionTypeBase<eDgdkNlpcActionTypeIds.KETQUA_LOAD_ERROR, string> { }

export interface IDgdkNlpcValidateStart extends IActionTypeBase<eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_START, ImportDgdkNlpcRequest> { }
export interface IDgdkNlpcValidateSuccess extends IActionTypeBase<eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_SUCCESS, any> { }
export interface IDgdkNlpcValidateError extends IActionTypeBase<eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_ERRROR, any> { }

export interface IDgdkNlpcImportStart extends IActionTypeBase<eDgdkNlpcActionTypeIds.IMPORT_START, ImportDgdkNlpcRequest> { }
export interface IDgdkNlpcImportSuccess extends IActionTypeBase<eDgdkNlpcActionTypeIds.IMPORT_SUCCESS, undefined> { }
export interface IDgdkNlpcImportError extends IActionTypeBase<eDgdkNlpcActionTypeIds.IMPORT_ERRROR, any> { }

export interface IDgdkNlpcLockCheckStart extends IActionTypeBase<eDgdkNlpcActionTypeIds.LOCK_CHECK_START, IDgdkLockDetailSelectRequest> { }
export interface IDgdkNlpcLockCheckSuccess extends IActionTypeBase<eDgdkNlpcActionTypeIds.LOCK_CHECK_SUCCESS, sis_dgdk_lock> { }
export interface IDgdkNlpcLockCheckError extends IActionTypeBase<eDgdkNlpcActionTypeIds.LOCK_CHECK_ERRROR, any> { }

export interface IDgdkNlpcLockLopBoMonStart extends IActionTypeBase<eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_START, IDgdkLockDetailSelectByLopBoMonRequest> { }
export interface IDgdkNlpcLockLopBoMonSuccess extends IActionTypeBase<eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_SUCCESS, sis_dgdk_lock> { }
export interface IDgdkNlpcLockLopBoMonError extends IActionTypeBase<eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_ERRROR, any> { }

export type IDgdkNlpcActionTypes = IDgdkNlpcChangeFilter |
    IDgdkNlpcHocSinhLoadStart | IDgdkNlpcHocSinhLoadSuccess | IDgdkNlpcHocSinhLoadError |
    IDgdkNlpcDmLoadStart | IDgdkNlpcDmLoadSuccess | IDgdkNlpcDmLoadError |
    IDgdkNlpcLoadKetQuaStart | IDgdkNlpcLoadKetQuaSuccess | IDgdkNlpcLoadKetQuaError |
    IDgdkNlpcSaveKetQuaStart | IDgdkNlpcSaveKetQuaSuccess | IDgdkNlpcSaveKetQuaError |
    IDgdkNlpcValidateStart | IDgdkNlpcValidateSuccess | IDgdkNlpcValidateError |
    IDgdkNlpcImportStart | IDgdkNlpcImportSuccess | IDgdkNlpcImportError |
    IDgdkNlpcLockCheckStart | IDgdkNlpcLockCheckSuccess | IDgdkNlpcLockCheckError |
    IDgdkNlpcLockLopBoMonStart | IDgdkNlpcLockLopBoMonSuccess | IDgdkNlpcLockLopBoMonError