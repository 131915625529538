import { sis_thanhphandiem } from "../../../models/response/so-diem/sis_thanhphandiem"

export enum eThanhPhanDiemActionTypeIds {
    LOAD_START = "THANH_PHAN_DIEM_LOAD_START",
    LOAD_SUCCESS = "THANH_PHAN_DIEM_LOAD_SUCCESS",
    LOAD_ERRROR = "THANH_PHAN_DIEM_LOAD_ERRROR"
}
export interface IThanhPhanDiemLoadStart {
    type: eThanhPhanDiemActionTypeIds.LOAD_START
}
export interface IThanhPhanDiemLoadSuccess {
    type: eThanhPhanDiemActionTypeIds.LOAD_SUCCESS,
    payload: sis_thanhphandiem[]
}
export interface IThanhPhanDiemLoadError {
    type: eThanhPhanDiemActionTypeIds.LOAD_ERRROR,
    payload: string
}
export type IThanhPhanDiemActionTypes = IThanhPhanDiemLoadStart |
    IThanhPhanDiemLoadSuccess |
    IThanhPhanDiemLoadError