import { DiemDanhAnResponse } from "../../../models/response/diem-danh-an/DiemDanhAnResponse"
import { IDiemDanhAnConfigSelectRequest } from "../../../models/request/diem-danh-an/IDiemDanhAnConfigSelectRequest"
import { IDiemDanhAnLopChuNhiemSelectRequest } from "../../../models/request/diem-danh-an/IDiemDanhAnLopChuNhiemSelectRequest"
import { IDiemDanhAnLopChuNhiemChangeRequest } from "../../../models/request/diem-danh-an/IDiemDanhAnLopChuNhiemChangeRequest"
import { IDiemDanhAnConfigResponse } from "../../../models/response/diem-danh-an/DiemDanhAnConfigResponse"
import { ILopDiemDanhAnResponse } from "../../../models/response/diem-danh-an/LopDiemDanhAnResponse"
import { IActionTypeBase } from "../IActionTypeBase"

export enum eDiemDanhAnActionTypeIds {
    SELECT_CONFIG_START = "DIEMDANH_AN_CONFIG_LOPCHUNHIEM_START",
    SELECT_CONFIG_SUCCESS = "DIEMDANH_AN_CONFIG_LOPCHUNHIEM_SUCCESS",
    SELECT_CONFIG_ERROR = "DIEMDANH_AN_CONFIG_LOPCHUNHIEM_ERROR",

    SELECT_LOPCHUNHIEM_START = "DIEMDANH_AN_SELECT_LOPCHUNHIEM_START",
    SELECT_LOPCHUNHIEM_SUCCESS = "DIEMDANH_AN_SELECT_LOPCHUNHIEM_SUCCESS",
    SELECT_LOPCHUNHIEM_ERROR = "DIEMDANH_AN_SELECT_LOPCHUNHIEM_ERROR",

    CHANGE_DANHSACH_DIEMDANH = "CHANGE_DANHSACH_DIEMDANH",
    SHOW_DIEMDANH_AN_MODAL = "SHOW_DIEMDANH_AN_MODAL",

    DIEMDANH_AN_LOPCHUNHIEM_START = "DIEMDANH_AN_LOPCHUNHIEM_START",
    DIEMDANH_AN_LOPCHUNHIEM_SUCCESS = "DIEMDANH_AN_LOPCHUNHIEM_SUCCESS",
    DIEMDANH_AN_LOPCHUNHIEM_ERROR = "DIEMDANH_AN_LOPCHUNHIEM_ERROR",

    CHANGE_SELECTED_IDS = "DIEMDANH_AN_CHANGE_SELECTED_IDS",
}
export interface IDiemDanhAnSelectConfigStart extends IActionTypeBase<eDiemDanhAnActionTypeIds.SELECT_CONFIG_START, IDiemDanhAnConfigSelectRequest> { }
export interface IDiemDanhAnSelectConfigSuccess extends IActionTypeBase<eDiemDanhAnActionTypeIds.SELECT_CONFIG_SUCCESS, ILopDiemDanhAnResponse[]> { }
export interface IDiemDanhAnSelectConfigErr extends IActionTypeBase<eDiemDanhAnActionTypeIds.SELECT_CONFIG_ERROR, string> { }

export interface IDiemDanhAnSelectLopChuNhiemStart extends IActionTypeBase<eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_START, IDiemDanhAnLopChuNhiemSelectRequest> { }
export interface IDiemDanhAnSelectLopChuNhiemSuccess extends IActionTypeBase<eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS, DiemDanhAnResponse[]> { }
export interface IDiemDanhAnSelectLopChuNhiemErr extends IActionTypeBase<eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_ERROR, string> { }

export interface IDiemDanhAnShowModal extends IActionTypeBase<eDiemDanhAnActionTypeIds.SHOW_DIEMDANH_AN_MODAL, boolean> { }
export interface IDiemDanhAnChangeDanhSachDiemDanh extends IActionTypeBase<eDiemDanhAnActionTypeIds.CHANGE_DANHSACH_DIEMDANH, DiemDanhAnResponse[]> { }

export interface IDiemDanhAnLopChuNhiemStart extends IActionTypeBase<eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_START, IDiemDanhAnLopChuNhiemChangeRequest> { }
export interface IDiemDanhAnLopChuNhiemSuccess extends IActionTypeBase<eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_SUCCESS, undefined> { }
export interface IDiemDanhAnLopChuNhiemErr extends IActionTypeBase<eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_ERROR, string> { }

export interface IDiemDanhAnChangeSelectedIds extends IActionTypeBase<eDiemDanhAnActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }

export type IDiemDanhAnActionTypes = IDiemDanhAnSelectConfigStart | IDiemDanhAnSelectConfigSuccess | IDiemDanhAnSelectConfigErr |
    IDiemDanhAnSelectLopChuNhiemStart | IDiemDanhAnSelectLopChuNhiemSuccess | IDiemDanhAnSelectLopChuNhiemErr |
    IDiemDanhAnShowModal | IDiemDanhAnChangeDanhSachDiemDanh |
    IDiemDanhAnLopChuNhiemStart | IDiemDanhAnLopChuNhiemSuccess | IDiemDanhAnLopChuNhiemErr |
    IDiemDanhAnChangeSelectedIds