import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_tokhoi, sis_tokhoi_view } from "../../../models/response/to-khoi/sis_tokhoi";
import { eToKhoiActionTypeIds, IToKhoiChangeSelectedIds, IToKhoiCloseConfirm, IToKhoiCloseDetail, 
    IToKhoiDeleteErr, IToKhoiDeleteStart, IToKhoiDeleteSuccess, 
    IToKhoiLoadErr, IToKhoiLoadStart, IToKhoiLoadSuccess, 
    IToKhoiLoadSelectViewErr, IToKhoiLoadSelectViewStart, IToKhoiLoadSelectViewSuccess, 
    IToKhoiShowConfirm, IToKhoiShowDetail } from "../../action-types/IToKhoiActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const toKhoiActions = {
    loadToKhoiStart: () => BaseAction(undefined, eToKhoiActionTypeIds.LOAD_START),
    loadToKhoiSuccess: (respone: sis_tokhoi_view[]): IToKhoiLoadSuccess => BaseAction(respone, eToKhoiActionTypeIds.LOAD_SUCCESS),
    loadToKhoiError: (messege: string): IToKhoiLoadErr => BaseAction(messege, eToKhoiActionTypeIds.LOAD_ERROR),
    showToKhoiDetail: (data?: sis_tokhoi): IToKhoiShowDetail => BaseAction(data, eToKhoiActionTypeIds.SHOW_DETAIL),
    closeToKhoiDetail: (): IToKhoiCloseDetail => BaseAction(undefined, eToKhoiActionTypeIds.CLOSE_DETAIL),
    showToKhoiConfirm: (): IToKhoiShowConfirm => BaseAction(undefined, eToKhoiActionTypeIds.SHOW_CONFIRM),
    closeToKhoiConfirm: (): IToKhoiCloseConfirm => BaseAction(undefined, eToKhoiActionTypeIds.CLOSE_CONFIRM),
    changeToKhoiSelectedIds: (ids: number[]): IToKhoiChangeSelectedIds => BaseAction(ids, eToKhoiActionTypeIds.CHANGE_SELECTED_IDS),
    deleteToKhoiStart: (ids: IDeleteMultipleBaseRequest): IToKhoiDeleteStart => BaseAction(ids, eToKhoiActionTypeIds.DELETE_START),
    deleteToKhoiSuccess: (): IToKhoiDeleteSuccess => BaseAction(undefined, eToKhoiActionTypeIds.DELETE_SUCCESS),
    deleteToKhoiErr: (message: string): IToKhoiDeleteErr => BaseAction(message, eToKhoiActionTypeIds.DELETE_ERROR),
    
    loadToKhoiSelectViewStart: () => BaseAction(undefined, eToKhoiActionTypeIds.LOAD_SELECT_VIEW_START),
    loadToKhoiSelectViewSuccess: (respone: sis_tokhoi_view[]): IToKhoiLoadSelectViewSuccess => BaseAction(respone, eToKhoiActionTypeIds.LOAD_SELECT_VIEW_SUCCESS),
    loadToKhoiSelectViewError: (messege: string): IToKhoiLoadSelectViewErr => BaseAction(messege, eToKhoiActionTypeIds.LOAD_SELECT_VIEW_ERROR),
    //SAVE
    saveToKhoiStart: (data: sis_tokhoi) => BaseAction(data, eToKhoiActionTypeIds.SAVE_START),
    saveToKhoiSuccess: () => BaseAction(undefined, eToKhoiActionTypeIds.SAVE_SUCCESS),
    saveToKhoiError: (message: string) =>  BaseAction(message, eToKhoiActionTypeIds.SAVE_ERR)
}