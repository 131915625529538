import { INotifySelectRequest } from './../models/request/notify/INotifySelectRequest';
import { apiClient } from "./apiClient";

export const notifyApi = {
    select: (request: INotifySelectRequest) => {
        const uri = `notify?search_key=${request.search_key}&paging.page_number=${request.paging.page_number}&paging.page_size=${request.paging.page_size}`;
        return apiClient.get(uri);
    },
    markAsRead: (notifyId: number) => apiClient.post(`notify/mark-as-read/${notifyId}`),
    markAllAsRead: () => apiClient.post(`notify/mark-all-as-read`),
    countUnReadTotal: () => apiClient.get(`notify/unread/total`),
}