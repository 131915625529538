import { IHocSinhHoTroModel } from "../../../models/response/hoc-sinh/IHocSinhHoTroModel";
import { eHocSinhHoTroActionTypeIds } from "../../action-types/hoc-sinh/IHocSinhHoTroActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const hocSinhHoTroActions = {
    loadByTruongStart: (dm_truong_id: number) => BaseAction(dm_truong_id, eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_START),
    loadByTruongSuccess: (response: IHocSinhHoTroModel[]) => BaseAction(response, eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_SUCCESS),
    loadByTruongError: (response: string) => BaseAction(response, eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_ERROR),

    saveStart: (data: IHocSinhHoTroModel) => BaseAction(data, eHocSinhHoTroActionTypeIds.SAVE_START),
    saveSuccess: () => BaseAction(undefined, eHocSinhHoTroActionTypeIds.SAVE_SUCCESS),
    saveError: (response: string) => BaseAction(response, eHocSinhHoTroActionTypeIds.SAVE_ERROR),
};