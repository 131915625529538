import { ILocalizedResourceReducer } from '../../page-state-model/localized-resource/ILocalizeResourceReducer';
import { ePageBaseStatus } from './../../../models/ePageBaseStatus';
import { eLocalizedResourceActionTypeIds, ILocalizedResourceActionTypes } from './../../action-types/localized-resource/ILocalizedResourceActions';
const iniState: ILocalizedResourceReducer = {
    localized_resources: new Map<string,string>(),
    status: ePageBaseStatus.is_not_initialization
}
export const localizedResourceReducer = (state: ILocalizedResourceReducer=iniState, action: ILocalizedResourceActionTypes): ILocalizedResourceReducer => {
    switch (action.type) {
        case eLocalizedResourceActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLocalizedResourceActionTypeIds.LOAD_SUCCESS:
            // console.log({
            //     localized_resources: action.payload
            // });
            
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                localized_resources: action.payload
            }
        case eLocalizedResourceActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }

        default:
            return { ...state }
    }
}