import { NotifyHelper, showNotify } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eFormKhaoSatActionTypeIds, IFormKhaoSatActionTypes } from "../../action-types/form-khaosat/IFormKhaoSatActionTypes";
import { IFormKhaoSatPageStateModel } from "../../page-state-model/form-khaosat/IFormKhaoSatState";

const initialState: IFormKhaoSatPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_form_khaosats: [],
    sis_form_khaosat_selected_ids: [],
    sis_form_khaosat_editing: undefined,
    is_show_confirm: false,
    is_show_modal: false
}
export const formKhaoSatReducer = (state: IFormKhaoSatPageStateModel = initialState, action: IFormKhaoSatActionTypes): IFormKhaoSatPageStateModel => {
    switch (action.type) {
        case eFormKhaoSatActionTypeIds.LOAD_START:
        case eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eFormKhaoSatActionTypeIds.LOAD_SUCCESS:
        case eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosats: action.payload
            }
        case eFormKhaoSatActionTypeIds.LOAD_ERROR:
        case eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosats: []
            }
        case eFormKhaoSatActionTypeIds.CHANGE_DETAIL:
            return {
                ...state,
                sis_form_khaosat_editing: action.payload
            }
        case eFormKhaoSatActionTypeIds.SHOW_MODAL:
            return {
                ...state,
                is_show_modal: action.payload,
                sis_form_khaosat_selected_ids: []
            }
        case eFormKhaoSatActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_form_khaosat_selected_ids: action.payload
            }
        case eFormKhaoSatActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_confirm: true
            }
        case eFormKhaoSatActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eFormKhaoSatActionTypeIds.DELETE_SUCCESS:
            showNotify({ type: "success", message: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_confirm: false
            }
        case eFormKhaoSatActionTypeIds.DELETE_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_confirm: false
            }


        case eFormKhaoSatActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eFormKhaoSatActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case eFormKhaoSatActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_modal: false
            }
        case eFormKhaoSatActionTypeIds.CLEAR_STATUS:
            return {
                ...initialState
            }
        default:
            return state;
    }

}
