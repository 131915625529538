import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eThongTuActionTypeIds, IThongTuActionTypes } from "../../action-types/danh-muc/IThongTuActionTypes"
import { IThongTuPageState } from "../../page-state-model/danh-muc/IThongTuPageState"

const initialState: IThongTuPageState = {
    status: ePageBaseStatus.is_not_initialization,
    sis_thongtus: [],
    sis_thongtu_selected_ids: [],
    sis_thongtu_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const thongTuReducer = (state: IThongTuPageState = initialState, action: IThongTuActionTypes): IThongTuPageState => {
    switch (action.type) {
        case eThongTuActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eThongTuActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thongtus: action.payload
            }
        case eThongTuActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_thongtus: []
            }
        case eThongTuActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_thongtu_editing: action.payload
            }
        case eThongTuActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_thongtu_editing: undefined
            }
        case eThongTuActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_thongtu_selected_ids: action.payload
            }
        case eThongTuActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eThongTuActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        
            case eThongTuActionTypeIds.DELETE_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_deleting
                }
            case eThongTuActionTypeIds.DELETE_SUCCESS:
                NotifyHelper.Success("Xoá dữ liệu thành công.")
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }
            case eThongTuActionTypeIds.DELETE_ERROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }

        
            case eThongTuActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eThongTuActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }
        case eThongTuActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }

        default:
            return state;
    }

}