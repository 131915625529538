import { INamHocHocKyTruongKhoiLopThangFilter } from '../../../../models/INamHocTruongKhoiLopThangFilter';
import { IBaoCaoThuongXuyenSelectRequest } from '../../../../models/request/bao-cao-thuong-xuyen/IBaoCaoTienBoSelectRequest';
import { ImportDgdkNlpcRequest } from '../../../../models/request/danh-gia/dinh-ky/IImportDgdkNlpcRequest';
import { ImportDgtxNlpcRequest } from '../../../../models/request/danh-gia/dinh-ky/ImportDgtxNlpcRequest';
import { ISelectDgtxKetQuaRequest } from '../../../../models/request/danh-gia/thuong-xuyen/ISelectDgtxKetQuaRequest';
import { IUpdateDgtxKetQuaRequest } from '../../../../models/request/danh-gia/thuong-xuyen/IUpdateDgtxKetQuaRequest';
import { IHocSinhSelectRequest } from '../../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { IBaoCaoThuongXuyen } from '../../../../models/response/danh-gia/thuong-xuyen/IBaoCaoThuongXuyen';
import { IDgtxKetQua } from '../../../../models/response/danh-gia/thuong-xuyen/IDgtxKetQua';
import { ISoNhanXetItem } from '../../../../models/response/dgdk-dm/ISoNhanXetItem';
import { IHocSinhSelectResponeItem } from '../../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { IActionTypeBase } from '../../IActionTypeBase';
//IDgtxKetQua
//DGTX_NLPC_
export enum eDgtxKetQuaActionTypeIds {
    CHANGE_FILTER = "DGTX_NLPC_CHANGE_FILTER",

    HOCSINH_LOAD_START = "DGTX_NLPC_HOCSINH_LOAD_START",
    HOCSINH_LOAD_SUCCESS = "DGTX_NLPC_HOCSINH_LOAD_SUCCESS",
    HOCSINH_LOAD_ERROR = "DGTX_NLPC_HOCSINH_LOAD_ERROR",

    SONHANXET_ITEM_LOAD_START = "DGTX_NLPC_DM_NLPC_LOAD_START",
    SONHANXET_ITEM_LOAD_SUCCESS = "DGTX_NLPC_DM_NLPC_LOAD_SUCCESS",
    SONHANXET_ITEM_LOAD_ERROR = "DGTX_NLPC_DM_NLPC_LOAD_ERROR",

    KETQUA_LOAD_START = "DGTX_NLPC_KETQUA_LOAD_START",
    KETQUA_LOAD_SUCCESS = "DGTX_NLPC_KETQUA_LOAD_SUCCESS",
    KETQUA_LOAD_ERROR = "DGTX_NLPC_KETQUA_LOAD_ERROR",

    KETQUA_SAVE_START = "DGTX_NLPC_KETQUA_SAVE_START",
    KETQUA_SAVE_SUCCESS = "DGTX_NLPC_KETQUA_SAVE_SUCCESS",
    KETQUA_SAVE_ERROR = "DGTX_NLPC_KETQUA_SAVE_ERROR",

    BCTX_LOAD_START = "DGTX_NLPC_BCTX_LOAD_START",
    BCTX_LOAD_SUCCESS = "DGTX_NLPC_BCTX_LOAD_SUCCESS",
    BCTX_LOAD_ERROR = "DGTX_NLPC_BCTX_LOAD_ERROR",

    IMPORT_START = "DGTX_NLPC_IMPORT_START",
    IMPORT_SUCCESS = "DGTX_NLPC_IMPORT_SUCCESS",
    IMPORT_ERRROR = "DGTX_NLPC_IMPORT_ERRROR",


    VALIDATE_IMPORT_START = "DGTX_NLPC_VALIDATE_IMPORT_START",
    VALIDATE_IMPORT_SUCCESS = "DGTX_NLPC_VALIDATE_IMPORT_SUCCESS",
    VALIDATE_IMPORT_ERRROR = "DGTX_NLPC_VALIDATE_IMPORT_ERRROR",

}
export interface IDgtxKetQuaChangeFilter extends IActionTypeBase<eDgtxKetQuaActionTypeIds.CHANGE_FILTER, INamHocHocKyTruongKhoiLopThangFilter> { }

export interface IDgtxKetQuaHocSinhLoadStart extends IActionTypeBase<eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_START, IHocSinhSelectRequest> { }
export interface IDgtxKetQuaHocSinhLoadSuccess extends IActionTypeBase<eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_SUCCESS, IHocSinhSelectResponeItem[]> { }
export interface IDgtxKetQuaHocSinhLoadError extends IActionTypeBase<eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_ERROR, string> { }

export interface IDgtxKetQuaDmLoadStart extends IActionTypeBase<eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_START, number> { }
export interface IDgtxKetQuaDmLoadSuccess extends IActionTypeBase<eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_SUCCESS, ISoNhanXetItem[]> { }
export interface IDgtxKetQuaDmLoadError extends IActionTypeBase<eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_ERROR, string> { }

export interface IDgtxKetQuaLoadKetQuaStart extends IActionTypeBase<eDgtxKetQuaActionTypeIds.KETQUA_LOAD_START, ISelectDgtxKetQuaRequest> { }
export interface IDgtxKetQuaLoadKetQuaSuccess extends IActionTypeBase<eDgtxKetQuaActionTypeIds.KETQUA_LOAD_SUCCESS, IDgtxKetQua[]> { }
export interface IDgtxKetQuaLoadKetQuaError extends IActionTypeBase<eDgtxKetQuaActionTypeIds.KETQUA_LOAD_ERROR, string> { }

export interface IDgtxKetQuaSaveKetQuaStart extends IActionTypeBase<eDgtxKetQuaActionTypeIds.KETQUA_SAVE_START, IUpdateDgtxKetQuaRequest> { }
export interface IDgtxKetQuaSaveKetQuaSuccess extends IActionTypeBase<eDgtxKetQuaActionTypeIds.KETQUA_SAVE_SUCCESS, IDgtxKetQua> { }
export interface IDgtxKetQuaSaveKetQuaError extends IActionTypeBase<eDgtxKetQuaActionTypeIds.KETQUA_LOAD_ERROR, string> { }

export interface IDgtxKetQuaLoadBaoCaoThuongXuyenStart extends IActionTypeBase<eDgtxKetQuaActionTypeIds.BCTX_LOAD_START, IBaoCaoThuongXuyenSelectRequest> { }
export interface IDgtxKetQuaLoadBaoCaoThuongXuyenSuccess extends IActionTypeBase<eDgtxKetQuaActionTypeIds.BCTX_LOAD_SUCCESS, IBaoCaoThuongXuyen[]> { }
export interface IDgtxKetQuaLoadBaoCaoThuongXuyenError extends IActionTypeBase<eDgtxKetQuaActionTypeIds.BCTX_LOAD_ERROR, string> { }



export interface IDgtxNlpcValidateStart extends IActionTypeBase<eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_START, ImportDgtxNlpcRequest> { }
export interface IDgtxNlpcValidateSuccess extends IActionTypeBase<eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_SUCCESS, any> { }
export interface IDgtxNlpcValidateError extends IActionTypeBase<eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_ERRROR, any> { }

export interface IDgtxNlpcImportStart extends IActionTypeBase<eDgtxKetQuaActionTypeIds.IMPORT_START, ImportDgtxNlpcRequest> { }
export interface IDgtxNlpcImportSuccess extends IActionTypeBase<eDgtxKetQuaActionTypeIds.IMPORT_SUCCESS, undefined> { }
export interface IDgtxNlpcImportError extends IActionTypeBase<eDgtxKetQuaActionTypeIds.IMPORT_ERRROR, any> { }

export type IDgtxKetQuaActionTypes = IDgtxKetQuaChangeFilter |
    IDgtxKetQuaHocSinhLoadStart | IDgtxKetQuaHocSinhLoadSuccess | IDgtxKetQuaHocSinhLoadError |
    IDgtxKetQuaDmLoadStart | IDgtxKetQuaDmLoadSuccess | IDgtxKetQuaDmLoadError |
    IDgtxKetQuaLoadKetQuaStart | IDgtxKetQuaLoadKetQuaSuccess | IDgtxKetQuaLoadKetQuaError |
    IDgtxKetQuaSaveKetQuaStart | IDgtxKetQuaSaveKetQuaSuccess | IDgtxKetQuaSaveKetQuaError |
    IDgtxKetQuaLoadBaoCaoThuongXuyenStart | IDgtxKetQuaLoadBaoCaoThuongXuyenSuccess | IDgtxKetQuaLoadBaoCaoThuongXuyenError |
    IDgtxNlpcValidateStart | IDgtxNlpcValidateSuccess | IDgtxNlpcValidateError |
    IDgtxNlpcImportStart | IDgtxNlpcImportSuccess | IDgtxNlpcImportError 
