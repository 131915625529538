import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_chamtiet_loaitiet } from "../../../models/response/cham-tiet/sis_chamtiet_loaitiet";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eChamTietLoaiTietActionTypeIds {
    LOAD_START = "LOAITIET_LOAD_START",
    LOAD_SUCCESS = "LOAITIET_LOAD_SUCCESS",
    LOAD_ERROR = "LOAITIET_LOAD_ERROR",

    LOAD_BYTRUONG_START = "LOAITIET_LOAD_BYTRUONG_START",
    LOAD_BYTRUONG_SUCCESS = "LOAITIET_LOAD_BYTRUONG_SUCCESS",
    LOAD_BYTRUONG_ERROR = "LOAITIET_LOAD_BYTRUONG_ERROR",

    SHOW_ADD_MODAL = "LOAITIET_SHOW_ADD_MODAL",
    SHOW_DETAIL = "LOAITIET_SHOW_DETAIL",
    CLOSE_DETAIL = "LOAITIET_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "LOAITIET_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "LOAITIET_SHOW_CONFIRM",
    CLOSE_CONFIRM = "LOAITIET_CLOSE_CONFIRM",

    DELETE_START = "LOAITIET_DELETE_START",
    DELETE_SUCCESS = "LOAITIET_DELETE_SUCCESS",
    DELETE_ERROR = "LOAITIET_DELETE_ERROR",

    SAVE_START = "LOAITIET_SAVE_START",
    SAVE_SUCCESS = "LOAITIET_SAVE_SUCCESS",
    SAVE_ERR = "LOAITIET_SAVE_ERR",

    CLEAR_STATUS = "LOAITIET_CLEAR_STATUS",
}
//ORIGIN MODEL
export interface IChamTietLoaiTietLoadStart extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.LOAD_START, undefined> { }
export interface IChamTietLoaiTietLoadSuccess extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.LOAD_SUCCESS, sis_chamtiet_loaitiet[]> { }
export interface IChamTietLoaiTietLoadErr extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.LOAD_ERROR, string> { }

export interface IChamTietLoaiTietLoadByTruongStart extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_START, number> { }
export interface IChamTietLoaiTietLoadByTruongSuccess extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_SUCCESS, sis_chamtiet_loaitiet[]> { }
export interface IChamTietLoaiTietLoadByTruongErr extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_ERROR, string> { }

//DETAIL
export interface IChamTietLoaiTietShowDetail extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.SHOW_DETAIL, sis_chamtiet_loaitiet | undefined> { }
export interface IChamTietLoaiTietCloseDetail extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.CLOSE_DETAIL, undefined> { }
export interface IChamTietLoaiTietChangeSelectedIds extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
//CONFIRM
export interface IChamTietLoaiTietShowAddModal extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.SHOW_ADD_MODAL, boolean> { }
export interface IChamTietLoaiTietShowConfirm extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.SHOW_CONFIRM, undefined> { }
export interface IChamTietLoaiTietCloseConfirm extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.CLOSE_CONFIRM, undefined> { }
//DELETE
export interface IChamTietLoaiTietDeleteStart extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.DELETE_START, IDeleteMultipleBaseRequest> { }
export interface IChamTietLoaiTietDeleteSuccess extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface IChamTietLoaiTietDeleteErr extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.DELETE_ERROR, string> { }
//SAVE
export interface IChamTietLoaiTietSaveStart extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.SAVE_START, sis_chamtiet_loaitiet> { }
export interface IChamTietLoaiTietSaveSuccess extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface IChamTietLoaiTietSaveErr extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.SAVE_ERR, string> { }

export interface IChamTietLoaiTietClearStatus extends IActionTypeBase<eChamTietLoaiTietActionTypeIds.CLEAR_STATUS, undefined> { }

export type IChamTietLoaiTietActionTypes =
    IChamTietLoaiTietLoadStart | IChamTietLoaiTietLoadSuccess | IChamTietLoaiTietLoadErr |
    IChamTietLoaiTietShowDetail | IChamTietLoaiTietCloseDetail |
    IChamTietLoaiTietChangeSelectedIds | IChamTietLoaiTietShowAddModal |
    IChamTietLoaiTietShowConfirm | IChamTietLoaiTietCloseConfirm |
    IChamTietLoaiTietDeleteStart | IChamTietLoaiTietDeleteSuccess | IChamTietLoaiTietDeleteErr |
    IChamTietLoaiTietSaveStart | IChamTietLoaiTietSaveSuccess | IChamTietLoaiTietSaveErr |
    IChamTietLoaiTietLoadByTruongStart | IChamTietLoaiTietLoadByTruongSuccess | IChamTietLoaiTietLoadByTruongErr  |
    IChamTietLoaiTietClearStatus