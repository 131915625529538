import { app_menu_nhom } from "../../models/response/app-phuhuynh/app_menu_nhom";
import { BaseCRUDApi } from "../baseCRUDApi";

export const API_APP_MENU_NHOM_PATH = "app-menu-nhom"

class AppMenuNhomApi extends BaseCRUDApi<app_menu_nhom> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
}
export const appMenuNhomApi = new AppMenuNhomApi({ path: API_APP_MENU_NHOM_PATH });