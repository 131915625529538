import { IReadUploadedExcelFileRequest } from "../models/request/upload-file/IReadUploadedExcelFileRequest";
import { apiClient } from "./apiClient"

const uploadApi = {
    uploadImage: (file: File) => {
        let formData = new FormData();
        formData.append("form_file", file);
        return apiClient.upload(`upload/image`, formData)
    },
    uploadImages: (files: File[] | FileList) => {
        let formData = new FormData();
        if(files){
            if (files.length > 0) {
                Array.from(files).map((file) => {
                    return formData.append("form_files", file);
                })
            }
        }
        return apiClient.upload(`upload/images`, formData)
    },
    uploadSvg: (file: File) => {
        let formData = new FormData();
        formData.append("form_file", file);
        return apiClient.upload(`upload/svg`, formData)
    },
    uploadExcel: (file: File) => {
        let formData = new FormData();
        formData.append('form_file', file);
        return apiClient.upload(`upload/excel`, formData)
    },
    readExcel(request: IReadUploadedExcelFileRequest) {
        return apiClient.post(`read/excel`, request)
    }

}
export { uploadApi }