import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eMappingActionTypeIds, IMappingActionTypes } from './../../action-types/mapping/IMappingActionTypes';
import { IMappingReducer, eMappingReducerStatus } from './../../page-state-model/mapping/IMappingReducer';
const iniState: IMappingReducer = {
    mappingId: 0,
    steps: [],
    status: ePageBaseStatus.is_not_initialization
}
export const mappingReducer = (state: IMappingReducer = iniState, action: IMappingActionTypes): IMappingReducer => {
    switch (action.type) {
        case eMappingActionTypeIds.SAVING_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eMappingActionTypeIds.SAVING_SUCCESS:
            return {
                ...state,
                status: eMappingReducerStatus.is_save_successed,
                mappingId: action.payload
            }
        case eMappingActionTypeIds.SAVING_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eMappingActionTypeIds.GET_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eMappingActionTypeIds.GET_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                steps: action.payload.steps
            }
        case eMappingActionTypeIds.GET_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                steps: []
            }
        case eMappingActionTypeIds.CLEAR_STATE:
            return {
                ...iniState
            }
        default:
            return { ...state }
    }
}