import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eTuanActionTypeIds, ITuanDeleteStart, ITuanGenerateStart, ITuanSaveStart, ITuanSelectStart } from "../../action-types/danh-muc/ITuanActionTypes";
import { actions } from "../../actions/actionsWrapper";

const tuanApi = apiWrapper.tuan;

export function* tuanSaga(): any {
    yield takeLatest(eTuanActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eTuanActionTypeIds.SELECT_START, selectWorker)
    yield takeEvery(eTuanActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eTuanActionTypeIds.SAVE_START, saveWorker)
    yield takeEvery(eTuanActionTypeIds.GENERATE_START, generateWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([tuanApi, tuanApi.SelectAll])
    if (res.is_success) {
        yield put(actions.danhMucWrapper.tuan.loadSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.tuan.loadError(res.message || "Không tải được tuần"))
    }
}
function* selectWorker(action: ITuanSelectStart): any {
    const res: IBaseResponeModel = yield call([tuanApi, tuanApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.danhMucWrapper.tuan.selectSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.tuan.selectError(res.message || "Không tải được tuần"))
    }
}
function* deleteWorker(action: ITuanDeleteStart): any {
    const res: IBaseResponeModel = yield call([tuanApi, tuanApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.danhMucWrapper.tuan.deleteSuccess())
    } else {
        yield put(actions.danhMucWrapper.tuan.deleteError(res.message || "Không xóa được tuần"))
    }
}
function* saveWorker(action: ITuanSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([tuanApi, tuanApi.Update], action.payload);
    } else {
        res = yield call([tuanApi, tuanApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.danhMucWrapper.tuan.saveSuccess())
    } else {
        yield put(actions.danhMucWrapper.tuan.saveError(res.message || "Không lưu được tuần"))
    }
}

function* generateWorker(data: ITuanGenerateStart): any {
    const res: IBaseResponeModel = yield call([tuanApi, tuanApi.Generate], data.payload)
    if (res.is_success) {
        yield put(actions.danhMucWrapper.tuan.generateSuccess())
    } else {
        yield put(actions.danhMucWrapper.tuan.generateError(res.message || "Không khởi tạo được tuần"))
    }
}