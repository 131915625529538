import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IKhaoSatDeleteStart, IKhaoSatLoadByTruongStart, IKhaoSatLoadKetQuaKhaoSatChiTietStart, IKhaoSatLoadKetQuaKhaosatStart, IKhaoSatLoadResultByTruongStart, IKhaoSatLoadResultDetailStart, IKhaoSatPushNotifyStart, IKhaoSatSaveStart, eKhaoSatActionTypeIds } from "../../action-types/form-khaosat/IKhaoSatActionTypes";

import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from "../../actions/actionsWrapper";
import { apiWrapper } from "../../../api/apiWrapper";

const KhaoSatApi = apiWrapper.formKhaoSatWrapper.khaoSat;

export function* khaoSatSaga(): any {
    yield takeLatest(eKhaoSatActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eKhaoSatActionTypeIds.LOAD_BYTRUONG_START, loadByTruongWorker)
    yield takeEvery(eKhaoSatActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eKhaoSatActionTypeIds.SAVE_START, saveWorker)
    yield takeEvery(eKhaoSatActionTypeIds.PUSHNOTIFY_START, pushNotifyWorker)
    yield takeEvery(eKhaoSatActionTypeIds.LOAD_RESULT_BYTRUONG_START, loadResultByTruongWorker)
    yield takeEvery(eKhaoSatActionTypeIds.LOAD_RESULT_DETAIL_START, loadResultDetailWorker)
    yield takeEvery(eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_START, loadKetQuaKhaoSatWorker)
    yield takeEvery(eKhaoSatActionTypeIds.LOAD_KETQUAKHAOSAT_CHITIET_START, loadKetQuaKhaoSatChiTietWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([KhaoSatApi, KhaoSatApi.SelectAll])
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadError(res.message || "No data"))
    }
}
function* loadByTruongWorker(action: IKhaoSatLoadByTruongStart): any {
    const res: IBaseResponeModel = yield call([KhaoSatApi, KhaoSatApi.SelectByTruongAsync], action.payload.dm_truong_id, action.payload.nam_hoc)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadByTruongSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadByTruongError(res.message || "No data"))
    }
}
function* loadResultByTruongWorker(action: IKhaoSatLoadResultByTruongStart): any {
    const res: IBaseResponeModel = yield call([KhaoSatApi, KhaoSatApi.SelectKhaoSatResultAsync], action.payload)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadResultByTruongSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadResultByTruongError(res.message || "No data"))
    }
}
function* loadKetQuaKhaoSatWorker(action: IKhaoSatLoadKetQuaKhaosatStart): any {
    const res: IBaseResponeModel = yield call([KhaoSatApi, KhaoSatApi.KetQuaKhaoSatResultAsync], action.payload)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadKetQuaKhaosatSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadKetQuaKhaosatError(res.message || "No data"))
    }
}
function* loadKetQuaKhaoSatChiTietWorker(action: IKhaoSatLoadKetQuaKhaoSatChiTietStart): any {
    const res: IBaseResponeModel = yield call([KhaoSatApi, KhaoSatApi.KetQuaKhaoSatResultDetailAsync], action.payload)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadKetQuaKhaoSatChiTietSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadKetQuaKhaoSatChiTietError(res.message || "No data"))
    }
}
function* loadResultDetailWorker(action: IKhaoSatLoadResultDetailStart): any {
    const res: IBaseResponeModel = yield call([KhaoSatApi, KhaoSatApi.SelectKhaoSatResultDetailAsync], action.payload)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadResultDetailSuccess(res.data))
    } else {
        yield put(actions.formKhaoSatWrapper.khaoSat.loadResultDetailError(res.message || "No data"))
    }
}
function* deleteWorker(action: IKhaoSatDeleteStart): any {
    const res: IBaseResponeModel = yield call([KhaoSatApi, KhaoSatApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.khaoSat.deleteSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.khaoSat.deleteErr(res.message || "No data"))
    }
}
function* saveWorker(action: IKhaoSatSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([KhaoSatApi, KhaoSatApi.Update], action.payload);
    } else {
        res = yield call([KhaoSatApi, KhaoSatApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.khaoSat.saveSuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.khaoSat.saveError(res.message || "Save fail"))
    }
}
function* pushNotifyWorker(action: IKhaoSatPushNotifyStart): any {
    const res: IBaseResponeModel = yield call([KhaoSatApi, KhaoSatApi.KhaoSatMarkAsync], action.payload)
    if (res.is_success) {
        yield put(actions.formKhaoSatWrapper.khaoSat.pushNotifySuccess())
    } else {
        yield put(actions.formKhaoSatWrapper.khaoSat.pushNotifyError(res.message || "Push notify fail"))
    }
}
