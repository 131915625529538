import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { ITongHopDinhKyPageState } from '../../page-state-model/tong-hop/ITongHopDinhKyPageState';
import { ITongHopDinhKyActionTypes, eTongHopDinhKyActionTypeIds } from './../../action-types/tong-hop/ITongHopDinhKyActionTypes';
const iniState: ITongHopDinhKyPageState = {
    filter: {
        dm_khoi_id: 0,
        dm_lop_id: 0,
        dm_truong_id: 0,
        nam_hoc: "",
        sis_giaidoan_id: 0,
        hoc_ky: 0
    },
    hocSinhs: [],
    monHocs: [],
    soNhanXets: [],
    ketQuaHocTaps: [],
    ketQuaNLPCs: [],
    isShowConfirm: false,
    loading: {
        hocSinhs: "",
        monHocs: "",
        ketQuas: ""
    },
    status: ePageBaseStatus.is_not_initialization
}
export const tongHopDinhKyReducer = (state: ITongHopDinhKyPageState = iniState, action: ITongHopDinhKyActionTypes): ITongHopDinhKyPageState => {
    switch (action.type) {
        case eTongHopDinhKyActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinhs: "isLoading"
                }
            }
        case eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_SUCCESS:
            return {
                ...state,
                hocSinhs: action.payload,
                loading: {
                    ...state.loading,
                    hocSinhs: ""
                }
            }
        case eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinhs: ""
                }
            }
        case eTongHopDinhKyActionTypeIds.MONHOC_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    monHocs: "isLoading"
                }
            }
        case eTongHopDinhKyActionTypeIds.MONHOC_LOAD_SUCCESS:
            return {
                ...state,
                monHocs: action.payload.monHocs,
                soNhanXets: action.payload.soNhanXets,
                loading: {
                    ...state.loading,
                    monHocs: ""
                }
            }
        case eTongHopDinhKyActionTypeIds.MONHOC_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    monHocs: ""
                }
            }
        case eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: "isLoading"
                }
            }
        case eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_SUCCESS:
            return {
                ...state,
                ketQuaHocTaps: action.payload.ketQuaHocTaps,
                ketQuaNLPCs: action.payload.ketQuaNlpcs,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                }
            }
        case eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                }
            }

        default:
            return {
                ...state
            }
    }
}