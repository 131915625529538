import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from "../../actions/actionsWrapper";
import { IAppYeuCauHoTroLoadDetailStart, IAppYeuCauHoTroLoadSelectStart, IAppYeuCauHoTroUpdatePhanHoiStart, 
    IAppYeuCauHoTroLoadGroupStart, IAppYeuCauHoTroLoadGroupDetailStart, IAppYeuCauHoTroSaveGroupStart,
    IAppYeuCauHoTroDeleteGroupStart,
    eAppYeuCauHoTroActionTypeIds } from "../../action-types/app-phuhuynh/IAppYeuCauHoTroActionTypes";

const appYeuCauHoTroApi = apiWrapper.appYeuCauHoTro;
function* loadSelectWorker(action: IAppYeuCauHoTroLoadSelectStart): any {

    const res: IBaseResponeModel = yield call([appYeuCauHoTroApi, appYeuCauHoTroApi.selectAsync], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.loadSelectSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.loadSelectError(res.message || "Không tải được yêu cầu hỗ trợ"))
    }
}

function* loadDetailWorker(action: IAppYeuCauHoTroLoadDetailStart): any {

    const res: IBaseResponeModel = yield call([appYeuCauHoTroApi, appYeuCauHoTroApi.selectDetailAsync], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.loadDetailSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.loadDetailError(res.message || "Không tải được yêu cầu hỗ trợ"))
    }
}


function* updatePhanHoiWorker(action: IAppYeuCauHoTroUpdatePhanHoiStart): any {
    const res: IBaseResponeModel = yield call([appYeuCauHoTroApi, appYeuCauHoTroApi.updateKetQuaAsync], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.updatePhanHoiSuccess())
    } else {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.updatePhanHoiError(res.message || "Không cập nhật được kết quả hỗ trợ"))
    }
}
function* loadSelectGroupWorker(action: IAppYeuCauHoTroLoadGroupStart): any {

    const res: IBaseResponeModel = yield call([appYeuCauHoTroApi, appYeuCauHoTroApi.selectAllGroupAsync], action.payload)
    if (res.is_success) {
        console.log(res);
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.loadGroupSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.loadGroupError(res.message || "Không tải được yêu cầu hỗ trợ"))
    }
}
function* saveGroupWorker(action: IAppYeuCauHoTroSaveGroupStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([appYeuCauHoTroApi, appYeuCauHoTroApi.updateGroupAsync], action.payload);
    } else {
        res = yield call([appYeuCauHoTroApi, appYeuCauHoTroApi.insertGroupAsync], action.payload);
    }
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.saveGroupSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.saveGroupError(res.message || "Không lưu được nhóm bài viết"))
    }
}
function* deleteGroupWorker(action: IAppYeuCauHoTroDeleteGroupStart): any {
    const res: IBaseResponeModel = yield call([appYeuCauHoTroApi, appYeuCauHoTroApi.DeleteMultipleGroupAsync], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.deleteGroupSuccess())
    } else {
        yield put(actions.appPhuHuynhWrapper.appYeuCauHoTro.deleteGroupError(res.message || "Không xóa được nhóm bài viết"))
    }
}
export function* appYeuCauHoTroSaga(): any {
    yield takeLatest(eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_START, loadSelectWorker)
    yield takeEvery(eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_START, loadDetailWorker)
    yield takeEvery(eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_START, updatePhanHoiWorker)
    yield takeLatest(eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_START, loadSelectGroupWorker)
    yield takeEvery(eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_START, deleteGroupWorker)
    yield takeEvery(eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_START, saveGroupWorker)
}