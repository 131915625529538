import { sis_pl_dotdangky_ketqua } from "../models/response/dangky-monhoc/sis_pl_dotdangky_ketqua";
import { sis_pl_dotdangky_ketqua_chitiet } from "../models/response/dangky-monhoc/sis_pl_dotdangky_ketqua_chitiet";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_DOTDANGKY_KETQUACHITIET_PATH = "dang-ky-mon-hoc/ket-qua-chi-tiet";

export const dotDangKyKetQuaChiTietApi = new class DotDangKyKetQuaChiTietApi extends BaseCRUDApi<sis_pl_dotdangky_ketqua_chitiet> {
    constructor(path: string) {
        super(path);
    }
    SelectByDotDangKyAsync(dotdangky_id: number) {
        return apiClient.get(`${this._path}/dot-dang-ky/${dotdangky_id}/select-ket-qua-dang-ky`);
    }
}(API_DOTDANGKY_KETQUACHITIET_PATH);
