import { IMonHocDangKyUpdateRequest } from "../models/request/dangky-monhoc/IDotDangKySelectRequest";
import { sis_pl_dotdangky_monhoc } from "../models/response/dangky-monhoc/sis_pl_dotdangky_monhoc";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_DOTDANGKY_MONHOC_PATH = "dang-ky-mon-hoc/mon-hoc";

export const dotDangKyMonHocApi = new class DotDangKyMonHocApi extends BaseCRUDApi<sis_pl_dotdangky_monhoc> {
    constructor(path: string) {
        super(path);
    }
    SelectByDotDangKyAsync(dotdangky_id: number) {
        return apiClient.get(`${this._path}/dot-dang-ky/${dotdangky_id}/select-mon-hoc`);
    }
    AddMonHocAsync(request: IMonHocDangKyUpdateRequest) {
        return apiClient.post(`${this._path}/add-mon-hoc`, request);
    }
}(API_DOTDANGKY_MONHOC_PATH);
