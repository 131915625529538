import { ISoDiemAddThanhPhanRequest } from '../../../models/request/so-diem/ISoDiemAddThanhPhanRequest';
import { ISoDiemRemoveThanhPhanRequest } from '../../../models/request/so-diem/ISoDiemRemoveThanhPhanRequest';
import { sis_sodiem_thanhphandiem } from './../../../models/response/so-diem/sis_sodiem_thanhphandiem';
export enum eSoDiemConfigActionTypeIds {
    LOAD_SODIEM_THANHPHAN_START = "SODIEM_CONFIG_LOAD_SODIEM_THANHPHAN_START",
    LOAD_SODIEM_THANHPHAN_SUCCESS = "SODIEM_CONFIG_LOAD_SODIEM_THANHPHAN_SUCCESSS",
    LOAD_SODIEM_THANHPHAN_ERROR = "SODIEM_CONFIG_LOAD_SODIEM_THANHPHAN_ERROR",

    SHOW_ADD_THANHPHAN_MODAL = "SODIEM_CONFIG_SHOW_ADD_THANHPHAN_MODAL",

    ADD_THANHPHAN_START = "SODIEM_CONFIG_ADD_THANHPHAN_START",
    ADD_THANHPHAN_SUCCESS = "SODIEM_CONFIG_ADD_THANHPHAN_SUCCESS",
    ADD_THANHPHAN_ERROR = "SODIEM_CONFIG_ADD_THANHPHAN_ERROR",

    SHOW_REMOVE_THANHPHAN_MODAL = "SODIEM_CONFIG_SHOW_REMOVE_THANHPHAN_MODAL",

    REMOVE_THANHPHAN_START = "SODIEM_CONFIG_REMOVE_THANHPHAN_START",
    REMOVE_THANHPHAN_SUCCESS = "SODIEM_CONFIG_REMOVE_THANHPHAN_SUCCESS",
    REMOVE_THANHPHAN_ERROR = "SODIEM_CONFIG_REMOVE_THANHPHAN_ERROR",

    CHANGE_DATA_SOURCE = "SODIEM_CONFIG_CHANGE_DATA_SOURCE",

    UPDATE_THANHPHAN_START = "SODIEM_CONFIG_UPDATE_THANHPHAN_START",
    UPDATE_THANHPHAN_SUCCESS = "SODIEM_CONFIG_UPDATE_THANHPHAN_SUCCESS",
    UPDATE_THANHPHAN_ERROR = "SODIEM_CONFIG_UPDATE_THANHPHAN_ERROR",

}
export interface ISoDiemConfigLoadThanhPhanStart {
    type: eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_START,
    payload: number
}
export interface ISoDiemConfigLoadThanhPhanSuccess {
    type: eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_SUCCESS,
    payload: sis_sodiem_thanhphandiem[]
}
export interface ISoDiemConfigLoadThanhPhanError {
    type: eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_ERROR,
    payload: string
}
export interface ISoDiemConfigShowAddThanhPhanModal {
    type: eSoDiemConfigActionTypeIds.SHOW_ADD_THANHPHAN_MODAL,
    payload: boolean
}

export interface ISoDiemConfigAddThahPhanStart {
    type: eSoDiemConfigActionTypeIds.ADD_THANHPHAN_START,
    payload: ISoDiemAddThanhPhanRequest
}
export interface ISoDiemConfigAddThahPhanSuccess {
    type: eSoDiemConfigActionTypeIds.ADD_THANHPHAN_SUCCESS,
}
export interface ISoDiemConfigAddThahPhanError {
    type: eSoDiemConfigActionTypeIds.ADD_THANHPHAN_ERROR,
    payload: string
}

export interface ISoDiemConfigShowRemoveThanhPhanModal {
    type: eSoDiemConfigActionTypeIds.SHOW_REMOVE_THANHPHAN_MODAL,
    payload: boolean
}

export interface ISoDiemConfigRemoveThanhPhanStart {
    type: eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_START,
    payload: ISoDiemRemoveThanhPhanRequest
}

export interface ISoDiemConfigRemoveThanhPhanSuccess {
    type: eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_SUCCESS,
}

export interface ISoDiemConfigRemoveThanhPhanError {
    type: eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_ERROR,
    payload: string
}


export interface ISoDiemConfigChangeDataSource {
    type: eSoDiemConfigActionTypeIds.CHANGE_DATA_SOURCE,
    payload: any[]
}

export interface ISoDiemConfigUpdateThanhPhanStart {
    type: eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_START,
    payload: sis_sodiem_thanhphandiem
}
export interface ISoDiemConfigUpdateThanhPhanSuccess {
    type: eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_SUCCESS
}

export interface ISoDiemConfigUpdateThanhPhanError {
    type: eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_ERROR,
    payload: string
}

export type ISoDiemConfigActionTypes = ISoDiemConfigLoadThanhPhanStart |
    ISoDiemConfigLoadThanhPhanSuccess | ISoDiemConfigLoadThanhPhanError |
    ISoDiemConfigShowAddThanhPhanModal |
    ISoDiemConfigAddThahPhanStart | ISoDiemConfigAddThahPhanSuccess | ISoDiemConfigAddThahPhanError |
    ISoDiemConfigShowRemoveThanhPhanModal |
    ISoDiemConfigRemoveThanhPhanStart | ISoDiemConfigRemoveThanhPhanSuccess | ISoDiemConfigRemoveThanhPhanError |
    ISoDiemConfigChangeDataSource |
    ISoDiemConfigUpdateThanhPhanStart | ISoDiemConfigUpdateThanhPhanSuccess | ISoDiemConfigUpdateThanhPhanError
