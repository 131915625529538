import { call, put, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { apiWrapper } from './../../../api/apiWrapper';
import { eHocSinhSelectionActionTypeIds, IHocSinhSelectionLoadStart } from './../../action-types/hoc-sinh/IHocSinhSelectionActionTypes';
import { actions } from './../../actions/actionsWrapper';
export function* hocSinhSelectionSaga(): any {
    yield takeLatest(eHocSinhSelectionActionTypeIds.LOAD_START, loadWorker)

}
function* loadWorker(data: IHocSinhSelectionLoadStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.hocSinh, apiWrapper.hocSinh.Select], data.payload)
    if (res.is_success) {
        yield put(actions.hocSinh.hocSinhSelection.loadSuccess(res.data))
    } else {
        yield put(actions.hocSinh.hocSinhSelection.loadErr(res.message || "Không tải được học sinh"))
    }
}
