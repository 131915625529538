import { call, put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from "../../../api/apiWrapper";
import { actions } from '../../actions/actionsWrapper';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { eBaiVietTrangThaiActionTypeIds } from './../../action-types/bai-viet/IBaiVietTrangThaiActionTypes';

const appBaiVietTrangThaiApi = apiWrapper.appBaiVietTrangThai;

export function* baiVietTrangThaiSaga(): any {
    yield takeLatest(eBaiVietTrangThaiActionTypeIds.LOAD_START, loadWorker)

}

function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([appBaiVietTrangThaiApi, appBaiVietTrangThaiApi.SelectAll])
    if (res.is_success) {
        yield put(actions.baiVietTrangThai.loadSuccess(res.data))
    } else {
        yield put(actions.baiVietTrangThai.loadError(res.message || "Không tải được danh mục trạng thái bài viết"))
    }
}
