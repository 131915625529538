import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { IDotDangKyMonHocActionTypes, eDotDangKyMonHocActionTypeIds } from "../../action-types/dangky-monhoc/IDotDangKyMonHocActionTypes"
import { IDotDangKyMonHocState } from "../../page-state-model/dangky-monhoc/IDotDangKyMonHocState"

const initialState: IDotDangKyMonHocState = {
    status: ePageBaseStatus.is_not_initialization,
    sis_pl_dotdangky_monhocs: [],
    sis_pl_dotdangky_monhoc_selected_ids: [],
    sis_pl_dotdangky_monhoc_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const dotDangKyMonHocReducer = (state: IDotDangKyMonHocState = initialState, action: IDotDangKyMonHocActionTypes): IDotDangKyMonHocState => {
    switch (action.type) {
        case eDotDangKyMonHocActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDotDangKyMonHocActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_pl_dotdangky_monhocs: action.payload
            }
        case eDotDangKyMonHocActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_pl_dotdangky_monhocs: []
            }
        case eDotDangKyMonHocActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_pl_dotdangky_monhoc_editing: action.payload
            }
        case eDotDangKyMonHocActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_pl_dotdangky_monhoc_editing: undefined
            }
        case eDotDangKyMonHocActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_pl_dotdangky_monhoc_selected_ids: action.payload
            }
        case eDotDangKyMonHocActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eDotDangKyMonHocActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eDotDangKyMonHocActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eDotDangKyMonHocActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eDotDangKyMonHocActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eDotDangKyMonHocActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDotDangKyMonHocActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eDotDangKyMonHocActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        case eDotDangKyMonHocActionTypeIds.ADDMONHOC_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }

        case eDotDangKyMonHocActionTypeIds.ADDMONHOC_SUCCESS:
            NotifyHelper.Success("Thêm môn học thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eDotDangKyMonHocActionTypeIds.ADDMONHOC_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}