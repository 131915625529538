import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eTKBChiTietActionTypeIds, ITKBChiTietActionTypes } from "../../action-types/thoi-khoa-bieu/ITKBChiTietActionTypes"
import { ITKBChiTietPageState } from "../../page-state-model/thoi-khoa-bieu/ITKBChiTietPageState"

const initialState: ITKBChiTietPageState = {
    status: ePageBaseStatus.is_not_initialization,
    pl_tkb_chitiets: [],
    pl_tkb_chitiet_selected_ids: [],
    pl_tkb_chitiet_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false,
    data_from_excel: undefined,
    previewListItems: [],
    list_loi_copys: []
}

export const thoiKhoaBieuChiTietReducer = (state: ITKBChiTietPageState = initialState, action: ITKBChiTietActionTypes): ITKBChiTietPageState => {
    switch (action.type) {
        case eTKBChiTietActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eTKBChiTietActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_tkb_chitiets: action.payload
            }
        case eTKBChiTietActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_tkb_chitiets: []
            }
        case eTKBChiTietActionTypeIds.SELECT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eTKBChiTietActionTypeIds.SELECT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                previewListItems: action.payload
            }
        case eTKBChiTietActionTypeIds.SELECT_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                previewListItems: []
            }
        case eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_tkb_chitiets: action.payload
            }
        case eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_tkb_chitiets: []
            }
        case eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                previewListItems: action.payload
            }
        case eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                previewListItems: []
            }
        case eTKBChiTietActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                pl_tkb_chitiet_editing: action.payload
            }
        case eTKBChiTietActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                pl_tkb_chitiet_editing: undefined
            }
        case eTKBChiTietActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                pl_tkb_chitiet_selected_ids: action.payload
            }
        case eTKBChiTietActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eTKBChiTietActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eTKBChiTietActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eTKBChiTietActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eTKBChiTietActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eTKBChiTietActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eTKBChiTietActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eTKBChiTietActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }
        case eTKBChiTietActionTypeIds.UPLOAD_EXCEL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eTKBChiTietActionTypeIds.UPLOAD_EXCEL_SUCCESS:
            NotifyHelper.Success("Load file excel thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                data_from_excel: action.payload
            }
        case eTKBChiTietActionTypeIds.UPLOAD_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eTKBChiTietActionTypeIds.UPLOAD_EXCEL_CLEAR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                data_from_excel: undefined
            }
        case eTKBChiTietActionTypeIds.SAVE_EXCEL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eTKBChiTietActionTypeIds.SAVE_EXCEL_SUCCESS:
            NotifyHelper.Success("Import dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                data_from_excel: action.payload
            }
        case eTKBChiTietActionTypeIds.SAVE_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eTKBChiTietActionTypeIds.DUPLICATE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eTKBChiTietActionTypeIds.DUPLICATE_SUCCESS:
            NotifyHelper.Success("Nhân bản thời khóa biểu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                list_loi_copys: action.payload
            }
        case eTKBChiTietActionTypeIds.DUPLICATE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                list_loi_copys: []
            }
        case eTKBChiTietActionTypeIds.DUPLICATE_CLEAR_ERROR:
            return {
                ...state,
                list_loi_copys: []
            }
        case eTKBChiTietActionTypeIds.DELETE_MULTIPLE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eTKBChiTietActionTypeIds.DELETE_MULTIPLE_SUCCESS:
            NotifyHelper.Success("Xóa thời khóa biểu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eTKBChiTietActionTypeIds.DELETE_MULTIPLE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return state;
    }

}