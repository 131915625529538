import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { ISoNhanXetItem, sis_dgdk_dm_nlpc_treeview } from "../../../models/response/dgdk-dm/ISoNhanXetItem";
import { eSoNhanXetItemActionTypeIds, ISoNhanXetItemChangeSelectedIds, ISoNhanXetItemCloseConfirm, ISoNhanXetItemCloseDetail, ISoNhanXetItemDeleteErr, ISoNhanXetItemDeleteStart, ISoNhanXetItemDeleteSuccess, ISoNhanXetItemLoadErr, ISoNhanXetItemLoadSuccess, ISoNhanXetItemShowConfirm, ISoNhanXetItemShowDetail } from "../../action-types/so-diem-tieu-hoc/ISoNhanXetItemActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const soNhanXetItemActions = {
    loadSoNhanXetItemStart: (dgdkDmNlpcSetId: number) => BaseAction(dgdkDmNlpcSetId, eSoNhanXetItemActionTypeIds.LOAD_START),
    loadSoNhanXetItemSuccess: (respone: sis_dgdk_dm_nlpc_treeview[]): ISoNhanXetItemLoadSuccess => BaseAction(respone, eSoNhanXetItemActionTypeIds.LOAD_SUCCESS),
    loadSoNhanXetItemError: (messege: string): ISoNhanXetItemLoadErr => BaseAction(messege, eSoNhanXetItemActionTypeIds.LOAD_ERROR),
    showSoNhanXetItemDetail: (data?: sis_dgdk_dm_nlpc_treeview): ISoNhanXetItemShowDetail => BaseAction(data, eSoNhanXetItemActionTypeIds.SHOW_DETAIL),
    closeSoNhanXetItemDetail: (): ISoNhanXetItemCloseDetail => BaseAction(undefined, eSoNhanXetItemActionTypeIds.CLOSE_DETAIL),
    showSoNhanXetItemConfirm: (): ISoNhanXetItemShowConfirm => BaseAction(undefined, eSoNhanXetItemActionTypeIds.SHOW_CONFIRM),
    closeSoNhanXetItemConfirm: (): ISoNhanXetItemCloseConfirm => BaseAction(undefined, eSoNhanXetItemActionTypeIds.CLOSE_CONFIRM),
    changeSoNhanXetItemSelectedIds: (ids: number[]): ISoNhanXetItemChangeSelectedIds => BaseAction(ids, eSoNhanXetItemActionTypeIds.CHANGE_SELECTED_IDS),
    deleteSoNhanXetItemStart: (ids: IDeleteMultipleBaseRequest): ISoNhanXetItemDeleteStart => BaseAction(ids, eSoNhanXetItemActionTypeIds.DELETE_START),
    deleteSoNhanXetItemSuccess: (): ISoNhanXetItemDeleteSuccess => BaseAction(undefined, eSoNhanXetItemActionTypeIds.DELETE_SUCCESS),
    deleteSoNhanXetItemErr: (message: string): ISoNhanXetItemDeleteErr => BaseAction(message, eSoNhanXetItemActionTypeIds.DELETE_ERROR),
    //SAVE
    saveSoNhanXetItemStart: (data: ISoNhanXetItem) => BaseAction(data, eSoNhanXetItemActionTypeIds.SAVE_START),
    saveSoNhanXetItemSuccess: () => BaseAction(undefined, eSoNhanXetItemActionTypeIds.SAVE_SUCCESS),
    saveSoNhanXetItemError: (message: string) => BaseAction(message, eSoNhanXetItemActionTypeIds.SAVE_ERR)
}


