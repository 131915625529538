import { sis_sodiem_thanhphandiem } from './../../../models/response/so-diem/sis_sodiem_thanhphandiem';
import { ISoDiemDetailRespone } from '../../../models/response/so-diem/ISoDiemDetailRespone';
import { ePageBaseStatus } from './../../../models/ePageBaseStatus';
import { IDiem } from './../../../models/response/diem/IDiem';
import { pl_lopbomon_hocsinh_viewmodel } from './../../../models/response/lop-bo-mon/pl_lopbomon_hocsinh';
export enum eNhapDiemPageState {
    is_need_reload_diems = "is_need_reload_diems"

}
export enum eNhapDiemViewMode {
    moet = "moet",
    subject = "subject"
}
export interface IINhapDiemPagaStateFilter {
    sis_monhoc_id: number,
    dm_khoi_id: number,
    pl_lopbomon_id: number,
}

export interface INhapDiemPagaState {
    status: ePageBaseStatus | eNhapDiemPageState,
    loading: {
        hocSinh: "pending" | "",
        soDiem: "pending" | "",
        diem: "pending" | ""
    },
    lopBoMonHocSinhs: pl_lopbomon_hocsinh_viewmodel[],
    diems: IDiem[],
    diems_chuyende: IDiem[],
    diems_moet: IDiem[],
    soDiemInfo?: ISoDiemDetailRespone,
    soDiemMoetInfo?: ISoDiemDetailRespone,
    filter: IINhapDiemPagaStateFilter,
    isShowMappingDiemBaiTapModal: boolean,
    isShowCalculatorModal: boolean,
    soDiemThanhPhanDiemCalculating?: sis_sodiem_thanhphandiem,
    viewMode: eNhapDiemViewMode,
    isShowConvertMoetConfirm: boolean,
    importData: any[]
}