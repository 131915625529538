import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { app_menu, app_menu_view } from "../../../models/response/app-phuhuynh/app_menu"

export enum eAppMenuActionTypeIds {
    LOAD_START = "APP_MENU_LOAD_START",
    LOAD_SUCCESS = "APP_MENU_LOAD_SUCCESS",
    LOAD_ERROR = "APP_MENU_LOAD_ERROR",

    SHOW_DETAIL = "APP_MENU_SHOW_DETAIL",
    CLOSE_DETAIL = "APP_MENU_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "APP_MENU_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "APP_MENU_SHOW_CONFIRM",
    CLOSE_CONFIRM = "APP_MENU_CLOSE_CONFIRM",

    DELETE_START = "APP_MENU_DELETE_START",
    DELETE_SUCCESS = "APP_MENU_DELETE_SUCCESS",
    DELETE_ERROR = "APP_MENU_DELETE_ERROR",

    SAVE_START = "APP_MENU_SAVE_START",
    SAVE_SUCCESS = "APP_MENU_SAVE_SUCCESS",
    SAVE_ERR = "APP_MENU_SAVE_ERR"
}

export interface IAppMenuLoadStart {
    type: eAppMenuActionTypeIds.LOAD_START,
}
export interface IAppMenuLoadSuccess {
    type: eAppMenuActionTypeIds.LOAD_SUCCESS,
    payload: app_menu_view[]
}
export interface IAppMenuLoadErr {
    type: eAppMenuActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IAppMenuShowDetail {
    type: eAppMenuActionTypeIds.SHOW_DETAIL,
    payload?: app_menu_view
}
export interface IAppMenuCloseDetail {
    type: eAppMenuActionTypeIds.CLOSE_DETAIL,
}
export interface IAppMenuChangeSelectedIds {
    type: eAppMenuActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IAppMenuShowConfirm {
    type: eAppMenuActionTypeIds.SHOW_CONFIRM
}
export interface IAppMenuCloseConfirm {
    type: eAppMenuActionTypeIds.CLOSE_CONFIRM
}

export interface IAppMenuDeleteStart {
    type: eAppMenuActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IAppMenuDeleteSuccess {
    type: eAppMenuActionTypeIds.DELETE_SUCCESS,
}
export interface IAppMenuDeleteErr {
    type: eAppMenuActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IAppMenuSaveStart {
    type: eAppMenuActionTypeIds.SAVE_START,
    payload: app_menu
}
export interface IAppMenuSaveSuccess {
    type: eAppMenuActionTypeIds.SAVE_SUCCESS
}
export interface IAppMenuSaveErr {
    type: eAppMenuActionTypeIds.SAVE_ERR,
    payload: string
}
export type IAppMenuActionTypes = IAppMenuLoadStart | IAppMenuLoadSuccess | IAppMenuLoadErr |
    IAppMenuShowDetail | IAppMenuCloseDetail |
    IAppMenuChangeSelectedIds |
    IAppMenuShowConfirm | IAppMenuCloseConfirm |
    IAppMenuDeleteStart | IAppMenuDeleteSuccess | IAppMenuDeleteErr |
    IAppMenuSaveStart | IAppMenuSaveSuccess | IAppMenuSaveErr