import { IBaiTapKetQuaItemRespone } from './../../../models/response/bai-tap/IBaiTapKetQuaItemRespone';
import { NotifyHelper } from './../../../helpers/toast';
import { IBaiTapTreeViewItem } from './../../../models/response/bai-tap/IBaiTapTreeViewItem';
import { eBaiTapActionTypeIds } from './../../action-types/bai-tap/IBaiTapActionType';
import { baseAction } from '../IActionBase';
import { IBaiTapSelectRequest } from './../../../models/request/bai-tap/IBaiTapSelectRequest';
import { IBaiTap } from '../../../models/response/bai-tap/IBaiTap';
import { IBaiTapKetQua } from '../../../models/response/bai-tap/IBaiTapKetQua';
export const baiTapActions = {
    loadTreeViewStart: (request: IBaiTapSelectRequest) => baseAction(eBaiTapActionTypeIds.LOAD_TREEVIEW_START, request),
    loadTreeViewSuccess: (data: IBaiTapTreeViewItem[]) => baseAction(eBaiTapActionTypeIds.LOAD_TREEVIEW_SUCCESS, data),
    loadTreeViewError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapActionTypeIds.LOAD_TREEVIEW_ERRR, message)
    },
    showDetailModal: (data?: IBaiTap) => baseAction(eBaiTapActionTypeIds.SHOW_DETAIL_MODAL, data),
    closeDetailModal: () => baseAction(eBaiTapActionTypeIds.CLOSE_DETAIL_MODAL, undefined),

    saveStart: (data: IBaiTap) => baseAction(eBaiTapActionTypeIds.SAVE_START, data),
    saveSuccess: () => {
        NotifyHelper.Success("Cập nhật thành công")
        return baseAction(eBaiTapActionTypeIds.SAVE_SUCCESS, undefined)
    },
    saveError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapActionTypeIds.SAVE_ERROR, message)
    },
    changeSelected: (data: IBaiTapTreeViewItem) => baseAction(eBaiTapActionTypeIds.CHANGE_SELECTED, data),

    
    showDeleteConfirm: (baiTap: IBaiTap) => baseAction(eBaiTapActionTypeIds.SHOW_DELETE_CONFIRM, baiTap),
    closeDeleteConfirm: () => baseAction(eBaiTapActionTypeIds.CLOSE_DELETE_CONFIRM, undefined),

    deleteStart: (baiTapId: number) => baseAction(eBaiTapActionTypeIds.DELETE_START, baiTapId),
    deleteSuccess: () => baseAction(eBaiTapActionTypeIds.DELETE_SUCCESS, undefined),
    deleteError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapActionTypeIds.DELETE_ERROR, message)
    },

    changeExpandNodeKeys: (keys: string[]) => baseAction(eBaiTapActionTypeIds.CHANGE_EXPANDED_NODE_KEYS, keys)
}