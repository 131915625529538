import { INamHocHocKyTruongKhoiLopGiaiDoanFilter } from '../../../../models/INamHocTruongKhoiLopGiaiDoanFilter';
import { IImportDgdkHocTapRequest } from '../../../../models/request/danh-gia/dinh-ky/IImportDgdkHocTapRequest';
import { ISelectDgdkHocTapRequest } from '../../../../models/request/danh-gia/dinh-ky/ISelectDgdkHocTapRequest';
import { IUpdateDgdkHocTapRequest } from '../../../../models/request/danh-gia/dinh-ky/IUpdateDgdkHocTapRequest';
import { IUpdateDgdkNlpcRequest } from '../../../../models/request/danh-gia/dinh-ky/IUpdateDgdkNlpcRequest';
import { IHocSinhSelectRequest } from '../../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { ILopBoMonSelectHocSinhRequest } from '../../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonSelectHocSinhRequest';
import { IDgdkKetQuaHocTap } from '../../../../models/response/danh-gia/dinh-ky/IDgdkKetQuaHocTap';
import { ISoNhanXetItem } from '../../../../models/response/dgdk-dm/ISoNhanXetItem';
import { IHocSinhSelectResponeItem } from '../../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { IDgdkHocTapFilter } from '../../../page-state-model/danh-gia/dinh-ky/IDgdkHocTapPageState';
import { IActionTypeBase } from '../../IActionTypeBase';
//IDgdkHocTap
//DGDK_NLPC_
export enum eDgdkHocTapActionTypeIds {
    CHANGE_FILTER = "DGDK_HOCTAP_CHANGE_FILTER",

    HOCSINH_LOAD_START = "DGDK_HOCTAP_HOCSINH_LOAD_START",
    HOCSINH_LOAD_SUCCESS = "DGDK_HOCTAP_HOCSINH_LOAD_SUCCESS",
    HOCSINH_LOAD_ERROR = "DGDK_HOCTAP_HOCSINH_LOAD_ERROR",

    SONHANXET_ITEM_LOAD_START = "DGDK_HOCTAP_LOAD_START",
    SONHANXET_ITEM_LOAD_SUCCESS = "DGDK_HOCTAP_LOAD_SUCCESS",
    SONHANXET_ITEM_LOAD_ERROR = "DGDK_HOCTAP_LOAD_ERROR",

    KETQUA_LOAD_START = "DGDK_HOCTAP_KETQUA_LOAD_START",
    KETQUA_LOAD_SUCCESS = "DGDK_HOCTAP_KETQUA_LOAD_SUCCESS",
    KETQUA_LOAD_ERROR = "DGDK_HOCTAP_KETQUA_LOAD_ERROR",

    KETQUA_SAVE_START = "DGDK_HOCTAP_KETQUA_SAVE_START",
    KETQUA_SAVE_SUCCESS = "DGDK_HOCTAP_KETQUA_SAVE_SUCCESS",
    KETQUA_SAVE_ERROR = "DGDK_HOCTAP_KETQUA_SAVE_ERROR",

    VALIDATE_IMPORT_START = "DGDK_HOCTAP_VALIDATE_IMPORT_START",
    VALIDATE_IMPORT_SUCCESS = "DGDK_HOCTAP_VALIDATE_IMPORT_SUCCESS",
    VALIDATE_IMPORT_ERRROR = "DGDK_HOCTAP_VALIDATE_IMPORT_ERRROR",

    IMPORT_START = "DGDK_HOCTAP_IMPORT_START",
    IMPORT_SUCCESS = "DGDK_HOCTAP_IMPORT_SUCCESS",
    IMPORT_ERRROR = "DGDK_HOCTAP_IMPORT_ERRROR",


}
export interface IDgdkHocTapChangeFilter extends IActionTypeBase<eDgdkHocTapActionTypeIds.CHANGE_FILTER, IDgdkHocTapFilter> { }

export interface IDgdkHocTapHocSinhLoadStart extends IActionTypeBase<eDgdkHocTapActionTypeIds.HOCSINH_LOAD_START, ILopBoMonSelectHocSinhRequest> { }
export interface IDgdkHocTapHocSinhLoadSuccess extends IActionTypeBase<eDgdkHocTapActionTypeIds.HOCSINH_LOAD_SUCCESS, IHocSinhSelectResponeItem[]> { }
export interface IDgdkHocTapHocSinhLoadError extends IActionTypeBase<eDgdkHocTapActionTypeIds.HOCSINH_LOAD_ERROR, string> { }

export interface IDgdkHocTapDmLoadStart extends IActionTypeBase<eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_START, number> { }
export interface IDgdkHocTapDmLoadSuccess extends IActionTypeBase<eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_SUCCESS, ISoNhanXetItem[]> { }
export interface IDgdkHocTapDmLoadError extends IActionTypeBase<eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_ERROR, string> { }

export interface IDgdkHocTapLoadKetQuaStart extends IActionTypeBase<eDgdkHocTapActionTypeIds.KETQUA_LOAD_START, ISelectDgdkHocTapRequest> { }
export interface IDgdkHocTapLoadKetQuaSuccess extends IActionTypeBase<eDgdkHocTapActionTypeIds.KETQUA_LOAD_SUCCESS, IDgdkKetQuaHocTap[]> { }
export interface IDgdkHocTapLoadKetQuaError extends IActionTypeBase<eDgdkHocTapActionTypeIds.KETQUA_LOAD_ERROR, string> { }

export interface IDgdkHocTapSaveKetQuaStart extends IActionTypeBase<eDgdkHocTapActionTypeIds.KETQUA_SAVE_START, IUpdateDgdkHocTapRequest> { }
export interface IDgdkHocTapSaveKetQuaSuccess extends IActionTypeBase<eDgdkHocTapActionTypeIds.KETQUA_SAVE_SUCCESS, IDgdkKetQuaHocTap> { }
export interface IDgdkHocTapSaveKetQuaError extends IActionTypeBase<eDgdkHocTapActionTypeIds.KETQUA_LOAD_ERROR, string> { }

export interface IDgdkHocTapValidateStart extends IActionTypeBase<eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_START, IImportDgdkHocTapRequest> { }
export interface IDgdkHocTapValidateSuccess extends IActionTypeBase<eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_SUCCESS, any> { }
export interface IDgdkHocTapValidateError extends IActionTypeBase<eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_ERRROR, any> { }

export interface IDgdkHocTapImportStart extends IActionTypeBase<eDgdkHocTapActionTypeIds.IMPORT_START, IImportDgdkHocTapRequest> { }
export interface IDgdkHocTapImportSuccess extends IActionTypeBase<eDgdkHocTapActionTypeIds.IMPORT_SUCCESS, undefined> { }
export interface IDgdkHocTapImportError extends IActionTypeBase<eDgdkHocTapActionTypeIds.IMPORT_ERRROR, any> { }

export type IDgdkHocTapActionTypes = IDgdkHocTapChangeFilter |
    IDgdkHocTapHocSinhLoadStart | IDgdkHocTapHocSinhLoadSuccess | IDgdkHocTapHocSinhLoadError |
    IDgdkHocTapDmLoadStart | IDgdkHocTapDmLoadSuccess | IDgdkHocTapDmLoadError |
    IDgdkHocTapLoadKetQuaStart | IDgdkHocTapLoadKetQuaSuccess | IDgdkHocTapLoadKetQuaError |
    IDgdkHocTapSaveKetQuaStart | IDgdkHocTapSaveKetQuaSuccess | IDgdkHocTapSaveKetQuaError |
    IDgdkHocTapValidateStart | IDgdkHocTapValidateSuccess | IDgdkHocTapValidateError |
    IDgdkHocTapImportStart | IDgdkHocTapImportSuccess | IDgdkHocTapImportError
