import { sis_diemdanh_status } from "../../../models/response/diem-danh/sis_diemdanh_status"

export enum eDiemDanhStatusActionTypeIds {
    LOAD_START = "DIEMDANH_STATUS_LOAD_START",
    LOAD_SUCCESS = "DIEMDANH_STATUS_LOAD_SUCCESS",
    LOAD_ERROR = "DIEMDANH_STATUS_LOAD_ERROR",
}

export interface IDiemDanhStatusLoadStart {
    type: eDiemDanhStatusActionTypeIds.LOAD_START
}
export interface IDiemDanhStatusLoadSuccess {
    type: eDiemDanhStatusActionTypeIds.LOAD_SUCCESS,
    payload: sis_diemdanh_status[]
}
export interface IDiemDanhStatusLoadError {
    type: eDiemDanhStatusActionTypeIds.LOAD_ERROR,
    payload: string
}

export type IDiemDanhStatusActionTypes = IDiemDanhStatusLoadStart | IDiemDanhStatusLoadSuccess | IDiemDanhStatusLoadError