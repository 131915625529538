import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eAppAccountActionTypeIds, IAppAccountChangePasswordStart, IAppAccountLoadStart, IAppAccountUpdateStatusStart } from "../../action-types/app-phuhuynh/IAppAccountActionTypes";
import { actions } from "../../actions/actionsWrapper";

const appAccountApi = apiWrapper.appAccount;

export function* appAccountSaga(): any {
    yield takeLatest(eAppAccountActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eAppAccountActionTypeIds.UPDATE_STATUS_START, updateStatusWorker)
    yield takeEvery(eAppAccountActionTypeIds.CHANGE_PASSWORD_START, changePasswordWorker)
}
function* loadWorker(action: IAppAccountLoadStart): any {

    const res: IBaseResponeModel = yield call([appAccountApi, appAccountApi.getAllPhuHuynhAccountAsync], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appAccount.loadSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appAccount.loadError(res.message || "Không tải được tài khoản"))
    }
}
function* updateStatusWorker(action: IAppAccountUpdateStatusStart): any {
    const res: IBaseResponeModel = yield call([appAccountApi, appAccountApi.updateStatus], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appAccount.updateStatusSuccess())
    } else {
        yield put(actions.appPhuHuynhWrapper.appAccount.updateStatusError(res.message || "Không cập nhật được trạng thái"))
    }
}
function* changePasswordWorker(action: IAppAccountChangePasswordStart): any {
    const res: IBaseResponeModel = yield call([appAccountApi, appAccountApi.resetPassword], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appAccount.changePasswordSuccess())
    } else {
        yield put(actions.appPhuHuynhWrapper.appAccount.changePasswordError(res.message || "Không cập nhật được trạng thái"))
    }
}