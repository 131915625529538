import { IDeleteMultipleBaseRequest } from "../../models/request/base/deleteRequest";
import { sis_dm_nhanxet } from "../../models/response/giao-vien-nhan-xet/sis_dm_nhanxet";
import { eGiaoVienNhanXetActionTypeIds, IGiaoVienNhanXetChangeSelectedIds, IGiaoVienNhanXetCloseConfirm, IGiaoVienNhanXetCloseDetail, IGiaoVienNhanXetDeleteErr, IGiaoVienNhanXetDeleteStart, IGiaoVienNhanXetDeleteSuccess, IGiaoVienNhanXetLoadErr, IGiaoVienNhanXetLoadStart, IGiaoVienNhanXetLoadSuccess, IGiaoVienNhanXetSaveErr, IGiaoVienNhanXetSaveStart, IGiaoVienNhanXetSaveSuccess, IGiaoVienNhanXetShowConfirm, IGiaoVienNhanXetShowDetail } from "../action-types/IGiaoVienNhanXetActionTypes";
import { BaseAction } from "./IBaseActionResult";

export const giaoVienNhanXetActions = {
    loadStart: (): IGiaoVienNhanXetLoadStart => BaseAction(undefined, eGiaoVienNhanXetActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_dm_nhanxet[]): IGiaoVienNhanXetLoadSuccess => BaseAction(respone, eGiaoVienNhanXetActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IGiaoVienNhanXetLoadErr => BaseAction(messege, eGiaoVienNhanXetActionTypeIds.LOAD_ERROR),
    showDetail: (data?: sis_dm_nhanxet): IGiaoVienNhanXetShowDetail => BaseAction(data, eGiaoVienNhanXetActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IGiaoVienNhanXetCloseDetail => BaseAction(undefined, eGiaoVienNhanXetActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IGiaoVienNhanXetShowConfirm => BaseAction(undefined, eGiaoVienNhanXetActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IGiaoVienNhanXetCloseConfirm => BaseAction(undefined, eGiaoVienNhanXetActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IGiaoVienNhanXetChangeSelectedIds => BaseAction(ids, eGiaoVienNhanXetActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IGiaoVienNhanXetDeleteStart => BaseAction(ids, eGiaoVienNhanXetActionTypeIds.DELETE_START),
    deleteSuccess: (): IGiaoVienNhanXetDeleteSuccess => BaseAction(undefined, eGiaoVienNhanXetActionTypeIds.DELETE_SUCCESS),
    deleteErr: (message: string): IGiaoVienNhanXetDeleteErr => BaseAction(message, eGiaoVienNhanXetActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_dm_nhanxet): IGiaoVienNhanXetSaveStart => BaseAction(data, eGiaoVienNhanXetActionTypeIds.SAVE_START),
    saveSuccess: (): IGiaoVienNhanXetSaveSuccess => BaseAction(undefined, eGiaoVienNhanXetActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IGiaoVienNhanXetSaveErr => BaseAction(message, eGiaoVienNhanXetActionTypeIds.SAVE_ERR)
}
