import { IKhenThuongKyLuatSelectRequest } from '../../../models/request/khen-thuong-ky-luat/IKhenThuongKyLuatRequest';
import { IKhenThuongKyLuat } from '../../../models/response/khen-thuong-ky-luat/IKhenThuongKyLuat';
import { baseAction } from '../IActionBase';
import { IKhenThuongKyLuatViewModel } from './../../../models/response/khen-thuong-ky-luat/IKhenThuongKyLuatViewModel';
import { eKhenThuongKyLuatActionTypeIds } from './../../action-types/khen-thuong-ky-luat/IKhenThuongKyLuatActionType';

export const khenThuongKyLuatActions={
    loadStart: (request:IKhenThuongKyLuatSelectRequest) => baseAction(eKhenThuongKyLuatActionTypeIds.LOAD_START, request),
    loadSuccess: (respone: IKhenThuongKyLuatViewModel[]) => baseAction(eKhenThuongKyLuatActionTypeIds.LOAD_SUCCESS, respone),
    loadError: (messege: string) => baseAction( eKhenThuongKyLuatActionTypeIds.LOAD_ERROR, messege),
    saveStart: (payload:IKhenThuongKyLuat) => baseAction(eKhenThuongKyLuatActionTypeIds.SAVE_START, payload),
    saveSuccess: () => baseAction(eKhenThuongKyLuatActionTypeIds.SAVE_SUCCESS, undefined),
    saveError: (messege: string) => baseAction(eKhenThuongKyLuatActionTypeIds.SAVE_ERROR, messege),
    deleteStart: (payload:number) => baseAction(eKhenThuongKyLuatActionTypeIds.DELETE_START, payload),
    deleteSuccess: () => baseAction(eKhenThuongKyLuatActionTypeIds.DELETE_SUCCESS, undefined),
    deleteError: (messege: string) => baseAction(eKhenThuongKyLuatActionTypeIds.DELETE_ERROR, messege),
    showDetailModal:(payload?: IKhenThuongKyLuatViewModel)=>baseAction(eKhenThuongKyLuatActionTypeIds.SHOW_DETAIL_MODAL,payload),
    closeDetailModal:()=>baseAction(eKhenThuongKyLuatActionTypeIds.CLOSE_DETAIL_MODAL,undefined),
    showDeleteConfirm:(payload: IKhenThuongKyLuatViewModel)=>baseAction(eKhenThuongKyLuatActionTypeIds.SHOW_DELETE_CONFIRM,payload),
    closeDeleteConfirm:()=>baseAction(eKhenThuongKyLuatActionTypeIds.CLOSE_DELETE_CONFIRM,undefined),
}