import { IDeleteMultipleBaseRequest } from '../../models/request/base/deleteRequest';
import { sis_meal } from "../../models/response/meal/sis_meal";


export enum eMealActionTypeIds {
    LOAD_START = "MEAL_LOAD_START",
    LOAD_SUCCESS = "MEAL_LOAD_SUCCESS",
    LOAD_ERROR = "MEAL_LAOD_ERROR",

    SHOW_DETAIL = "MEAL_SHOW_DETAIL",
    CLOSE_DETAIL = "CLOSE_MEAL_DETAIL",

    CHANGE_SELECTED_IDS = "CHANGE_MEAL_SELECTED_IDS",

    SHOW_CONFIRM = "MEAL_SHOW_CONFIRM",
    CLOSE_CONFIRM = "MEAL_CLOSE_CONFIRM",

    DELETE_START = "DELETE_MEAL_START",
    DELETE_SUCCESS = "DELETE_MEAL_SUCCESS",
    DELETE_ERROR = "DELETE_MEAL_ERROR",

    SAVE_START = "SAVE_MEAL_START",
    SAVE_SUCCESS = "SAVE_MEAL_SUCCESS",
    SAVE_ERR = "SAVE_MEAL_ERR"
}

export interface IMealLoadStart {
    type: eMealActionTypeIds.LOAD_START,
    payload: number
}
export interface IMealLoadSuccess {
    type: eMealActionTypeIds.LOAD_SUCCESS,
    payload: sis_meal[]
}
export interface IMealLoadErr {
    type: eMealActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IMealShowDetail {
    type: eMealActionTypeIds.SHOW_DETAIL,
    payload?: sis_meal
}
export interface IMealCloseDetail {
    type: eMealActionTypeIds.CLOSE_DETAIL,
}
export interface IMealChangeSelectedIds {
    type: eMealActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IMealShowConfirm {
    type: eMealActionTypeIds.SHOW_CONFIRM
}
export interface IMealCloseConfirm {
    type: eMealActionTypeIds.CLOSE_CONFIRM
}

export interface IMealDeleteStart {
    type: eMealActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IMealDeleteSuccess {
    type: eMealActionTypeIds.DELETE_SUCCESS,
}
export interface IMealDeleteErr {
    type: eMealActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IMealSaveStart {
    type: eMealActionTypeIds.SAVE_START,
    payload: sis_meal
}
export interface IMealSaveSuccess {
    type: eMealActionTypeIds.SAVE_SUCCESS
}
export interface IMealSaveErr {
    type: eMealActionTypeIds.SAVE_ERR,
    payload: string
}
export type IMealActionTypes = IMealLoadStart | IMealLoadSuccess | IMealLoadErr |
    IMealShowDetail | IMealCloseDetail |
    IMealChangeSelectedIds |
    IMealShowConfirm | IMealCloseConfirm |
    IMealDeleteStart | IMealDeleteSuccess | IMealDeleteErr |
    IMealSaveStart | IMealSaveSuccess | IMealSaveErr
