import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { sis_pl_dotdangky_nhommonhoc } from "../../../models/response/dangky-monhoc/sis_pl_dotdangky_nhommonhoc"

export enum eDotDangKyNhomMonHocActionTypeIds {
    LOAD_START = "DotDangKyNhomMonHoc_LOAD_START",
    LOAD_SUCCESS = "DotDangKyNhomMonHoc_LOAD_SUCCESS",
    LOAD_ERROR = "DotDangKyNhomMonHoc_LOAD_ERROR",

    SHOW_DETAIL = "DotDangKyNhomMonHoc_SHOW_DETAIL",
    CLOSE_DETAIL = "DotDangKyNhomMonHoc_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "DotDangKyNhomMonHoc_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "DotDangKyNhomMonHoc_SHOW_CONFIRM",
    CLOSE_CONFIRM = "DotDangKyNhomMonHoc_CLOSE_CONFIRM",

    DELETE_START = "DotDangKyNhomMonHoc_DELETE_START",
    DELETE_SUCCESS = "DotDangKyNhomMonHoc_DELETE_SUCCESS",
    DELETE_ERROR = "DotDangKyNhomMonHoc_DELETE_ERROR",

    SAVE_START = "DotDangKyNhomMonHoc_SAVE_START",
    SAVE_SUCCESS = "DotDangKyNhomMonHoc_SAVE_SUCCESS",
    SAVE_ERR = "DotDangKyNhomMonHoc_SAVE_ERR"
}

export interface IDotDangKyNhomMonHocLoadStart {
    type: eDotDangKyNhomMonHocActionTypeIds.LOAD_START,
    payload: number
}
export interface IDotDangKyNhomMonHocLoadSuccess {
    type: eDotDangKyNhomMonHocActionTypeIds.LOAD_SUCCESS,
    payload: sis_pl_dotdangky_nhommonhoc[]
}
export interface IDotDangKyNhomMonHocLoadErr {
    type: eDotDangKyNhomMonHocActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IDotDangKyNhomMonHocShowDetail {
    type: eDotDangKyNhomMonHocActionTypeIds.SHOW_DETAIL,
    payload?: sis_pl_dotdangky_nhommonhoc
}
export interface IDotDangKyNhomMonHocCloseDetail {
    type: eDotDangKyNhomMonHocActionTypeIds.CLOSE_DETAIL,
}
export interface IDotDangKyNhomMonHocChangeSelectedIds {
    type: eDotDangKyNhomMonHocActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IDotDangKyNhomMonHocShowConfirm {
    type: eDotDangKyNhomMonHocActionTypeIds.SHOW_CONFIRM
}
export interface IDotDangKyNhomMonHocCloseConfirm {
    type: eDotDangKyNhomMonHocActionTypeIds.CLOSE_CONFIRM
}

export interface IDotDangKyNhomMonHocDeleteStart {
    type: eDotDangKyNhomMonHocActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IDotDangKyNhomMonHocDeleteSuccess {
    type: eDotDangKyNhomMonHocActionTypeIds.DELETE_SUCCESS,
}
export interface IDotDangKyNhomMonHocDeleteErr {
    type: eDotDangKyNhomMonHocActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IDotDangKyNhomMonHocSaveStart {
    type: eDotDangKyNhomMonHocActionTypeIds.SAVE_START,
    payload: sis_pl_dotdangky_nhommonhoc
}
export interface IDotDangKyNhomMonHocSaveSuccess {
    type: eDotDangKyNhomMonHocActionTypeIds.SAVE_SUCCESS
}
export interface IDotDangKyNhomMonHocSaveErr {
    type: eDotDangKyNhomMonHocActionTypeIds.SAVE_ERR,
    payload: string
}
export type IDotDangKyNhomMonHocActionTypes = IDotDangKyNhomMonHocLoadStart | IDotDangKyNhomMonHocLoadSuccess | IDotDangKyNhomMonHocLoadErr |
    IDotDangKyNhomMonHocShowDetail | IDotDangKyNhomMonHocCloseDetail |
    IDotDangKyNhomMonHocChangeSelectedIds |
    IDotDangKyNhomMonHocShowConfirm | IDotDangKyNhomMonHocCloseConfirm |
    IDotDangKyNhomMonHocDeleteStart | IDotDangKyNhomMonHocDeleteSuccess | IDotDangKyNhomMonHocDeleteErr |
    IDotDangKyNhomMonHocSaveStart | IDotDangKyNhomMonHocSaveSuccess | IDotDangKyNhomMonHocSaveErr