import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { app_baiviet } from "../../../models/response/app-phuhuynh/app_baiviet"

export enum eBaiVietActionTypeIds {
    LOAD_START = "BAI_VIET_LOAD_START",
    LOAD_SUCCESS = "BAI_VIET_LOAD_SUCCESS",
    LOAD_ERROR = "BAI_VIET_LOAD_ERROR",

    LOAD_APPROVED_START = "BAI_VIET_LOAD_APPROVED_START",
    LOAD_APPROVED_SUCCESS = "BAI_VIET_LOAD_APPROVED_SUCCESS",
    LOAD_APPROVED_ERROR = "BAI_VIET_LOAD_APPROVED_ERROR",

    LOAD_WAITING_APPROVE_START = "BAI_VIET_LOAD_WAITING_APPROVE_START",
    LOAD_WAITING_APPROVE_SUCCESS = "BAI_VIET_LOAD_WAITING_APPROVE_SUCCESS",
    LOAD_WAITING_APPROVE_ERROR = "BAI_VIET_LOAD_WAITING_APPROVE_ERROR",

    COUNT_WAITING_APPROVE_START = "BAI_VIET_COUNT_WAITING_APPROVE_START",
    COUNT_WAITING_APPROVE_SUCCESS = "BAI_VIET_COUNT_WAITING_APPROVE_SUCCESS",
    COUNT_WAITING_APPROVE_ERROR = "BAI_VIET_COUNT_WAITING_APPROVE_ERROR",

    CHANGE_SELECTED_IDS = "BAI_VIET_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "BAI_VIET_SHOW_CONFIRM",
    CLOSE_CONFIRM = "BAI_VIET_CLOSE_CONFIRM",

    DELETE_START = "BAI_VIET_DELETE_START",
    DELETE_SUCCESS = "BAI_VIET_DELETE_SUCCESS",
    DELETE_ERROR = "BAI_VIET_DELETE_ERROR",

}

export interface IBaiVietLoadStart {
    type: eBaiVietActionTypeIds.LOAD_START,
    payload: number
}
export interface IBaiVietLoadSuccess {
    type: eBaiVietActionTypeIds.LOAD_SUCCESS,
    payload: app_baiviet[]
}
export interface IBaiVietLoadErr {
    type: eBaiVietActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IBaiVietLoadApprovedStart {
    type: eBaiVietActionTypeIds.LOAD_APPROVED_START,
    payload: number
}
export interface IBaiVietLoadApprovedSuccess {
    type: eBaiVietActionTypeIds.LOAD_APPROVED_SUCCESS,
    payload: app_baiviet[]
}
export interface IBaiVietLoadApprovedErr {
    type: eBaiVietActionTypeIds.LOAD_APPROVED_ERROR,
    payload: string
}

export interface IBaiVietLoadWaitingApproveStart {
    type: eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_START,
    payload: number
}
export interface IBaiVietLoadWaitingApproveSuccess {
    type: eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_SUCCESS,
    payload: app_baiviet[]
}
export interface IBaiVietLoadWaitingApproveErr {
    type: eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_ERROR,
    payload: string
}

export interface IBaiVietCountWaitingApproveStart {
    type: eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_START,
    payload: number
}
export interface IBaiVietCountWaitingApproveSuccess {
    type: eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_SUCCESS,
    payload: number
}
export interface IBaiVietCountWaitingApproveErr {
    type: eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_ERROR,
    payload: string
}

export interface IBaiVietChangeSelectedIds {
    type: eBaiVietActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IBaiVietShowConfirm {
    type: eBaiVietActionTypeIds.SHOW_CONFIRM
}
export interface IBaiVietCloseConfirm {
    type: eBaiVietActionTypeIds.CLOSE_CONFIRM
}

export interface IBaiVietDeleteStart {
    type: eBaiVietActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IBaiVietDeleteSuccess {
    type: eBaiVietActionTypeIds.DELETE_SUCCESS,
}
export interface IBaiVietDeleteErr {
    type: eBaiVietActionTypeIds.DELETE_ERROR,
    payload: string
}

export type IBaiVietActionTypes = IBaiVietLoadStart | IBaiVietLoadSuccess | IBaiVietLoadErr |
    IBaiVietChangeSelectedIds |
    IBaiVietShowConfirm | IBaiVietCloseConfirm |
    IBaiVietDeleteStart | IBaiVietDeleteSuccess | IBaiVietDeleteErr |
    IBaiVietLoadWaitingApproveStart | IBaiVietLoadWaitingApproveSuccess | IBaiVietLoadWaitingApproveErr |
    IBaiVietCountWaitingApproveStart | IBaiVietCountWaitingApproveSuccess | IBaiVietCountWaitingApproveErr |
    IBaiVietLoadApprovedStart | IBaiVietLoadApprovedSuccess | IBaiVietLoadApprovedErr 