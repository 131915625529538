import { call, put, takeEvery } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { initialUserInfo } from "../../models/response/account/user-info";
import { IBaseResponeModel } from "../../models/response/base-response";
import { eAuthActionTypeIds } from "../action-types/IAuthActionTypes";
import { actions } from './../actions/actionsWrapper';


function* authSaga(): any {
    yield takeEvery(eAuthActionTypeIds.GET_USER_AUTH, getUserWorker)
}

function* getUserWorker() {
    try {
        const res: IBaseResponeModel = yield call(apiWrapper.account.getUser);
        if (res.is_success) {
            yield put(actions.auth.setUser(res.data))
        } else {
            yield put(actions.auth.setUser(initialUserInfo))
        }
    } catch (error) {
        yield put(actions.auth.setUser(initialUserInfo))
    }
}
const setJWTToken = (access_token: string, refresh_token: string) => {
    localStorage.access_token = access_token;
    localStorage.refresh_token = refresh_token;

}
const clearJWTToken = () => {
    localStorage.access_token = null;
    localStorage.refresh_token = null;
}

export { authSaga, setJWTToken, clearJWTToken };

