import React from "react";
import { useCommonContext } from "../../contexts/common";
import BaoCaoDiemDanh from "../bao-cao-diem-danh";
export default () => {
  const { checkAccesiableTo } = useCommonContext();
  return (
    <React.Fragment>
      {/* <Print
      pagesHtml={["https://megaedu-sis-prod.s3.ap-southeast-1.amazonaws.com/Megaedu/SIS/e66bb835-900d-4ff0-9d49-decabded51bb.html"]}
      /> */}
      {/* <TestPrint /> */}
      {checkAccesiableTo("lop-chu-nhiem/report/{date}/co-so/{dm_truong_id}", "GET") &&
        <BaoCaoDiemDanh />
      }
    </React.Fragment >
  );
}
