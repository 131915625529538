import { CommonSeriesSettings, Size } from 'devextreme-react/chart';
import DateBox from 'devextreme-react/date-box';
import PieChart, { Connector, Export, Label, Legend, Series } from 'devextreme-react/pie-chart';
import PivotGrid, { FieldChooser } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { diemDanhApi } from '../../api/diemDanhApi';
import { Button } from '../../components/buttons';
import TextTranslated from '../../components/text';
import { useCommonContext } from '../../contexts/common';
import { IDiemDanhLopChuNhiemReportItemRespone } from '../../models/response/diem-danh/IDiemDanhLopChuNhiemReportItemRespone';
import { actions } from '../../state/actions/actionsWrapper';
import { RootState } from '../../state/reducers';
import styles from './BaoCaoDiemDanh.module.css';
import DiemDanhDetailModal from './detail';
import TongHopDiemDanhModal from './tong-hop';

const BaoCaoDiemDanh = () => {
	const refChart = useRef<any>();
	const refGrid = useRef<any>();
	const { dm_truong_selected_id, language } = useSelector((x: RootState) => x.common);
	const [diemDanhData, setDiemDanhData] = useState<IDiemDanhLopChuNhiemReportItemRespone[]>([]);
	const { sis_diemdanh_status } = useSelector((x: RootState) => x.diemDanhStatus);
	const [ngayDiemDanh, setNgayDiemDanh] = useState<Date>(new Date());
	const [isShowDiemDanhDetailModal, setIsShowDiemDanhDetailModal] = useState<boolean>(false);
	const [isShowTongHopDiemDanhModal, setIsShowTongHopDiemDanhModal] = useState<boolean>(false);

	const { translate } = useCommonContext();
	const dispatch = useDispatch();
	useEffect(() => {
		// refGrid.current.instance.bindChart(refChart.current.instance, {
		//     dataFieldsDisplayMode: 'splitPanes',
		//     alternateDataFields: false,
		// });
	}, []);
	useEffect(() => {
		dispatch(actions.diemDanhStatus.loadStart());
	}, []);
	useEffect(() => {
		handleGetDataAsync();
	}, [ngayDiemDanh, dm_truong_selected_id]);
	const handleGetDataAsync = useCallback(async () => {
		const res = await diemDanhApi.LopChuNhiemReportAsync({
			date: ngayDiemDanh,
			dm_truong_id: dm_truong_selected_id,
		});
		if (res.is_success) {
			setDiemDanhData(res.data);
		}
	}, [ngayDiemDanh, dm_truong_selected_id]);
	const tongToanTruong = useMemo(() => {
		return sis_diemdanh_status.map((status) => {
			const total = diemDanhData
				.filter((x) => x.sis_diemdanh_status_id == status.id)
				.map((x) => x.so_luong)
				.reduce((a, b) => a + b, 0);
			return {
				id: status.id,
				name: language == 'en' ? status.name_en : status.name,
				total: total,
				color: status.color,
			};
		});
	}, [sis_diemdanh_status, diemDanhData, language]);
	const tongTungKhois = useMemo(() => {
		const tenKhois = [
			...Array.from(new Set(diemDanhData.map((x) => (language == 'en' ? x.ten_khoi_en : x.ten_khoi)))),
		].sort();
		// console.log({
		// 	tenKhois,
		// });
		return tenKhois.map((tenKhoi) => {
			const khoiData = sis_diemdanh_status.map((status) => {
				const total = diemDanhData
					.filter((x) => (x.ten_khoi == tenKhoi || x.ten_khoi_en == tenKhoi) && x.sis_diemdanh_status_id == status.id)
					.map((x) => x.so_luong)
					.reduce((a, b) => a + b, 0);
				return {
					id: status.id,
					name: language == 'en' ? status.name_en : status.name,
					total: total,
					color: status.color,
				};
			});
			return {
				name: tenKhoi,
				data: khoiData,
			};
		});
	}, [sis_diemdanh_status, diemDanhData, language]);
	// console.log({
	//     tongToanTruong,
	//     tongTungKhois
	// });

	const dataSource = new PivotGridDataSource({
		fields: [
			{
				caption: translate('Base.Label.Grade'),
				width: 120,
				dataField: language == 'en' ? 'ten_khoi_en' : 'ten_khoi',
				area: 'row',
				sortBySummaryField: 'Total',
				expanded: true,
			},
			{
				caption: translate('Base.Label.Program'),
				dataField: language == 'en' ? 'ten_he_en' : 'ten_he',
				width: 150,
				area: 'row',
			},
			{
				caption: translate('Base.Label.Grid.ClassName'),
				dataField: language == 'en' ? 'ten_lop_en' : 'ten_lop',
				width: 150,
				area: 'row',
			},
			{
				caption: translate('Base.Label.Status'),
				dataField: language == 'en' ? 'sis_diemdanh_status_name_en' : 'sis_diemdanh_status_name',
				dataType: 'string',
				width: 150,
				area: 'column',
			},
			{
				caption: translate('BaoCaoDiemDanh.Label.SoLuong'),
				dataField: 'so_luong',
				dataType: 'number',
				summaryType: 'sum',
				// format: 'currency',
				area: 'data',
			},
			// {
			//     caption: 'Sĩ số',
			//     dataField: 'si_so',
			//     dataType: 'number',
			//     summaryType: 'sum',
			//     // format: 'currency',
			//     area: 'data',

			// }
		],
		store: diemDanhData,
	});

	return (
		<div className={styles.container}>
			<div className={styles.caption}>
				<p>
					<TextTranslated value='BaoCaoDiemDanh.Page.Caption' />
				</p>
				<div>
					<DateBox
						value={ngayDiemDanh}
						onValueChanged={(e) => {
							if (e.event) {
								setNgayDiemDanh(e.value);
							}
						}}
						displayFormat={'dd/MM/yyyy'}
					/>
				</div>
			</div>
			<div className={styles.chart}>
				{/* <Chart ref={refChart}
                    palette="Soft"
                >
                    <CommonSeriesSettings
                        // argumentField="state"
                        type="bar"
                        barPadding={0.5}
                    />
                    <Size height={200} />
                    <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
                    <AdaptiveLayout width={450} />
                </Chart> */}
				<div className={styles.main}>
					<div>
						<PieChart
							palette='Bright'
							dataSource={tongToanTruong}
							title={translate('BaoCaoDiemDanh.Label.AllSchool')}
							customizePoint={(e) => {
								return { color: `${e.data.color}` };
							}}
							resolveLabelOverlapping={'shift'}
						>
							<Size height={350} />

							<Legend
								orientation='horizontal'
								itemTextPosition='right'
								horizontalAlignment='center'
								verticalAlignment='bottom'
								columnCount={5}
							/>
							<Export enabled={true} />
							<CommonSeriesSettings />
							<Series argumentField='name' valueField='total'>
								<Label
									visible={true}
									position='columns'

									// customizeText={customizeText}
								>
									{/* <Font size={16} /> */}
									<Connector visible={true} width={0.5} />
								</Label>
							</Series>
						</PieChart>
					</div>
				</div>
				<div className={styles.extends}>
					{tongTungKhois.map((x, idx) => {
						return (
							<div className={styles.khoiChart} key={idx}>
								<div>
									<PieChart
										palette='Bright'
										dataSource={x.data}
										// title={x.name.toString()}
										type='doughnut'
										centerRender={(pieChart) => {
											return (
												<svg>
													<circle cx='100' cy='100' r={pieChart.getInnerRadius() - 6} fill='#CBCCFF'></circle>
													{/* <image href={getImagePath(country)} x="70" y="58" width="60" height="40" /> */}
													<text textAnchor='middle' x='100' y='105' style={{ fill: '#494949', fontWeight: 600 }}>
														<tspan x='100'>{x.name}</tspan>
													</text>
												</svg>
											);
										}}
										customizePoint={(e) => {
											return { color: `${e.data.color}` };
										}}
										resolveLabelOverlapping={'shift'}
									>
										<CommonSeriesSettings
											// argumentField="state"
											type='bar'
											barPadding={0.5}
										/>
										<Size height={190} />
										<Legend
											orientation='horizontal'
											// orientation="vertical"
											itemTextPosition='right'
											horizontalAlignment='center'
											verticalAlignment='bottom'
											columnCount={4}
										/>
										{/* <Export enabled={true} /> */}
										<Series argumentField='name' valueField='total'>
											<Label
												visible={true}
												position='columns'
												// customizeText={customizeText}
											>
												{/* <Font size={16} /> */}
												<Connector visible={true} width={0.5} />
											</Label>
										</Series>
									</PieChart>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className={styles.actions}>
				<Button
					text={translate('Base.Label.Aggregate')}
					id='btnDetail'
					onClick={() => {
						setIsShowTongHopDiemDanhModal(true);
					}}
				/>
				<Button
					text='View Detail'
					id='btnDetail'
					onClick={() => {
						setIsShowDiemDanhDetailModal(true);
					}}
				/>
				<Button
					text='Export'
					type='success'
					style='filled'
					onClick={() => {
						if (refGrid && refGrid.current && refGrid.current.instance) {
							refGrid.current.instance.exportToExcel();
						}
					}}
				/>
			</div>
			<PivotGrid
				id='pivotgrid'
				dataSource={dataSource}
				allowSortingBySummary={true}
				allowFiltering={true}
				showBorders={true}
				showColumnTotals={true}
				showColumnGrandTotals={true}
				showRowTotals={false}
				showRowGrandTotals={true}
				ref={refGrid}
				height={window.innerHeight - 400}
				// rowHeaderLayout='tree'
			>
				<FieldChooser enabled={false} height={250} />
			</PivotGrid>
			{isShowDiemDanhDetailModal && (
				<DiemDanhDetailModal
					animationOf='btnDetail'
					date={ngayDiemDanh}
					onClose={() => {
						setIsShowDiemDanhDetailModal(false);
					}}
				/>
			)}
			{isShowTongHopDiemDanhModal && (
				<TongHopDiemDanhModal
					animationOf='btnDetail'
					date={ngayDiemDanh}
					dm_khoi_id={0}
					dm_lop_id={0}
					onClose={() => {
						setIsShowTongHopDiemDanhModal(false);
					}}
				/>
			)}
		</div>
	);
};

export default BaoCaoDiemDanh;
