import { NotifyHelper } from './../../../helpers/toast';
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eHocSinhSelectionActionTypeIds, IHocSinhSelectionActionTypes } from '../../action-types/hoc-sinh/IHocSinhSelectionActionTypes';
import { IHocSinhSelectionState } from './../../page-state-model/hoc-sinh/IHocSinhSelectionState';
const iniState: IHocSinhSelectionState = {
    filter: {
        dm_he_id: 0,
        dm_khoi_id: 0,
        dm_lop_id: 0,
        dm_truong_id: 0,
        nam_hoc: ""
    },
    hocSinhs: [],
    hocSinhSelectedIds: [],
    status: ePageBaseStatus.is_not_initialization
}
export const hocSinhSelectionReducer = (state: IHocSinhSelectionState = iniState,
    action: IHocSinhSelectionActionTypes
): IHocSinhSelectionState => {
    switch (action.type) {
        case eHocSinhSelectionActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case eHocSinhSelectionActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eHocSinhSelectionActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                hocSinhs: action.payload,
                hocSinhSelectedIds:[]
            }
        case eHocSinhSelectionActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                hocSinhs: []
            }
        case eHocSinhSelectionActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                hocSinhSelectedIds: action.payload
            }
        default:
            return { ...state }
    }
}