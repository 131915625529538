import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { ITuanGenerateRequest } from "../../../models/request/category/ITuanGenerateRequest"
import { IPLTuanSelectRequest } from "../../../models/request/danh-muc/IPLTuanSelectRequest"
import { pl_tuan } from "../../../models/response/danh-muc/pl_tuan"

export enum eTuanActionTypeIds {
    LOAD_START = "TUAN_LOAD_START",
    LOAD_SUCCESS = "TUAN_LOAD_SUCCESS",
    LOAD_ERROR = "TUAN_LOAD_ERROR",

    SELECT_START = "TUAN_SELECT_START",
    SELECT_SUCCESS = "TUAN_SELECT_SUCCESS",
    SELECT_ERROR = "TUAN_SELECT_ERROR",

    SHOW_DETAIL = "TUAN_SHOW_DETAIL",
    CLOSE_DETAIL = "TUAN_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "TUAN_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "TUAN_SHOW_CONFIRM",
    CLOSE_CONFIRM = "TUAN_CLOSE_CONFIRM",

    DELETE_START = "TUAN_DELETE_START",
    DELETE_SUCCESS = "TUAN_DELETE_SUCCESS",
    DELETE_ERROR = "TUAN_DELETE_ERROR",

    SAVE_START = "TUAN_SAVE_START",
    SAVE_SUCCESS = "TUAN_SAVE_SUCCESS",
    SAVE_ERR = "TUAN_SAVE_ERR",

    GENERATE_START = "TUAN_LIST_GENERATE_START",
    GENERATE_SUCCESS = "TUAN_LIST_GENERATE_SUCCESS",
    GENERATE_ERROR = "TUAN_LIST_GENERATE_ERROR",
}

export interface ITuanLoadStart {
    type: eTuanActionTypeIds.LOAD_START,
}
export interface ITuanLoadSuccess {
    type: eTuanActionTypeIds.LOAD_SUCCESS,
    payload: pl_tuan[]
}
export interface ITuanLoadErr {
    type: eTuanActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface ITuanSelectStart {
    type: eTuanActionTypeIds.SELECT_START,
    payload: IPLTuanSelectRequest
}
export interface ITuanSelectSuccess {
    type: eTuanActionTypeIds.SELECT_SUCCESS,
    payload: pl_tuan[]
}
export interface ITuanSelectErr {
    type: eTuanActionTypeIds.SELECT_ERROR,
    payload: string
}
export interface ITuanShowDetail {
    type: eTuanActionTypeIds.SHOW_DETAIL,
    payload?: pl_tuan
}
export interface ITuanCloseDetail {
    type: eTuanActionTypeIds.CLOSE_DETAIL,
}
export interface ITuanChangeSelectedIds {
    type: eTuanActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface ITuanShowConfirm {
    type: eTuanActionTypeIds.SHOW_CONFIRM
}
export interface ITuanCloseConfirm {
    type: eTuanActionTypeIds.CLOSE_CONFIRM
}

export interface ITuanDeleteStart {
    type: eTuanActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface ITuanDeleteSuccess {
    type: eTuanActionTypeIds.DELETE_SUCCESS,
}
export interface ITuanDeleteErr {
    type: eTuanActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface ITuanSaveStart {
    type: eTuanActionTypeIds.SAVE_START,
    payload: pl_tuan
}
export interface ITuanSaveSuccess {
    type: eTuanActionTypeIds.SAVE_SUCCESS
}
export interface ITuanSaveErr {
    type: eTuanActionTypeIds.SAVE_ERR,
    payload: string
}

export interface ITuanGenerateStart {
    type: eTuanActionTypeIds.GENERATE_START,
    payload: ITuanGenerateRequest
}
export interface ITuanGenerateSuccess {
    type: eTuanActionTypeIds.GENERATE_SUCCESS
}
export interface ITuanGenerateError {
    type: eTuanActionTypeIds.GENERATE_ERROR,
    payload: string
}

export type ITuanActionTypes = ITuanLoadStart | ITuanLoadSuccess | ITuanLoadErr |
    ITuanSelectStart | ITuanSelectSuccess | ITuanSelectErr |
    ITuanShowDetail | ITuanCloseDetail |
    ITuanChangeSelectedIds |
    ITuanShowConfirm | ITuanCloseConfirm |
    ITuanDeleteStart | ITuanDeleteSuccess | ITuanDeleteErr |
    ITuanSaveStart | ITuanSaveSuccess | ITuanSaveErr |
    ITuanGenerateStart | ITuanGenerateSuccess | ITuanGenerateError 