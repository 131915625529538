import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { IThaiDoHocTapSelectRequest } from "../../../models/request/nhan-xet/IThaiDoHocTapSelectRequest"
import { sis_thaidohoctap } from "../../../models/response/nhan-xet/sis_thaidohoctap"

export enum eThaiDoHocTapActionTypeIds {
    LOAD_START = "ThaiDoHocTap_LOAD_START",
    LOAD_SUCCESS = "ThaiDoHocTap_LOAD_SUCCESS",
    LOAD_ERROR = "ThaiDoHocTap_LOAD_ERROR",

    SELECT_START = "ThaiDoHocTap_SELECT_START",
    SELECT_SUCCESS = "ThaiDoHocTap_SELECT_SUCCESS",
    SELECT_ERROR = "ThaiDoHocTap_SELECT_ERROR",

    SHOW_DETAIL = "ThaiDoHocTap_SHOW_DETAIL",
    CLOSE_DETAIL = "ThaiDoHocTap_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "ThaiDoHocTap_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "ThaiDoHocTap_SHOW_CONFIRM",
    CLOSE_CONFIRM = "ThaiDoHocTap_CLOSE_CONFIRM",

    DELETE_START = "ThaiDoHocTap_DELETE_START",
    DELETE_SUCCESS = "ThaiDoHocTap_DELETE_SUCCESS",
    DELETE_ERROR = "ThaiDoHocTap_DELETE_ERROR",

    SAVE_START = "ThaiDoHocTap_SAVE_START",
    SAVE_SUCCESS = "ThaiDoHocTap_SAVE_SUCCESS",
    SAVE_ERR = "ThaiDoHocTap_SAVE_ERR"
}

export interface IThaiDoHocTapLoadStart {
    type: eThaiDoHocTapActionTypeIds.LOAD_START,
}
export interface IThaiDoHocTapLoadSuccess {
    type: eThaiDoHocTapActionTypeIds.LOAD_SUCCESS,
    payload: sis_thaidohoctap[]
}
export interface IThaiDoHocTapLoadErr {
    type: eThaiDoHocTapActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IThaiDoHocTapSelectStart {
    type: eThaiDoHocTapActionTypeIds.SELECT_START,
    payload: IThaiDoHocTapSelectRequest
}
export interface IThaiDoHocTapSelectSuccess {
    type: eThaiDoHocTapActionTypeIds.SELECT_SUCCESS,
    payload: sis_thaidohoctap[]
}
export interface IThaiDoHocTapSelectErr {
    type: eThaiDoHocTapActionTypeIds.SELECT_ERROR,
    payload: string
}
export interface IThaiDoHocTapShowDetail {
    type: eThaiDoHocTapActionTypeIds.SHOW_DETAIL,
    payload?: sis_thaidohoctap
}
export interface IThaiDoHocTapCloseDetail {
    type: eThaiDoHocTapActionTypeIds.CLOSE_DETAIL,
}
export interface IThaiDoHocTapChangeSelectedIds {
    type: eThaiDoHocTapActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IThaiDoHocTapShowConfirm {
    type: eThaiDoHocTapActionTypeIds.SHOW_CONFIRM
}
export interface IThaiDoHocTapCloseConfirm {
    type: eThaiDoHocTapActionTypeIds.CLOSE_CONFIRM
}

export interface IThaiDoHocTapDeleteStart {
    type: eThaiDoHocTapActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IThaiDoHocTapDeleteSuccess {
    type: eThaiDoHocTapActionTypeIds.DELETE_SUCCESS,
}
export interface IThaiDoHocTapDeleteErr {
    type: eThaiDoHocTapActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IThaiDoHocTapSaveStart {
    type: eThaiDoHocTapActionTypeIds.SAVE_START,
    payload: sis_thaidohoctap
}
export interface IThaiDoHocTapSaveSuccess {
    type: eThaiDoHocTapActionTypeIds.SAVE_SUCCESS
}
export interface IThaiDoHocTapSaveErr {
    type: eThaiDoHocTapActionTypeIds.SAVE_ERR,
    payload: string
}
export type IThaiDoHocTapActionTypes = IThaiDoHocTapLoadStart | IThaiDoHocTapLoadSuccess | IThaiDoHocTapLoadErr |
    IThaiDoHocTapSelectStart | IThaiDoHocTapSelectSuccess | IThaiDoHocTapSelectErr |
    IThaiDoHocTapShowDetail | IThaiDoHocTapCloseDetail |
    IThaiDoHocTapChangeSelectedIds |
    IThaiDoHocTapShowConfirm | IThaiDoHocTapCloseConfirm |
    IThaiDoHocTapDeleteStart | IThaiDoHocTapDeleteSuccess | IThaiDoHocTapDeleteErr |
    IThaiDoHocTapSaveStart | IThaiDoHocTapSaveSuccess | IThaiDoHocTapSaveErr