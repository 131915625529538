import axios from 'axios';
import { appInfo } from '../AppInfo';
import { ILoginSSORequestModel } from '../models/request/account';
import { apiClient } from './apiClient';

const accountApi = {
    getUser: () => {
        return apiClient.post(`account/detail`)
    },
    logInSSO: (data: ILoginSSORequestModel) => {
        return axios.post(`${appInfo.baseApiURL}/account/login`, data)
    },
    logOut() {
        return apiClient.post(`api/logout`, undefined,appInfo.homeURL)
    },
    menuCountData: (dm_truong_id: number) => {
        return apiClient.get(`menu/truong/${dm_truong_id}/count-by-type`)
    }

}
export {accountApi}