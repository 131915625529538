import { IUserInfoModel } from "../../models/response/account/user-info";
import { eAuthActionTypeIds, IAuthActionTypes } from "../action-types/IAuthActionTypes";
export interface IAuthState {
    isLoading: boolean,
    user_info?: IUserInfoModel
}
const initialState: IAuthState = {
    isLoading: false,
}
const authReducer = (state: IAuthState = initialState, action: IAuthActionTypes): IAuthState => {
    switch (action.type) {
        case eAuthActionTypeIds.GET_USER_AUTH:
            return {
                ...state,
                isLoading: true
            }
        case eAuthActionTypeIds.SET_USER_AUTH:

            return {
                ...state,
                user_info: action.payload,
                isLoading: false,
            }
        case eAuthActionTypeIds.CLEAR_USER_AUTH:
            return initialState
        default:
            return state;
    }
}
export { authReducer };

