

import { app_nhombaiviet } from "../../models/response/app-phuhuynh/app_nhombaiviet";
import { apiClient } from "../apiClient";
import { BaseCRUDApi } from "../baseCRUDApi";

export const API_APP_NHOM_BAIVIET_PATH = "app-nhom-baiviet"

class AppNhomBaiVietApi extends BaseCRUDApi<app_nhombaiviet> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectByTruongAsync(dm_truong_id: number) {
        return apiClient.get(`${this._path}/${dm_truong_id}/select-all-by-truong`)
    }
}
export const appNhomBaiVietApi = new AppNhomBaiVietApi({ path: API_APP_NHOM_BAIVIET_PATH });