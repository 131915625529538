import { sis_nhomthanhphandiem } from './../../../models/response/so-diem/sis_nhomthanhphandiem';
export enum eNhomThanhPhanDiemActionTypeIds {
    LOAD_START = "NHOM_THAN_PHAN_DIEM_LOAD_START",
    LOAD_SUCCESS = "NHOM_THAN_PHAN_DIEM_LOAD_SUCCESS",
    LOAD_ERRROR = "NHOM_THAN_PHAN_DIEM_LOAD_ERROR"
}
export interface INhomThanhPhanDiemLoadStart {
    type: eNhomThanhPhanDiemActionTypeIds.LOAD_START
}
export interface INhomThanhPhanDiemLoadSuccess {
    type: eNhomThanhPhanDiemActionTypeIds.LOAD_SUCCESS,
    payload: sis_nhomthanhphandiem[]
}
export interface INhomThanhPhanDiemLoadError {
    type: eNhomThanhPhanDiemActionTypeIds.LOAD_ERRROR,
    payload: string
}
export type INhomThanhPhanDiemActionTypes = INhomThanhPhanDiemLoadStart |
    INhomThanhPhanDiemLoadSuccess |
    INhomThanhPhanDiemLoadError