import { INotifySelectRequest } from '../../../models/request/notify/INotifySelectRequest';
import { INotifyUserItemRespone } from '../../../models/response/notify/INotifyUserItemRespone';
import { IActionTypeBase } from './../IActionTypeBase';
export enum eNotifyActionTypeIds {
    LOAD_START = "NOTIFY_LOAD_START",
    LOAD_SUCCESS = "NOTIFY_LOAD_SUCCESS",
    LOAD_ERROR = "NOTIFY_LOAD_ERROR",

    MARK_AS_READ_START = "NOTIFY_MARK_AS_READ_START",
    MARK_AS_READ_SUCCESS = "NOTIFY_MARK_AS_READ_SUCCESS",
    MARK_AS_READ_ERROR = "NOTIFY_MARK_AS_READ_ERROR",

    MARK_ALL_AS_READ_START = "NOTIFY_MARK_ALL_AS_READ_START",
    MARK_ALL_AS_READ_SUCCESS = "NOTIFY_MARK_ALL_AS_READ_SUCCESS",
    MARK_ALL_AS_READ_ERROR = "NOTIFY_MARK_ALL_AS_READ_ERROR",

    COUNT_UN_READ_START = "NOTIFY_COUNT_UN_READ_START",
    COUNT_UN_READ_SUCCESS = "NOTIFY_COUNT_UN_READ_SUCCESS",
    COUNT_UN_READ_ERROR = "NOTIFY_COUNT_UN_READ_ERROR",
}
export interface INotifyLoadStart extends IActionTypeBase<eNotifyActionTypeIds.LOAD_START, INotifySelectRequest> { }
export interface INotifyLoadSuccess extends IActionTypeBase<eNotifyActionTypeIds.LOAD_SUCCESS, INotifyUserItemRespone[]> { }
export interface INotifyLoadError extends IActionTypeBase<eNotifyActionTypeIds.LOAD_ERROR, string> { }


export interface INotifyMarkAsReadStart extends IActionTypeBase<eNotifyActionTypeIds.MARK_AS_READ_START, number> { }
export interface INotifyMarkAsReadSuccess extends IActionTypeBase<eNotifyActionTypeIds.MARK_AS_READ_SUCCESS, number> { }
export interface INotifyMarkAsReadError extends IActionTypeBase<eNotifyActionTypeIds.MARK_AS_READ_ERROR, string> { }

export interface INotifyMarkAllAsReadStart extends IActionTypeBase<eNotifyActionTypeIds.MARK_ALL_AS_READ_START, undefined> { }
export interface INotifyMarkAllAsReadSuccess extends IActionTypeBase<eNotifyActionTypeIds.MARK_ALL_AS_READ_SUCCESS, undefined> { }
export interface INotifyMarkAllAsReadError extends IActionTypeBase<eNotifyActionTypeIds.MARK_ALL_AS_READ_ERROR, string> { }

export interface INotifyCountUnReadStart extends IActionTypeBase<eNotifyActionTypeIds.COUNT_UN_READ_START, undefined> { }
export interface INotifyCountUnReadSuccess extends IActionTypeBase<eNotifyActionTypeIds.COUNT_UN_READ_SUCCESS, number> { }
export interface INotifyCountUnReadError extends IActionTypeBase<eNotifyActionTypeIds.COUNT_UN_READ_ERROR, string> { }

export type INotifyActionTyes = INotifyLoadStart | INotifyLoadSuccess | INotifyLoadError |
    INotifyMarkAsReadStart | INotifyMarkAsReadSuccess | INotifyMarkAsReadError |
    INotifyMarkAllAsReadStart | INotifyMarkAllAsReadSuccess | INotifyMarkAllAsReadError |
    INotifyCountUnReadStart | INotifyCountUnReadSuccess | INotifyCountUnReadError