import { IExportDgdkHocTapRequest } from '../models/request/danh-gia/dinh-ky/IExportDgdkHocTapRequest';
import { IImportDgdkHocTapRequest } from '../models/request/danh-gia/dinh-ky/IImportDgdkHocTapRequest';
import { ISelectDgdkHocTapRequest } from '../models/request/danh-gia/dinh-ky/ISelectDgdkHocTapRequest';
import { IUpdateDgdkHocTapRequest } from '../models/request/danh-gia/dinh-ky/IUpdateDgdkHocTapRequest';
import { apiClient } from './apiClient';
export const API_APP_DGDK_KETQUA_HOCTAP_PATH = "dgdk-hoctap"
export const dgdkKetQuaHocTapApi = {
    Select: (request: ISelectDgdkHocTapRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_HOCTAP_PATH}/select`, request),
    Save: (request: IUpdateDgdkHocTapRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_HOCTAP_PATH}/save`, request),
    ValidateImport: (request: IImportDgdkHocTapRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_HOCTAP_PATH}/import/validate`, request),
    Import: (request: IImportDgdkHocTapRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_HOCTAP_PATH}/import`, request),
    Export: (request: IExportDgdkHocTapRequest) => apiClient.post(`${API_APP_DGDK_KETQUA_HOCTAP_PATH}/export`, request),
}