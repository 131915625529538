import { dm_he } from "../../models/response/category/dm_he"
import { dm_khoi } from "../../models/response/category/dm_khoi"
import { dm_namhoc } from "../../models/response/category/dm_namhoc"
import { dm_truong } from "../../models/response/category/dm_truong"
import { dm_truong_khoi_he } from "../../models/response/category/dm_truong_khoi_he"
import { dm_trangthaihocsinh } from "../../models/response/category/dm_trangthaihocsinh"

export enum eCategorySourceActionTypeIds {
    DM_NAMHOC_LOAD_START = "DM_NAMHOC_LOAD_START",
    DM_NAMHOC_LOAD_SUCESS = "DM_NAMHOC_LOAD_SUCESS",

    DM_TRUONG_LOAD_START = "DM_TRUONG_LOAD_START",
    DM_TRUONG_LOAD_SUCCESS = "DM_TRUONG_LOAD_SUCCESS",

    DM_KHOI_LOAD_START = "DM_KHOI_LOAD_START",
    DM_KHOI_LOAD_SUCCESS = "DM_KHOI_LOAD_SUCCESS",

    DM_HE_LOAD_START = "DM_HE_LOAD_START",
    DM_HE_LOAD_SUCCESS = "DM_HE_LOAD_SUCCESS",

    DM_TRUONG_KHOI_HE_LOAD_START = "DM_TRUONG_KHOI_HE_LOAD_START",
    DM_TRUONG_KHOI_HE_LOAD_SUCESS = "DM_TRUONG_KHOI_HE_LOAD_SUCESS",

    DM_TRANGTHAIHOCSINH_LOAD_START = "DM_TRANGTHAIHOCSINH_LOAD_START",
    DM_TRANGTHAIHOCSINH_LOAD_SUCCESS = "DM_TRANGTHAIHOCSINH_LOAD_SUCCESS",


}

export interface INamHocLoadStart {
    type: eCategorySourceActionTypeIds.DM_NAMHOC_LOAD_START
}
export interface INamHocLoadSuccess {
    type: eCategorySourceActionTypeIds.DM_NAMHOC_LOAD_SUCESS,
    payload: dm_namhoc[]
}

export interface ITruongLoadStart {
    type: eCategorySourceActionTypeIds.DM_TRUONG_LOAD_START
}
export interface ITruongLoadSuccess {
    type: eCategorySourceActionTypeIds.DM_TRUONG_LOAD_SUCCESS,
    payload: dm_truong[]
}
export interface IKhoiLoadStart {
    type: eCategorySourceActionTypeIds.DM_KHOI_LOAD_START
}
export interface IKhoiLoadSuccess {
    type: eCategorySourceActionTypeIds.DM_KHOI_LOAD_SUCCESS,
    payload: dm_khoi[]
}
export interface IHeLoadStart {
    type: eCategorySourceActionTypeIds.DM_HE_LOAD_START
}
export interface IHeLoadSuccess {
    type: eCategorySourceActionTypeIds.DM_HE_LOAD_SUCCESS,
    payload: dm_he[]
}

export interface ITruongKhoiHeLoadStart {
    type: eCategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_START
}
export interface ITruongKhoiHeLoadSuccess {
    type: eCategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_SUCESS,
    payload: dm_truong_khoi_he[]
}
export interface ITrangThaiHocSinhLoadStart {
    type: eCategorySourceActionTypeIds.DM_TRANGTHAIHOCSINH_LOAD_START,
    payload: number

}
export interface ITrangThaiHocSinhLoadSuccess {
    type: eCategorySourceActionTypeIds.DM_TRANGTHAIHOCSINH_LOAD_SUCCESS,
    payload: dm_trangthaihocsinh[]
}


export type ICategorySourceActionTypes = INamHocLoadStart | INamHocLoadSuccess |
    ITruongLoadStart | ITruongLoadSuccess |
    IKhoiLoadStart | IKhoiLoadSuccess |
    IHeLoadStart | IHeLoadSuccess |
    ITrangThaiHocSinhLoadStart | ITrangThaiHocSinhLoadSuccess |
    ITruongKhoiHeLoadStart | ITruongKhoiHeLoadSuccess 