import { hocKyActions } from "./hocKyActions";
import { lopActions } from "./lopActions";
import { plNamHocActions } from "./plNamHocActions";
import { thongTuActions } from "./thongTuActions";
import { thuActions } from "./thuActions";
import { tietActions } from "./tietActions";
import { tuanActions } from "./tuanActions";

export const danhMucActionsWrapper = {
    thu: thuActions,
    tuan: tuanActions,
    tiet: tietActions,
    namHoc: plNamHocActions,
    hocKy: hocKyActions,
    lop: lopActions,
    thongTu: thongTuActions
}