import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eThaiDoHocTapTieuChiActionTypeIds, IThaiDoHocTapTieuChiDeleteStart, IThaiDoHocTapTieuChiSaveStart, IThaiDoHocTapTieuChiSelectStart } from "../../action-types/nhan-xet/IThaiDoHocTapTieuChiActionTypes";
import { actions } from "../../actions/actionsWrapper";

const thaiDoHocTapTieuChiApi = apiWrapper.thaiDoHocTapWrapper.thaiDoHocTapTieuChi;

export function* thaiDoHocTapTieuChiSaga(): any {
    yield takeLatest(eThaiDoHocTapTieuChiActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eThaiDoHocTapTieuChiActionTypeIds.SELECT_START, selectWorker)
    yield takeEvery(eThaiDoHocTapTieuChiActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eThaiDoHocTapTieuChiActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([thaiDoHocTapTieuChiApi, thaiDoHocTapTieuChiApi.SelectAll])
    if (res.is_success) {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.loadSuccess(res.data))
    } else {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.loadError(res.message || "Không tải được thái độ học tập tiêu chí"))
    }
}
function* selectWorker(action: IThaiDoHocTapTieuChiSelectStart): any {
    const res: IBaseResponeModel = yield call([thaiDoHocTapTieuChiApi, thaiDoHocTapTieuChiApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.selectSuccess(res.data))
    } else {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.selectError(res.message || "Không tải được thái độ học tập tiêu chí"))
    }
}
function* deleteWorker(action: IThaiDoHocTapTieuChiDeleteStart): any {
    const res: IBaseResponeModel = yield call([thaiDoHocTapTieuChiApi, thaiDoHocTapTieuChiApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.deleteSuccess())
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.loadStart())
    } else {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.deleteError(res.message || "Không xóa được thái độ học tập tiêu chí"))
    }
}
function* saveWorker(action: IThaiDoHocTapTieuChiSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([thaiDoHocTapTieuChiApi, thaiDoHocTapTieuChiApi.Update], action.payload);
    } else {
        res = yield call([thaiDoHocTapTieuChiApi, thaiDoHocTapTieuChiApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.saveSuccess())
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.loadStart())
    } else {
        yield put(actions.thaiDoHocTapWrapper.thaiDoHocTapTieuChi.saveError(res.message || "Không lưu được thái độ học tập tiêu chí"))
    }
}