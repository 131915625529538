import { NotifyHelper } from '../../../helpers/toast';
import { IBaiTap } from '../../../models/response/bai-tap/IBaiTap';
import { IBaiTapKetQua } from '../../../models/response/bai-tap/IBaiTapKetQua';
import { IBaiTapKetQuaItemRespone } from '../../../models/response/bai-tap/IBaiTapKetQuaItemRespone';
import { baseAction } from '../IActionBase';
import { eBaiTapKetQuaActionTypeIds } from './../../action-types/bai-tap/IBaiTapKetQuaActionType';
export const baiTapKetQuaActions = {

    setBaiTap: (data: IBaiTap) => baseAction(eBaiTapKetQuaActionTypeIds.SET_BAITAP, data),

    loadKetQuaStart: (baiTapId: number) => baseAction(eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_START, baiTapId),
    loadKetQuaSuccess: (data: IBaiTapKetQuaItemRespone[]) => baseAction(eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_SUCCESS, data),
    loadKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapKetQuaActionTypeIds.LOAD_KETQUA_ERROR, message)
    },
    saveKetQuaStart: (data: IBaiTapKetQua) => baseAction(eBaiTapKetQuaActionTypeIds.SAVE_KETQUA_START, data),
    saveKetQuaSuccess: (data: IBaiTapKetQua) => baseAction(eBaiTapKetQuaActionTypeIds.SAVE_KETQUA_SUCCESS, data),
    saveKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapKetQuaActionTypeIds.SAVE_KETQUA_ERROR, message)
    },

    showPulishMarkModal: (isOpen: boolean) => baseAction(eBaiTapKetQuaActionTypeIds.SHOW_PUBLISH_MARK_MODAL, isOpen),

    publishMarkStart: (baiTapId: number) => baseAction(eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_START, baiTapId),
    publishMarkSuccess: () => baseAction(eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_SUCCESS, undefined),
    publishMarkError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapKetQuaActionTypeIds.PUBLISH_MARK_ERROR, message)
    },

    showSendNotifyModal: (isOpen: boolean) => baseAction(eBaiTapKetQuaActionTypeIds.SHOW_NOTIFY_MODAL, isOpen),

    sendNotifyStart: (baiTapId: number) => baseAction(eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_START, baiTapId),
    sendNotifySuccess: () => baseAction(eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_SUCCESS, undefined),
    sendNotifyError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapKetQuaActionTypeIds.SEND_NOTIFY_ERROR, message)
    },

}