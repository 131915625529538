import { IHocSinhHoTroModel } from "../../../models/response/hoc-sinh/IHocSinhHoTroModel";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eHocSinhHoTroActionTypeIds {
    LOAD_BY_TRUONG_START = 'HocSinhHoTro_LOAD_BY_TRUONG_START',
    LOAD_BY_TRUONG_SUCCESS = 'HocSinhHoTro_LOAD_BY_TRUONG_SUCCESS',
    LOAD_BY_TRUONG_ERROR = 'HocSinhHoTro_LOAD_BY_TRUONG_ERROR',

    SAVE_START = "HocSinhHoTro_SAVE_START",
    SAVE_SUCCESS = "HocSinhHoTro_SAVE_SUCCESS",
    SAVE_ERROR = "HocSinhHoTro_SAVE_ERROR",
}

export interface IHocSinhHoTroLoadByTruongStart extends IActionTypeBase<eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_START, number> { }
export interface IHocSinhHoTroLoadByTruongSuccess extends IActionTypeBase<eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_SUCCESS, IHocSinhHoTroModel[]> { }
export interface IHocSinhHoTroLoadByTruongError extends IActionTypeBase<eHocSinhHoTroActionTypeIds.LOAD_BY_TRUONG_ERROR, string> { }

export interface IHocSinhHoTroSaveStart extends IActionTypeBase<eHocSinhHoTroActionTypeIds.SAVE_START, IHocSinhHoTroModel> { }
export interface IHocSinhHoTroSaveSuccess extends IActionTypeBase<eHocSinhHoTroActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface IHocSinhHoTroSaveError extends IActionTypeBase<eHocSinhHoTroActionTypeIds.SAVE_ERROR, string> { }

export type IHocSinhHoTroActionTypes = IHocSinhHoTroLoadByTruongStart | IHocSinhHoTroLoadByTruongSuccess | IHocSinhHoTroLoadByTruongError | IHocSinhHoTroSaveStart | IHocSinhHoTroSaveSuccess | IHocSinhHoTroSaveError;