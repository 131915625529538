import { takeEvery } from 'redux-saga/effects';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from '../../actions/actionsWrapper';
import { apiWrapper } from './../../../api/apiWrapper';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { eNotifyActionTypeIds, INotifyLoadStart, INotifyMarkAllAsReadStart, INotifyMarkAsReadStart, INotifyCountUnReadStart } from './../../action-types/notify/INotifyActionTypes';



export function* notifySaga(): any {
    yield takeEvery(eNotifyActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eNotifyActionTypeIds.MARK_AS_READ_START, markAsRead)
    yield takeEvery(eNotifyActionTypeIds.MARK_ALL_AS_READ_START, markAllAsRead)
    yield takeLatest(eNotifyActionTypeIds.COUNT_UN_READ_START, countUnReadTotal)
}

function* loadWorker(action: INotifyLoadStart): any {
    const res: IBaseResponeModel = yield call(apiWrapper.notify.select, action.payload)
    if (res.is_success) {
        yield put(actions.notify.loadSuccess(res.data))
    } else {
        yield put(actions.notify.loadError(res.message ?? "Error"))
    }
}

function* markAsRead(action: INotifyMarkAsReadStart): any {
    const res: IBaseResponeModel = yield call(apiWrapper.notify.markAsRead, action.payload)
    if (res.is_success) {
        yield put(actions.notify.markAsReadSuccess(action.payload))
        yield call(countUnReadTotal)
    } else {
        yield put(actions.notify.markAsReadError(res.message ?? "Error"))
    }
}

function* markAllAsRead(action: INotifyMarkAllAsReadStart): any {
    const res: IBaseResponeModel = yield call(apiWrapper.notify.markAllAsRead)
    if (res.is_success) {
        yield put(actions.notify.markAllAsReadSuccess())
    } else {
        yield put(actions.notify.markAllAsReadError(res.message ?? "Error"))
    }
}

function* countUnReadTotal(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.notify.countUnReadTotal)
    if (res.is_success) {
        yield put(actions.notify.countUnReadSuccess(res.data))
    } else {
        yield put(actions.notify.countUnReadError(res.message ?? "Error"))
    }
}
