import { NotifyHelper } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eDiemDanhAnActionTypeIds, IDiemDanhAnActionTypes } from "../../action-types/diem-danh-an/IDiemDanhAnActionTypes";
import { IDiemDanhAnPageState } from "../../page-state-model/diem-danh-an/IDiemDanhAnPageState";

const initialState: IDiemDanhAnPageState = {
    status: ePageBaseStatus.is_not_initialization,
    sis_diemdanh_an_hocsinhs: [],
    is_show_diemdanh_an_modal: false,
    sis_diemdanh_an_hocsinhs_modal: [],
    lop_diem_danhs_an: [],
    sis_diemdanh_an_selected_ids: []
}

export const diemDanhAnReducer = (state: IDiemDanhAnPageState = initialState, action: IDiemDanhAnActionTypes): IDiemDanhAnPageState => {
    switch (action.type) {
        case eDiemDanhAnActionTypeIds.SELECT_CONFIG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
            }
        case eDiemDanhAnActionTypeIds.SELECT_CONFIG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lop_diem_danhs_an: action.payload
            }
        case eDiemDanhAnActionTypeIds.SELECT_CONFIG_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lop_diem_danhs_an: []
            }
        case eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS:
            console.log(action.payload);
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_diemdanh_an_hocsinhs: action.payload
            }
        case eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_diemdanh_an_hocsinhs: []
            }
        case eDiemDanhAnActionTypeIds.SHOW_DIEMDANH_AN_MODAL:
            return {
                ...state,
                is_show_diemdanh_an_modal: action.payload
            }
        case eDiemDanhAnActionTypeIds.CHANGE_DANHSACH_DIEMDANH:
            return {
                ...state,
                sis_diemdanh_an_hocsinhs_modal: action.payload
            }
        case eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_diemdanh_an_modal: false
            }
        case eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_diemdanh_an_modal: false
            }
        case eDiemDanhAnActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_diemdanh_an_selected_ids: action.payload
            }
        default:
            return state;
    }

}