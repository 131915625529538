import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_chamtiet_loaitiet } from "../../../models/response/cham-tiet/sis_chamtiet_loaitiet";
import {
    IChamTietLoaiTietChangeSelectedIds, IChamTietLoaiTietCloseConfirm,
    IChamTietLoaiTietCloseDetail, IChamTietLoaiTietDeleteErr, IChamTietLoaiTietDeleteStart, IChamTietLoaiTietDeleteSuccess,
    IChamTietLoaiTietLoadByTruongErr, IChamTietLoaiTietLoadByTruongStart, IChamTietLoaiTietLoadByTruongSuccess, IChamTietLoaiTietLoadErr,
    IChamTietLoaiTietLoadStart, IChamTietLoaiTietLoadSuccess, IChamTietLoaiTietShowAddModal, IChamTietLoaiTietShowConfirm, IChamTietLoaiTietShowDetail,
    eChamTietLoaiTietActionTypeIds
} from "../../action-types/cham-tiet/IChamTietLoaiTietActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const chamTietLoaiTietActions = {
    //ALL
    loadChamTietLoaiTietStart: (): IChamTietLoaiTietLoadStart => BaseAction(undefined, eChamTietLoaiTietActionTypeIds.LOAD_START),
    loadChamTietLoaiTietSuccess: (respone: sis_chamtiet_loaitiet[]): IChamTietLoaiTietLoadSuccess => BaseAction(respone, eChamTietLoaiTietActionTypeIds.LOAD_SUCCESS),
    loadChamTietLoaiTietError: (messege: string): IChamTietLoaiTietLoadErr => BaseAction(messege, eChamTietLoaiTietActionTypeIds.LOAD_ERROR),
    //By truong
    loadByTruongChamTietLoaiTietStart: (dm_truong_id: number): IChamTietLoaiTietLoadByTruongStart => BaseAction(dm_truong_id, eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_START),
    loadByTruongChamTietLoaiTietSuccess: (respone: sis_chamtiet_loaitiet[]): IChamTietLoaiTietLoadByTruongSuccess => BaseAction(respone, eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_SUCCESS),
    loadByTruongChamTietLoaiTietError: (messege: string): IChamTietLoaiTietLoadByTruongErr => BaseAction(messege, eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_ERROR),
    //not in truong
    showChamTietLoaiTietAddModal: (show: boolean): IChamTietLoaiTietShowAddModal => BaseAction(show, eChamTietLoaiTietActionTypeIds.SHOW_ADD_MODAL),
    showChamTietLoaiTietDetail: (data?: sis_chamtiet_loaitiet): IChamTietLoaiTietShowDetail => BaseAction(data, eChamTietLoaiTietActionTypeIds.SHOW_DETAIL),
    closeChamTietLoaiTietDetail: (): IChamTietLoaiTietCloseDetail => BaseAction(undefined, eChamTietLoaiTietActionTypeIds.CLOSE_DETAIL),
    showChamTietLoaiTietConfirm: (): IChamTietLoaiTietShowConfirm => BaseAction(undefined, eChamTietLoaiTietActionTypeIds.SHOW_CONFIRM),
    closeChamTietLoaiTietConfirm: (): IChamTietLoaiTietCloseConfirm => BaseAction(undefined, eChamTietLoaiTietActionTypeIds.CLOSE_CONFIRM),
    changeChamTietLoaiTietSelectedIds: (ids: number[]): IChamTietLoaiTietChangeSelectedIds => BaseAction(ids, eChamTietLoaiTietActionTypeIds.CHANGE_SELECTED_IDS),
    //delete
    deleteChamTietLoaiTietStart: (ids: IDeleteMultipleBaseRequest): IChamTietLoaiTietDeleteStart => BaseAction(ids, eChamTietLoaiTietActionTypeIds.DELETE_START),
    deleteChamTietLoaiTietSuccess: (): IChamTietLoaiTietDeleteSuccess => BaseAction(undefined, eChamTietLoaiTietActionTypeIds.DELETE_SUCCESS),
    deleteChamTietLoaiTietErr: (message: string): IChamTietLoaiTietDeleteErr => BaseAction(message, eChamTietLoaiTietActionTypeIds.DELETE_ERROR),
    //SAVE
    saveChamTietLoaiTietStart: (data: sis_chamtiet_loaitiet) => BaseAction(data, eChamTietLoaiTietActionTypeIds.SAVE_START),
    saveChamTietLoaiTietSuccess: () => BaseAction(undefined, eChamTietLoaiTietActionTypeIds.SAVE_SUCCESS),
    saveChamTietLoaiTietError: (message: string) => BaseAction(message, eChamTietLoaiTietActionTypeIds.SAVE_ERR),
    clearStatus: () => BaseAction(undefined, eChamTietLoaiTietActionTypeIds.CLEAR_STATUS)
}


