import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eAppXinNghiActionTypeIds, IAppXinNghiLoadDetailStart, IAppXinNghiLoadSelectStart, IAppXinNghiUpdatePhanHoiStart, IAppXinNghiUpdateStatusStart } from "../../action-types/app-phuhuynh/IAppXinNghiActionTypes";
import { actions } from "../../actions/actionsWrapper";

const appXinNghiApi = apiWrapper.appXinNghi;

function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([appXinNghiApi, appXinNghiApi.getAllViewAsync])
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.loadSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.loadError(res.message || "Không tải được đơn nghỉ phép"))
    }
}

function* loadSelectWorker(action: IAppXinNghiLoadSelectStart): any {

    const res: IBaseResponeModel = yield call([appXinNghiApi, appXinNghiApi.selectViewAsync], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.loadSelectSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.loadSelectError(res.message || "Không tải được đơn nghỉ phép"))
    }
}

function* loadDetailWorker(action: IAppXinNghiLoadDetailStart): any {

    const res: IBaseResponeModel = yield call([appXinNghiApi, appXinNghiApi.getDetailViewAsync], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.loadDetailSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.loadDetailError(res.message || "Không tải được đơn nghỉ phép"))
    }
}

function* updateStatusWorker(action: IAppXinNghiUpdateStatusStart): any {
    const res: IBaseResponeModel = yield call([appXinNghiApi, appXinNghiApi.updateStatus], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.updateStatusSuccess())
        yield put(actions.appPhuHuynhWrapper.appXinNghi.loadStart())
    } else {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.updateStatusError(res.message || "Không cập nhật được trạng thái"))
    }
}

function* updatePhanHoiWorker(action: IAppXinNghiUpdatePhanHoiStart): any {
    const res: IBaseResponeModel = yield call([appXinNghiApi, appXinNghiApi.updatePhanHoi], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.updatePhanHoiSuccess())
        yield put(actions.appPhuHuynhWrapper.appXinNghi.loadStart())
    } else {
        yield put(actions.appPhuHuynhWrapper.appXinNghi.updatePhanHoiError(res.message || "Không cập nhật được phản hồi"))
    }
}

export function* appXinNghiSaga(): any {
    yield takeLatest(eAppXinNghiActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eAppXinNghiActionTypeIds.LOAD_SELECT_START, loadSelectWorker)
    yield takeLatest(eAppXinNghiActionTypeIds.LOAD_DETAIL_START, loadDetailWorker)
    yield takeEvery(eAppXinNghiActionTypeIds.UPDATE_STATUS_START, updateStatusWorker)
    yield takeEvery(eAppXinNghiActionTypeIds.UPDATE_PHANHOI_START, updatePhanHoiWorker)
}