import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IChamTietLoaiTietDeleteStart, IChamTietLoaiTietLoadByTruongStart, IChamTietLoaiTietSaveStart, eChamTietLoaiTietActionTypeIds } from "../../action-types/cham-tiet/IChamTietLoaiTietActionTypes";
import { chamTietLoaiTietApi } from "../../../api/chamTietLoaiTietApi";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from "../../actions/actionsWrapper";

export function* chamTietLoaiTietSaga(): any {
    yield takeLatest(eChamTietLoaiTietActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eChamTietLoaiTietActionTypeIds.LOAD_BYTRUONG_START, loadByTruongWorker)
    yield takeEvery(eChamTietLoaiTietActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eChamTietLoaiTietActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([chamTietLoaiTietApi, chamTietLoaiTietApi.SelectAll])
    if (res.is_success) {
        yield put(actions.chamTietLoaiTiet.loadChamTietLoaiTietSuccess(res.data))
    } else {
        yield put(actions.chamTietLoaiTiet.loadChamTietLoaiTietError(res.message || "Không tải được loại tiết"))
    }
}
function* loadByTruongWorker(action: IChamTietLoaiTietLoadByTruongStart): any {
    const res: IBaseResponeModel = yield call([chamTietLoaiTietApi, chamTietLoaiTietApi.SelectAllAsync], action.payload)
    if (res.is_success) {
        yield put(actions.chamTietLoaiTiet.loadByTruongChamTietLoaiTietSuccess(res.data))
    } else {
        yield put(actions.chamTietLoaiTiet.loadByTruongChamTietLoaiTietError(res.message || "Không tải được loại tiết"))
    }
}
function* deleteWorker(action: IChamTietLoaiTietDeleteStart): any {
    const res: IBaseResponeModel = yield call([chamTietLoaiTietApi, chamTietLoaiTietApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.chamTietLoaiTiet.deleteChamTietLoaiTietSuccess())
    } else {
        yield put(actions.chamTietLoaiTiet.deleteChamTietLoaiTietErr(res.message || "Không tải được loại tiết"))
    }
}
function* saveWorker(action: IChamTietLoaiTietSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([chamTietLoaiTietApi, chamTietLoaiTietApi.Update], action.payload);
    } else {
        res = yield call([chamTietLoaiTietApi, chamTietLoaiTietApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.chamTietLoaiTiet.saveChamTietLoaiTietSuccess())
    } else {
        yield put(actions.chamTietLoaiTiet.saveChamTietLoaiTietError(res.message || "Không lưu được loại tiết"))
    }
}

