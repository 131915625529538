import { INhapDiemLoadDiemSuccess } from './../../action-types/nhap-diem/INhapDiemActionType';
import { call, put, takeLatest } from "redux-saga/effects";
import { hocSinhApi } from '../../../api/hocSinhApi';
import { lopMonHocApi } from '../../../api/lopMonHocApi';
import { xepHangHocSinhApi } from "../../../api/xepHangHocSinhApi";
import { IBaseResponeModel } from '../../../models/response/base-response';
import { actions } from '../../actions/actionsWrapper';
import { eXepHangHocSinhActionTypeIds, IXepHangHocSinhHocSinhLoadStart, IXepHangHocSinhMonHocLoadStart, IXepHangHocSinhStart, IXepHangKetQuaLoadStart, IXepHangDiemLoadStart } from './../../action-types/xep-hang-hoc-sinh/IXepHangHocSinhActionTypes';


export function* xepHangHocSinhSaga(): any {
    yield takeLatest(eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_START, loadHocSinhWorker)
    yield takeLatest(eXepHangHocSinhActionTypeIds.MONHOC_LOAD_START, loadMonHocWorker)
    yield takeLatest(eXepHangHocSinhActionTypeIds.KETQUA_LOAD_START, loadKetQuaWorker)
    yield takeLatest(eXepHangHocSinhActionTypeIds.XEPHANG_START, xepHangWorker)
    yield takeLatest(eXepHangHocSinhActionTypeIds.DIEM_LOAD_START, loadDiemWorker)
}

function* loadHocSinhWorker(action: IXepHangHocSinhHocSinhLoadStart): any {
    const res: IBaseResponeModel = yield call([hocSinhApi, hocSinhApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.xepHangHocSinh.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.xepHangHocSinh.loadHocSinhError(res.message ?? ""))
    }
}

function* loadMonHocWorker(action: IXepHangHocSinhMonHocLoadStart): any {
    const res: IBaseResponeModel = yield call(lopMonHocApi.SelectByLop, action.payload)
    if (res.is_success) {
        yield put(actions.xepHangHocSinh.loadMonHocSuccess(res.data.filter((x: any) => x.is_mon_xet_danh_hieu)))
    } else {
        yield put(actions.xepHangHocSinh.loadMonHocError(res.message ?? ""))
    }
}

function* xepHangWorker(action: IXepHangHocSinhStart): any {
    const res: IBaseResponeModel = yield call(xepHangHocSinhApi.XepHang, action.payload)
    if (res.is_success) {
        yield put(actions.xepHangHocSinh.xepHangSuccess(res.data))
    } else {
        yield put(actions.xepHangHocSinh.xepHangError(res.message ?? ""))
    }
}

function* loadKetQuaWorker(action: IXepHangKetQuaLoadStart): any {
    const res: IBaseResponeModel = yield call(xepHangHocSinhApi.Select, action.payload)
    if (res.is_success) {
        yield put(actions.xepHangHocSinh.loadKetQuaSuccess(res.data))
    } else {
        yield put(actions.xepHangHocSinh.loadKetQuaError(res.message ?? ""))
    }
}

function* loadDiemWorker(action: IXepHangDiemLoadStart): any {
    const res: IBaseResponeModel = yield call(xepHangHocSinhApi.SelectDiem, action.payload)
    if (res.is_success) {
        yield put(actions.xepHangHocSinh.loadDiemSuccess(res.data))
    } else {
        yield put(actions.xepHangHocSinh.loadDiemError(res.message ?? ""))
    }
}