import { toast } from 'react-toastify';
type NotifyInputType = {
    message: string,
    type: "info" | "warning" | "success" | "error" | "default",
    displayTime?: number

}

export const showNotify = (props: NotifyInputType) => {
    toast(props.message, {
        type: props.type,
        hideProgressBar: true,
        autoClose: props.displayTime || 3000,
        position: "bottom-center",
        theme: "colored"
    })
}

export const NotifyHelper = {
    Success: (message: string) => showNotify({ message: message, type: "success" }),
    Error: (message: string) => showNotify({ message: message, type: "error" }),
    Warning: (message: string) => showNotify({ message: message, type: "warning" })
}