import { IHocSinhHoTroModel } from "../models/response/hoc-sinh/IHocSinhHoTroModel";
import { apiClient } from "./apiClient";

export const hocSinhHoTroApi = {
    SelectByLopAsync(dm_lop_id: number) {
        const queryString = new URLSearchParams({ dm_lop_id: dm_lop_id } as any);
        return apiClient.get(`hoc-sinh-ho-tro/select-by-lop${queryString ? `?${queryString}` : ''}`);
    },
    SaveHoTroAsync(request: IHocSinhHoTroModel) {
        return apiClient.post('hoc-sinh-ho-tro/save-hotro', request); 
    },
}