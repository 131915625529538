import { call, put, takeEvery } from "redux-saga/effects";
import { IBaseResponeModel } from "../../models/response/base-response";
import { eDictionaryActionTypeIds } from "../action-types/IDictionaryActionTypes";
import { apiWrapper } from './../../api/apiWrapper';
import { actions } from './../actions/actionsWrapper';

export function* dictionarySaga(): any {
    yield takeEvery(eDictionaryActionTypeIds.LOAD_DICTIONARY_START, loadDictionaryWorker)
}
function* loadDictionaryWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.dictionary.getDictionary)
    if (res.is_success) {
        yield put(actions.dictionary.loadDictionarySuccess(res.data))
    }
}
