import { sis_thangdiem } from './../../../models/response/so-diem/sis_thangdiem';
import { IThangDiemChangeSelectedIds, IThangDiemCloseConfirm, IThangDiemCloseDetail, IThangDiemDeleteErr, IThangDiemDeleteStart, IThangDiemDeleteSuccess, IThangDiemShowConfirm, IThangDiemShowDetail, eThangDiemActionTypeIds } from './../../action-types/so-diem/IThangDiemActions';
import { BaseAction } from "../IBaseActionResult"
import { baseAction } from '../IActionBase';
import { sis_thangdiem_option } from '../../../models/response/so-diem/sis_thangdiem_option';

export const thangDiemActions = {
    loadStart: () => BaseAction(undefined, eThangDiemActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_thangdiem[]) => BaseAction(respone, eThangDiemActionTypeIds.LOAD_SUCCESS),
    loadError: (message: string) => BaseAction(message, eThangDiemActionTypeIds.LOAD_ERROR),

    showThangDiemDetail: (data?: sis_thangdiem): IThangDiemShowDetail => {
        return {
            type: eThangDiemActionTypeIds.SHOW_DETAIL,
            payload: data
        }
    },
    closeThangDiemDetail: (): IThangDiemCloseDetail => {
        return {
            type: eThangDiemActionTypeIds.CLOSE_DETAIL,

        }
    },
    showThangDiemConfirm: (): IThangDiemShowConfirm => {
        return {
            type: eThangDiemActionTypeIds.SHOW_CONFIRM,
        }
    },
    closeThangDiemConfirm: (): IThangDiemCloseConfirm => {
        return {
            type: eThangDiemActionTypeIds.CLOSE_CONFIRM,
        }
    },
    changeThangDiemSelectedIds: (ids: number[]): IThangDiemChangeSelectedIds => {
        return {
            type: eThangDiemActionTypeIds.CHANGE_SELECTED_IDS,
            payload: ids
        }
    },
    deleteThangDiemStart: (ids: number): IThangDiemDeleteStart => {
        return {
            type: eThangDiemActionTypeIds.DELETE_START,
            payload: ids
        }
    },
    deleteThangDiemSuccess: (): IThangDiemDeleteSuccess => {
        return {
            type: eThangDiemActionTypeIds.DELETE_SUCCESS,
        }
    },
    deleteThangDiemErr: (message: string): IThangDiemDeleteErr => {
        return {
            type: eThangDiemActionTypeIds.DELETE_ERROR,
            payload: message
        }
    },
    saveThangDiemStart: (data: sis_thangdiem) => {
        return {
            type: eThangDiemActionTypeIds.SAVE_START,
            payload: data
        }
    },
    saveThangDiemSuccess: () => {
        return {
            type: eThangDiemActionTypeIds.SAVE_SUCCESS,
        }
    },
    saveThangDiemError: (message: string) => {
        return {
            type: eThangDiemActionTypeIds.SAVE_ERR,
            payload: message
        }
    },
    showOptionDetail:(thangDiem:sis_thangdiem)=>baseAction(eThangDiemActionTypeIds.SHOW_OPTION_DETAIL,thangDiem),
    closeOptionDetail:()=>baseAction(eThangDiemActionTypeIds.CLOSE_OPTION_DETAIL,undefined),

    saveOptionStart:(data:sis_thangdiem_option)=> baseAction(eThangDiemActionTypeIds.SAVE_OPTION_START, data),
    saveOptionSuccess:(data:sis_thangdiem_option)=> baseAction(eThangDiemActionTypeIds.SAVE_OPTION_SUCCESS, data),
    saveOptionError:(data:string)=> baseAction(eThangDiemActionTypeIds.SAVE_OPTION_ERR, data),

    deleteOptionStart:(data:number)=> baseAction(eThangDiemActionTypeIds.DELETE_OPTION_START, data),
    deleteOptionSuccess:()=> baseAction(eThangDiemActionTypeIds.DELETE_OPTION_START, undefined),
    deleteOptionError:(data:string)=> baseAction(eThangDiemActionTypeIds.DELETE_OPTION_ERROR, data),

    loadOptionStart:(thangDiemId: number)=> baseAction(eThangDiemActionTypeIds.LOAD_OPTION_START, thangDiemId),
    loadOptionSuccess:(data:sis_thangdiem_option[])=> baseAction(eThangDiemActionTypeIds.LOAD_OPTION_START, data),
    loadOptionError:(data:string)=> baseAction(eThangDiemActionTypeIds.LOAD_OPTION_ERROR, data),
}