import { apiWrapper } from './../../../api/apiWrapper';
import { actions } from './../../actions/actionsWrapper';
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { soDiemApi } from './../../../api/soDiemApi';
import { eSoDiemListActionTypeIds, ISoDiemListDeleteStart, ISoDiemListLoadStart, ISoDiemListSaveStart } from './../../action-types/so-diem/ISoDiemListAcitonTypes';



export function* soDiemListSaga(): any {
    yield takeLatest(eSoDiemListActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eSoDiemListActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eSoDiemListActionTypeIds.SAVE_START, saveWorker)

}

function* loadWorker(action: ISoDiemListLoadStart): any {
    const res: IBaseResponeModel = yield call([soDiemApi, soDiemApi.SelectByTruong], action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemList.loadSuccess(res.data))
    } else {
        yield put(actions.soDiem.soDiemList.loadError(res.message || "Không tải được sổ điểm"))
    }
}

function* deleteWorker(action: ISoDiemListDeleteStart): any {
    const res: IBaseResponeModel = yield call([soDiemApi, soDiemApi.Delete], action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemList.deleteSuccess())
    } else {
        yield put(actions.soDiem.soDiemList.deleteError(res.message || "Không xóa được sổ điểm"))
    }
}

function* saveWorker(action: ISoDiemListSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([soDiemApi, soDiemApi.Update], action.payload)
    } else {
        res = yield call([soDiemApi, soDiemApi.Insert], action.payload)
    }
    if (res.is_success) {
        yield put(actions.soDiem.soDiemList.saveSuccess())
    } else {
        yield put(actions.soDiem.soDiemList.saveError(res.message || "Cập nhật thất bại"))
    }
}