import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { apiWrapper } from "../../../api/apiWrapper"
import { IBaseResponeModel } from "../../../models/response/base-response"
import { eLopActionTypeIds, ILopChangeHocSinhStart, ILopChangeHocSinhThuTuStart, 
    ILopChangeThongTuStart, ILopChangeThuTuMacDinhStart, ILopDeleteStart, 
    ILopLoadAllStart, ILopLoadStart, ILopLockParentAppStudentStart, 
    ILopRemoveHocSinhStart, ILopSaveStart, 
    ILopSelectDetailStart, ILopSelectHocSinhChuaPhanLopStart, ILopSelectHocSinhStart, 
    ILopSelectLopChuNhiemStart, ILopSelectStart, ILopTreeCteLoadStart, 
    ILopUploadExcelStartAction, ILopSaveExcelStartAction, ILopUploadLopExcelStartAction, ILopSaveLopExcelStartAction } from "../../action-types/danh-muc/ILopActionTypes"
import { actions } from "../../actions/actionsWrapper"

function* loadLopAllWorker(data: ILopLoadAllStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.SelectAll])
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.loadAllSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.lop.loadAllError(res.message || "Không tải được lớp"))
    }
}

function* loadLopWorker(data: ILopLoadStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.Select], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.loadSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.lop.loadError(res.message || "Không tải được lớp"))
    }
}

function* loadLopTreeCteWorker(data: ILopTreeCteLoadStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.SelectTreeView], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.loadTreeCteSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.lop.loadTreeCteError(res.message || "Không tải được lớp"))
    }
}


function* selectLopChuNhiemWorker(data: ILopSelectLopChuNhiemStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.SelectLopChuNhiemAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.selectLopChuNhiemSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.lop.selectLopChuNhiemError(res.message || "Không tìm thấy lớp chủ nhiệm"))
    }
}

function* selectWorker(data: ILopSelectStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.Select], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.selectSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.lop.selectError(res.message || "Không tải được lớp"))
    }
}

function* selectDetailWorker(data: ILopSelectDetailStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.SelectById], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.selectDetailSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.lop.selectDetailError(res.message || "Không tải được lớp"))
    }
}

function* deleteWorker(action: ILopDeleteStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.danhMucWrapper.lop.deleteSuccess())
    } else {
        yield put(actions.danhMucWrapper.lop.deleteError(res.message || "Không xóa được lớp"))
    }
}
function* saveWorker(action: ILopSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([apiWrapper.lop, apiWrapper.lop.Update], action.payload);
    } else {
        res = yield call([apiWrapper.lop, apiWrapper.lop.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.danhMucWrapper.lop.saveSuccess())
        // yield put(actions.danhMucWrapper.lop.selectStart({ nam_hoc: action.payload.nam_hoc, dm_he_id: 0, dm_khoi_id: 0, dm_truong_id: action.payload.dm_truong_id  }))
    } else {
        yield put(actions.danhMucWrapper.lop.saveError(res.message || "Không lưu được lớp"))
    }
}

function* changeThongTuWorker(data: ILopChangeThongTuStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.ChangeThongTuAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.changeThongTuSuccess())
    } else {
        yield put(actions.danhMucWrapper.lop.changeThongTuError(res.message || "Không lưu được thay đổi"))
    }
}

function* selectHocSinhWorker(data: ILopSelectHocSinhStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.SelectHocSinhByLopsAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.selectHocSinhSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.lop.selectHocSinhError(res.message || "Không tải được học sinh"))
    }
}

function* selectHocSinhChuaPhanLopWorker(data: ILopSelectHocSinhChuaPhanLopStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.SelectHocSinhChuaPhanLopAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.selectHocSinhChuaPhanLopSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.lop.selectHocSinhChuaPhanLopError(res.message || "Không tải được học sinh"))
    }
}

function* selectPhuHuynhWorker(data: ILopSelectHocSinhStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.SelectPhuHuynh], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.selectPhuHuynhSuccess(res.data))
    } else {
        yield put(actions.danhMucWrapper.lop.selectPhuHuynhError(res.message ?? "Error"))
    }
}

function* changeHocSinhWorker(data: ILopChangeHocSinhStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.ChangeHocSinhClassAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.changeHocSinhSuccess())
    } else {
        yield put(actions.danhMucWrapper.lop.changeHocSinhError(res.message || "Không lưu được thay đổi"))
    }
}

function* changeHocSinhThuTuWorker(data: ILopChangeHocSinhThuTuStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.ChangeThuTuHocSinhAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.changeHocSinhThuTuSuccess())
    } else {
        yield put(actions.danhMucWrapper.lop.changeHocSinhThuTuError(res.message || "Không lưu được thay đổi"))
    }
}

function* changeThuTuMacDinhWorker(data: ILopChangeThuTuMacDinhStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.ChangeThuTuTheoHoTenABCAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.changeThuTuMacDinhSuccess())
    } else {
        yield put(actions.danhMucWrapper.lop.changeThuTuMacDinhError(res.message || "Không lưu được thay đổi"))
    }
}

function* removeHocSinhWorker(data: ILopRemoveHocSinhStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.RemoveHocSinhClassAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.danhMucWrapper.lop.changeHocSinhSuccess())
    } else {
        yield put(actions.danhMucWrapper.lop.changeHocSinhError(res.message || "Không lưu được thay đổi"))
    }
}

function* loadFromExcelWorker(data: ILopUploadExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.LoadFromExcel], data.payload);
        if (res.is_success) {
            yield put(actions.danhMucWrapper.lop.loadFromExcelSuccess(res.data))
        } else {
            yield put(actions.danhMucWrapper.lop.loadFromExcelError(res.message || "Không upload được file excel"))
        }
    } catch (error) {
        yield put(actions.danhMucWrapper.lop.loadFromExcelError("Upload file excel có lỗi"))
    }
}


function* saveFromExcelWorker(data: ILopSaveExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.SaveDataExcel], data.payload);
        if (res.is_success) {
            yield put(actions.danhMucWrapper.lop.saveFromExcelSuccess(res.data))
        } else {
            yield put(actions.danhMucWrapper.lop.saveFromExcelError(res.message || "Không lưu được dữ liệu excel"))
        }
    } catch (error) {
        yield put(actions.danhMucWrapper.lop.saveFromExcelError("Lưu được dữ liệu excel có lỗi"))
    }
}

function* lockParentAppWorker(data: ILopLockParentAppStudentStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.LockParentApp], data.payload);
    if (res.is_success) {
        yield put(actions.danhMucWrapper.lop.lockParentAppSuccess())
    } else {
        yield put(actions.danhMucWrapper.lop.lockParentAppError(res.message ?? "Error"))
    }
}

function* loadLopFromExcelWorker(data: ILopUploadLopExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.LoadLopFromExcel], data.payload);
        if (res.is_success) {
            yield put(actions.danhMucWrapper.lop.loadLopFromExcelSuccess(res.data))
        } else {
            yield put(actions.danhMucWrapper.lop.loadLopFromExcelError(res.message || "Không upload được file excel"))
        }
    } catch (error) {
        yield put(actions.danhMucWrapper.lop.loadLopFromExcelError("Upload file excel có lỗi"))
    }
}


function* saveLopFromExcelWorker(data: ILopSaveLopExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([apiWrapper.lop, apiWrapper.lop.SaveDataLopExcel], data.payload);
        if (res.is_success) {
            yield put(actions.danhMucWrapper.lop.saveLopFromExcelSuccess(res.data))
        } else {
            yield put(actions.danhMucWrapper.lop.saveLopFromExcelError(res.message || "Không lưu được dữ liệu excel"))
        }
    } catch (error) {
        yield put(actions.danhMucWrapper.lop.saveLopFromExcelError("Lưu được dữ liệu excel có lỗi"))
    }
}

export function* lopSaga(): any {
    yield takeLatest(eLopActionTypeIds.LOAD_START, loadLopWorker)
    yield takeLatest(eLopActionTypeIds.LOAD_ALL_START, loadLopAllWorker)
    yield takeLatest(eLopActionTypeIds.LOAD_TREE_CTE_START, loadLopTreeCteWorker)
    yield takeLatest(eLopActionTypeIds.SELECT_LOPCHUNHIEM_START, selectLopChuNhiemWorker)
    yield takeLatest(eLopActionTypeIds.SELECT_START, selectWorker)
    yield takeLatest(eLopActionTypeIds.SELECT_DETAIL_START, selectDetailWorker)
    yield takeLatest(eLopActionTypeIds.SELECT_HOCSINH_START, selectHocSinhWorker)
    yield takeLatest(eLopActionTypeIds.SELECT_HOCSINH_CHUAPHANLOP_START, selectHocSinhChuaPhanLopWorker)
    yield takeEvery(eLopActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eLopActionTypeIds.SAVE_START, saveWorker)
    yield takeEvery(eLopActionTypeIds.CHANGE_THONGTU_START, changeThongTuWorker)
    yield takeEvery(eLopActionTypeIds.CHANGE_HOCSINH_START, changeHocSinhWorker)
    yield takeEvery(eLopActionTypeIds.CHANGE_HOCSINH_THUTU_START, changeHocSinhThuTuWorker)
    yield takeEvery(eLopActionTypeIds.CHANGE_THUTU_MACDINH_START, changeThuTuMacDinhWorker)
    yield takeEvery(eLopActionTypeIds.REMOVE_HOCSINH_START, removeHocSinhWorker)
    yield takeEvery(eLopActionTypeIds.UPLOAD_HOCSINH_EXCEL_START, loadFromExcelWorker)
    yield takeEvery(eLopActionTypeIds.SAVE_HOCSINH_EXCEL_START, saveFromExcelWorker)
    yield takeEvery(eLopActionTypeIds.LOCK_PARENT_APP_START, lockParentAppWorker)
    yield takeEvery(eLopActionTypeIds.LOAD_PHUHUYNH_START, selectPhuHuynhWorker)
    yield takeEvery(eLopActionTypeIds.UPLOAD_LOP_EXCEL_START, loadLopFromExcelWorker)
    yield takeEvery(eLopActionTypeIds.SAVE_LOP_EXCEL_START, saveLopFromExcelWorker)
}