import { IReadUploadedExcelFileRequest } from "../../../models/request/upload-file/IReadUploadedExcelFileRequest";
import { IReadExcelResult } from "../../../models/response/upload-file/IReadExcelResult";
import { IUploadExcelResult } from "../../../models/response/upload-file/IUploadExcelResult";
import { IUploadImageResult } from "../../../models/response/upload-file/IUploadImageResult";
import { eUploadActionTypeIds, IReadExcelErrorAction, IReadExcelStartAction, IReadExcelSuccessAction, IUploadExcelErrorAction, IUploadExcelStartAction, IUploadExcelSuccessAction, IUploadImageCompleteAction, IUploadImageErrorAction, IUploadImageStartAction, IUploadImageSuccessAction, IUploadSvgCompleteAction, IUploadSvgErrorAction, IUploadSvgStartAction, IUploadSvgSuccessAction } from "../../action-types/upload-file/IUploadActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const uploadActions = {
    uploadExcelStart: (data: File): IUploadExcelStartAction => BaseAction(data, eUploadActionTypeIds.UPLOAD_EXCEL_START),
    uploadExcelSuccess: (respone: IUploadExcelResult): IUploadExcelSuccessAction => BaseAction(respone, eUploadActionTypeIds.UPLOAD_EXCEL_SUCCESS),
    uploadExcelError: (message: string): IUploadExcelErrorAction => BaseAction(message, eUploadActionTypeIds.UPLOAD_EXCEL_ERROR),

    uploadSvgStart: (data: File): IUploadSvgStartAction => BaseAction(data, eUploadActionTypeIds.UPLOAD_SVG_START),
    uploadSvgSuccess: (respone?: IUploadImageResult): IUploadSvgSuccessAction => BaseAction(respone, eUploadActionTypeIds.UPLOAD_SVG_SUCCESS),
    uploadSvgError: (message: string): IUploadSvgErrorAction => BaseAction(message, eUploadActionTypeIds.UPLOAD_SVG_ERROR),
    uploadSvgComplete: (): IUploadSvgCompleteAction => BaseAction(undefined, eUploadActionTypeIds.UPLOAD_SVG_COMPLETE),

    uploadImageStart: (data: File): IUploadImageStartAction => BaseAction(data, eUploadActionTypeIds.UPLOAD_IMAGE_START),
    uploadImageSuccess: (respone?: IUploadImageResult): IUploadImageSuccessAction => BaseAction(respone, eUploadActionTypeIds.UPLOAD_IMAGE_SUCCESS),
    uploadImageError: (message: string): IUploadImageErrorAction => BaseAction(message, eUploadActionTypeIds.UPLOAD_IMAGE_ERROR),
    uploadImageComplete: (): IUploadImageCompleteAction => BaseAction(undefined, eUploadActionTypeIds.UPLOAD_IMAGE_COMPLETE),

    readExcelStart: (data: IReadUploadedExcelFileRequest): IReadExcelStartAction => BaseAction(data, eUploadActionTypeIds.READ_EXCEL_START),
    readExcelSuccess: (respone: IReadExcelResult): IReadExcelSuccessAction => BaseAction(respone, eUploadActionTypeIds.READ_EXCEL_SUCCESS),
    readExcelError: (message: string): IReadExcelErrorAction => BaseAction(message, eUploadActionTypeIds.READ_EXCEL_ERROR),
}