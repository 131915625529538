import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../../models/response/base-response";
import { eDgdkHocTapActionTypeIds, IDgdkHocTapDmLoadStart, IDgdkHocTapHocSinhLoadStart, IDgdkHocTapImportStart, IDgdkHocTapSaveKetQuaStart, IDgdkHocTapValidateStart } from "../../../action-types/danh-gia/dinh-ky/IDgdkHocTapActionType";
import { actions } from '../../../actions/actionsWrapper';
import { IDgdkHocTapLoadKetQuaStart } from './../../../action-types/danh-gia/dinh-ky/IDgdkHocTapActionType';


const lopBoMonHocSinhApi = apiWrapper.lopBoMonHocSinh;
const soNhanXetItemApi = apiWrapper.soNhanXetItem;
const dgdkHocTapApi = apiWrapper.danhGia.dinhKy.hocTap;

export function* dgdkHocTapSaga(): any {
    yield takeLatest(eDgdkHocTapActionTypeIds.HOCSINH_LOAD_START, loadHocSinhWorker)
    yield takeLatest(eDgdkHocTapActionTypeIds.SONHANXET_ITEM_LOAD_START, loadDmNLPCWorker)
    yield takeLatest(eDgdkHocTapActionTypeIds.KETQUA_LOAD_START, loadKetQuaWorker)
    yield takeEvery(eDgdkHocTapActionTypeIds.KETQUA_SAVE_START, saveKetQuaWorker)
    yield takeEvery(eDgdkHocTapActionTypeIds.VALIDATE_IMPORT_START, validateImportWorker)
    yield takeEvery(eDgdkHocTapActionTypeIds.IMPORT_START, importWorker)
}

function* loadHocSinhWorker(action: IDgdkHocTapHocSinhLoadStart): any {
    const res: IBaseResponeModel = yield call([lopBoMonHocSinhApi, lopBoMonHocSinhApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.hocTap.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.hocTap.loadHocSinhError(res.message ?? ""))
    }
}

function* loadDmNLPCWorker(action: IDgdkHocTapDmLoadStart): any {
    const res: IBaseResponeModel = yield call([soNhanXetItemApi, soNhanXetItemApi.SelectBySet], action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.hocTap.soNhanXetLoadItemSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.hocTap.soNhanXetLoadItemError(res.message ?? ""))
    }
}

function* loadKetQuaWorker(action: IDgdkHocTapLoadKetQuaStart): any {
    const res: IBaseResponeModel = yield call(dgdkHocTapApi.Select, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.hocTap.loadKetQuaSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.hocTap.loadKetQuaError(res.message ?? ""))
    }
}

function* saveKetQuaWorker(action: IDgdkHocTapSaveKetQuaStart): any {
    const res: IBaseResponeModel = yield call(dgdkHocTapApi.Save, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.hocTap.saveKetQuaSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.hocTap.saveKetQuaError(res.message ?? ""))
    }
}
function* validateImportWorker(action: IDgdkHocTapValidateStart): any {
    const res: IBaseResponeModel = yield call(dgdkHocTapApi.ValidateImport, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.hocTap.validateImportSuccess(res.data))
    } else {
        yield put(actions.danhGia.dinhKy.hocTap.validateImportError(res.message ?? ""))
    }
}

function* importWorker(action: IDgdkHocTapImportStart): any {
    const res: IBaseResponeModel = yield call(dgdkHocTapApi.Import, action.payload)
    if (res.is_success) {
        yield put(actions.danhGia.dinhKy.hocTap.importSuccess())
    } else {
        yield put(actions.danhGia.dinhKy.hocTap.importError(res.message ?? ""))
    }
}
