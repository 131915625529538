import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { ISoNhanXetItem, sis_dgdk_dm_nlpc_treeview } from "../../../models/response/dgdk-dm/ISoNhanXetItem"

export enum eSoNhanXetItemActionTypeIds {
    LOAD_START = "SONHANXET_ITEM_LOAD_START",
    LOAD_SUCCESS = "SONHANXET_ITEM_LOAD_SUCCESS",
    LOAD_ERROR = "SONHANXET_ITEM_LOAD_ERROR",

    SHOW_DETAIL = "SONHANXET_ITEM_SHOW_DETAIL",
    CLOSE_DETAIL = "SONHANXET_ITEM_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "SONHANXET_ITEM_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "SONHANXET_ITEM_SHOW_CONFIRM",
    CLOSE_CONFIRM = "SONHANXET_ITEM_CLOSE_CONFIRM",

    DELETE_START = "SONHANXET_ITEM_DELETE_START",
    DELETE_SUCCESS = "SONHANXET_ITEM_DELETE_SUCCESS",
    DELETE_ERROR = "SONHANXET_ITEM_DELETE_ERROR",

    SAVE_START = "SONHANXET_ITEM_SAVE_START",
    SAVE_SUCCESS = "SONHANXET_ITEM_SAVE_SUCCESS",
    SAVE_ERR = "SONHANXET_ITEM_SAVE_ERR"
}
//ORIGIN MODEL
export interface ISoNhanXetItemLoadStart {
    type: eSoNhanXetItemActionTypeIds.LOAD_START,
    payload: number
}
export interface ISoNhanXetItemLoadSuccess {
    type: eSoNhanXetItemActionTypeIds.LOAD_SUCCESS,
    payload: sis_dgdk_dm_nlpc_treeview[]
}
export interface ISoNhanXetItemLoadErr {
    type: eSoNhanXetItemActionTypeIds.LOAD_ERROR,
    payload: string
}
//DETAIL
export interface ISoNhanXetItemShowDetail {
    type: eSoNhanXetItemActionTypeIds.SHOW_DETAIL,
    payload?: sis_dgdk_dm_nlpc_treeview
}
export interface ISoNhanXetItemCloseDetail {
    type: eSoNhanXetItemActionTypeIds.CLOSE_DETAIL,
}
export interface ISoNhanXetItemChangeSelectedIds {
    type: eSoNhanXetItemActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
//CONFIRM
export interface ISoNhanXetItemShowConfirm {
    type: eSoNhanXetItemActionTypeIds.SHOW_CONFIRM
}
export interface ISoNhanXetItemCloseConfirm {
    type: eSoNhanXetItemActionTypeIds.CLOSE_CONFIRM
}
//DELETE
export interface ISoNhanXetItemDeleteStart {
    type: eSoNhanXetItemActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface ISoNhanXetItemDeleteSuccess {
    type: eSoNhanXetItemActionTypeIds.DELETE_SUCCESS,
}
export interface ISoNhanXetItemDeleteErr {
    type: eSoNhanXetItemActionTypeIds.DELETE_ERROR,
    payload: string
}
//SAVE
export interface ISoNhanXetItemSaveStart {
    type: eSoNhanXetItemActionTypeIds.SAVE_START,
    payload: ISoNhanXetItem
}
export interface ISoNhanXetItemSaveSuccess {
    type: eSoNhanXetItemActionTypeIds.SAVE_SUCCESS
}
export interface ISoNhanXetItemSaveErr {
    type: eSoNhanXetItemActionTypeIds.SAVE_ERR,
    payload: string
}

export type ISoNhanXetItemActionTypes =
    ISoNhanXetItemLoadStart | ISoNhanXetItemLoadSuccess | ISoNhanXetItemLoadErr |
    ISoNhanXetItemShowDetail | ISoNhanXetItemCloseDetail |
    ISoNhanXetItemChangeSelectedIds |
    ISoNhanXetItemShowConfirm | ISoNhanXetItemCloseConfirm |
    ISoNhanXetItemDeleteStart | ISoNhanXetItemDeleteSuccess | ISoNhanXetItemDeleteErr |
    ISoNhanXetItemSaveStart | ISoNhanXetItemSaveSuccess | ISoNhanXetItemSaveErr