
import moment from "moment";
import { IDiemDanhAnConfigSelectRequest } from "../models/request/diem-danh-an/IDiemDanhAnConfigSelectRequest";
import { IDiemDanhAnLopChuNhiemChangeRequest } from "../models/request/diem-danh-an/IDiemDanhAnLopChuNhiemChangeRequest";
import { IDiemDanhAnReportRequest } from "../models/request/diem-danh-an/IDiemDanhAnReportRequest";
import { IDiemDanhAnLopChuNhiemSelectRequest } from "../models/request/diem-danh-an/IDiemDanhAnLopChuNhiemSelectRequest";
import { apiClient } from "./apiClient";
import { IDiemDanhAnSelectByTruongRequest } from "../models/request/diem-danh-an/IDiemDanhAnSelectByTruongRequest";

export const API_DIEMDANH_AN_PATH = "diem-danh-an"
export const diemDanhAnApi = {
    SelectLopDiemDanhAsync: (request: IDiemDanhAnConfigSelectRequest) => {
        return apiClient.post(`${API_DIEMDANH_AN_PATH}/select-lop-diem-danh-an`, request)
    },
    SelectByLopChuNhiemAsync: (request: IDiemDanhAnLopChuNhiemSelectRequest) => {
        return apiClient.post(`${API_DIEMDANH_AN_PATH}/select-by-lopchunhiem`, request)
    },
    DiemDanhLopChuNhiemAsync: (request: IDiemDanhAnLopChuNhiemChangeRequest) => {
        return apiClient.post(`${API_DIEMDANH_AN_PATH}/diem-danh-an-lopchunhiem`, request)
    },
    ReportAsync: (request: IDiemDanhAnReportRequest) => {
        return apiClient.get(`${API_DIEMDANH_AN_PATH}/report/${request.meal_id}/${moment(request.ngayDiemDanh).format("YYYY-MM-DD")}/co-so/${request.dm_truong_id}`)
    },
    SelectByTruongAsync: (request: IDiemDanhAnSelectByTruongRequest) => {
        return apiClient.get(`${API_DIEMDANH_AN_PATH}/from-date/${moment(request.fromDate).format("YYYY-MM-DD")}/to-date/${moment(request.toDate).format("YYYY-MM-DD")}/truong/${request.dm_truong_id}/khoi/${request.dm_khoi_id}`)
    }
};