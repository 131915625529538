import { call, put, takeLatest } from 'redux-saga/effects';
import { appBaiVietCommentApi } from '../../../api/app/appBaiVietCommentApi';
import { IBaseResponeModel } from '../../../models/response/base-response';
import { IBaiVietCommentAddStart, IBaiVietCommentLoadStart, eBaiVietCommentActionTypeIds } from '../../action-types/bai-viet-comment/IBaiVietCommentActionts';
import { actions } from '../../actions/actionsWrapper';


export function* baiVietCommentSaga(): any {
    yield takeLatest(eBaiVietCommentActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eBaiVietCommentActionTypeIds.ADD_COMMENT_START, addCommentWorker)
}

function* loadWorker(action: IBaiVietCommentLoadStart): any {
    const res: IBaseResponeModel = yield call(appBaiVietCommentApi.selectByBaiVietAsync, action.payload)
    if (res.is_success && res.data) {
        yield put(actions.baiVietComment.loadSuccess(res.data))
    } else {
        yield put(actions.baiVietComment.loadError(res.message || "Không tải được bài viết"))
    }
}



function* addCommentWorker(action: IBaiVietCommentAddStart): any {
    const res: IBaseResponeModel = yield call(appBaiVietCommentApi.addCommentAsync, action.payload)
    if (res.is_success && res.data) {
        yield put(actions.baiVietComment.addCommentSuccess(res.data))
    } else {
        yield put(actions.baiVietComment.addCommentError(res.message || "Không tải được bài viết"))
    }
}

