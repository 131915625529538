import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { pl_namhoc } from "../../../models/response/danh-muc/pl_namhoc";
import { ePLNamHocActionTypeIds, IPLNamHocChangeSelectedIds, IPLNamHocCloseConfirm, IPLNamHocCloseDetail, IPLNamHocDeleteErr, IPLNamHocDeleteStart, IPLNamHocDeleteSuccess, IPLNamHocLoadErr, IPLNamHocLoadStart, IPLNamHocLoadSuccess, IPLNamHocSaveErr, IPLNamHocSaveStart, IPLNamHocSaveSuccess, IPLNamHocShowConfirm, IPLNamHocShowDetail } from "../../action-types/danh-muc/IPLNamHocActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const plNamHocActions = {
    loadStart: (): IPLNamHocLoadStart => BaseAction(undefined, ePLNamHocActionTypeIds.LOAD_START),
    loadSuccess: (respone: pl_namhoc[]): IPLNamHocLoadSuccess => BaseAction(respone, ePLNamHocActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IPLNamHocLoadErr => BaseAction(messege, ePLNamHocActionTypeIds.LOAD_ERROR),
    showDetail: (data?: pl_namhoc): IPLNamHocShowDetail => BaseAction(data, ePLNamHocActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IPLNamHocCloseDetail => BaseAction(undefined, ePLNamHocActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IPLNamHocShowConfirm => BaseAction(undefined, ePLNamHocActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IPLNamHocCloseConfirm => BaseAction(undefined, ePLNamHocActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IPLNamHocChangeSelectedIds => BaseAction(ids, ePLNamHocActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IPLNamHocDeleteStart => BaseAction(ids, ePLNamHocActionTypeIds.DELETE_START),
    deleteSuccess: (): IPLNamHocDeleteSuccess => BaseAction(undefined, ePLNamHocActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IPLNamHocDeleteErr => BaseAction(message, ePLNamHocActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: pl_namhoc): IPLNamHocSaveStart => BaseAction(data, ePLNamHocActionTypeIds.SAVE_START),
    saveSuccess: ():IPLNamHocSaveSuccess => BaseAction(undefined, ePLNamHocActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IPLNamHocSaveErr =>  BaseAction(message, ePLNamHocActionTypeIds.SAVE_ERR)
}