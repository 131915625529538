import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { IThaiDoHocTapSelectRequest } from "../../../models/request/nhan-xet/IThaiDoHocTapSelectRequest";
import { sis_thaidohoctap } from "../../../models/response/nhan-xet/sis_thaidohoctap";
import { eThaiDoHocTapActionTypeIds, IThaiDoHocTapChangeSelectedIds, IThaiDoHocTapCloseConfirm, IThaiDoHocTapCloseDetail, IThaiDoHocTapDeleteErr, IThaiDoHocTapDeleteStart, IThaiDoHocTapDeleteSuccess, IThaiDoHocTapLoadErr, IThaiDoHocTapLoadStart, IThaiDoHocTapLoadSuccess, IThaiDoHocTapSaveErr, IThaiDoHocTapSaveStart, IThaiDoHocTapSaveSuccess, IThaiDoHocTapSelectErr, IThaiDoHocTapSelectStart, IThaiDoHocTapSelectSuccess, IThaiDoHocTapShowConfirm, IThaiDoHocTapShowDetail } from "../../action-types/nhan-xet/IThaiDoHocTapActionTypes";

import { BaseAction } from "../IBaseActionResult";

export const thaiDoHocTapActions = {
    loadStart: (): IThaiDoHocTapLoadStart => BaseAction(undefined, eThaiDoHocTapActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_thaidohoctap[]): IThaiDoHocTapLoadSuccess => BaseAction(respone, eThaiDoHocTapActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IThaiDoHocTapLoadErr => BaseAction(messege, eThaiDoHocTapActionTypeIds.LOAD_ERROR),

    selectStart: (request: IThaiDoHocTapSelectRequest): IThaiDoHocTapSelectStart => BaseAction(request, eThaiDoHocTapActionTypeIds.SELECT_START),
    selectSuccess: (respone: sis_thaidohoctap[]): IThaiDoHocTapSelectSuccess => BaseAction(respone, eThaiDoHocTapActionTypeIds.SELECT_SUCCESS),
    selectError: (messege: string): IThaiDoHocTapSelectErr => BaseAction(messege, eThaiDoHocTapActionTypeIds.SELECT_ERROR),

    showDetail: (data?: sis_thaidohoctap): IThaiDoHocTapShowDetail => BaseAction(data, eThaiDoHocTapActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IThaiDoHocTapCloseDetail => BaseAction(undefined, eThaiDoHocTapActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IThaiDoHocTapShowConfirm => BaseAction(undefined, eThaiDoHocTapActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IThaiDoHocTapCloseConfirm => BaseAction(undefined, eThaiDoHocTapActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IThaiDoHocTapChangeSelectedIds => BaseAction(ids, eThaiDoHocTapActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IThaiDoHocTapDeleteStart => BaseAction(ids, eThaiDoHocTapActionTypeIds.DELETE_START),
    deleteSuccess: (): IThaiDoHocTapDeleteSuccess => BaseAction(undefined, eThaiDoHocTapActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IThaiDoHocTapDeleteErr => BaseAction(message, eThaiDoHocTapActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_thaidohoctap): IThaiDoHocTapSaveStart => BaseAction(data, eThaiDoHocTapActionTypeIds.SAVE_START),
    saveSuccess: ():IThaiDoHocTapSaveSuccess => BaseAction(undefined, eThaiDoHocTapActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IThaiDoHocTapSaveErr =>  BaseAction(message, eThaiDoHocTapActionTypeIds.SAVE_ERR)
}