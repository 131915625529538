import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eAppMenuTruongActionTypeIds, IAppMenuTruongAddMenuStart, IAppMenuTruongDeleteStart, IAppMenuTruongLoadStart } from "../../action-types/app-phuhuynh/IAppMenuTruongActionTypes";
import { actions } from "../../actions/actionsWrapper";

const appMenuTruongApi = apiWrapper.appMenuTruong;

export function* appMenuTruongSaga(): any {
    yield takeLatest(eAppMenuTruongActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eAppMenuTruongActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eAppMenuTruongActionTypeIds.ADD_APP_MENU_START, addMenuWorker)
}
function* loadWorker(action: IAppMenuTruongLoadStart): any {

    const res: IBaseResponeModel = yield call([appMenuTruongApi, appMenuTruongApi.getAllAsync], action.payload)
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appMenuTruong.loadSuccess(res.data))
    } else {
        yield put(actions.appPhuHuynhWrapper.appMenuTruong.loadError(res.message || "Không tải được chức năng"))
    }
}

function* deleteWorker(action: IAppMenuTruongDeleteStart): any {
    const { dm_truong_id, app_menu_truong_ids } = action.payload;
    const res: IBaseResponeModel = yield call([appMenuTruongApi, appMenuTruongApi.deleteAppMenuAsync], { ids: app_menu_truong_ids })
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appMenuTruong.deleteSuccess())
        yield put(actions.appPhuHuynhWrapper.appMenuTruong.loadStart(dm_truong_id))
    } else {
        yield put(actions.appPhuHuynhWrapper.appMenuTruong.deleteError(res.message || "Không xóa được chức năng"))
    }
}
function* addMenuWorker(action: IAppMenuTruongAddMenuStart): any {
    const { dm_truong_id } = action.payload;
    const res: IBaseResponeModel = yield call([appMenuTruongApi, appMenuTruongApi.addAppMenuAsync], action.payload);
    if (res.is_success) {
        yield put(actions.appPhuHuynhWrapper.appMenuTruong.addMenuSuccess())
        yield put(actions.appPhuHuynhWrapper.appMenuTruong.loadStart(dm_truong_id))
    } else {
        yield put(actions.appPhuHuynhWrapper.appMenuTruong.addMenuError(res.message || "Không lưu được nhóm chức năng"))
    }
}