
// export abstract class IBaseActionRespone<X, Y> {
//     type: X;
//     payload: Y;
//     constructor(type: X, payload: Y) {
//         this.type = type;
//         this.payload = payload
//     }
// }
export class BaseActionRespone<X, Y> {
    type: X;
    payload: Y;
    constructor(type: X, payload: Y) {
        this.type = type;
        this.payload = payload
    }
}

export function BaseAction<X, Y>(input: X, type: Y) {
    return { ...new BaseActionRespone(type, input) }
}
export function BaseActionWithPayloadDifference<X, Y, Z>(input: X, type: Y, payload: Z) {
    return { ...new BaseActionRespone(type, payload) }
}