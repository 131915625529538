import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eLopBoMonTypeActionTypeIds, ILopBoMonTypeActionTypes } from "../../action-types/lop-bo-mon/ILopBoMonTypeActionTypes"
import { ILopBoMonTypePageState } from "../../page-state-model/lop-bo-mon/ILopBoMonTypeState"

const initialState: ILopBoMonTypePageState = {
    status: ePageBaseStatus.is_not_initialization,
    pl_lopbomon_types: [],
    pl_lopbomon_type_selected_ids: [],
    pl_lopbomon_type_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false
}

export const lopBoMonTypeReducer = (state: ILopBoMonTypePageState = initialState, action: ILopBoMonTypeActionTypes): ILopBoMonTypePageState => {
    switch (action.type) {
        case eLopBoMonTypeActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopBoMonTypeActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_lopbomon_types: action.payload
            }
        case eLopBoMonTypeActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_lopbomon_types: []
            }
        case eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_lopbomon_types: action.payload
            }
        case eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_lopbomon_types: []
            }
        case eLopBoMonTypeActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                pl_lopbomon_type_editing: action.payload
            }
        case eLopBoMonTypeActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                pl_lopbomon_type_editing: undefined
            }
        case eLopBoMonTypeActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                pl_lopbomon_type_selected_ids: action.payload
            }
        case eLopBoMonTypeActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eLopBoMonTypeActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eLopBoMonTypeActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eLopBoMonTypeActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eLopBoMonTypeActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eLopBoMonTypeActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonTypeActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eLopBoMonTypeActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}