
//IDgtxKetQuaMonHoc

import { IImportDgtxHocTapRequest } from "../../../../models/request/danh-gia/thuong-xuyen/IImportDgtxHocTapRequest";
import { ISelectDgtxKetQuaHocTapRequest } from "../../../../models/request/danh-gia/thuong-xuyen/ISelectDgtxKetQuaHocTapRequest";
import { IUpdateDgtxHocTapRequest } from "../../../../models/request/danh-gia/thuong-xuyen/IUpdateDgtxHocTapRequest";

import { ILopBoMonSelectHocSinhRequest } from "../../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonSelectHocSinhRequest";
import { IDgtxKetQuaMonHoc } from "../../../../models/response/danh-gia/thuong-xuyen/IDgtxKetQuaMonHoc";
import { ISoNhanXetItem } from "../../../../models/response/dgdk-dm/ISoNhanXetItem";
import { IHocSinhSelectResponeItem } from "../../../../models/response/hoc-sinh/IHocSinhSelectRespone";
import { IDgtxKetQuaMonHocFilter } from "../../../page-state-model/danh-gia/thuong-xuyen/IDgtxKetQuaMonHocPageState";
import { IActionTypeBase } from "../../IActionTypeBase";

//DGTX_HOCTAP_
export enum eDgtxKetQuaMonHocActionTypeIds {
    CHANGE_FILTER = "DGTX_HOCTAP_CHANGE_FILTER",

    HOCSINH_LOAD_START = "DGTX_HOCTAP_HOCSINH_LOAD_START",
    HOCSINH_LOAD_SUCCESS = "DGTX_HOCTAP_HOCSINH_LOAD_SUCCESS",
    HOCSINH_LOAD_ERROR = "DGTX_HOCTAP_HOCSINH_LOAD_ERROR",

    SONHANXET_ITEM_LOAD_START = "DGTX_HOCTAP_DM_NLPC_LOAD_START",
    SONHANXET_ITEM_LOAD_SUCCESS = "DGTX_HOCTAP_DM_NLPC_LOAD_SUCCESS",
    SONHANXET_ITEM_LOAD_ERROR = "DGTX_HOCTAP_DM_NLPC_LOAD_ERROR",

    KETQUA_LOAD_START = "DGTX_HOCTAP_KETQUA_LOAD_START",
    KETQUA_LOAD_SUCCESS = "DGTX_HOCTAP_KETQUA_LOAD_SUCCESS",
    KETQUA_LOAD_ERROR = "DGTX_HOCTAP_KETQUA_LOAD_ERROR",

    KETQUA_SAVE_START = "DGTX_HOCTAP_KETQUA_SAVE_START",
    KETQUA_SAVE_SUCCESS = "DGTX_HOCTAP_KETQUA_SAVE_SUCCESS",
    KETQUA_SAVE_ERROR = "DGTX_HOCTAP_KETQUA_SAVE_ERROR",

    VALIDATE_IMPORT_START = "DGTX_HOCTAP_VALIDATE_IMPORT_START",
    VALIDATE_IMPORT_SUCCESS = "DGTX_HOCTAP_VALIDATE_IMPORT_SUCCESS",
    VALIDATE_IMPORT_ERRROR = "DGTX_HOCTAP_VALIDATE_IMPORT_ERRROR",

    IMPORT_START = "DGTX_HOCTAP_IMPORT_START",
    IMPORT_SUCCESS = "DGTX_HOCTAP_IMPORT_SUCCESS",
    IMPORT_ERRROR = "DGTX_HOCTAP_IMPORT_ERRROR",


}
export interface IDgtxKetQuaMonHocChangeFilter extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.CHANGE_FILTER, IDgtxKetQuaMonHocFilter> { }

export interface IDgtxKetQuaMonHocHocSinhLoadStart extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_START, ILopBoMonSelectHocSinhRequest> { }
export interface IDgtxKetQuaMonHocHocSinhLoadSuccess extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_SUCCESS, IHocSinhSelectResponeItem[]> { }
export interface IDgtxKetQuaMonHocHocSinhLoadError extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_ERROR, string> { }

export interface IDgtxKetQuaMonHocDmLoadStart extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_START, number> { }
export interface IDgtxKetQuaMonHocDmLoadSuccess extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_SUCCESS, ISoNhanXetItem[]> { }
export interface IDgtxKetQuaMonHocDmLoadError extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_ERROR, string> { }

export interface IDgtxKetQuaMonHocLoadKetQuaStart extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_START, ISelectDgtxKetQuaHocTapRequest> { }
export interface IDgtxKetQuaMonHocLoadKetQuaSuccess extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_SUCCESS, IDgtxKetQuaMonHoc[]> { }
export interface IDgtxKetQuaMonHocLoadKetQuaError extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_ERROR, string> { }

export interface IDgtxKetQuaMonHocSaveKetQuaStart extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.KETQUA_SAVE_START, IUpdateDgtxHocTapRequest> { }
export interface IDgtxKetQuaMonHocSaveKetQuaSuccess extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.KETQUA_SAVE_SUCCESS, IDgtxKetQuaMonHoc> { }
export interface IDgtxKetQuaMonHocSaveKetQuaError extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_ERROR, string> { }


export interface IDgtxHocTapValidateStart extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_START, IImportDgtxHocTapRequest> { }
export interface IDgtxHocTapValidateSuccess extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_SUCCESS, any> { }
export interface IDgtxHocTapValidateError extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_ERRROR, any> { }

export interface IDgtxHocTapImportStart extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.IMPORT_START, IImportDgtxHocTapRequest> { }
export interface IDgtxHocTapImportSuccess extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.IMPORT_SUCCESS, undefined> { }
export interface IDgtxHocTapImportError extends IActionTypeBase<eDgtxKetQuaMonHocActionTypeIds.IMPORT_ERRROR, any> { }

export type IDgtxKetQuaMonHocActionTypes = IDgtxKetQuaMonHocChangeFilter |
    IDgtxKetQuaMonHocHocSinhLoadStart | IDgtxKetQuaMonHocHocSinhLoadSuccess | IDgtxKetQuaMonHocHocSinhLoadError |
    IDgtxKetQuaMonHocDmLoadStart | IDgtxKetQuaMonHocDmLoadSuccess | IDgtxKetQuaMonHocDmLoadError |
    IDgtxKetQuaMonHocLoadKetQuaStart | IDgtxKetQuaMonHocLoadKetQuaSuccess | IDgtxKetQuaMonHocLoadKetQuaError |
    IDgtxKetQuaMonHocSaveKetQuaStart | IDgtxKetQuaMonHocSaveKetQuaSuccess | IDgtxKetQuaMonHocSaveKetQuaError|
    IDgtxHocTapValidateStart | IDgtxHocTapValidateSuccess | IDgtxHocTapValidateError |
    IDgtxHocTapImportStart | IDgtxHocTapImportSuccess | IDgtxHocTapImportError
