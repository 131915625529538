import { call, put, takeEvery } from "redux-saga/effects";
import { IBaseResponeModel } from '../../../models/response/base-response';
import { mappingApi } from './../../../api/mappingApi';
import { eMappingActionTypeIds, IMappingSavingStart, IMappingGetStart } from './../../action-types/mapping/IMappingActionTypes';
import { mappingActions } from './../../actions/mapping/mappingAction';

export function* mappingSaga(): any {
    yield takeEvery(eMappingActionTypeIds.SAVING_START, saveWorker)
    yield takeEvery(eMappingActionTypeIds.GET_START, getWorker)
}

function* getWorker(action: IMappingGetStart): any {
    const res: IBaseResponeModel = yield call(mappingApi.get, action.payload)
    if (res.is_success) {
        yield put(mappingActions.getSuccess(res.data))
    } else {
        yield put(mappingActions.getError(res.message ?? ""))
    }
}

function* saveWorker(action: IMappingSavingStart): any {
    const res: IBaseResponeModel = yield call(mappingApi.save, action.payload)
    if (res.is_success) {
        yield put(mappingActions.saveSuccess(res.data))
    } else {
        yield put(mappingActions.saveError(res.message ?? ""))
    }
}
