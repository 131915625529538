import { IAppMenuTruongAddMenuRequest } from "../../../models/request/app-phuhuynh/AppMenuTruongAddMenuRequest"
import { IAppMenuTruongDeleteMenuRequest } from "../../../models/request/app-phuhuynh/AppMenuTruongDeleteMenuRequest"
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { app_menu_truong_view } from "../../../models/response/app-phuhuynh/app_menu_truong"

export enum eAppMenuTruongActionTypeIds {
    LOAD_START = "APP_MENU_TRUONG_LOAD_START",
    LOAD_SUCCESS = "APP_MENU_TRUONG_LOAD_SUCCESS",
    LOAD_ERROR = "APP_MENU_TRUONG_LOAD_ERROR",

    CHANGE_SELECTED_IDS = "APP_MENU_TRUONG_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "APP_MENU_TRUONG_SHOW_CONFIRM",
    CLOSE_CONFIRM = "APP_MENU_TRUONG_CLOSE_CONFIRM",

    DELETE_START = "APP_MENU_TRUONG_DELETE_START",
    DELETE_SUCCESS = "APP_MENU_TRUONG_DELETE_SUCCESS",
    DELETE_ERROR = "APP_MENU_TRUONG_DELETE_ERROR",

    SHOW_ADD_APP_MENU_MODAL = "SHOW_ADD_APP_MENU_MODAL",
    CHANGE_APP_MENU_SELECTED_IDS = "CHANGE_APP_MENU_SELECTED_IDS",
    ADD_APP_MENU_START = "APP_MENU_TRUONG_ADD_APP_MENU_START",
    ADD_APP_MENU_SUCCESS = "APP_MENU_TRUONG_ADD_APP_MENU_SUCCESS",
    ADD_APP_MENU_ERROR = "APP_MENU_TRUONG_ADD_APP_MENU_ERROR",
}

export interface IAppMenuTruongLoadStart {
    type: eAppMenuTruongActionTypeIds.LOAD_START,
    payload: number
}
export interface IAppMenuTruongLoadSuccess {
    type: eAppMenuTruongActionTypeIds.LOAD_SUCCESS,
    payload: app_menu_truong_view[]
}
export interface IAppMenuTruongLoadErr {
    type: eAppMenuTruongActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IAppMenuTruongChangeSelectedIds {
    type: eAppMenuTruongActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IAppMenuTruongShowConfirm {
    type: eAppMenuTruongActionTypeIds.SHOW_CONFIRM
}
export interface IAppMenuTruongCloseConfirm {
    type: eAppMenuTruongActionTypeIds.CLOSE_CONFIRM
}

export interface IAppMenuTruongDeleteStart {
    type: eAppMenuTruongActionTypeIds.DELETE_START,
    payload: IAppMenuTruongDeleteMenuRequest
}
export interface IAppMenuTruongDeleteSuccess {
    type: eAppMenuTruongActionTypeIds.DELETE_SUCCESS,
}
export interface IAppMenuTruongDeleteErr {
    type: eAppMenuTruongActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IAppMenuTruongShowAddMenuModalStart {
    type: eAppMenuTruongActionTypeIds.SHOW_ADD_APP_MENU_MODAL,
    payload: boolean
}
export interface IAppMenuTruongChangeAppMenuSelectedIds {
    type: eAppMenuTruongActionTypeIds.CHANGE_APP_MENU_SELECTED_IDS,
    payload: number[]
}
export interface IAppMenuTruongAddMenuStart {
    type: eAppMenuTruongActionTypeIds.ADD_APP_MENU_START,
    payload: IAppMenuTruongAddMenuRequest
}
export interface IAppMenuTruongAddMenuSuccess {
    type: eAppMenuTruongActionTypeIds.ADD_APP_MENU_SUCCESS
}
export interface IAppMenuTruongAddMenuErr {
    type: eAppMenuTruongActionTypeIds.ADD_APP_MENU_ERROR,
    payload: string
}
export type IAppMenuTruongActionTypes = IAppMenuTruongLoadStart | IAppMenuTruongLoadSuccess | IAppMenuTruongLoadErr |
    IAppMenuTruongChangeSelectedIds |
    IAppMenuTruongShowConfirm | IAppMenuTruongCloseConfirm |
    IAppMenuTruongDeleteStart | IAppMenuTruongDeleteSuccess | IAppMenuTruongDeleteErr |
    IAppMenuTruongShowAddMenuModalStart | IAppMenuTruongChangeAppMenuSelectedIds |
    IAppMenuTruongAddMenuStart | IAppMenuTruongAddMenuSuccess | IAppMenuTruongAddMenuErr