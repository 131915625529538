import { INamHocTruongFilter } from '../../../models/INamHocTruongFilter';
import { IDeleteMultipleBaseRequest } from '../../../models/request/base/deleteRequest';
import { IDiemVanMinhTieuChi } from './../../../models/response/diemvanminh/IDiemVanMinhTieuChi';
export enum eDiemVanMinhTieuChiActionTypeIds {
    LOAD_START = "DIEM_VAN_MINH_TIEU_CHI_LOAD_START",
    LOAD_SUCCESS = "DIEM_VAN_MINH_TIEU_CHI_LOAD_SUCCESS",
    LOAD_ERROR = "DIEM_VAN_MINH_TIEU_CHI_LOAD_ERROR",

    SHOW_DETAIL_MODAL = "DIEM_VAN_MINH_TIEU_CHI_SHOW_DETAIL_MODAL",
    CLOSE_DETAIL_MODAL = "DIEM_VAN_MINH_TIEU_CHI_CLOSE_DETAIL_MODAL",

    SAVE_START = "DIEM_VAN_MINH_TIEU_CHI_SAVE_START",
    SAVE_SUCCESS = "DIEM_VAN_MINH_TIEU_CHI_SAVE_SUCCESS",
    SAVE_ERROR = "DIEM_VAN_MINH_TIEU_CHI_SAVE_ERROR",

    CHANGE_SELECTED = "DIEM_VAN_MINH_TIEU_CHI_CHANGE_SELECTED",

    SHOW_DELETE_CONFIRM = "DIEM_VAN_MINH_TIEU_CHI_SHOW_DELETE_CONFIRM",
    CLOSE_DELETE_CONFIRM = "DIEM_VAN_MINH_TIEU_CHI_CLOSE_DELETE_CONFIRM",

    DELETE_START = "DIEM_VAN_MINH_TIEU_CHI_DELETE_START",
    DELETE_SUCCESS = "DIEM_VAN_MINH_TIEU_CHI_DELETE_SUCCESS",
    DELETE_ERROR = "DIEM_VAN_MINH_TIEU_CHI_DELETE_ERROR",

    CHANGE_EXPANDED_NODE_KEYS = "DIEM_VAN_MINH_TIEU_CHI_CHANGE_EXPANDED_NODE_KEYS",

    CHANGE_FILTER = "DIEM_VAN_MINH_TIEU_CHI_CHANGE_FILTER"

}
export interface IDiemVanMinhTieuChiLoadStart {
    type: eDiemVanMinhTieuChiActionTypeIds.LOAD_START,
    payload: INamHocTruongFilter
}
export interface IDiemVanMinhTieuChiLoadSuccess {
    type: eDiemVanMinhTieuChiActionTypeIds.LOAD_SUCCESS,
    payload: IDiemVanMinhTieuChi[]
}
export interface IDiemVanMinhTieuChiLoadErr {
    type: eDiemVanMinhTieuChiActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IDiemVanMinhTieuChiShowDetail {
    type: eDiemVanMinhTieuChiActionTypeIds.SHOW_DETAIL_MODAL,
    payload?: IDiemVanMinhTieuChi
}
export interface IDiemVanMinhTieuChiCloseDetail {
    type: eDiemVanMinhTieuChiActionTypeIds.CLOSE_DETAIL_MODAL,
}
export interface IDiemVanMinhTieuChiChangeSelectedIds {
    type: eDiemVanMinhTieuChiActionTypeIds.CHANGE_SELECTED,
    payload: number[]
}
export interface IDiemVanMinhTieuChiShowConfirm {
    type: eDiemVanMinhTieuChiActionTypeIds.SHOW_DELETE_CONFIRM
}
export interface IDiemVanMinhTieuChiCloseConfirm {
    type: eDiemVanMinhTieuChiActionTypeIds.CLOSE_DELETE_CONFIRM
}

export interface IDiemVanMinhTieuChiDeleteStart {
    type: eDiemVanMinhTieuChiActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IDiemVanMinhTieuChiDeleteSuccess {
    type: eDiemVanMinhTieuChiActionTypeIds.DELETE_SUCCESS,
}
export interface IDiemVanMinhTieuChiDeleteErr {
    type: eDiemVanMinhTieuChiActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IDiemVanMinhTieuChiSaveStart {
    type: eDiemVanMinhTieuChiActionTypeIds.SAVE_START,
    payload: IDiemVanMinhTieuChi
}
export interface IDiemVanMinhTieuChiSaveSuccess {
    type: eDiemVanMinhTieuChiActionTypeIds.SAVE_SUCCESS
}
export interface IDiemVanMinhTieuChiSaveErr {
    type: eDiemVanMinhTieuChiActionTypeIds.SAVE_ERROR,
    payload: string
}
export interface IDiemVanMinhTieuChiChangeFilter {
    type: eDiemVanMinhTieuChiActionTypeIds.CHANGE_FILTER,
    payload: INamHocTruongFilter
}
export type IDiemVanMinhTieuChiActionTypes = IDiemVanMinhTieuChiLoadStart |
    IDiemVanMinhTieuChiLoadSuccess | IDiemVanMinhTieuChiLoadErr |
    IDiemVanMinhTieuChiShowDetail | IDiemVanMinhTieuChiCloseDetail |
    IDiemVanMinhTieuChiChangeSelectedIds |
    IDiemVanMinhTieuChiShowConfirm | IDiemVanMinhTieuChiCloseConfirm |
    IDiemVanMinhTieuChiDeleteStart | IDiemVanMinhTieuChiDeleteSuccess | IDiemVanMinhTieuChiDeleteErr |
    IDiemVanMinhTieuChiSaveStart | IDiemVanMinhTieuChiSaveSuccess | IDiemVanMinhTieuChiSaveErr |
    IDiemVanMinhTieuChiChangeFilter