import React, { useCallback, useEffect, useRef, useState,useMemo } from 'react';
import { AnimationPopup } from '../../../components/modal';
import { PopUpForm, PopUpFormActions } from '../../../components/pop-up-form/PopUpForm';
import { Button } from '../../../components/buttons';
import { diemDanhApi } from '../../../api/diemDanhApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/reducers';
import { PlaceHolder } from '../../../components/place-holder';
import { DefaultDataGrid } from '../../../components/data-grid';
import DataGrid, { Column, Lookup} from 'devextreme-react/data-grid';
import { useCommonContext } from '../../../contexts/common';
import styles from "./TongHopDiemDanhModal.module.css"
import DateBox from 'devextreme-react/date-box';
import { FormGroupInline } from '../../../components/form-group';
import ComboboxLop from '../../../components/combobox-lop';
import ComboboxLopTheoKhoi from '../../../components/combobox-lop-theo-khoi';
import ComboboxKhoi from '../../../components/combobox-khoi';
interface ITongHopDiemDanhModalProps {
    animationOf: string,
    onClose: () => void,
    date: Date,
    dm_lop_id: number| 0,
    dm_khoi_id: number | 0,
}
export const report_status = [
	{
		id: 1,
		name: '1',
		name_en: '1',
	},
	{
		id: 3,
		name: 'Vắng CP',
		name_en: 'Vắng CP',
	},
	{
		id: 4,
		name: 'Vắng KP',
		name_en: 'Vắng KP',
	},
];
const TongHopDiemDanhModal = (props: ITongHopDiemDanhModalProps) => {
    const [dataSource, setDataSource] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const { translate, createUUID } = useCommonContext();
    const { dm_truong_selected_id, nam_hoc, language} = useSelector((x: RootState) => x.common)
    const [filter, setFilter] = useState({
        fromDate: props.date,
        dm_lop_id: props.dm_lop_id | 0,
        dm_khoi_id: props.dm_khoi_id | 0,
    });

    const refGrid = useRef<any>();

    useEffect(() => {
        handleGetDataSourceAsync()
    }, [filter.fromDate, filter.dm_lop_id, dm_truong_selected_id])
    const handleGetDataSourceAsync = async () => {
        setIsLoading(true)
        const res = await diemDanhApi.SelectTongHopByLopAsync({
            month: filter.fromDate,
            dm_lop_id: filter.dm_lop_id | 0,
            dm_khoi_id: filter.dm_khoi_id| 0,
            dm_truong_id: dm_truong_selected_id,
            nam_hoc
        })
        setIsLoading(false)
        if (res.is_success) {
            setDataSource(res.data)
        }
    }
    const onClose = useCallback(() => {
        window.animationHidePopup(props.animationOf || "")
        setTimeout(() => {
            props.onClose();
        }, 500);
    }, [props.animationOf])
    const resource = useMemo(() => {
        if (dataSource != undefined && dataSource.diemDanhs) {
            dataSource.diemDanhs.forEach((hs: any) => {
                var item: any = hs;
                var kq = hs.ket_qua_diem_danh;
                var tong = 0;
                var vang_cp = 0;
                var vang_kp = 0;
                if (kq != '' && kq != null) {
                    kq = "{" + kq + "}";
                    var objKq = JSON.parse(kq);
                    for (const key in objKq) {
                        item[key] =  objKq[key] ;
                        if (objKq[key] == 1) {
                            tong++;
                        }
                        if (objKq[key] == 3) {
                            vang_cp++;
                        }
                        if (objKq[key] == 4) {
                            vang_kp++;
                        }
                    }
                }
                var k = 0;
                hs.xinnghis.forEach((nghi: any) => {
                    item['content_formated_' + k] = nghi.content_formated;
                    item['content_formated_en_' + k] = nghi.content_formated_en;
                    item['app_xinnghi_lydo_name_' + k] = nghi.app_xinnghi_lydo_name;
                    item['app_xinnghi_lydo_name_en_' + k] = nghi.app_xinnghi_lydo_name_en;
                    item['dang_ky_luc_' + k] = nghi.ngay_tao;
                    item['phone_number_' + k] = nghi.phone_number;
                    k++;
                })
                item['total'] = tong;
                item['vang_cp'] = vang_cp;
                item['vang_kp'] = vang_kp;
            });
        }
		return dataSource;
	}, [filter, dataSource]);
    const columns = useMemo(() => {
		const result: any = [
			<Column key={createUUID()} fixed={true} dataField={"ma_hs"} alignment={"center middle"} width={100} caption={translate("Base.Label.StudentID")} />,
            <Column key={createUUID()} fixed={true} dataField={"ho_ten"} alignment={"center"} width={150} caption={translate("Base.Label.StudentName")} />,
            <Column key={createUUID()} fixed={true} dataField={"ten_lop_hc"} width={100} caption={translate("Base.Label.ClassName")} alignment={'center'}/>,
		];
        // const result1: any = 
        let time = filter.fromDate;
        time.setDate(1);
        let month = time.getMonth();
        var months = [];
        while (time.getMonth() == month) {
            var d = time.getDate();
            var key = time.toISOString().substring(0, 10);
            var t: any = [];
            t['key'] = key;
            t['day'] = d.toString().padStart(2, '0') + '/' + (month + 1).toString().padStart(2, '0');
            months.push(t);
            d++;
            time.setDate(d);
        }
        result.push(
            <Column key={'attend_month'} alignment={"center"}  caption={'Tháng ' + (month+ 1) + '/' + time.getFullYear()}>
                {months.map(showColumn => {
                    return <Column
                        key={showColumn.key}
                        dataField={`${showColumn.key}`}
                        caption={showColumn.day}
                        width={75}
                        alignment={"center"}
                        // cellRender={(cell) => {
                        //     const ketQua = ketQuaHocTaps.find(x => x.ts_hocsinh_id == cell.data.id
                        //         && x.sis_monhoc_id == monHoc.sis_monhoc_id
                        //         && x.sis_sonhanxet_item_id == sis_sonhanxet_item_id_nhanxet
                        //     );
                        //     return (
                        //         <span id={`student${cell.data.id}_M_${monHoc.sis_monhoc_id}`} className={styles.cellResult}
                        //             onMouseEnter={() => {
                        //                 clearTimeout(_timer ? _timer.current : 0)
                        //                 _timer.current = setTimeout(() => {
                        //                     setTooltipTarget(`student${cell.data.id}_M_${monHoc.sis_monhoc_id}`)
                        //                     setTooltipData(ketQua?.ket_qua ?
                        //                         {
                        //                             ho_ten: cell.data.ho_ten,
                        //                             mon_hoc: monHoc.ten_mon_hoc,
                        //                             nhan_xet: ketQua?.ket_qua ?? ""
                        //                         } : undefined)
                        //                 }, 200)
                        //             }}
                        //             onMouseLeave={() => {
                        //                 clearTimeout(_timer ? _timer.current : 0)

                        //             }}

                        //         >
                        //             {cell.data[`M_${monHoc.sis_monhoc_id}_${showColumn.id}`]}

                        //         </span>
                        //     );
                        // }}
                    >
                    <Lookup dataSource={report_status} valueExpr={'id'} displayExpr={language === 'en' ? 'name_en' : 'name'} />
                    </Column>;
                })}
                <Column  key={createUUID()} dataField={'total'} caption={'Tổng'} width={70} alignment={'center'}></Column>,
                <Column  key={createUUID()} dataField={'vang_cp'} caption={'Vắng CP'} width={70} alignment={'center'}></Column>,
                <Column  key={createUUID()} dataField={'vang_kp'} caption={'Vắng KP'} width={70} alignment={'center'}></Column>
            </Column>
        )
        var result1: any = [];
        var max = (dataSource != undefined && dataSource.max_record_xinnghi) ? dataSource.max_record_xinnghi : 1;
        for(var i= 0; i< max; i++) {
            result1.push(<Column key={createUUID()} dataField={'phone_number_'+ i} caption={'SĐT PH đăng ký'} width={100} alignment={'center'}
            ></Column>);
            result1.push(<Column key={createUUID()} dataField={'dang_ky_luc_' + i} caption={'Đăng ký lúc'} width={120} alignment={'center'} dataType={'datetime'} format={'HH:mm, dd/MM/yyyy'}
            ></Column>);
            result1.push(<Column key={createUUID()} dataField={'content_formated_' + i} caption={'Thời gian nghỉ phép'} width={120} alignment={'center'} dataType={'datetime'} format={'HH:mm, dd/MM/yyyy'}
            ></Column>);
            result1.push(<Column key={createUUID()} dataField={'app_xinnghi_lydo_name_' + i} caption={'Lý do'} width={120} alignment={'center'} dataType={'datetime'} format={'HH:mm, dd/MM/yyyy'}
            ></Column>);
        }
        result.push(
            <Column key={'xin_nghi'} alignment={"center"} caption={'PH xin nghỉ trên App'}>
                {result1}    
            </Column>
        )
        time.setMonth(month);        
		return result;
	}, [filter.fromDate, dataSource]);

    return (
        <>
            <AnimationPopup
                animationOf={props.animationOf}
                maxWidth={window.innerWidth - 150}
                minHeight={window.innerHeight - 100}

            >
                <PopUpForm onSubmit={() => { }}>
                    {isLoading && <PlaceHolder line_number={10} />}
                    {!isLoading &&
                        <div>
                            <div className={styles.filter}>
                                <div className={styles.left}>
                                    <div className={styles.item}>
                                        <FormGroupInline label='Base.Label.Month'>
                                            <DateBox value={filter.fromDate}
                                                displayFormat = 'monthAndYear'
                                                type="date"
                                                calendarOptions = {{
                                                        'maxZoomLevel': 'year'
                                                    }
                                                }
                                                // minZoomLevel='century'
                                                onValueChanged={(e) => {
                                                    if (e.event) {
                                                        setFilter({ ...filter, fromDate: e.value })
                                                    }
                                                }}
                                            />
                                        </FormGroupInline>
                                    </div>
                                    <div className={styles.item}>
                                        <FormGroupInline label='Base.Label.Grade'>
                                        <ComboboxKhoi
                                            value={filter.dm_khoi_id}
                                            dm_truong_id={dm_truong_selected_id}
                                            isShowClearButton={true}
                                            stylingMode='filled'
                                            width={110}
                                            onValueChanged={(id) =>
                                                setFilter((prev) => ({ ...prev, dm_khoi_id: id ?? 0, dm_he_id: 0, dm_lop_id: 0 }))
                                            }
                                        />
                                        </FormGroupInline>
                                    </div>
                                    <div className={styles.item}>
                                        <FormGroupInline label='Base.Label.Class'>
                                            <ComboboxLopTheoKhoi
                                                nam_hoc={nam_hoc}
                                                dm_truong_id={dm_truong_selected_id}
                                                dm_khoi_id={filter.dm_khoi_id}
                                                // dm_he_id={filter.dm_he_id}
                                                value={filter.dm_lop_id}
                                                isShowClearButton={true}
                                                onValueChanged={(value) => {
                                                    setFilter((prev) => ({ ...prev, dm_lop_id: value }));
                                                }}
                                            />
                                        </FormGroupInline>
                                    </div>

                                </div>
                                <div className={styles.right}>
                                    <Button text='Export' type='success'
                                        icon='fas fa-file-export'
                                        style='text' onClick={() => {
                                            if (refGrid && refGrid.current && refGrid.current.instance) {
                                                refGrid.current.instance.exportToExcel();
                                            }
                                        }} />
                                    <Button text='Refresh' type='success'
                                        icon='fas fa-sync-alt'
                                        style='filled' onClick={() => {
                                            handleGetDataSourceAsync()
                                        }} />
                                </div>

                            </div>
                            <DefaultDataGrid dataSource={dataSource?.diemDanhs}
                                keyExpr='ts_hocsinh_id'
                                gridRef={refGrid}
                                height={window.innerHeight - 200}
                                width={window.innerWidth - 180}
                                // defaultPageSize={50}
                                columns={columns}
                                wordWrapEnabled={true}
                            >
                                {/* {columns} */}
                            </DefaultDataGrid>
                        </div>
                    }
                    <PopUpFormActions>
                        <Button type="normal" text="Base.Label.Close" onClick={onClose}></Button>{' '}

                    </PopUpFormActions>
                </PopUpForm>

            </AnimationPopup>
        </>
    );
};

export default TongHopDiemDanhModal;