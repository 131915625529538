import { NotifyHelper } from '../../../helpers/toast';
import { IHocSinhSelectRequest } from '../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { IDiem } from '../../../models/response/diem/IDiem';
import { IHocSinhSelectResponeItem } from '../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { ILopMonHocItemRespone } from '../../../models/response/lop-mon-hoc/ILopMonHocItemRespone';
import { baseAction } from "../IActionBase";
import { IXepHangHocSinhSelectRequest } from './../../../models/request/xep-hang-hoc-sinh/IXepHangHocSinhRequest';
import { IXepHangHocSinhRequest } from './../../../models/request/xep-hang-hoc-sinh/IXepHangHocSinhSelectRequest';
import { IXepHangHocSinh } from './../../../models/response/xep-hang-hoc-sinh/IXepHangHocSinh';
import { eXepHangHocSinhActionTypeIds } from './../../action-types/xep-hang-hoc-sinh/IXepHangHocSinhActionTypes';

export const xepHangHocSinhActions = {
    changeFilter: (filter: any) => baseAction(eXepHangHocSinhActionTypeIds.CHANGE_FILTER, filter),

    loadHocSinhStart: (request: IHocSinhSelectRequest) => baseAction(eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_START, request),
    loadHocSinhSuccess: (data: IHocSinhSelectResponeItem[]) => baseAction(eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_SUCCESS, data),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eXepHangHocSinhActionTypeIds.HOCSINH_LOAD_ERROR, message)
    },
    loadMonHocStart: (request: number) => baseAction(eXepHangHocSinhActionTypeIds.MONHOC_LOAD_START, request),
    loadMonHocSuccess: (data: ILopMonHocItemRespone[]) => baseAction(eXepHangHocSinhActionTypeIds.MONHOC_LOAD_SUCCESS, data),
    loadMonHocError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eXepHangHocSinhActionTypeIds.MONHOC_LOAD_ERROR, message)
    },
    showXepHangConfirm: (isOpen: boolean) => baseAction(eXepHangHocSinhActionTypeIds.SHOW_XEPHANG_CONFIRM, isOpen),
    xepHangStart: (request: IXepHangHocSinhRequest) => {
        return baseAction(eXepHangHocSinhActionTypeIds.XEPHANG_START, request)
    },
    xepHangSuccess: (data: IXepHangHocSinh[]) => {
        NotifyHelper.Success('Success')
        return baseAction(eXepHangHocSinhActionTypeIds.XEPHANG_SUCCESS, data)
    },
    xepHangError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eXepHangHocSinhActionTypeIds.XEPHANG_ERROR, message)
    },
    loadKetQuaStart: (request: IXepHangHocSinhSelectRequest) => baseAction(eXepHangHocSinhActionTypeIds.KETQUA_LOAD_START, request),
    loadKetQuaSuccess: (data: IXepHangHocSinh[]) => baseAction(eXepHangHocSinhActionTypeIds.KETQUA_LOAD_SUCCESS, data),
    loadKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eXepHangHocSinhActionTypeIds.KETQUA_LOAD_ERROR, message)
    },
    loadDiemStart: (request: IXepHangHocSinhSelectRequest) => baseAction(eXepHangHocSinhActionTypeIds.DIEM_LOAD_START, request),
    loadDiemSuccess: (data: IDiem[]) => baseAction(eXepHangHocSinhActionTypeIds.DIEM_LOAD_SUCCESS, data),
    loadDiemError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eXepHangHocSinhActionTypeIds.DIEM_LOAD_ERROR, message)
    },
}