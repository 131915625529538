import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { pl_lopbomon_type } from "../../../models/response/lop-bo-mon/pl_lopbomon_type"

export enum eLopBoMonTypeActionTypeIds {
    LOAD_START = "LOPBOMON_TYPE_LOAD_START",
    LOAD_SUCCESS = "LOPBOMON_TYPE_LOAD_SUCCESS",
    LOAD_ERROR = "LOPBOMON_TYPE_LOAD_ERROR",

    LOAD_BY_TRUONG_START = "LOPBOMON_TYPE_LOAD_BY_TRUONG_START",
    LOAD_BY_TRUONG_SUCCESS = "LOPBOMON_TYPE_LOAD_BY_TRUONG_SUCCESS",
    LOAD_BY_TRUONG_ERROR = "LOPBOMON_TYPE_LOAD_BY_TRUONG_ERROR",

    SHOW_DETAIL = "LOPBOMON_TYPE_SHOW_DETAIL",
    CLOSE_DETAIL = "LOPBOMON_TYPE_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "LOPBOMON_TYPE_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "LOPBOMON_TYPE_SHOW_CONFIRM",
    CLOSE_CONFIRM = "LOPBOMON_TYPE_CLOSE_CONFIRM",

    DELETE_START = "LOPBOMON_TYPE_DELETE_START",
    DELETE_SUCCESS = "LOPBOMON_TYPE_DELETE_SUCCESS",
    DELETE_ERROR = "LOPBOMON_TYPE_DELETE_ERROR",

    SAVE_START = "LOPBOMON_TYPE_SAVE_START",
    SAVE_SUCCESS = "LOPBOMON_TYPE_SAVE_SUCCESS",
    SAVE_ERR = "LOPBOMON_TYPE_SAVE_ERR"
}

export interface ILopBoMonTypeLoadStart {
    type: eLopBoMonTypeActionTypeIds.LOAD_START,
}
export interface ILopBoMonTypeLoadSuccess {
    type: eLopBoMonTypeActionTypeIds.LOAD_SUCCESS,
    payload: pl_lopbomon_type[]
}
export interface ILopBoMonTypeLoadErr {
    type: eLopBoMonTypeActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface ILopBoMonTypeLoadByTruongStart {
    type: eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_START,
    payload: number
}
export interface ILopBoMonTypeLoadByTruongSuccess {
    type: eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_SUCCESS,
    payload: pl_lopbomon_type[]
}
export interface ILopBoMonTypeLoadByTruongError {
    type: eLopBoMonTypeActionTypeIds.LOAD_BY_TRUONG_ERROR,
    payload: string
}

export interface ILopBoMonTypeShowDetail {
    type: eLopBoMonTypeActionTypeIds.SHOW_DETAIL,
    payload?: pl_lopbomon_type
}
export interface ILopBoMonTypeCloseDetail {
    type: eLopBoMonTypeActionTypeIds.CLOSE_DETAIL,
}
export interface ILopBoMonTypeChangeSelectedIds {
    type: eLopBoMonTypeActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface ILopBoMonTypeShowConfirm {
    type: eLopBoMonTypeActionTypeIds.SHOW_CONFIRM
}
export interface ILopBoMonTypeCloseConfirm {
    type: eLopBoMonTypeActionTypeIds.CLOSE_CONFIRM
}

export interface ILopBoMonTypeDeleteStart {
    type: eLopBoMonTypeActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface ILopBoMonTypeDeleteSuccess {
    type: eLopBoMonTypeActionTypeIds.DELETE_SUCCESS,
}
export interface ILopBoMonTypeDeleteErr {
    type: eLopBoMonTypeActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface ILopBoMonTypeSaveStart {
    type: eLopBoMonTypeActionTypeIds.SAVE_START,
    payload: pl_lopbomon_type
}
export interface ILopBoMonTypeSaveSuccess {
    type: eLopBoMonTypeActionTypeIds.SAVE_SUCCESS
}
export interface ILopBoMonTypeSaveErr {
    type: eLopBoMonTypeActionTypeIds.SAVE_ERR,
    payload: string
}
export type ILopBoMonTypeActionTypes = ILopBoMonTypeLoadStart | ILopBoMonTypeLoadSuccess | ILopBoMonTypeLoadErr |
    ILopBoMonTypeLoadByTruongStart | ILopBoMonTypeLoadByTruongSuccess | ILopBoMonTypeLoadByTruongError |
    ILopBoMonTypeShowDetail | ILopBoMonTypeCloseDetail |
    ILopBoMonTypeChangeSelectedIds |
    ILopBoMonTypeShowConfirm | ILopBoMonTypeCloseConfirm |
    ILopBoMonTypeDeleteStart | ILopBoMonTypeDeleteSuccess | ILopBoMonTypeDeleteErr |
    ILopBoMonTypeSaveStart | ILopBoMonTypeSaveSuccess | ILopBoMonTypeSaveErr
