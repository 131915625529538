import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { IAppBaiVietComment } from '../../../models/response/app-phuhuynh/IAppBaiVietComment';
import { eBaiVietCommentActionTypeIds } from '../../action-types/bai-viet-comment/IBaiVietCommentActionts';
import { IBaiVietCommentReducer } from "../../page-state-model/bai-viet-comment/baiVietCommentReducer";
import { IBaiVietCommentActionTypes } from './../../action-types/bai-viet-comment/IBaiVietCommentActionts';
const iniState: IBaiVietCommentReducer = {
    status: ePageBaseStatus.is_not_initialization,
    baiVietId: 0,
    comments: [],
    paging: {
        page_number: 1,
        page_size: 20
    }
}
const settingNewDataSource = (comments: IAppBaiVietComment[], newComment: IAppBaiVietComment) => {
    if (newComment.app_baiviet_comment_parent_id > 0) return [...comments];
    const result = comments.map(x => {
        if (x.id == newComment.id) {
            return newComment;
        }
        return x;
    })
    if (result.find(x => x.id == newComment.id)) {
        return result;
    } else {
        return [newComment, ...result];
    }
}
export const baiVietCommentReducer = (state: IBaiVietCommentReducer = iniState, action: IBaiVietCommentActionTypes): IBaiVietCommentReducer => {
    switch (action.type) {
        case eBaiVietCommentActionTypeIds.CHANGE_BAIVIET:
            return {
                ...state,
                baiVietId: action.payload
            }
        case eBaiVietCommentActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiVietCommentActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                comments: action.payload
            }

        case eBaiVietCommentActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }

        case eBaiVietCommentActionTypeIds.ADD_COMMENT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }

        case eBaiVietCommentActionTypeIds.ADD_COMMENT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                comments: settingNewDataSource(state.comments, action.payload)
            }
        case eBaiVietCommentActionTypeIds.ADD_COMMENT_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }

        default:
            return {
                ...state
            }
    }
}