import { combineReducers } from "redux";
import { appAccountReducer } from "./appAccountReducer";
import { appMenuNhomReducer } from "./appMenuNhomReducer";
import { appMenuReducer } from "./appMenuReducer";
import { appMenuTruongReducer } from "./appMenuTruongReducer";
import { appXinNghiReducer } from "./appXinNghiReducer";
import { appYeuCauHoTroReducer } from "./appYeuCauHoTroReducer";

export const appPhuHuynhReducerWrapper = combineReducers({
    appMenu: appMenuReducer,
    appMenuNhom: appMenuNhomReducer,
    appMenuTruong: appMenuTruongReducer,
    appAccount: appAccountReducer,
    appXinNghi: appXinNghiReducer,
    appYeuCauHoTro: appYeuCauHoTroReducer
})