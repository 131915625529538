import { IMonHocSoDiem } from './../models/response/so-diem/IMonHocSoDiem';
import { sis_nhomthanhphandiem } from '../models/response/so-diem/sis_nhomthanhphandiem';
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_MONHOC_SODIEM_PATH = 'monhoc-sodiem';

class MonHocSoDiemApi extends BaseCRUDApi<IMonHocSoDiem>{
    constructor(path: string) {
        super(path)
    }
}
export const monHocSoDiemApi = new MonHocSoDiemApi(API_MONHOC_SODIEM_PATH)