import { baiTapKetQuaApi } from './../../../api/baiTapKetQuaApi';
import { IBaiTapKetQuaLopLoadHocSinhStart, IBaiTapKetQuaLopLoadKetQuaStart, IBaiTapKetQuaLopSyncLmsStart } from './../../action-types/bai-tap/IBaiTapKetQuaLop';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from "../../../api/apiWrapper";
import { eBaiTapKetQuaLopTypeIds } from '../../action-types/bai-tap/IBaiTapKetQuaLop';
import { actions } from '../../actions/actionsWrapper';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { baiTapApi } from '../../../api/baiTapApi';

const lopBoMonHocSinhApi = apiWrapper.lopBoMonHocSinh;

export function* baiTapKetQuaLopSaga(): any {
    yield takeLatest(eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_START, loadHocSinhWorker)
    yield takeLatest(eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_START, loadKetQuaWorker)
    yield takeLatest(eBaiTapKetQuaLopTypeIds.SYNC_LMS_START, lmsSyncWorker)
}

function* loadHocSinhWorker(action: IBaiTapKetQuaLopLoadHocSinhStart): any {
    const res: IBaseResponeModel = yield call([lopBoMonHocSinhApi, lopBoMonHocSinhApi.Select], {
        pl_lopbomon_ids: [action.payload]
    })
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTapKetQuaLop.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.baiTapWrapper.baiTapKetQuaLop.loadHocSinhError(res.message || ""))
    }
}

function* loadKetQuaWorker(action: IBaiTapKetQuaLopLoadKetQuaStart): any {
    const res: IBaseResponeModel = yield call([baiTapKetQuaApi, baiTapKetQuaApi.SelectByLopBoMon], action.payload)
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTapKetQuaLop.loadKetQuaSuccess(res.data))
    } else {
        yield put(actions.baiTapWrapper.baiTapKetQuaLop.loadKetQuaError(res.message || ""))
    }
}

function* lmsSyncWorker(action: IBaiTapKetQuaLopSyncLmsStart): any {
    const res: IBaseResponeModel = yield call([baiTapApi, baiTapApi.SyncLms], action.payload)
    if (res.is_success) {
        yield put(actions.baiTapWrapper.baiTapKetQuaLop.syncLmsSuccess())
    } else {
        yield put(actions.baiTapWrapper.baiTapKetQuaLop.syncLmsError(res.message || ""))
    }
}
