import { sis_form_khaosat_editor } from "../../models/response/form-khaosat/sis_form_khaosat_editor";
import { apiClient } from "../apiClient";
import { BaseCRUDApi } from "../baseCRUDApi";

export const API_FORMKHAOSAT_EDITOR_PATH = "form-khao-sat-editor";

export const formKhaoSatEditorApi = new class FormKhaoSatEditorApi extends BaseCRUDApi<sis_form_khaosat_editor> {
    constructor(path: string) {
        super(path);
    }
    SelectAllTypeAsync() {
        return apiClient.get(`${this._path}/select-type`);
    }
    UpdateSelectOptionsAsync(request: { sis_form_khaosat_editor_id: number, select_options: string }) {
        return apiClient.put(`${this._path}/update-select-options`, request);
    }
}(API_FORMKHAOSAT_EDITOR_PATH);