import { IDgdkKetQuaNlpc } from './../../../../models/response/danh-gia/dinh-ky/IDgdkKetQuaNlpc';
import { ePageBaseStatus } from "../../../../models/ePageBaseStatus";
import { eDgdkNlpcActionTypeIds, IDgdkNlpcActionTypes } from "../../../action-types/danh-gia/dinh-ky/IDgdkNlpcActionType";
import { IDgdkNlpcPageState } from "../../../page-state-model/danh-gia/dinh-ky/IDgdkNlpcPageState";
const iniState: IDgdkNlpcPageState = {
    filter: {
        dm_khoi_id: 0,
        dm_lop_id: 0,
        dm_truong_id: 0,
        nam_hoc: '',
        sis_giaidoan_id: 0,
        hoc_ky: 0
    },
    loading: {
        hocSinh: "",
        dgdkDmNlpcs: "",
        ketQuas: ""
    },
    status: ePageBaseStatus.is_not_initialization,
    hocSinhs: [],
    dgdkDmNlpcs: [],
    ketQuas: [],
    importData: []
}
const mergeKetQuas = (ketQuas: IDgdkKetQuaNlpc[], newKetQuas: IDgdkKetQuaNlpc[]): IDgdkKetQuaNlpc[] => {
    let result = ketQuas.map(x => {
        var _newDiem = newKetQuas.find(y => x.id == y.id);
        if (_newDiem) return _newDiem;
        return x;
    })
    newKetQuas.map(y => {
        var _existedDiem = ketQuas.find(x => x.id == y.id)
        if (!_existedDiem) {
            result.push(y);
        }
    })
    return result;
}
export const dgdkNlpcReducer = (state: IDgdkNlpcPageState = iniState, action: IDgdkNlpcActionTypes): IDgdkNlpcPageState => {
    switch (action.type) {
        case eDgdkNlpcActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload,
                loading: {
                    ...state.loading,
                    hocSinh: ""
                },
                // hocSinhs: []
            }
        case eDgdkNlpcActionTypeIds.HOCSINH_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: "is_loading"
                }
            }
        case eDgdkNlpcActionTypeIds.HOCSINH_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: ""
                },
                hocSinhs: action.payload
            }
        case eDgdkNlpcActionTypeIds.DM_NLPC_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    dgdkDmNlpcs: "is_loading",
                },
                dgdkDmNlpcs: []
            }
        case eDgdkNlpcActionTypeIds.DM_NLPC_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    dgdkDmNlpcs: ""
                },
                dgdkDmNlpcs: action.payload
            }
        case eDgdkNlpcActionTypeIds.KETQUA_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: "is_loading"
                },
            }
        case eDgdkNlpcActionTypeIds.KETQUA_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: action.payload
            }
        case eDgdkNlpcActionTypeIds.KETQUA_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: []
            }
        case eDgdkNlpcActionTypeIds.KETQUA_SAVE_SUCCESS:
            return {
                ...state,
                ketQuas: mergeKetQuas(state.ketQuas, [action.payload])
            }
        case eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: action.payload
            }
        case eDgdkNlpcActionTypeIds.VALIDATE_IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: []
            }
        case eDgdkNlpcActionTypeIds.IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDgdkNlpcActionTypeIds.IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eDgdkNlpcActionTypeIds.IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eDgdkNlpcActionTypeIds.LOCK_CHECK_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving,
                lockDetail: undefined
            }
        case eDgdkNlpcActionTypeIds.LOCK_CHECK_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                lockDetail: action.payload
            }
        case eDgdkNlpcActionTypeIds.LOCK_CHECK_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lockDetail: undefined
            }
        case eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving,
                lockDetail: undefined
            }
        case eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                lockDetail: action.payload
            }
        case eDgdkNlpcActionTypeIds.LOCKLOPBOMON_CHECK_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lockDetail: undefined
            }


        default:
            return {
                ...state
            }

    }

}