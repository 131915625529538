import { IDeleteMultipleBaseRequest } from '../../models/request/base/deleteRequest';
import { sis_monhoc } from "../../models/response/mon-hoc/sis_monhoc";


export enum eMonHocActionTypeIds {
    LOAD_START = "MONHOC_LOAD_START",
    LOAD_SUCCESS = "MONHOC_LOAD_SUCCESS",
    LOAD_ERROR = "MONHOC_LAOD_ERROR",

    SHOW_DETAIL = "MONHOC_SHOW_DETAIL",
    CLOSE_DETAIL = "CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "MONHOC_SHOW_CONFIRM",
    CLOSE_CONFIRM = "MONHOC_CLOSE_CONFIRM",

    DELETE_START = "DELETE_START",
    DELETE_SUCCESS = "DELETE_SUCCESS",
    DELETE_ERROR = "DELETE_ERROR",

    SAVE_START = "SAVE_START",
    SAVE_SUCCESS = "SAVE_SUCCESS",
    SAVE_ERR = "SAVE_ERR"
}

export interface IMonHocLoadStart {
    type: eMonHocActionTypeIds.LOAD_START,
    payload: number
}
export interface IMonHocLoadSuccess {
    type: eMonHocActionTypeIds.LOAD_SUCCESS,
    payload: sis_monhoc[]
}
export interface IMonHocLoadErr {
    type: eMonHocActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IMonHocShowDetail {
    type: eMonHocActionTypeIds.SHOW_DETAIL,
    payload?: sis_monhoc
}
export interface IMonHocCloseDetail {
    type: eMonHocActionTypeIds.CLOSE_DETAIL,
}
export interface IMonHocChangeSelectedIds {
    type: eMonHocActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IMonHocShowConfirm {
    type: eMonHocActionTypeIds.SHOW_CONFIRM
}
export interface IMonHocCloseConfirm {
    type: eMonHocActionTypeIds.CLOSE_CONFIRM
}

export interface IMonHocDeleteStart {
    type: eMonHocActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IMonHocDeleteSuccess {
    type: eMonHocActionTypeIds.DELETE_SUCCESS,
}
export interface IMonHocDeleteErr {
    type: eMonHocActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IMonHocSaveStart {
    type: eMonHocActionTypeIds.SAVE_START,
    payload: sis_monhoc
}
export interface IMonHocSaveSuccess {
    type: eMonHocActionTypeIds.SAVE_SUCCESS
}
export interface IMonHocSaveErr {
    type: eMonHocActionTypeIds.SAVE_ERR,
    payload: string
}
export type IMonHocActionTypes = IMonHocLoadStart | IMonHocLoadSuccess | IMonHocLoadErr |
    IMonHocShowDetail | IMonHocCloseDetail |
    IMonHocChangeSelectedIds |
    IMonHocShowConfirm | IMonHocCloseConfirm |
    IMonHocDeleteStart | IMonHocDeleteSuccess | IMonHocDeleteErr |
    IMonHocSaveStart | IMonHocSaveSuccess | IMonHocSaveErr
