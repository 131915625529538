import { ILopChuNhiemSelectRequest } from "../models/request/lop-chu-nhiem/ILopChuNhiemSelectRequest";
import { sis_lop_gvcn } from "../models/response/lop-chu-nhiem/sis_lop_gvcn";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_LOPCHUNHIEM_PATH = "lop-chu-nhiem";
class LopChuNhiemApi extends BaseCRUDApi<sis_lop_gvcn> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    Select(request: ILopChuNhiemSelectRequest) { return apiClient.post(`${this._path}/select`, request) }
    UpdateGiaoVien(request: sis_lop_gvcn[]) {
        return apiClient.post(`${this._path}/giao-vien`, request)
    }
}
export const lopChuNhiemApi = new LopChuNhiemApi({ path: API_LOPCHUNHIEM_PATH });