import { IKhenThuongKyLuatImportRequest } from '../models/request/khen-thuong-ky-luat/IKhenThuongKyLuatImportRequest';
import { IKhenThuongKyLuatSelectRequest } from '../models/request/khen-thuong-ky-luat/IKhenThuongKyLuatRequest';
import { IKhenThuongKyLuat } from './../models/response/khen-thuong-ky-luat/IKhenThuongKyLuat';
import { apiClient } from "./apiClient";
export const _KHEN_THUONG_KY_LUAT_ENDPOINT = "khen-thuong-ky-luat";
export const khenThuongKyLuatApi = {
    SelectAsync: (request: IKhenThuongKyLuatSelectRequest) => {
        // debugger
        return apiClient.get(`${_KHEN_THUONG_KY_LUAT_ENDPOINT}/nam-hoc/${request.nam_hoc}/hoc-ky/${request.hoc_ky}/truong/${request.dm_truong_id}`);
    },
    Insert: (data: IKhenThuongKyLuat) => {
        return apiClient.post(_KHEN_THUONG_KY_LUAT_ENDPOINT, data);
    },
    Update: (data: IKhenThuongKyLuat) => {
        return apiClient.put(_KHEN_THUONG_KY_LUAT_ENDPOINT, data);
    },
    Delete: (id: number) => {
        return apiClient.delete(`${_KHEN_THUONG_KY_LUAT_ENDPOINT}/${id}`);
    },
    ValidateImport: (request: IKhenThuongKyLuatImportRequest) => {
        return apiClient.post(_KHEN_THUONG_KY_LUAT_ENDPOINT + '/import/validate', request);
    },
    Import: (request: IKhenThuongKyLuatImportRequest) => {
        return apiClient.post(_KHEN_THUONG_KY_LUAT_ENDPOINT + '/import', request);
    }
}