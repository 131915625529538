import { sis_thangdiem_option } from '../../../models/response/so-diem/sis_thangdiem_option';
import { IActionTypeBase } from '../IActionTypeBase';
import { sis_thangdiem } from './../../../models/response/so-diem/sis_thangdiem';
export enum eThangDiemActionTypeIds {
    LOAD_START = "THANG_DIEM_LOAD_START",
    LOAD_SUCCESS = "THANG_DIEM_LOAD_SUCCESS",
    LOAD_ERROR = "THANG_DIEM_LOAD_ERROR",

    SHOW_DETAIL = "THANG_DIEM_SHOW_DETAIL",
    CLOSE_DETAIL = "THANG_DIEM_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "THANG_DIEM_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "THANG_DIEM_SHOW_CONFIRM",
    CLOSE_CONFIRM = "THANG_DIEM_CLOSE_CONFIRM",

    DELETE_START = "THANG_DIEM_DELETE_START",
    DELETE_SUCCESS = "THANG_DIEM_DELETE_SUCCESS",
    DELETE_ERROR = "THANG_DIEM_DELETE_ERROR",

    SAVE_START = "THANG_DIEM_SAVE_START",
    SAVE_SUCCESS = "THANG_DIEM_SAVE_SUCCESS",
    SAVE_ERR = "THANG_DIEM_SAVE_ERR",

    SHOW_OPTION_DETAIL = "THANG_DIEM_SHOW_OPTION_DETAIL",
    CLOSE_OPTION_DETAIL = "THANG_DIEM_CLOSE_OPTION_DETAIL",

    LOAD_OPTION_START = "THANG_DIEM_LOAD_OPTION_START",
    LOAD_OPTION_SUCCESS = "THANG_DIEM_LOAD_OPTION_SUCCESS",
    LOAD_OPTION_ERROR = "THANG_DIEM_LOAD_OPTION_ERROR",

    DELETE_OPTION_START = "THANG_DIEM_DELETE_OPTION_START",
    DELETE_OPTION_SUCCESS = "THANG_DIEM_DELETE_OPTION_SUCCESS",
    DELETE_OPTION_ERROR = "THANG_DIEM_DELETE_OPTION_ERROR",

    SAVE_OPTION_START = "THANG_DIEM_SAVE_OPTION_START",
    SAVE_OPTION_SUCCESS = "THANG_DIEM_SAVE_OPTION_SUCCESS",
    SAVE_OPTION_ERR = "THANG_DIEM_SAVE_OPTION_ERR",
}
export interface IThangDiemLoadStart {
    type: eThangDiemActionTypeIds.LOAD_START
}
export interface IThangDiemLoadSuucess {
    type: eThangDiemActionTypeIds.LOAD_SUCCESS,
    payload: sis_thangdiem[]
}
export interface IThangDiemLoadError {
    type: eThangDiemActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IThangDiemShowDetail {
    type: eThangDiemActionTypeIds.SHOW_DETAIL,
    payload?: sis_thangdiem
}
export interface IThangDiemCloseDetail {
    type: eThangDiemActionTypeIds.CLOSE_DETAIL,
}
export interface IThangDiemChangeSelectedIds {
    type: eThangDiemActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IThangDiemShowConfirm {
    type: eThangDiemActionTypeIds.SHOW_CONFIRM
}
export interface IThangDiemCloseConfirm {
    type: eThangDiemActionTypeIds.CLOSE_CONFIRM
}

export interface IThangDiemDeleteStart {
    type: eThangDiemActionTypeIds.DELETE_START,
    payload: number
}
export interface IThangDiemDeleteSuccess {
    type: eThangDiemActionTypeIds.DELETE_SUCCESS,
}
export interface IThangDiemDeleteErr {
    type: eThangDiemActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IThangDiemSaveStart {
    type: eThangDiemActionTypeIds.SAVE_START,
    payload: sis_thangdiem
}
export interface IThangDiemSaveSuccess {
    type: eThangDiemActionTypeIds.SAVE_SUCCESS
}
export interface IThangDiemSaveErr {
    type: eThangDiemActionTypeIds.SAVE_ERR,
    payload: string
}

export interface IThangDiemShowOption extends IActionTypeBase<eThangDiemActionTypeIds.SHOW_OPTION_DETAIL, sis_thangdiem> { }
export interface IThangDiemCloseOption extends IActionTypeBase<eThangDiemActionTypeIds.CLOSE_OPTION_DETAIL, undefined> { }

export interface IThangDiemLoadOptionStart extends IActionTypeBase<eThangDiemActionTypeIds.LOAD_OPTION_START, number> { }
export interface IThangDiemLoadOptionSuccess extends IActionTypeBase<eThangDiemActionTypeIds.LOAD_OPTION_SUCCESS, sis_thangdiem_option[]> { }
export interface IThangDiemLoadOptionError extends IActionTypeBase<eThangDiemActionTypeIds.LOAD_OPTION_ERROR, string> { }


export interface IThangDiemSaveOptionStart extends IActionTypeBase<eThangDiemActionTypeIds.SAVE_OPTION_START, sis_thangdiem_option> { }
export interface IThangDiemSaveOptionSuccess extends IActionTypeBase<eThangDiemActionTypeIds.SAVE_OPTION_SUCCESS, sis_thangdiem_option> { }
export interface IThangDiemSaveOptionError extends IActionTypeBase<eThangDiemActionTypeIds.SAVE_OPTION_ERR, string> { }



export interface IThangDiemDeleteOptionStart extends IActionTypeBase<eThangDiemActionTypeIds.DELETE_OPTION_START, number> { }
export interface IThangDiemDeleteOptionSuccess extends IActionTypeBase<eThangDiemActionTypeIds.DELETE_OPTION_SUCCESS, undefined> { }
export interface IThangDiemDeleteOptionError extends IActionTypeBase<eThangDiemActionTypeIds.DELETE_OPTION_ERROR, string> { }


export type IThangDiemActionTypes = IThangDiemLoadStart | IThangDiemLoadSuucess | IThangDiemLoadError |
    IThangDiemShowDetail | IThangDiemCloseDetail |
    IThangDiemChangeSelectedIds |
    IThangDiemShowConfirm | IThangDiemCloseConfirm |
    IThangDiemDeleteStart | IThangDiemDeleteSuccess | IThangDiemDeleteErr |
    IThangDiemSaveStart | IThangDiemSaveSuccess | IThangDiemSaveErr |

    IThangDiemShowOption | IThangDiemCloseOption |
    IThangDiemLoadOptionStart | IThangDiemLoadOptionSuccess | IThangDiemLoadOptionError |
    IThangDiemSaveOptionStart | IThangDiemSaveOptionSuccess | IThangDiemSaveOptionError |
    IThangDiemDeleteOptionStart | IThangDiemDeleteOptionSuccess | IThangDiemDeleteOptionError