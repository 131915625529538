import { sis_sodiem_thanhphandiem } from './../../../models/response/so-diem/sis_sodiem_thanhphandiem';
import { ePageBaseStatus } from './../../../models/ePageBaseStatus';
import { ISoDiemMapping } from './../../../models/response/so-diem/ISoDiemMapping';
export enum eSoDiemMappingStatus {
    is_need_reload_mappings = "is_need_reload_mappings"
}
export interface ISoDiemMappingStateModel {
    status: ePageBaseStatus | eSoDiemMappingStatus,
    soDiemId: number,
    soDiemMappings: ISoDiemMapping[],
    soDiemMoetThanhPhans: sis_sodiem_thanhphandiem[],
    isShowMappingModal: boolean,
    soDiemThanhPhanMapping?: sis_sodiem_thanhphandiem,
    isShowDeleteConfirmModal: boolean,
    loading: {
        soDiemMoetThanhPhans: "isLoading" | "",
        soDiemMappings: "isLoading" | "",
    }
}