import { NotifyHelper, showNotify } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eMealActionTypeIds, IMealActionTypes } from "../../action-types/IMealActionTypes";
import { IMealPageStateModel } from "../../page-state-model/IMealPageState";

const initialState: IMealPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_meals: [],
    is_show_delete_confirm: false,
    sis_meal_selected_ids: [],
    sis_meal_editing: undefined,
    is_show_detail_modal: false
}
export const mealReducer = (state: IMealPageStateModel = initialState, action: IMealActionTypes): IMealPageStateModel => {
    switch (action.type) {
        case eMealActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eMealActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_meals: action.payload
            }
        case eMealActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_meals: []
            }
        case eMealActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_meal_editing: action.payload
            }
        case eMealActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_meal_editing: undefined
            }
        case eMealActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_meal_selected_ids: action.payload
            }
        case eMealActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eMealActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eMealActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eMealActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eMealActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }


        case eMealActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eMealActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false
            }
        case eMealActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal: false
            }

        default:
            return state;
    }

}
