import { NotifyHelper } from '../../../../helpers/toast';
import { IImportDgtxHocTapRequest } from '../../../../models/request/danh-gia/thuong-xuyen/IImportDgtxHocTapRequest';
import { ISelectDgtxKetQuaHocTapRequest } from '../../../../models/request/danh-gia/thuong-xuyen/ISelectDgtxKetQuaHocTapRequest';
import { IUpdateDgtxHocTapRequest } from '../../../../models/request/danh-gia/thuong-xuyen/IUpdateDgtxHocTapRequest';
import { ILopBoMonSelectHocSinhRequest } from '../../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonSelectHocSinhRequest';
import { ISoNhanXetItem } from '../../../../models/response/dgdk-dm/ISoNhanXetItem';
import { IHocSinhSelectResponeItem } from '../../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { eDgtxKetQuaMonHocActionTypeIds } from '../../../action-types/danh-gia/thuong-xuyen/IDgtxKetQuaHocTapActionType';
import { IDgtxKetQuaMonHocFilter } from '../../../page-state-model/danh-gia/thuong-xuyen/IDgtxKetQuaMonHocPageState';
import { baseAction } from "../../IActionBase";
import { IDgtxKetQuaMonHoc } from './../../../../models/response/danh-gia/thuong-xuyen/IDgtxKetQuaMonHoc';

export const dgtxKetQuaMonHocActions = {
    changeFilter: (filter: IDgtxKetQuaMonHocFilter) => baseAction(eDgtxKetQuaMonHocActionTypeIds.CHANGE_FILTER, filter),
    loadHocSinhStart: (rq: ILopBoMonSelectHocSinhRequest) => baseAction(eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_START, rq),
    loadHocSinhSuccess: (hocSinh: IHocSinhSelectResponeItem[]) => baseAction(eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_SUCCESS, hocSinh),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaMonHocActionTypeIds.HOCSINH_LOAD_ERROR, message)
    },
    loadSoNhanXetItemsStart: (setId: number) => baseAction(eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_START, setId),
    loadSoNhanXetItemsSuccess: (data: ISoNhanXetItem[]) => baseAction(eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_SUCCESS, data),
    loadSoNhanXetItemsError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaMonHocActionTypeIds.SONHANXET_ITEM_LOAD_ERROR, message)
    },
    loadKetQuaStart: (request: ISelectDgtxKetQuaHocTapRequest) => baseAction(eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_START, request),
    loadKetQuaSuccess: (data: IDgtxKetQuaMonHoc[]) => baseAction(eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_SUCCESS, data),
    loadKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaMonHocActionTypeIds.KETQUA_LOAD_ERROR, message)
    },
    saveKetQuaStart: (request: IUpdateDgtxHocTapRequest) => baseAction(eDgtxKetQuaMonHocActionTypeIds.KETQUA_SAVE_START, request),
    saveKetQuaSuccess: (data: IDgtxKetQuaMonHoc) => baseAction(eDgtxKetQuaMonHocActionTypeIds.KETQUA_SAVE_SUCCESS, data),
    saveKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaMonHocActionTypeIds.KETQUA_SAVE_ERROR, message)
    },
    validateImportStart: (request: IImportDgtxHocTapRequest) => baseAction(eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_START, request),
    validateImportSuccess: (data: any) => baseAction(eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_SUCCESS, data),
    validateImportError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaMonHocActionTypeIds.VALIDATE_IMPORT_ERRROR, message)
    },
    importStart: (request: IImportDgtxHocTapRequest) => baseAction(eDgtxKetQuaMonHocActionTypeIds.IMPORT_START, request),
    importSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eDgtxKetQuaMonHocActionTypeIds.IMPORT_SUCCESS, undefined)
    },
    importError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDgtxKetQuaMonHocActionTypeIds.IMPORT_ERRROR, message)
    },
}