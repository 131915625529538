import { app_menu } from "../../models/response/app-phuhuynh/app_menu";
import { apiClient } from "../apiClient";
import { BaseCRUDApi } from "../baseCRUDApi";

export const API_APP_MENU_PATH = "app-menu"

class AppMenuApi extends BaseCRUDApi<app_menu> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectAllView() {
        return apiClient.get(`${this._path}/select-all-view`)
    }
}
export const appMenuApi = new AppMenuApi({ path: API_APP_MENU_PATH });