import { apiClient } from "./apiClient";

export const API_NAM_HOC_PATH = "nam-hoc";
export const API_TRUONG_PATH = "truong";
export const API_KHOI_PATH = "khoi";
export const DM_KHOI_SELECT_BY_TRUONG = "dm_khoi/select_by_truong";
export const API_HE_PATH = "he";
export const DM_HE_SELECT_BY_KHOI = "dm_he/select_by_khoi";
export const DM_TRUONG_TREEVIEW_SELECT = "dm_truong/select_treeview";
export const API_TRANGTHAIHOCSINH_PATH = "trang-thai-hoc-sinh/truong";



export const categorySourceApi = {
    SelectHes: () => apiClient.get(API_HE_PATH),
    SelectHesByKhoi: (dm_khoi_id: number) => apiClient.post(`${API_KHOI_PATH}/${dm_khoi_id}/he`),
    SelectKhois: () => apiClient.get(API_KHOI_PATH),
    SelectKhoisByTruong: (dm_truong_id: number) => apiClient.get(`${API_TRUONG_PATH}/${dm_truong_id}/khoi`),
    SelectNamHocs: () => apiClient.get(API_NAM_HOC_PATH),
    SelectTruongs: () => apiClient.get(API_TRUONG_PATH),
    dm_truong_treeview: () => apiClient.get(DM_TRUONG_TREEVIEW_SELECT),
    SelectTruongKhoiHes: () => apiClient.get('truong/khoi/he'),
    SelectTrangThaiHocSinhs: (dm_truong_id: number) => apiClient.get(API_TRANGTHAIHOCSINH_PATH + "/" + dm_truong_id),
}