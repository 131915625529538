import { DangKyMonHocKetQuaUpdateRequest } from "../models/request/dangky-monhoc/DangKyMonHocKetQuaUpdateRequest";
import { sis_pl_dotdangky_ketqua } from "../models/response/dangky-monhoc/sis_pl_dotdangky_ketqua";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_DOTDANGKY_KETQUA_PATH = "dang-ky-mon-hoc/ket-qua";

export const dotDangKyKetQuaApi = new class DotDangKyKetQuaApi extends BaseCRUDApi<sis_pl_dotdangky_ketqua> {
    constructor(path: string) {
        super(path);
    }
    SelectByDotDangKyAsync(dotdangky_id: number) {
        return apiClient.get(`${this._path}/dot-dang-ky/${dotdangky_id}/select-ket-qua-dang-ky`);
    }
    SelectHocSinhByDotDangKyAsync(dotdangky_id: number) {
        return apiClient.get(`${this._path}/dot-dang-ky/${dotdangky_id}/select-hoc-sinh-dang-ky`);
    }
    PostDangKyMonHocAsync(request: DangKyMonHocKetQuaUpdateRequest) {
        return apiClient.post(`${this._path}/dang-ky-thay-hoc-sinh`, request);
    }
}(API_DOTDANGKY_KETQUA_PATH);
