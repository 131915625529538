import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { ILopBoMonAddHocSinhRequest } from "../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonAddHocSinhRequest";
import { ILopBoMonSelectHocSinhRequest } from "../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonSelectHocSinhRequest";
import { ILopBoMonChangeThuTuHocSinhRequest } from "../../../models/request/lop-bo-mon-hoc-sinh/ILopBoMonChangeThuTuHocSinhRequest";
import { ILopBoMonImportHocSinhRequest } from "../../../models/request/lop-bo-mon/ILopBoMonImportHocSinhRequest";
import { ILopBoMonImportHocSinhResponse } from "../../../models/response/lop-bo-mon/ILopBoMonImportHocSinhResponse";
import { ILopBoMonChangeHocSinhClassRequest } from "./../../../models/request/lop-bo-mon/ILopBoMonChangeHocSinhClassRequest";
import { BaseAction } from '../IBaseActionResult';
import { pl_lopbomon_hocsinh_viewmodel } from './../../../models/response/lop-bo-mon/pl_lopbomon_hocsinh';
import { eLopBoMonHocSinhActionTypeIds, ILopBoMonHocSinhChangeSelectedIds, ILopBoMonHocSinhLoadErr, ILopBoMonHocSinhLoadStart, ILopBoMonHocSinhLoadSuccess, 
    ILopBoMonAddHocSinhStart, ILopBoMonAddHocSinhSuccess, ILopBoMonUploadExcelStartAction, ILopBoMonUploadExcelSuccessAction, 
    ILopBoMonSaveExcelStartAction, ILopBoMonSaveExcelSuccessAction, ILopBoMonSaveExcelErrorAction, 
    ILopBoMonUploadExcelErrorAction, ILopBoMonChangeThuTuMacDinhError, ILopBoMonChangeThuTuMacDinhStart, ILopBoMonChangeThuTuMacDinhSuccess,
     ILopBoMonChangeHocSinhThuTuError, ILopBoMonChangeHocSinhThuTuStart, ILopBoMonChangeHocSinhThuTuSuccess, ILopBoMonChangeHocSinhStart,
    ILopBoMonChangeHocSinhSuccess, ILopBoMonChangeHocSinhError } from './../../action-types/lop-bo-mon/ILopBoMonHocSinhActionTypes';

export const lopBoMonHocSinhActions = {
    loadHocSinhStart: (request: ILopBoMonSelectHocSinhRequest): ILopBoMonHocSinhLoadStart => BaseAction(request, eLopBoMonHocSinhActionTypeIds.LOAD_START),
    loadHocSinhSuccess: (respone: pl_lopbomon_hocsinh_viewmodel[]): ILopBoMonHocSinhLoadSuccess => BaseAction(respone, eLopBoMonHocSinhActionTypeIds.LOAD_SUCCESS),
    loadHocSinhError: (message: string): ILopBoMonHocSinhLoadErr => BaseAction(message, eLopBoMonHocSinhActionTypeIds.LOAD_ERROR),
    changeSelectedIds: (ids: number[]): ILopBoMonHocSinhChangeSelectedIds => BaseAction(ids, eLopBoMonHocSinhActionTypeIds.CHANGE_SELECTED_IDS),
    showConfirmDelete: (isShow: boolean) => BaseAction(isShow, eLopBoMonHocSinhActionTypeIds.SHOW_CONFIRM_DELETE),
    deleteHocSinhStart: (request: IDeleteMultipleBaseRequest) => BaseAction(request, eLopBoMonHocSinhActionTypeIds.DELETE_START),
    deleteHocSinhSuccess: () => BaseAction(undefined, eLopBoMonHocSinhActionTypeIds.DELETE_SUCCESS),
    deleteHocSinhError: (message: string) => BaseAction(message, eLopBoMonHocSinhActionTypeIds.DELETE_ERROR),
    showAddHocSinhModal: (isShow: boolean) => BaseAction(isShow, eLopBoMonHocSinhActionTypeIds.SHOW_ADD_HOCSINH_MODAL),
    addHocSinhStart: (request: ILopBoMonAddHocSinhRequest): ILopBoMonAddHocSinhStart => BaseAction(request, eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_START),
    addHocSinhSuccess: (): ILopBoMonAddHocSinhSuccess => BaseAction(undefined, eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_SUCCESS),
    addHocSinhError: (message: string) => BaseAction(message, eLopBoMonHocSinhActionTypeIds.ADD_HOCSINH_ERROR),
    
    loadFromExcelStart: (data: FormData): ILopBoMonUploadExcelStartAction => BaseAction(data, eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_START),
    loadFromExcelSuccess: (respone: ILopBoMonImportHocSinhResponse): ILopBoMonUploadExcelSuccessAction => BaseAction(respone, eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_SUCCESS),
    loadFromExcelError: (message: string): ILopBoMonUploadExcelErrorAction => BaseAction(message, eLopBoMonHocSinhActionTypeIds.UPLOAD_EXCEL_ERROR),

    saveFromExcelStart: (data: ILopBoMonImportHocSinhRequest): ILopBoMonSaveExcelStartAction => BaseAction(data, eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_START),
    saveFromExcelSuccess: (respone: ILopBoMonImportHocSinhResponse): ILopBoMonSaveExcelSuccessAction => BaseAction(respone, eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_SUCCESS),
    saveFromExcelError: (message: string): ILopBoMonSaveExcelErrorAction => BaseAction(message, eLopBoMonHocSinhActionTypeIds.SAVE_EXCEL_ERROR),

    changeLopBoMonHocSinhThuTuStart: (request: ILopBoMonChangeThuTuHocSinhRequest): ILopBoMonChangeHocSinhThuTuStart => BaseAction(request, eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_START),
    changeLopBoMonHocSinhThuTuSuccess: (): ILopBoMonChangeHocSinhThuTuSuccess => BaseAction(undefined, eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_SUCCESS),
    changeLopBoMonHocSinhThuTuError: (messege: string): ILopBoMonChangeHocSinhThuTuError => BaseAction(messege, eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_THUTU_ERROR),

    
    changeLopBoMonThuTuMacDinhStart: (dm_lop_id: number): ILopBoMonChangeThuTuMacDinhStart => BaseAction(dm_lop_id, eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_START),
    changeLopBoMonThuTuMacDinhSuccess: (): ILopBoMonChangeThuTuMacDinhSuccess => BaseAction(undefined, eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_SUCCESS),
    changeLopBoMonThuTuMacDinhError: (messege: string): ILopBoMonChangeThuTuMacDinhError => BaseAction(messege, eLopBoMonHocSinhActionTypeIds.CHANGE_THUTU_MACDINH_ERROR),

    changeHocSinhStart: (request: ILopBoMonChangeHocSinhClassRequest): ILopBoMonChangeHocSinhStart => BaseAction(request, eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_START),
    changeHocSinhSuccess: (): ILopBoMonChangeHocSinhSuccess => BaseAction(undefined, eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_SUCCESS),
    changeHocSinhError: (messege: string): ILopBoMonChangeHocSinhError => BaseAction(messege, eLopBoMonHocSinhActionTypeIds.CHANGE_HOCSINH_ERROR),

}