import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eAppMenuTruongActionTypeIds, IAppMenuTruongActionTypes } from "../../action-types/app-phuhuynh/IAppMenuTruongActionTypes"
import { IAppMenuTruongPageState } from "../../page-state-model/app-phuhuynh/IAppMenuTruongPageState"

const initialState: IAppMenuTruongPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_menu_truongs: [],
    app_menu_truong_selected_ids: [],
    add_menu_selected_ids: [],
    is_show_delete_confirm: false,
    is_show_add_menu_modal: false
}

export const appMenuTruongReducer = (state: IAppMenuTruongPageState = initialState, action: IAppMenuTruongActionTypes): IAppMenuTruongPageState => {
    switch (action.type) {
        case eAppMenuTruongActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppMenuTruongActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_menu_truongs: action.payload
            }
        case eAppMenuTruongActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_menu_truongs: []
            }
        case eAppMenuTruongActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                app_menu_truong_selected_ids: action.payload
            }
        case eAppMenuTruongActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eAppMenuTruongActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eAppMenuTruongActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eAppMenuTruongActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case eAppMenuTruongActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case eAppMenuTruongActionTypeIds.SHOW_ADD_APP_MENU_MODAL:
            return {
                ...state,
                is_show_add_menu_modal: action.payload
            }
        case eAppMenuTruongActionTypeIds.CHANGE_APP_MENU_SELECTED_IDS:
            return {
                ...state,
                add_menu_selected_ids: action.payload
            }
        case eAppMenuTruongActionTypeIds.ADD_APP_MENU_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eAppMenuTruongActionTypeIds.ADD_APP_MENU_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_add_menu_modal: false
            }
        case eAppMenuTruongActionTypeIds.ADD_APP_MENU_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_add_menu_modal: false
            }

        default:
            return state;
    }

}