import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { sis_dgtx_dm_noidung_nlpc, sis_dgtx_dm_noidung_nlpc_treeview } from "../../../models/response/dgtx-dm/sis_dgtx_dm_noidung_nlpc"

export enum eDgtxDmPhamChatActionTypeIds {
    LOAD_START = "DGTX_DM_PHAMCHAT_LOAD_START",
    LOAD_SUCCESS = "DGTX_DM_PHAMCHAT_LOAD_SUCCESS",
    LOAD_ERROR = "DGTX_DM_PHAMCHAT_LOAD_ERROR",

    SHOW_DETAIL = "DGTX_DM_PHAMCHAT_SHOW_DETAIL",
    CLOSE_DETAIL = "DGTX_DM_PHAMCHAT_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "DGTX_DM_PHAMCHAT_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "DGTX_DM_PHAMCHAT_SHOW_CONFIRM",
    CLOSE_CONFIRM = "DGTX_DM_PHAMCHAT_CLOSE_CONFIRM",

    DELETE_START = "DGTX_DM_PHAMCHAT_DELETE_START",
    DELETE_SUCCESS = "DGTX_DM_PHAMCHAT_DELETE_SUCCESS",
    DELETE_ERROR = "DGTX_DM_PHAMCHAT_DELETE_ERROR",

    SAVE_START = "DGTX_DM_PHAMCHAT_SAVE_START",
    SAVE_SUCCESS = "DGTX_DM_PHAMCHAT_SAVE_SUCCESS",
    SAVE_ERR = "DGTX_DM_PHAMCHAT_SAVE_ERR"
}
//ORIGIN MODEL
export interface IDgtxDmPhamChatLoadStart {
    type: eDgtxDmPhamChatActionTypeIds.LOAD_START,
}
export interface IDgtxDmPhamChatLoadSuccess {
    type: eDgtxDmPhamChatActionTypeIds.LOAD_SUCCESS,
    payload: sis_dgtx_dm_noidung_nlpc_treeview[]
}
export interface IDgtxDmPhamChatLoadErr {
    type: eDgtxDmPhamChatActionTypeIds.LOAD_ERROR,
    payload: string
}
//DETAIL
export interface IDgtxDmPhamChatShowDetail {
    type: eDgtxDmPhamChatActionTypeIds.SHOW_DETAIL,
    payload?: sis_dgtx_dm_noidung_nlpc_treeview
}
export interface IDgtxDmPhamChatCloseDetail {
    type: eDgtxDmPhamChatActionTypeIds.CLOSE_DETAIL,
}
export interface IDgtxDmPhamChatChangeSelectedIds {
    type: eDgtxDmPhamChatActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
//CONFIRM
export interface IDgtxDmPhamChatShowConfirm {
    type: eDgtxDmPhamChatActionTypeIds.SHOW_CONFIRM
}
export interface IDgtxDmPhamChatCloseConfirm {
    type: eDgtxDmPhamChatActionTypeIds.CLOSE_CONFIRM
}
//DELETE
export interface IDgtxDmPhamChatDeleteStart {
    type: eDgtxDmPhamChatActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IDgtxDmPhamChatDeleteSuccess {
    type: eDgtxDmPhamChatActionTypeIds.DELETE_SUCCESS,
}
export interface IDgtxDmPhamChatDeleteErr {
    type: eDgtxDmPhamChatActionTypeIds.DELETE_ERROR,
    payload: string
}
//SAVE
export interface IDgtxDmPhamChatSaveStart {
    type: eDgtxDmPhamChatActionTypeIds.SAVE_START,
    payload: sis_dgtx_dm_noidung_nlpc
}
export interface IDgtxDmPhamChatSaveSuccess {
    type: eDgtxDmPhamChatActionTypeIds.SAVE_SUCCESS
}
export interface IDgtxDmPhamChatSaveErr {
    type: eDgtxDmPhamChatActionTypeIds.SAVE_ERR,
    payload: string
}

export type IDgtxDmPhamChatActionTypes = 
    IDgtxDmPhamChatLoadStart | IDgtxDmPhamChatLoadSuccess | IDgtxDmPhamChatLoadErr |
    IDgtxDmPhamChatShowDetail | IDgtxDmPhamChatCloseDetail |
    IDgtxDmPhamChatChangeSelectedIds |
    IDgtxDmPhamChatShowConfirm | IDgtxDmPhamChatCloseConfirm |
    IDgtxDmPhamChatDeleteStart | IDgtxDmPhamChatDeleteSuccess | IDgtxDmPhamChatDeleteErr |
    IDgtxDmPhamChatSaveStart | IDgtxDmPhamChatSaveSuccess | IDgtxDmPhamChatSaveErr