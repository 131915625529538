import { soDiemMappingReducer } from './soDiemMappingReducer';
import { thangDiemReducer } from './thangDiemReducer';
import { giaiDoanReducer } from './giaiDoanReducer';
import { nhomThanhPhanDiemReducer } from './nhomThanhPhanDiemReducer';
import { thanhPhanDiemReducer } from './thanhPhanDiemReducer';
import { soDiemConfigReducer } from './soDiemConfigReducer';
import { soDiemListReducer } from './soDiemListReducer';
import { combineReducers } from 'redux';
export const soDiemReducerWrapper = combineReducers({
    soDiemList: soDiemListReducer,
    soDiemConfig: soDiemConfigReducer,
    thanhPhanDiem: thanhPhanDiemReducer,
    nhomThanhPhanDiem: nhomThanhPhanDiemReducer,
    giaiDoan: giaiDoanReducer,
    thangDiem: thangDiemReducer,
    soDiemMapping: soDiemMappingReducer
})