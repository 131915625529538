import { IDiemVanMinhSelectRequest } from '../models/request/diemvanminh/IDiemVanMinhSelectRequest';
import { IDiemVanMinhKetQua } from '../models/response/diemvanminh/IDiemVanMinhKetQua';
import { apiClient } from './apiClient';
import { BaseCRUDApi } from './baseCRUDApi';
export const API_DIEM_VAN_MINH_KET_QUA_PATH = 'diemvanminh-ketqua'

class DiemVanMinhKetQuaApi extends BaseCRUDApi<IDiemVanMinhKetQua>{
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(path: string) {
        super(path);
    }
    Select(request: IDiemVanMinhSelectRequest) {
        return apiClient.post(`${API_DIEM_VAN_MINH_KET_QUA_PATH}/select`, request)
    }

}
export const diemVanMinhKetQuaApi = new DiemVanMinhKetQuaApi(API_DIEM_VAN_MINH_KET_QUA_PATH)