import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eDiemVanMinhTieuChiActionTypeIds, IDiemVanMinhTieuChiDeleteStart, IDiemVanMinhTieuChiLoadStart, IDiemVanMinhTieuChiSaveStart }
    from "../../action-types/diemvanminh-tieuchi/IDiemVanMinhTieuChiActionTypes";
import { actions } from "../../actions/actionsWrapper";

const DiemVanMinhTieuChiApi = apiWrapper.diemVanMinhTieuChi;

export function* diemVanMinhTieuChiSaga(): any {
    yield takeLatest(eDiemVanMinhTieuChiActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eDiemVanMinhTieuChiActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eDiemVanMinhTieuChiActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(action: IDiemVanMinhTieuChiLoadStart): any {

    const res: IBaseResponeModel = yield call([DiemVanMinhTieuChiApi, DiemVanMinhTieuChiApi.SelectByNamHocTruong], action.payload)
    if (res.is_success) {
        yield put(actions.diemVanMinhTieuChi.loadSuccess(res.data))
    } else {
        yield put(actions.diemVanMinhTieuChi.loadError(res.message || "Không tải được tiêu chí"))
    }
}
function* deleteWorker(action: IDiemVanMinhTieuChiDeleteStart): any {
    const res: IBaseResponeModel = yield call([DiemVanMinhTieuChiApi, DiemVanMinhTieuChiApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.diemVanMinhTieuChi.deleteSuccess())
    } else {
        yield put(actions.diemVanMinhTieuChi.deleteError(res.message || "Không xóa được tiêu chí"))
    }
}
function* saveWorker(action: IDiemVanMinhTieuChiSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([DiemVanMinhTieuChiApi, DiemVanMinhTieuChiApi.Update], action.payload);
    } else {
        res = yield call([DiemVanMinhTieuChiApi, DiemVanMinhTieuChiApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.diemVanMinhTieuChi.saveSuccess())
    } else {
        yield put(actions.diemVanMinhTieuChi.saveError(res.message || "Không lưu được tiêu chí"))
    }
}