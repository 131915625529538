import { eTongHopDinhKyActionTypeIds } from './../../action-types/tong-hop/ITongHopDinhKyActionTypes';
import { baseAction } from "../IActionBase"
import { IHocSinhSelectRequest } from '../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { IHocSinhSelectResponeItem } from '../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { NotifyHelper } from '../../../helpers/toast';
import { ILopMonHocItemRespone } from '../../../models/response/lop-mon-hoc/ILopMonHocItemRespone';
import { ITongHopDinhKySelectRequest } from '../../../models/request/tong-hop/ITongHopDinhKyRequest';
import { IKetQuaDinhKyRespone } from '../../../models/response/tong-hop/IKetQuaDinhKyRespone';

export const tongHopDinhKyActions = {
    changeFilter: (filter: any) => baseAction(eTongHopDinhKyActionTypeIds.CHANGE_FILTER, filter),

    loadHocSinhStart: (request: IHocSinhSelectRequest) => baseAction(eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_START, request),
    loadHocSinhSuccess: (data: IHocSinhSelectResponeItem[]) => baseAction(eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_SUCCESS, data),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_ERROR, message)
    },
    loadMonHocStart: (request: number) => baseAction(eTongHopDinhKyActionTypeIds.MONHOC_LOAD_START, request),
    loadMonHocSuccess: (data: ILopMonHocItemRespone[]) => baseAction(eTongHopDinhKyActionTypeIds.MONHOC_LOAD_SUCCESS, data),
    loadMonHocError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eTongHopDinhKyActionTypeIds.MONHOC_LOAD_ERROR, message)
    },
    loadKetQuaDinhKyStart: (request: ITongHopDinhKySelectRequest) => baseAction(eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_START, request),
    loadKetQuaDinhKySuccess: (data: IKetQuaDinhKyRespone) => baseAction(eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_SUCCESS, data),
    loadKetQuaDinhKyError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_ERROR, message)
    }
}