import { call, put, takeEvery } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { appInfo } from "../../AppInfo";
import { ILoginRespone } from "../../models/response/account/login-response";
import { eAccountActionTypeIds, ILoginStartAction, ILogOutAction } from "../action-types/IAccountActionTypes";
import { actions } from './../actions/actionsWrapper';
import { clearJWTToken, setJWTToken } from "./authSaga";

//watcher
export default function* accountSaga(): any {
    yield takeEvery(eAccountActionTypeIds.LOGIN_START, loginStartWorker)
    yield takeEvery(eAccountActionTypeIds.LOG_OUT, logoutWorker)
}


//workers
function* loginStartWorker(data: ILoginStartAction): any {
    const res: any = yield call(apiWrapper.account.logInSSO, data.payload)
    const result = res.data as ILoginRespone;
    if (result.is_success && result.data && result.data.user_info) {
        setJWTToken(result.data.token.access_token, result.data.token.refresh_token);
        yield put(actions.account.loginSuccess())
        yield put(actions.auth.setUser(result.data.user_info))
        return true;
    } else {
        yield put(actions.account.loginError(result.message || "Đăng nhập thất bại"))
        clearJWTToken();
        window.location.href = appInfo.homeURL;
    }
    return false;

}



//workers
function* logoutWorker(data: ILogOutAction): any {
    const res: any = yield call(apiWrapper.account.logOut)
    clearJWTToken();
    window.location.reload();
    // const result = res.data as ILoginRespone;
    // if (result.is_success && result.data && result.data.user_info) {
    //     setJWTToken(result.data.token.access_token, result.data.token.refresh_token);
    //     yield put(actions.account.loginSuccess())
    //     yield put(actions.auth.setUser(result.data.user_info))
    //     return true;
    // } else {
    //     yield put(actions.account.loginError(result.message || "Đăng nhập thất bại"))
    //     clearJWTToken();
    //     window.location.href = appInfo.homeURL;
    // }
    // return false;

}
