import { IAppXinNghiChangePhanHoiRequest } from "../../models/request/app-phuhuynh/AppXinNghiChangePhanHoiRequest";
import { IAppXinNghiChangeStatusRequest } from "../../models/request/app-phuhuynh/AppXinNghiChangeStatusRequest";
import { IAppXinNghiSelectRequest } from "../../models/request/app-phuhuynh/AppXinNghiSelectRequest";
import { AppXinNghiSelectByLopRequest } from "../../models/request/app-phuhuynh/IAppXinNghiSelectByLopRequest";
import { app_xinnghi } from "../../models/response/app-phuhuynh/app_xinnghi";
import { apiClient } from "../apiClient";

export const API_APP_XINNGHI_PATH = "app-xinnghi"
export const appXinNghiApi = {
    selectViewAsync: (request: IAppXinNghiSelectRequest) => {
        let queryModel: any = request ? { ...request } : undefined;
        let queryString = queryModel ? Object.keys(queryModel).map(key => key + '=' + queryModel[key]).join('&') : undefined;
        return apiClient.get(`${API_APP_XINNGHI_PATH}/select${queryString ? `?${queryString}` : ''}`)
    },
    getAllViewAsync: () => {
        return apiClient.get(`${API_APP_XINNGHI_PATH}`)
    },
    getDetailViewAsync: (id: number) => {
        return apiClient.get(`${API_APP_XINNGHI_PATH}/${id}`)
    },
    updateStatus: (request: IAppXinNghiChangeStatusRequest) => {
        return apiClient.post(`${API_APP_XINNGHI_PATH}/xac-nhan`, request)
    },
    updatePhanHoi: (request: IAppXinNghiChangePhanHoiRequest) => {
        return apiClient.post(`${API_APP_XINNGHI_PATH}/phan-hoi`, request)
    },
    selectByLopAsync: (request:AppXinNghiSelectByLopRequest) => apiClient.post(`${API_APP_XINNGHI_PATH}/select-lop`,request),
    insertDonXinNghiAsync: (request: app_xinnghi) => {
        return apiClient.post(`${API_APP_XINNGHI_PATH}`, request)
    },
};