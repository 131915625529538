import { eAccountActionTypeIds, IAccountActionTypes } from "../action-types/IAccountActionTypes";

export interface IAccountState {
    isLoading: boolean,
    message?: string
}
const initialState: IAccountState = {
    isLoading: false,
    message: undefined
}
const accountReducer = (state: IAccountState =initialState, action: IAccountActionTypes): IAccountState => {
    switch (action.type) {
        case eAccountActionTypeIds.LOGIN_START:
            return {
                ...state,
                isLoading: true,
                message: undefined
            }
        case eAccountActionTypeIds.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: undefined
            }
        case eAccountActionTypeIds.LOGIN_ERROR:
            return {
                ...state,
                isLoading: false,
                message: action.payload

            }
        default:
            return state;
    }
}
export { accountReducer }