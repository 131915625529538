import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import FormKhaoSatItemAddRequest from "../../../models/request/form-khaosat/FormKhaoSatItemAddRequest";
import { FormKhaoSatItemResponse, sis_form_khaosat_item } from "../../../models/response/form-khaosat/sis_form_khaosat_item";
import {
    IFormKhaoSatItemChangeDetail,
    IFormKhaoSatItemChangeSelectedIds,
    IFormKhaoSatItemDeleteErr, IFormKhaoSatItemDeleteStart, IFormKhaoSatItemDeleteSuccess,
    IFormKhaoSatItemLoadByFormErr, IFormKhaoSatItemLoadByFormStart, IFormKhaoSatItemLoadByFormSuccess, IFormKhaoSatItemLoadErr,
    IFormKhaoSatItemLoadStart, IFormKhaoSatItemLoadSuccess, IFormKhaoSatItemShowConfirm,
    IFormKhaoSatItemShowModal,
    eFormKhaoSatItemActionTypeIds
} from "../../action-types/form-khaosat/IFormKhaoSatItemActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const formKhaoSatItemActions = {
    //ALL
    loadStart: (): IFormKhaoSatItemLoadStart => BaseAction(undefined, eFormKhaoSatItemActionTypeIds.LOAD_START),
    loadSuccess: (respone: FormKhaoSatItemResponse[]): IFormKhaoSatItemLoadSuccess => BaseAction(respone, eFormKhaoSatItemActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IFormKhaoSatItemLoadErr => BaseAction(messege, eFormKhaoSatItemActionTypeIds.LOAD_ERROR),
    //By truong
    loadByFormStart: (sis_form_khaosat_id: number): IFormKhaoSatItemLoadByFormStart => BaseAction({ sis_form_khaosat_id }, eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_START),
    loadByFormSuccess: (respone: FormKhaoSatItemResponse[]): IFormKhaoSatItemLoadByFormSuccess => BaseAction(respone, eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_SUCCESS),
    loadByFormError: (messege: string): IFormKhaoSatItemLoadByFormErr => BaseAction(messege, eFormKhaoSatItemActionTypeIds.LOAD_BYFORM_ERROR),
    //not in truong
    showModal: (show: boolean): IFormKhaoSatItemShowModal => BaseAction(show, eFormKhaoSatItemActionTypeIds.SHOW_MODAL),
    changeDetail: (data?: sis_form_khaosat_item): IFormKhaoSatItemChangeDetail => BaseAction(data, eFormKhaoSatItemActionTypeIds.CHANGE_DETAIL),
    showConfirm: (show: boolean): IFormKhaoSatItemShowConfirm => BaseAction(show, eFormKhaoSatItemActionTypeIds.SHOW_CONFIRM),
    changeSelectedIds: (ids: number[]): IFormKhaoSatItemChangeSelectedIds => BaseAction(ids, eFormKhaoSatItemActionTypeIds.CHANGE_SELECTED_IDS),
    //delete
    deleteStart: (ids: IDeleteMultipleBaseRequest): IFormKhaoSatItemDeleteStart => BaseAction(ids, eFormKhaoSatItemActionTypeIds.DELETE_START),
    deleteSuccess: (): IFormKhaoSatItemDeleteSuccess => BaseAction(undefined, eFormKhaoSatItemActionTypeIds.DELETE_SUCCESS),
    deleteErr: (message: string): IFormKhaoSatItemDeleteErr => BaseAction(message, eFormKhaoSatItemActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_form_khaosat_item) => BaseAction(data, eFormKhaoSatItemActionTypeIds.SAVE_START),
    saveSuccess: () => BaseAction(undefined, eFormKhaoSatItemActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string) => BaseAction(message, eFormKhaoSatItemActionTypeIds.SAVE_ERR),
    clearStatus: () => BaseAction(undefined, eFormKhaoSatItemActionTypeIds.CLEAR_STATUS),

    additemsStart: (data: { sis_form_khaosat_id: number, sis_form_khaosat_items: sis_form_khaosat_item[] }) => BaseAction(data, eFormKhaoSatItemActionTypeIds.ADDITEMS_START),
    additemsSuccess: () => BaseAction(undefined, eFormKhaoSatItemActionTypeIds.ADDITEMS_SUCCESS),
    additemsError: (message: string) => BaseAction(message, eFormKhaoSatItemActionTypeIds.ADDITEMS_ERR),

    addeditorStart: (data: FormKhaoSatItemAddRequest) => BaseAction(data, eFormKhaoSatItemActionTypeIds.ADDEDITOR_START),
    addeditorSuccess: () => BaseAction(undefined, eFormKhaoSatItemActionTypeIds.ADDEDITOR_SUCCESS),
    addeditorError: (message: string) => BaseAction(message, eFormKhaoSatItemActionTypeIds.ADDEDITOR_ERR),

    updateIdxStart: (data: { sis_form_khaosat_item_ids: number[] }) => BaseAction(data, eFormKhaoSatItemActionTypeIds.UPDATEIDX_START),
    updateIdxSuccess: () => BaseAction(undefined, eFormKhaoSatItemActionTypeIds.UPDATEIDX_SUCCESS),
    updateIdxError: (message: string) => BaseAction(message, eFormKhaoSatItemActionTypeIds.UPDATEIDX_ERR),
}


