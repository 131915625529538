import { call, put, takeLatest } from 'redux-saga/effects';
import { monHocSoDiemApi } from '../../../api/monHocSoDiemApi';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { eMonHocSoDiemActionIds } from './../../action-types/so-diem/IMonHocSoDiem';
import { actions } from './../../actions/actionsWrapper';
export function* monHocSoDiemSaga(): any {
    yield takeLatest(eMonHocSoDiemActionIds.LOAD_START, loadWorker);
}
function* loadWorker(): any {
    const res: IBaseResponeModel = yield call([monHocSoDiemApi, monHocSoDiemApi.SelectAll])
    if (res.is_success) {
        yield put(actions.soDiem.monHocSoDiem.loadSuccess(res.data))
    } else {
        yield put(actions.soDiem.monHocSoDiem.loadError(res.message || ""))

    }
}