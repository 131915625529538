import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { IThaiDoHocTapTieuChiSelectRequest } from "../../../models/request/nhan-xet/IThaiDoHocTapTieuChiSelectRequest";
import { sis_thaidohoctap_tieuchi } from "../../../models/response/nhan-xet/sis_thaidohoctap_tieuchi";
import { eThaiDoHocTapTieuChiActionTypeIds, IThaiDoHocTapTieuChiChangeSelectedIds, IThaiDoHocTapTieuChiCloseConfirm, IThaiDoHocTapTieuChiCloseDetail, IThaiDoHocTapTieuChiDeleteErr, IThaiDoHocTapTieuChiDeleteStart, IThaiDoHocTapTieuChiDeleteSuccess, IThaiDoHocTapTieuChiLoadErr, IThaiDoHocTapTieuChiLoadStart, IThaiDoHocTapTieuChiLoadSuccess, IThaiDoHocTapTieuChiSaveErr, IThaiDoHocTapTieuChiSaveStart, IThaiDoHocTapTieuChiSaveSuccess, IThaiDoHocTapTieuChiSelectErr, IThaiDoHocTapTieuChiSelectStart, IThaiDoHocTapTieuChiSelectSuccess, IThaiDoHocTapTieuChiShowConfirm, IThaiDoHocTapTieuChiShowDetail } from "../../action-types/nhan-xet/IThaiDoHocTapTieuChiActionTypes";

import { BaseAction } from "../IBaseActionResult";

export const thaiDoHocTapTieuChiActions = {
    loadStart: (): IThaiDoHocTapTieuChiLoadStart => BaseAction(undefined, eThaiDoHocTapTieuChiActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_thaidohoctap_tieuchi[]): IThaiDoHocTapTieuChiLoadSuccess => BaseAction(respone, eThaiDoHocTapTieuChiActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IThaiDoHocTapTieuChiLoadErr => BaseAction(messege, eThaiDoHocTapTieuChiActionTypeIds.LOAD_ERROR),

    selectStart: (request: IThaiDoHocTapTieuChiSelectRequest): IThaiDoHocTapTieuChiSelectStart => BaseAction(request, eThaiDoHocTapTieuChiActionTypeIds.SELECT_START),
    selectSuccess: (respone: sis_thaidohoctap_tieuchi[]): IThaiDoHocTapTieuChiSelectSuccess => BaseAction(respone, eThaiDoHocTapTieuChiActionTypeIds.SELECT_SUCCESS),
    selectError: (messege: string): IThaiDoHocTapTieuChiSelectErr => BaseAction(messege, eThaiDoHocTapTieuChiActionTypeIds.SELECT_ERROR),

    showDetail: (data?: sis_thaidohoctap_tieuchi): IThaiDoHocTapTieuChiShowDetail => BaseAction(data, eThaiDoHocTapTieuChiActionTypeIds.SHOW_DETAIL),
    closeDetail: (): IThaiDoHocTapTieuChiCloseDetail => BaseAction(undefined, eThaiDoHocTapTieuChiActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): IThaiDoHocTapTieuChiShowConfirm => BaseAction(undefined, eThaiDoHocTapTieuChiActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): IThaiDoHocTapTieuChiCloseConfirm => BaseAction(undefined, eThaiDoHocTapTieuChiActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): IThaiDoHocTapTieuChiChangeSelectedIds => BaseAction(ids, eThaiDoHocTapTieuChiActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IThaiDoHocTapTieuChiDeleteStart => BaseAction(ids, eThaiDoHocTapTieuChiActionTypeIds.DELETE_START),
    deleteSuccess: (): IThaiDoHocTapTieuChiDeleteSuccess => BaseAction(undefined, eThaiDoHocTapTieuChiActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IThaiDoHocTapTieuChiDeleteErr => BaseAction(message, eThaiDoHocTapTieuChiActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: sis_thaidohoctap_tieuchi): IThaiDoHocTapTieuChiSaveStart => BaseAction(data, eThaiDoHocTapTieuChiActionTypeIds.SAVE_START),
    saveSuccess: ():IThaiDoHocTapTieuChiSaveSuccess => BaseAction(undefined, eThaiDoHocTapTieuChiActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IThaiDoHocTapTieuChiSaveErr =>  BaseAction(message, eThaiDoHocTapTieuChiActionTypeIds.SAVE_ERR)
}