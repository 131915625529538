import { eCommonActionTypeIds, ICommonActionTypes } from "../action-types/ICommonActionTypes";

export interface CommonState {
    dm_truong_selected_id: number,
    nam_hoc: string,
    hoc_ky: number,
    is_show_combobox_truong: boolean,
    is_show_combobox_namhoc: boolean,
    is_show_combobox_hocky: boolean,
    is_show_notify_panel: boolean,
    menu_mode: "compact" | "full",
    grid_direction_mode: "column" | "row",
    language: "vi" | "en"
}
const initialState: CommonState = {
    dm_truong_selected_id: localStorage.dm_truong_selected_id ? parseInt(localStorage.dm_truong_selected_id) : 0,
    nam_hoc: localStorage.nam_hoc ? localStorage.nam_hoc : "",
    hoc_ky: localStorage.hoc_ky ? parseInt(localStorage.hoc_ky) : 0,
    is_show_combobox_namhoc: false,
    is_show_combobox_truong: false,
    is_show_combobox_hocky: false,
    is_show_notify_panel: false,
    menu_mode: localStorage.getItem("memu_mode") == "compact" ? "compact" : "full",
    grid_direction_mode: localStorage.getItem("grid_direction_mode") == "row" ? "row" : "column",
    language: localStorage.language == "en" ? "en" : "vi"
}
const commonReducer = (state: CommonState = initialState, action: ICommonActionTypes): CommonState => {
    switch (action.type) {
        case eCommonActionTypeIds.HEADER_SHOW_COMBOBOX_TRUONG:
            return {
                ...state,
                is_show_combobox_truong: true
            }
        case eCommonActionTypeIds.HEADER_COMBOBOX_TRUONG_CHANGED:
            localStorage.dm_truong_selected_id = action.payload;
            return {
                ...state,
                dm_truong_selected_id: action.payload,
            }
        case eCommonActionTypeIds.HEADER_CLOSE_COMBOBOX_TRUONG:
            return {
                ...state,
                is_show_combobox_truong: false
            }
        case eCommonActionTypeIds.HEADER_SHOW_COMBOBOX_NAMHOC:
            return {
                ...state,
                is_show_combobox_namhoc: true
            }
        case eCommonActionTypeIds.HEADER_COMBOBOX_NAMHOC_CHANGED:
            localStorage.nam_hoc = action.payload;
            return {
                ...state,
                nam_hoc: action.payload
            }
        case eCommonActionTypeIds.HEADER_CLOSE_COMBOBOX_NAMHOC:
            return {
                ...state,
                is_show_combobox_namhoc: false
            }
        case eCommonActionTypeIds.HEADER_SHOW_NOTIFY_PANEL:
            return {
                ...state,
                is_show_notify_panel: true
            }
        case eCommonActionTypeIds.HEADER_CLOSE_NOTIFY_PANEL:
            return {
                ...state,
                is_show_notify_panel: false
            }
        case eCommonActionTypeIds.HEADER_SHOW_COMBOBOX_HOCKY:
            return {
                ...state,
                is_show_combobox_hocky: true
            }
        case eCommonActionTypeIds.HEADER_COMBOBOX_HOCKY_CHANGED:
            localStorage.hoc_ky = action.payload;
            return {
                ...state,
                hoc_ky: action.payload
            }
        case eCommonActionTypeIds.HEADER_CLOSE_COMBOBOX_HOCKY:
            return {
                ...state,
                is_show_combobox_hocky: false
            }
        case eCommonActionTypeIds.CHANGE_MENU_MODE:
            localStorage.setItem("memu_mode", action.payload)
            return {
                ...state,
                menu_mode: action.payload
            }
        case eCommonActionTypeIds.CHANGE_DIRECTION_MODE:
            localStorage.setItem("grid_direction_mode", action.payload)
            return {
                ...state,
                grid_direction_mode: action.payload
            }
        case eCommonActionTypeIds.CHANGE_LANGUAGE:
            localStorage.setItem("language", action.payload)
            return {
                ...state,
                language: action.payload
            }
        default:
            return state;
    }
}
export { commonReducer };

