import { NotifyHelper } from './../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eLopBoMonListActionTypeIds, ILopBoMonListActionTypes } from "../../action-types/lop-bo-mon/ILopBoMonListActionTypes";
import { ILopBoMonListStateModel } from "../../page-state-model/lop-bo-mon/ILopBoMonListStateModel";

const initialState: ILopBoMonListStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    treeViewItems: [],
    isShowDetailModal: false,
    lopBoMonEditingId: 0,
    isShowGenerateModal: false,
    lopBoMonSelectedIds: [],
    isShowPhanCongGiaoVienModal: false,
    isShowDeleteModal: false
}
export const lopBoMonListReducer = (state: ILopBoMonListStateModel = initialState, action: ILopBoMonListActionTypes): ILopBoMonListStateModel => {
    switch (action.type) {
        case eLopBoMonListActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopBoMonListActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                treeViewItems: action.payload
            }
        case eLopBoMonListActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                treeViewItems: []
            }
        case eLopBoMonListActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                isShowDetailModal: true,
                lopBoMonEditingId: action.payload
            }
        case eLopBoMonListActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                isShowDetailModal: false,
                lopBoMonEditingId: 0
            }
        case eLopBoMonListActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonListActionTypeIds.SAVE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eLopBoMonListActionTypeIds.SAVE_ERR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eLopBoMonListActionTypeIds.SHOW_GENERATE:
            return {
                ...state,
                isShowGenerateModal: true
            }
        case eLopBoMonListActionTypeIds.CLOSE_GENERATE:
            return {
                ...state,
                isShowGenerateModal: false
            }
        case eLopBoMonListActionTypeIds.GENERATE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonListActionTypeIds.GENERATE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eLopBoMonListActionTypeIds.GENERATE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eLopBoMonListActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                lopBoMonSelectedIds: action.payload
            }
        case eLopBoMonListActionTypeIds.SHOW_PHANCONG_MODAL:
            return {
                ...state,
                isShowPhanCongGiaoVienModal: true
            }
        case eLopBoMonListActionTypeIds.CLOSE_PHANCONG_MODAL:
            return {
                ...state,
                isShowPhanCongGiaoVienModal: false
            }
        case eLopBoMonListActionTypeIds.SHOW_DELETE_MODAL:
            return {
                ...state,
                isShowDeleteModal: true
            }
        case eLopBoMonListActionTypeIds.CLOSE_DELETE_MODAL:
            return {
                ...state,
                isShowDeleteModal: false
            }
        case eLopBoMonListActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eLopBoMonListActionTypeIds.DELETE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDeleteModal: false
            }
        case eLopBoMonListActionTypeIds.DELETE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        default:
            return state;
    }

}
