import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { IBaseResponeModel } from "../../models/response/base-response";
import { IGiaoVienDeleteStart, IGiaoVienLoadByTruongStart, IGiaoVienSaveStart, eGiaoVienActionTypeIds,
    IGiaoVienUploadExcelStartAction, IGiaoVienSaveExcelStartAction
 } from "../action-types/IGiaoVienActionTypes";
import { actions } from '../actions/actionsWrapper';

const giaoVienApi = apiWrapper.giaoVien;

export function* giaoVienSaga(): any {
    yield takeLatest(eGiaoVienActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eGiaoVienActionTypeIds.LOAD_BYTRUONG_START, loadByTruongWorker)
    yield takeEvery(eGiaoVienActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eGiaoVienActionTypeIds.SAVE_START, saveWorker)
    yield takeEvery(eGiaoVienActionTypeIds.UPLOAD_EXCEL_START, loadFromExcelWorker)
    yield takeEvery(eGiaoVienActionTypeIds.SAVE_EXCEL_START, saveFromExcelWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([giaoVienApi, giaoVienApi.SelectAll])
    if (res.is_success) {
        yield put(actions.giaoVien.loadGiaoVienSuccess(res.data))
    } else {
        yield put(actions.giaoVien.loadGiaoVienError(res.message || "Không tải được giáo viên"))
    }
}
function* loadByTruongWorker(action: IGiaoVienLoadByTruongStart): any {
    const res: IBaseResponeModel = yield call([giaoVienApi, giaoVienApi.SelectByTruongAsync], action.payload)
    if (res.is_success) {
        yield put(actions.giaoVien.loadByTruongGiaoVienSuccess(res.data))
    } else {
        yield put(actions.giaoVien.loadByTruongGiaoVienError(res.message || "Không tải được giáo viên"))
    }
}
function* deleteWorker(action: IGiaoVienDeleteStart): any {
    const res: IBaseResponeModel = yield call([giaoVienApi, giaoVienApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.giaoVien.deleteGiaoVienSuccess())
    } else {
        yield put(actions.giaoVien.deleteGiaoVienErr(res.message || "Không tải được giáo viên"))
    }
}
function* saveWorker(action: IGiaoVienSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([giaoVienApi, giaoVienApi.Update], action.payload);
    } else {
        res = yield call([giaoVienApi, giaoVienApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.giaoVien.saveGiaoVienSuccess())
    } else {
        yield put(actions.giaoVien.saveGiaoVienError(res.message || "Không lưu được giáo viên"))
    }
}
function* loadFromExcelWorker(data: IGiaoVienUploadExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([giaoVienApi, giaoVienApi.LoadFromExcel], data.payload);
        if (res.is_success) {
            yield put(actions.giaoVien.loadFromExcelSuccess(res.data))
        } else {
            yield put(actions.giaoVien.loadFromExcelError(res.message || "Không upload được file excel"))
        }
    } catch (error) {
        yield put(actions.giaoVien.loadFromExcelError("Upload file excel có lỗi"))
    }
}


function* saveFromExcelWorker(data: IGiaoVienSaveExcelStartAction): any {
    try {
        const res: IBaseResponeModel = yield call([giaoVienApi, giaoVienApi.SaveDataExcel], data.payload);
        if (res.is_success) {
            yield put(actions.giaoVien.saveFromExcelSuccess(res.data))
        } else {
            yield put(actions.giaoVien.saveFromExcelError(res.message || "Không lưu được dữ liệu excel"))
        }
    } catch (error) {
        yield put(actions.giaoVien.saveFromExcelError("Lưu được dữ liệu excel có lỗi"))
    }
}

