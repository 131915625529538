import { showNotify } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";

import { eToKhoiActionTypeIds, IToKhoiActionTypes } from "../../action-types/IToKhoiActionTypes";
import { IToKhoiPageStateModel } from "../../page-state-model/IToKhoiPageState";

const initialState: IToKhoiPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_tokhois: [],
    is_show_delete_confirm: false,
    sis_tokhoi_selected_ids: [],
    sis_tokhoi_editing: undefined,
    is_show_detail_modal: false
}
export const toKhoiReducer = (state: IToKhoiPageStateModel = initialState, action: IToKhoiActionTypes): IToKhoiPageStateModel => {
    switch (action.type) {
        case eToKhoiActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eToKhoiActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_tokhois: action.payload
            }
        case eToKhoiActionTypeIds.LOAD_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_tokhois: []
            }
        case eToKhoiActionTypeIds.LOAD_SELECT_VIEW_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eToKhoiActionTypeIds.LOAD_SELECT_VIEW_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_tokhois: action.payload
            }
        case eToKhoiActionTypeIds.LOAD_SELECT_VIEW_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_tokhois: []
            }
        case eToKhoiActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                sis_tokhoi_editing: action.payload
            }
        case eToKhoiActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                sis_tokhoi_editing: undefined
            }
        case eToKhoiActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_tokhoi_selected_ids: action.payload
            }
        case eToKhoiActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eToKhoiActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        
            case eToKhoiActionTypeIds.DELETE_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_deleting
                }
            case eToKhoiActionTypeIds.DELETE_SUCCESS:
                showNotify({ type: "success", message: 'Xóa dữ liệu thành công.' })
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }
            case eToKhoiActionTypeIds.DELETE_ERROR:
                showNotify({ type: "error", message: action.payload })
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }

        
            case eToKhoiActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eToKhoiActionTypeIds.SAVE_SUCCESS:
            showNotify({ type: "success", message: 'Thay đổi dữ liệu thành công.' })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }
        case eToKhoiActionTypeIds.SAVE_ERR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }

        default:
            return state;
    }

}
