import { AppYeuCauHoTroSelectRequest } from "../../../models/request/app-phuhuynh/AppYeuCauHoTroSelectRequest"
import { AppYeuCauHoTroUpdateRequest } from "../../../models/request/app-phuhuynh/AppYeuCauHoTroUpdateRequest"
import { app_yeu_cau_ho_tro } from "../../../models/response/app-phuhuynh/app_yeu_cau_ho_tro"
import { app_yeu_cau_ho_tro_group } from "../../../models/response/app-phuhuynh/app_yeu_cau_ho_tro_group"
import { AppYeuCauHoTroGroupAddOrUpdateRequest } from "../../../models/request/app-phuhuynh/AppYeuCauHoTroGroupAddOrUpdateRequest";
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { BaseAction } from "../../actions/IBaseActionResult"
import { IActionTypeBase } from "../IActionTypeBase"


export enum eAppYeuCauHoTroActionTypeIds {
    LOAD_SELECT_START = "APP_YeuCauHoTro_LOAD_SELECT_START",
    LOAD_SELECT_SUCCESS = "APP_YeuCauHoTro_LOAD_SELECT_SUCCESS",
    LOAD_SELECT_ERROR = "APP_YeuCauHoTro_LOAD_SELECT_ERROR",

    LOAD_DETAIL_START = "APP_YeuCauHoTro_LOAD_DETAIL_START",
    LOAD_DETAIL_SUCCESS = "APP_YeuCauHoTro_LOAD_DETAIL_SUCCESS",
    LOAD_DETAIL_ERROR = "APP_YeuCauHoTro_LOAD_DETAIL_ERROR",

    UPDATE_PHANHOI_START = "APP_YeuCauHoTro_UPDATE_PHANHOI_START",
    UPDATE_PHANHOI_SUCCESS = "APP_YeuCauHoTro_UPDATE_PHANHOI_SUCCESS",
    UPDATE_PHANHOI_ERROR = "APP_YeuCauHoTro_UPDATE_PHANHOI_ERROR",

    LOAD_GROUP_START = "APP_YeuCauHoTro_LOAD_GROUP_START",
    LOAD_GROUP_SUCCESS = "APP_YeuCauHoTro_LOAD_GROUP_SUCCESS",
    LOAD_GROUP_ERROR = "APP_YeuCauHoTro_LOAD_GROUP_ERROR",
    
    LOAD_GROUP_DETAIL_START = "APP_YeuCauHoTro_LOAD_GROUP_DETAIL_START",
    LOAD_GROUP_DETAIL_SUCCESS = "APP_YeuCauHoTro_LOAD_GROUP_DETAIL_SUCCESS",
    LOAD_GROUP_DETAIL_ERROR = "APP_YeuCauHoTro_LOAD_GROUP_DETAIL_ERROR",
    
    SAVE_GROUP_START = "APP_YeuCauHoTro_SAVE_GROUP_START",
    SAVE_GROUP_SUCCESS = "APP_YeuCauHoTro_SAVE_GROUP_SUCCESS",
    SAVE_GROUP_ERROR = "APP_YeuCauHoTro_SAVE_GROUP_ERROR",

    DELETE_GROUP_START = "APP_YeuCauHoTro_DELETE_GROUP_START",
    DELETE_GROUP_SUCCESS = "APP_YeuCauHoTro_DELETE_GROUP_SUCCESS",
    DELETE_GROUP_ERROR = "APP_YeuCauHoTro_DELETE_GROUP_ERROR",
    SHOW_GROUP_DETAIL = "APP_YeuCauHoTro_SHOW_GROUP_DETAIL",
    CLOSE_GROUP_DETAIL = "APP_YeuCauHoTro_CLOSE_GROUP_DETAIL",
    CHANGE_GROUP_SELECTED_IDS = "APP_YeuCauHoTro_CHANGE_GROUP_SELECTED_IDS",
    SHOW_GROUP_CONFIRM = "APP_YeuCauHoTro_SHOW_GROUP_CONFIRM",
    CLOSE_GROUP_CONFIRM = "APP_YeuCauHoTro_CLOSE_GROUP_CONFIRM"
}

export interface IAppYeuCauHoTroLoadDetailStart extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_START, number> { };
export interface IAppYeuCauHoTroLoadDetailSuccess extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_SUCCESS, app_yeu_cau_ho_tro> { };
export interface IAppYeuCauHoTroLoadDetailErr extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_ERROR, string> { };

export interface IAppYeuCauHoTroLoadSelectStart extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_START, AppYeuCauHoTroSelectRequest> { };
export interface IAppYeuCauHoTroLoadSelectSuccess extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_SUCCESS, app_yeu_cau_ho_tro[]> { };
export interface IAppYeuCauHoTroLoadSelectErr extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_ERROR, string> { };

export interface IAppYeuCauHoTroUpdatePhanHoiStart extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_START, AppYeuCauHoTroUpdateRequest> { };
export interface IAppYeuCauHoTroUpdatePhanHoiSuccess extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_SUCCESS, undefined> { };
export interface IAppYeuCauHoTroUpdatePhanHoiErr extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_ERROR, string> { };

export interface IAppYeuCauHoTroLoadGroupStart extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_START, number> { };
export interface IAppYeuCauHoTroLoadGroupSuccess extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_SUCCESS, app_yeu_cau_ho_tro_group[]> { };
export interface IAppYeuCauHoTroLoadGroupErr extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_ERROR, string> { };

export interface IAppYeuCauHoTroLoadGroupDetailStart extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_DETAIL_START, number> { };
export interface IAppYeuCauHoTroLoadGroupDetailSuccess extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_DETAIL_SUCCESS, app_yeu_cau_ho_tro_group> { };
export interface IAppYeuCauHoTroLoadGroupDetailErr extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_DETAIL_ERROR, string> { };

export interface IAppYeuCauHoTroSaveGroupStart extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_START, AppYeuCauHoTroGroupAddOrUpdateRequest> { };
export interface IAppYeuCauHoTroSaveGroupSuccess extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_SUCCESS, app_yeu_cau_ho_tro_group> { };
export interface IAppYeuCauHoTroSaveGroupErr extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_ERROR, string> { };

export interface IAppYeuCauHoTroDeleteGroupStart extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_START, IDeleteMultipleBaseRequest> { };
export interface IAppYeuCauHoTroDeleteGroupSuccess extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_SUCCESS, undefined> { };
export interface IAppYeuCauHoTroDeleteGroupErr extends IActionTypeBase<eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_ERROR, string> { };

export interface IAppYeuCauHoTroShowGroupDetail {
    type: eAppYeuCauHoTroActionTypeIds.SHOW_GROUP_DETAIL,
    payload?: app_yeu_cau_ho_tro_group
}
export interface IAppYeuCauHoTroGroupCloseDetail {
    type: eAppYeuCauHoTroActionTypeIds.CLOSE_GROUP_DETAIL,
}
export interface IAppYeuCauHoTroGroupChangeSelectedIds {
    type: eAppYeuCauHoTroActionTypeIds.CHANGE_GROUP_SELECTED_IDS,
    payload: number[]
}
export interface IAppYeuCauHoTroGroupShowConfirm {
    type: eAppYeuCauHoTroActionTypeIds.SHOW_GROUP_CONFIRM
}
export interface IAppYeuCauHoTroGroupCloseConfirm {
    type: eAppYeuCauHoTroActionTypeIds.CLOSE_GROUP_CONFIRM
}

export type IAppYeuCauHoTroActionTypes =
    IAppYeuCauHoTroLoadSelectStart | IAppYeuCauHoTroLoadSelectSuccess | IAppYeuCauHoTroLoadSelectErr |
    IAppYeuCauHoTroLoadDetailStart | IAppYeuCauHoTroLoadDetailSuccess | IAppYeuCauHoTroLoadDetailErr |
    IAppYeuCauHoTroUpdatePhanHoiStart | IAppYeuCauHoTroUpdatePhanHoiSuccess | IAppYeuCauHoTroUpdatePhanHoiErr  |
    IAppYeuCauHoTroLoadGroupStart | IAppYeuCauHoTroLoadGroupSuccess | IAppYeuCauHoTroLoadGroupErr  |
    IAppYeuCauHoTroLoadGroupDetailStart | IAppYeuCauHoTroLoadGroupDetailSuccess | IAppYeuCauHoTroLoadGroupDetailErr  |
    IAppYeuCauHoTroSaveGroupStart | IAppYeuCauHoTroSaveGroupSuccess | IAppYeuCauHoTroSaveGroupErr  |
    IAppYeuCauHoTroDeleteGroupStart | IAppYeuCauHoTroDeleteGroupSuccess | IAppYeuCauHoTroDeleteGroupErr | 
    IAppYeuCauHoTroShowGroupDetail | IAppYeuCauHoTroGroupCloseDetail | IAppYeuCauHoTroGroupChangeSelectedIds | 
    IAppYeuCauHoTroGroupShowConfirm | IAppYeuCauHoTroGroupCloseConfirm