import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { pl_tiet } from "../../../models/response/danh-muc/pl_tiet"

export enum eTietActionTypeIds {
    LOAD_START = "TIET_LOAD_START",
    LOAD_SUCCESS = "TIET_LOAD_SUCCESS",
    LOAD_ERROR = "TIET_LOAD_ERROR",

    SHOW_DETAIL = "TIET_SHOW_DETAIL",
    CLOSE_DETAIL = "TIET_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "TIET_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "TIET_SHOW_CONFIRM",
    CLOSE_CONFIRM = "TIET_CLOSE_CONFIRM",

    DELETE_START = "TIET_DELETE_START",
    DELETE_SUCCESS = "TIET_DELETE_SUCCESS",
    DELETE_ERROR = "TIET_DELETE_ERROR",

    SAVE_START = "TIET_SAVE_START",
    SAVE_SUCCESS = "TIET_SAVE_SUCCESS",
    SAVE_ERR = "TIET_SAVE_ERR"
}

export interface ITietLoadStart {
    type: eTietActionTypeIds.LOAD_START,
}
export interface ITietLoadSuccess {
    type: eTietActionTypeIds.LOAD_SUCCESS,
    payload: pl_tiet[]
}
export interface ITietLoadErr {
    type: eTietActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface ITietShowDetail {
    type: eTietActionTypeIds.SHOW_DETAIL,
    payload?: pl_tiet
}
export interface ITietCloseDetail {
    type: eTietActionTypeIds.CLOSE_DETAIL,
}
export interface ITietChangeSelectedIds {
    type: eTietActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface ITietShowConfirm {
    type: eTietActionTypeIds.SHOW_CONFIRM
}
export interface ITietCloseConfirm {
    type: eTietActionTypeIds.CLOSE_CONFIRM
}

export interface ITietDeleteStart {
    type: eTietActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface ITietDeleteSuccess {
    type: eTietActionTypeIds.DELETE_SUCCESS,
}
export interface ITietDeleteErr {
    type: eTietActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface ITietSaveStart {
    type: eTietActionTypeIds.SAVE_START,
    payload: pl_tiet
}
export interface ITietSaveSuccess {
    type: eTietActionTypeIds.SAVE_SUCCESS
}
export interface ITietSaveErr {
    type: eTietActionTypeIds.SAVE_ERR,
    payload: string
}
export type ITietActionTypes = ITietLoadStart | ITietLoadSuccess | ITietLoadErr |
    ITietShowDetail | ITietCloseDetail |
    ITietChangeSelectedIds |
    ITietShowConfirm | ITietCloseConfirm |
    ITietDeleteStart | ITietDeleteSuccess | ITietDeleteErr |
    ITietSaveStart | ITietSaveSuccess | ITietSaveErr