import FormKhaoSatItemAddRequest from "../../models/request/form-khaosat/FormKhaoSatItemAddRequest";
import { sis_form_khaosat_item } from "../../models/response/form-khaosat/sis_form_khaosat_item";
import { apiClient } from "../apiClient";
import { BaseCRUDApi } from "../baseCRUDApi";

export const API_FORMKHAOSAT_ITEM_PATH = "form-khao-sat-item";

export const formKhaoSatItemApi = new class FormKhaoSatItemApi extends BaseCRUDApi<sis_form_khaosat_item> {
    constructor(path: string) {
        super(path);
    }
    SelectByFormAsync(sis_form_khaosat_id: number) {
        return apiClient.get(`${this._path}/form/${sis_form_khaosat_id}/select-all`);
    }
    InsertMultipleAsync({ sis_form_khaosat_id, sis_form_khaosat_items }: { sis_form_khaosat_id: number, sis_form_khaosat_items: sis_form_khaosat_item[] }) {
        return apiClient.post(`${this._path}/add-items`, { sis_form_khaosat_id, sis_form_khaosat_items });
    }
    InsertSingleAsync(request: FormKhaoSatItemAddRequest) {
        return apiClient.post(`${this._path}/add-item`, request);
    }
    UpdateIdxMultipleAsync({ sis_form_khaosat_item_ids }: { sis_form_khaosat_item_ids: number[] }) {
        return apiClient.post(`${this._path}/update-idx-items`, { sis_form_khaosat_item_ids });
    }
}(API_FORMKHAOSAT_ITEM_PATH);