import { appAccountActions } from "./appAccountActions";
import { appMenuActions } from "./appMenuActions";
import { appMenuNhomActions } from "./appMenuNhomActions";
import { appMenuTruongActions } from "./appMenuTruongActions";
import { appXinNghiActions } from "./appXinNghiActions";
import { appYeuCauHoTroActions } from "./appYeuCauHoTroActions";

export const appPhuHuynhActionsWrapper = {
    appMenu: appMenuActions,
    appMenuNhom: appMenuNhomActions,
    appMenuTruong: appMenuTruongActions,
    appAccount: appAccountActions,
    appXinNghi: appXinNghiActions,
    appYeuCauHoTro: appYeuCauHoTroActions
}