
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonContext } from '../../contexts/common';
import { dm_khoi } from '../../models/response/category/dm_khoi';
import { actions } from '../../state/actions/actionsWrapper';
import { RootState } from '../../state/reducers';
import SelectBox from 'devextreme-react/select-box';
type ComboboxKhoiProps = {
	isReadonly?: boolean;
	value?: number;
	onValueChanged: (id: number, data?: dm_khoi) => void;
	className?: string;
	isShowClearButton?: boolean;
	preText?: string;
	width?: string | number;
	dm_truong_id?: number;
	stylingMode?: 'outlined' | 'filled' | 'underlined';
};
const ComboboxKhoi = (props: ComboboxKhoiProps) => {
	const { dm_khois, dm_truong_khoi_hes } = useSelector((state: RootState) => state.categorySource);
	const { language } = useSelector((x: RootState) => x.common);
	const dispatch = useDispatch();
	useEffect(() => {
		if (dm_khois.length === 0) dispatch(actions.categorySource.loadKhoiStart());
	}, []);
	const { translate } = useCommonContext();
	const source: dm_khoi[] = useMemo(() => {
		if (props.dm_truong_id) {
			const dm_khoi_ids = dm_truong_khoi_hes
				.filter((x) => x.dm_truong_id == props.dm_truong_id)
				.map((x) => x.dm_khoi_id);
			return dm_khois.filter((x) => dm_khoi_ids.includes(x.id));
		}
		return dm_khois;
	}, [dm_khois, dm_truong_khoi_hes, props.dm_truong_id]);
	return (
		<SelectBox
			dataSource={source}
			displayExpr={language === 'en' ? 'ten_khoi_en' : 'ten_khoi'}
			valueExpr={'id'}
			value={props.value}
			searchEnabled
			stylingMode={props.stylingMode || 'outlined'}
			readOnly={props.isReadonly === true}
			placeholder={translate('Base.Label.ChooseGrade')}
			width={props.width ? props.width : '100%'}
			className={'default_selectbox ' + (props.className ? props.className : '')}
			// showClearButton={props.isShowClearButton === true}
			showClearButton={true}
			onValueChanged={(e) => {
				if (e.event) {
					// console.log({ e });
					const data = source.find((x) => x.id == e.value);
					props.onValueChanged(e.value, data);
				}
			}}
		/>
	);
};

export default ComboboxKhoi;
