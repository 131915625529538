import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eSoNhanXetActionTypeIds, ISoNhanXetDeleteStart, ISoNhanXetLoadStart, ISoNhanXetSavingStart } from "../../action-types/so-diem-tieu-hoc/ISoNhanXetActionTypes";
import { actions } from '../../actions/actionsWrapper';


const soNhanXetApi = apiWrapper.soNhanXet;

export function* soNhanXetSaga(): any {
    yield takeLatest(eSoNhanXetActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eSoNhanXetActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eSoNhanXetActionTypeIds.SAVING_START, saveWorker)
}
function* loadWorker(action: ISoNhanXetLoadStart): any {

    const res: IBaseResponeModel = yield call([soNhanXetApi, soNhanXetApi.Select], action.payload)
    if (res.is_success) {
        yield put(actions.dgdkDmNlpcSetAction.loadSuccess(res.data))
    } else {
        yield put(actions.dgdkDmNlpcSetAction.loadError(res.message ?? ""))
    }
}
function* deleteWorker(action: ISoNhanXetDeleteStart): any {
    const res: IBaseResponeModel = yield call([soNhanXetApi, soNhanXetApi.Delete], action.payload)
    if (res.is_success) {
        yield put(actions.dgdkDmNlpcSetAction.deleteSuccess())
    } else {
        yield put(actions.dgdkDmNlpcSetAction.deleteError(res.message || ""))
    }
}
function* saveWorker(action: ISoNhanXetSavingStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([soNhanXetApi, soNhanXetApi.Update], action.payload);
    } else {
        res = yield call([soNhanXetApi, soNhanXetApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.dgdkDmNlpcSetAction.saveSuccess())
    } else {
        yield put(actions.dgdkDmNlpcSetAction.saveError(res.message ?? ""))
    }
}
