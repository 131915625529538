import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { GiaoVienUpdateRequest } from "../../../models/request/category/GiaoVienUpdateRequest";
import { sis_giaovien_view } from "../../../models/response/giao-vien/sis_giaovien";
import { IGiaoVienImportDsResponse } from "../../../models/response/giao-vien/GiaoVienImportDsResponse";
import { IGiaoVienImportDsRequest } from "../../../models/request/giao-vien/GiaoVienImportDsRequest";
import {
    IGiaoVienChangeSelectedIds, IGiaoVienCloseConfirm,
    IGiaoVienCloseDetail, IGiaoVienDeleteErr, IGiaoVienDeleteStart, IGiaoVienDeleteSuccess,
    IGiaoVienLoadByTruongErr, IGiaoVienLoadByTruongStart, IGiaoVienLoadByTruongSuccess, IGiaoVienLoadErr,
    IGiaoVienLoadStart, IGiaoVienLoadSuccess, IGiaoVienShowAddModal, IGiaoVienShowConfirm, IGiaoVienShowDetail,
    IGiaoVienUploadExcelStartAction, IGiaoVienUploadExcelSuccessAction,IGiaoVienUploadExcelErrorAction,
    IGiaoVienSaveExcelStartAction, IGiaoVienSaveExcelSuccessAction, IGiaoVienSaveExcelErrorAction,
    eGiaoVienActionTypeIds
} from "../../action-types/IGiaoVienActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const giaoVienActions = {
    //ALL
    loadGiaoVienStart: (): IGiaoVienLoadStart => BaseAction(undefined, eGiaoVienActionTypeIds.LOAD_START),
    loadGiaoVienSuccess: (respone: sis_giaovien_view[]): IGiaoVienLoadSuccess => BaseAction(respone, eGiaoVienActionTypeIds.LOAD_SUCCESS),
    loadGiaoVienError: (messege: string): IGiaoVienLoadErr => BaseAction(messege, eGiaoVienActionTypeIds.LOAD_ERROR),
    //By truong
    loadByTruongGiaoVienStart: (dm_truong_id: number): IGiaoVienLoadByTruongStart => BaseAction(dm_truong_id, eGiaoVienActionTypeIds.LOAD_BYTRUONG_START),
    loadByTruongGiaoVienSuccess: (respone: sis_giaovien_view[]): IGiaoVienLoadByTruongSuccess => BaseAction(respone, eGiaoVienActionTypeIds.LOAD_BYTRUONG_SUCCESS),
    loadByTruongGiaoVienError: (messege: string): IGiaoVienLoadByTruongErr => BaseAction(messege, eGiaoVienActionTypeIds.LOAD_BYTRUONG_ERROR),
    //not in truong
    showGiaoVienAddModal: (show: boolean): IGiaoVienShowAddModal => BaseAction(show, eGiaoVienActionTypeIds.SHOW_ADD_MODAL),
    showGiaoVienDetail: (data?: sis_giaovien_view): IGiaoVienShowDetail => BaseAction(data, eGiaoVienActionTypeIds.SHOW_DETAIL),
    closeGiaoVienDetail: (): IGiaoVienCloseDetail => BaseAction(undefined, eGiaoVienActionTypeIds.CLOSE_DETAIL),
    showGiaoVienConfirm: (): IGiaoVienShowConfirm => BaseAction(undefined, eGiaoVienActionTypeIds.SHOW_CONFIRM),
    closeGiaoVienConfirm: (): IGiaoVienCloseConfirm => BaseAction(undefined, eGiaoVienActionTypeIds.CLOSE_CONFIRM),
    changeGiaoVienSelectedIds: (ids: number[]): IGiaoVienChangeSelectedIds => BaseAction(ids, eGiaoVienActionTypeIds.CHANGE_SELECTED_IDS),
    deleteGiaoVienStart: (ids: IDeleteMultipleBaseRequest): IGiaoVienDeleteStart => BaseAction(ids, eGiaoVienActionTypeIds.DELETE_START),
    deleteGiaoVienSuccess: (): IGiaoVienDeleteSuccess => BaseAction(undefined, eGiaoVienActionTypeIds.DELETE_SUCCESS),
    deleteGiaoVienErr: (message: string): IGiaoVienDeleteErr => BaseAction(message, eGiaoVienActionTypeIds.DELETE_ERROR),
    //SAVE
    saveGiaoVienStart: (data: GiaoVienUpdateRequest) => BaseAction(data, eGiaoVienActionTypeIds.SAVE_START),
    saveGiaoVienSuccess: () => BaseAction(undefined, eGiaoVienActionTypeIds.SAVE_SUCCESS),
    saveGiaoVienError: (message: string) => BaseAction(message, eGiaoVienActionTypeIds.SAVE_ERR),
    clearStatus: () => BaseAction(undefined, eGiaoVienActionTypeIds.CLEAR_STATUS),

    loadFromExcelStart: (data: FormData): IGiaoVienUploadExcelStartAction => BaseAction(data, eGiaoVienActionTypeIds.UPLOAD_EXCEL_START),
    loadFromExcelSuccess: (respone: IGiaoVienImportDsResponse): IGiaoVienUploadExcelSuccessAction => BaseAction(respone, eGiaoVienActionTypeIds.UPLOAD_EXCEL_SUCCESS),
    loadFromExcelError: (message: string): IGiaoVienUploadExcelErrorAction => BaseAction(message, eGiaoVienActionTypeIds.UPLOAD_EXCEL_ERROR),

    saveFromExcelStart: (data: IGiaoVienImportDsRequest): IGiaoVienSaveExcelStartAction => BaseAction(data, eGiaoVienActionTypeIds.SAVE_EXCEL_START),
    saveFromExcelSuccess: (respone: IGiaoVienImportDsResponse): IGiaoVienSaveExcelSuccessAction => BaseAction(respone, eGiaoVienActionTypeIds.SAVE_EXCEL_SUCCESS),
    saveFromExcelError: (message: string): IGiaoVienSaveExcelErrorAction => BaseAction(message, eGiaoVienActionTypeIds.SAVE_EXCEL_ERROR),

}


