import { IDeleteMultipleBaseRequest } from '../../../models/request/base/deleteRequest';
import { baseAction } from '../IActionBase';
import { NotifyHelper } from './../../../helpers/toast';
import { ILopBoMonGenerateRequest } from './../../../models/request/lop-bo-mon/ILopBoMonGenerateRequest';
import { ILopBoMonSelectRequest } from './../../../models/request/lop-bo-mon/ILopBoMonSelectRequest';
import { ILopBoMonTreeViewItemRespone } from './../../../models/response/lop-bo-mon/LopBoMonTreeViewItem';
import { pl_lopbomon } from './../../../models/response/lop-bo-mon/pl_lopbomon';
import { eLopBoMonListActionTypeIds, ILopBoMonCloseGenerate, ILopBoMonGenerateError, 
    ILopBoMonGenerateStart, ILopBoMonGenerateSuccess, ILopBoMonListLoadErr, ILopBoMonListLoadStart, 
    ILopBoMonListLoadSuccess, ILopBoMonShowGenerate
} from './../../action-types/lop-bo-mon/ILopBoMonListActionTypes';
import { BaseAction } from "../IBaseActionResult";

export const lopBoMonListActions = {

    loadTreeViewItemStart: (request: ILopBoMonSelectRequest): ILopBoMonListLoadStart =>
        baseAction(eLopBoMonListActionTypeIds.LOAD_START, request),
    loadTreeViewItemSuccess: (respone: ILopBoMonTreeViewItemRespone[]): ILopBoMonListLoadSuccess =>
        baseAction(eLopBoMonListActionTypeIds.LOAD_SUCCESS, respone),
    loadTreeViewError: (message: string): ILopBoMonListLoadErr => {
        NotifyHelper.Error(message)
        return baseAction(eLopBoMonListActionTypeIds.LOAD_ERROR, message)
    },
    showDetailModal: (lopBoMonId: number) =>
        baseAction(eLopBoMonListActionTypeIds.SHOW_DETAIL, lopBoMonId),
    closeDetailModal: () => baseAction(eLopBoMonListActionTypeIds.CLOSE_DETAIL, undefined),
    saveStart: (request: pl_lopbomon) => baseAction(eLopBoMonListActionTypeIds.SAVE_START, request),
    saveSuccess: () => {
        NotifyHelper.Success('Base.Label.UpdateSuccess')
        return baseAction(eLopBoMonListActionTypeIds.SAVE_SUCCESS, undefined)
    },
    saveError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(message, eLopBoMonListActionTypeIds.SAVE_ERR)
    },
    showGenerate: (): ILopBoMonShowGenerate => baseAction(eLopBoMonListActionTypeIds.SHOW_GENERATE, undefined),
    closeGenerate: (): ILopBoMonCloseGenerate => baseAction(eLopBoMonListActionTypeIds.CLOSE_GENERATE, undefined),
    generateStart: (request: ILopBoMonGenerateRequest): ILopBoMonGenerateStart =>
        baseAction(eLopBoMonListActionTypeIds.GENERATE_START, request),
    generateSuccess: (): ILopBoMonGenerateSuccess => {
        NotifyHelper.Success("LopListContainer.Generate.Success")
        return baseAction(eLopBoMonListActionTypeIds.GENERATE_SUCCESS, undefined)
    },
    generateError: (message: string): ILopBoMonGenerateError => {
        NotifyHelper.Error(message)
        return baseAction(eLopBoMonListActionTypeIds.GENERATE_ERROR, message)
    },
    changeSelectedIds: (pl_lopbomon_ids: number[]) =>
        baseAction(eLopBoMonListActionTypeIds.CHANGE_SELECTED_IDS, pl_lopbomon_ids),

    showPhanCongModal: () => baseAction(eLopBoMonListActionTypeIds.SHOW_PHANCONG_MODAL, undefined),
    closePhanCongModal: () => baseAction(eLopBoMonListActionTypeIds.CLOSE_PHANCONG_MODAL, undefined),

    showDeleteModal: () => baseAction(eLopBoMonListActionTypeIds.SHOW_DELETE_MODAL, undefined),
    closeDeleteModal: () => baseAction(eLopBoMonListActionTypeIds.CLOSE_DELETE_MODAL, undefined),
    deleteStart: (request: IDeleteMultipleBaseRequest) =>
        baseAction(eLopBoMonListActionTypeIds.DELETE_START, request),
    deleteSuccess: () => {
        NotifyHelper.Success("LopListContainer.Delete.Success")
        return baseAction(eLopBoMonListActionTypeIds.DELETE_SUCCESS, undefined)
    },
    deleteError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eLopBoMonListActionTypeIds.DELETE_ERROR, message)
    },
}