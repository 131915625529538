import { ILopMonHocGenerateRequest } from "../../../models/request/dm-lop/ILopGenerateRequest";
import { ILopSelectRequest } from "../../../models/request/dm-lop/ILopSelectRequest";
import { ILopMonHoc } from "../../../models/response/lop-mon-hoc/ILopMonHoc";
import { ILopItemResponse } from "../../../models/response/lop/ILopItemResponse";
import { ILopMonHocCopyState } from "../../page-state-model/lop-mon-hoc/ILopMonHocPageState";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eLopMonHocActionTypIds {
    LOAD_LOP_START = "LOP_MONHOC_LOAD_LOP_START",
    LOAD_LOP_SUCCESS = "LOP_MONHOC_LOAD_LOP_SUCCESS",
    LOAD_LOP_ERROR = "LOP_MONHOC_LOAD_LOP_ERROR",

    LOAD_LOP_MONHOC_START = "LOP_MONHOC_LOAD_LOP_MONHOC_START",
    LOAD_LOP_MONHOC_SUCCESS = "LOP_MONHOC_LOAD_LOP_MONHOC_SUCCESS",
    LOAD_LOP_MONHOC_ERROR = "LOP_MONHOC_LOAD_LOP_MONHOC_ERROR",

    SAVE_START = "LOP_MONHOC_SAVE_START",
    SAVE_SUCCESS = "LOP_MONHOC_SAVE_SUCCESS",
    SAVE_ERROR = "LOP_MONHOC_SAVE_ERROR",

    CHANGE_KHOI_SELECTED_ID = "LOP_MONHOC_CHANGE_KHOI_SELECTED_ID",

    CHANGE_COPY = "LOP_MONHOC_CHANGE_COPY",

    COPY_START = "LOP_MONHOC_COPY_START",
    COPY_SUCCESS = "LOP_MONHOC_COPY_SUCCESS",
    COPY_ERROR = "LOP_MONHOC_COPY_ERROR",

    CREATE_LOPBOMON_START = "LOP_MONHOC_CREATE_LOPBOMON_START",
    CREATE_LOPBOMON_SUCCESS = "LOP_MONHOC_CREATE_LOPBOMON_SUCCESS",
    CREATE_LOPBOMON_ERROR = "LOP_MONHOC_CREATE_LOPBOMON_ERROR",
}
export interface ILopMonHocLoadLopStart extends IActionTypeBase<eLopMonHocActionTypIds.LOAD_LOP_START, ILopSelectRequest> { }
export interface ILopMonHocLoadLopSuccess extends IActionTypeBase<eLopMonHocActionTypIds.LOAD_LOP_SUCCESS, ILopItemResponse[]> { }
export interface ILopMonHocLoadLopError extends IActionTypeBase<eLopMonHocActionTypIds.LOAD_LOP_ERROR, string> { }

export interface ILopMonHocLoadStart extends IActionTypeBase<eLopMonHocActionTypIds.LOAD_LOP_MONHOC_START, ILopSelectRequest> { }
export interface ILopMonHocLoadSuccess extends IActionTypeBase<eLopMonHocActionTypIds.LOAD_LOP_MONHOC_SUCCESS, ILopMonHoc[]> { }
export interface ILopMonHocLoadError extends IActionTypeBase<eLopMonHocActionTypIds.LOAD_LOP_MONHOC_ERROR, string> { }

export interface ILopMonHocSavetart extends IActionTypeBase<eLopMonHocActionTypIds.SAVE_START, ILopMonHoc> { }
export interface ILopMonHocSaveSuccess extends IActionTypeBase<eLopMonHocActionTypIds.SAVE_SUCCESS, ILopMonHoc> { }
export interface ILopMonHocSaveError extends IActionTypeBase<eLopMonHocActionTypIds.SAVE_ERROR, string> { }

export interface ILopMonHocChangeKhoiSelectedId extends IActionTypeBase<eLopMonHocActionTypIds.CHANGE_KHOI_SELECTED_ID, number> { }

export interface ILopMonHocChangeCoppy extends IActionTypeBase<eLopMonHocActionTypIds.CHANGE_COPY, ILopMonHocCopyState> { }
export interface ILopMonHocCopyStart extends IActionTypeBase<eLopMonHocActionTypIds.COPY_START, ILopMonHocCopyState> { }
export interface ILopMonHocCopySuccess extends IActionTypeBase<eLopMonHocActionTypIds.COPY_SUCCESS, undefined> { }
export interface ILopMonHocCopyError extends IActionTypeBase<eLopMonHocActionTypIds.COPY_ERROR, string> { }

export interface ILopMonHocCreateLopBoMonStart extends IActionTypeBase<eLopMonHocActionTypIds.CREATE_LOPBOMON_START, ILopMonHocGenerateRequest> { }
export interface ILopMonHocCreateLopBoMonSuccess extends IActionTypeBase<eLopMonHocActionTypIds.CREATE_LOPBOMON_SUCCESS, undefined> { }
export interface ILopMonHocCreateLopBoMonError extends IActionTypeBase<eLopMonHocActionTypIds.CREATE_LOPBOMON_ERROR, string> { }

export type ILopMonHocActionTypes = ILopMonHocLoadLopStart | ILopMonHocLoadLopSuccess | ILopMonHocLoadLopError |
    ILopMonHocChangeKhoiSelectedId |
    ILopMonHocLoadStart | ILopMonHocLoadSuccess | ILopMonHocLoadError |
    ILopMonHocSavetart | ILopMonHocSaveSuccess | ILopMonHocSaveError |
    ILopMonHocChangeCoppy |
    ILopMonHocCopyStart | ILopMonHocCopySuccess | ILopMonHocCopyError |
    ILopMonHocCreateLopBoMonStart | ILopMonHocCreateLopBoMonSuccess | ILopMonHocCreateLopBoMonError
