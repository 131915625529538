import { NotifyHelper } from '../../../helpers/toast';
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eBaiVietDetailActionTypeIds, IBaiVietDetailActionTypes } from '../../action-types/bai-viet/IBaiVietDetailActionTypes';
import { IBaiVietDetailPageState } from '../../page-state-model/bai-viet/IBaiVietDetailPageState';
const iniState: IBaiVietDetailPageState = {
    status: ePageBaseStatus.is_not_initialization,
    status_change: 'idle',
    app_baiviet: undefined,
    app_baiviet_id: 0
}
export const baiVietDetailReducer = (state: IBaiVietDetailPageState = iniState, action: IBaiVietDetailActionTypes): IBaiVietDetailPageState => {

    switch (action.type) {
        case eBaiVietDetailActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiVietDetailActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviet: action.payload
            }
        case eBaiVietDetailActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviet: iniState.app_baiviet
            }
        case eBaiVietDetailActionTypeIds.CHANGE_DATA:
            return {
                ...state,
                app_baiviet: action.payload
            }

        case eBaiVietDetailActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving,
                status_change: 'idle'
            }
        case eBaiVietDetailActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Cập nhật thành công")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                status_change: 'complete',
                app_baiviet_id: action.payload
            }
        case eBaiVietDetailActionTypeIds.SAVE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                status_change: 'complete'
            }
        default:
            return {
                ...state
            }
    }
}