import { ILopMonHoc } from './../../../models/response/lop-mon-hoc/ILopMonHoc';
import { ILopMonHocActionTypes, eLopMonHocActionTypIds } from './../../action-types/lop-mon-hoc/ILopMonHocActionTypes';
import { ILopMonHocPageState } from "../../page-state-model/lop-mon-hoc/ILopMonHocPageState";
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';


const iniState: ILopMonHocPageState = {
    status: ePageBaseStatus.is_not_initialization,
    lops: [],
    khoiId: 0,
    lopMonHocs: [],
    copy: {
        fromLopId: 0,
        toLopIds: []
    }
}
const mergeData = (lopMonHocs: ILopMonHoc[], change: ILopMonHoc): ILopMonHoc[] => {
    let source: ILopMonHoc[] = lopMonHocs.map(x => {
        if (x.id == change.id) {
            return change;
        }
        return x;
    });
    let item = source.find(x => x.id == change.id);
    if (!item) {
        source.push(change);
    }

    return source;
}
export const lopMonHocReducer = (state: ILopMonHocPageState = iniState, action: ILopMonHocActionTypes): ILopMonHocPageState => {
    switch (action.type) {
        case eLopMonHocActionTypIds.LOAD_LOP_SUCCESS:
            return {
                ...state,
                lops: action.payload,
                status: ePageBaseStatus.is_completed
            }
        case eLopMonHocActionTypIds.CHANGE_KHOI_SELECTED_ID:
            return {
                ...state,
                khoiId: action.payload
            }
        case eLopMonHocActionTypIds.LOAD_LOP_MONHOC_SUCCESS:
            return {
                ...state,
                lopMonHocs: action.payload,
                status: ePageBaseStatus.is_completed
            }
        case eLopMonHocActionTypIds.SAVE_SUCCESS:
            return {
                ...state,
                lopMonHocs: mergeData(state.lopMonHocs, action.payload)
            }
        case eLopMonHocActionTypIds.CHANGE_COPY:
            return {
                ...state,
                copy: action.payload
            }
        case eLopMonHocActionTypIds.COPY_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopMonHocActionTypIds.COPY_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                copy: {
                    fromLopId: 0,
                    toLopIds: []
                }
            }
        case eLopMonHocActionTypIds.COPY_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eLopMonHocActionTypIds.CREATE_LOPBOMON_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopMonHocActionTypIds.CREATE_LOPBOMON_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eLopMonHocActionTypIds.CREATE_LOPBOMON_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        default:
            return {
                ...state
            }
    }
}