import clsx from 'clsx'
import React from 'react'
import TextTranslated from '../text'
import styles from './form-group.module.css'
type FormGroupType = {
    label?: string,
    help_text?: string,
    children?: React.ReactNode,
    fluid?: boolean,
    label_minwidth?: number,
    is_show_border?: boolean,
    is_label_bold?: boolean
}
const FormGroup = (props: FormGroupType) => {
    return <div className={styles.form_group}>
        {props.label && <p className={styles.form_group_label}><TextTranslated value={props.label} /></p>}
        {props.children}
        {props.help_text && <p className={styles.form_group_helptext}><TextTranslated value={props.help_text} /></p>}
    </div>
}

const FormGroupInline = (props: FormGroupType) => {
    return <div>
        <div className={clsx(styles.form_group_inline,
            props.fluid ? styles.fluid : "",
            props.is_show_border ? styles.border : ""
        )}>
            {props.label && <label className={clsx(
                styles.form_group_inline_label,
                props.is_label_bold ? styles.lable_bold : ""
            )} style={{ minWidth: props.label_minwidth }}>
                <TextTranslated value={props.label} />
            </label>}
            {props.children}
        </div>
        {props.help_text && <p className={styles.form_group_helptext}><TextTranslated value={props.help_text} /></p>}
    </div>
}

export { FormGroup, FormGroupInline }