import { combineReducers } from "redux";
import { formKhaoSatReducer } from "./formKhaoSatReducer";
import { formKhaoSatItemReducer } from "./formKhaoSatItemReducer";
import { formKhaoSatEditorReducer } from "./formKhaoSatEditorReducer";
import { khaoSatReducer } from "./khaoSatReducer";

export const formKhaoSatReducerWrapper = combineReducers({
    formKhaoSat: formKhaoSatReducer,
    formKhaoSatItem: formKhaoSatItemReducer,
    formKhaoSatEditor: formKhaoSatEditorReducer,
    khaoSat: khaoSatReducer
});