import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { IThoiKhoaBieuDeleteMultipleRequest } from "../../../models/request/thoi-khoa-bieu/IThoiKhoaBieuDeleteMultipleRequest";
import { IThoiKhoaBieuDuplicateRequest } from "../../../models/request/thoi-khoa-bieu/IThoiKhoaBieuDuplicateRequest";
import { ITKBChiTietSaveExcelRequest } from "../../../models/request/thoi-khoa-bieu/ITKBChiTietSaveExcelRequest";
import { ITKBChiTietSelectByDateRequest, ThoiKhoaBieuPreviewByGiaoVienRequest } from "../../../models/request/thoi-khoa-bieu/ITKBChiTietSelectByDateRequest";
import { ITKBChiTietSelectRequest } from "../../../models/request/thoi-khoa-bieu/ITKBChiTietSelectRequest";
import { ITKBChiTietUploadResponse, pl_tkb_chitiet, pl_tkb_chitiet_view } from "../../../models/response/thoi-khoa-bieu/pl_tkb_chitiet";
import { IThoiKhoaBieuPreviewItem } from "../../../models/response/thoi-khoa-bieu/ThoiKhoaBieuPreviewItem";
import { eTKBChiTietActionTypeIds, ITKBChiTietChangeSelectedIds, ITKBChiTietCloseConfirm, ITKBChiTietCloseDetail, ITKBChiTietDeleteErr, ITKBChiTietDeleteMultipleErr, ITKBChiTietDeleteMultipleStart, ITKBChiTietDeleteMultipleSuccess, ITKBChiTietDeleteStart, ITKBChiTietDeleteSuccess, ITKBChiTietDuplicateClearErrorStart, ITKBChiTietDuplicateErr, ITKBChiTietDuplicateStart, ITKBChiTietDuplicateSuccess, ITKBChiTietLoadErr, ITKBChiTietLoadStart, ITKBChiTietLoadSuccess, ITKBChiTietPreviewByGiaoVienErr, ITKBChiTietPreviewByGiaoVienStart, ITKBChiTietPreviewByGiaoVienSuccess, ITKBChiTietSaveErr, ITKBChiTietSaveExcelErrorAction, ITKBChiTietSaveExcelStartAction, ITKBChiTietSaveExcelSuccessAction, ITKBChiTietSaveStart, ITKBChiTietSaveSuccess, ITKBChiTietSelectByGiaoVienErr, ITKBChiTietSelectByGiaoVienStart, ITKBChiTietSelectByGiaoVienSuccess, ITKBChiTietSelectErr, ITKBChiTietSelectStart, ITKBChiTietSelectSuccess, ITKBChiTietShowConfirm, ITKBChiTietShowDetail, ITKBChiTietUploadExcelClearAction, ITKBChiTietUploadExcelErrorAction, ITKBChiTietUploadExcelStartAction, ITKBChiTietUploadExcelSuccessAction } from "../../action-types/thoi-khoa-bieu/ITKBChiTietActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const thoiKhoaBieuChiTietActions = {
    loadStart: (): ITKBChiTietLoadStart => BaseAction(undefined, eTKBChiTietActionTypeIds.LOAD_START),
    loadSuccess: (respone: pl_tkb_chitiet_view[]): ITKBChiTietLoadSuccess => BaseAction(respone, eTKBChiTietActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): ITKBChiTietLoadErr => BaseAction(messege, eTKBChiTietActionTypeIds.LOAD_ERROR),

    selectStart: (request: ITKBChiTietSelectRequest): ITKBChiTietSelectStart => BaseAction(request, eTKBChiTietActionTypeIds.SELECT_START),
    selectSuccess: (respone: IThoiKhoaBieuPreviewItem[]): ITKBChiTietSelectSuccess => BaseAction(respone, eTKBChiTietActionTypeIds.SELECT_SUCCESS),
    selectError: (messege: string): ITKBChiTietSelectErr => BaseAction(messege, eTKBChiTietActionTypeIds.SELECT_ERROR),

    selectByGiaoVienStart: (request: ITKBChiTietSelectByDateRequest): ITKBChiTietSelectByGiaoVienStart => BaseAction(request, eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_START),
    selectByGiaoVienSuccess: (respone: pl_tkb_chitiet_view[]): ITKBChiTietSelectByGiaoVienSuccess => BaseAction(respone, eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_SUCCESS),
    selectByGiaoVienError: (messege: string): ITKBChiTietSelectByGiaoVienErr => BaseAction(messege, eTKBChiTietActionTypeIds.SELECT_BYGIAOVIEN_ERROR),

    previewByGiaoVienStart: (request: ThoiKhoaBieuPreviewByGiaoVienRequest): ITKBChiTietPreviewByGiaoVienStart => BaseAction(request, eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_START),
    previewByGiaoVienSuccess: (respone: IThoiKhoaBieuPreviewItem[]): ITKBChiTietPreviewByGiaoVienSuccess => BaseAction(respone, eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_SUCCESS),
    previewByGiaoVienError: (messege: string): ITKBChiTietPreviewByGiaoVienErr => BaseAction(messege, eTKBChiTietActionTypeIds.PREVIEW_BYGIAOVIEN_ERROR),

    showDetail: (data?: pl_tkb_chitiet_view): ITKBChiTietShowDetail => BaseAction(data, eTKBChiTietActionTypeIds.SHOW_DETAIL),
    closeDetail: (): ITKBChiTietCloseDetail => BaseAction(undefined, eTKBChiTietActionTypeIds.CLOSE_DETAIL),
    showConfirm: (): ITKBChiTietShowConfirm => BaseAction(undefined, eTKBChiTietActionTypeIds.SHOW_CONFIRM),
    closeConfirm: (): ITKBChiTietCloseConfirm => BaseAction(undefined, eTKBChiTietActionTypeIds.CLOSE_CONFIRM),
    changeSelectedIds: (ids: number[]): ITKBChiTietChangeSelectedIds => BaseAction(ids, eTKBChiTietActionTypeIds.CHANGE_SELECTED_IDS),
    deleteStart: (ids: IDeleteMultipleBaseRequest): ITKBChiTietDeleteStart => BaseAction(ids, eTKBChiTietActionTypeIds.DELETE_START),
    deleteSuccess: (): ITKBChiTietDeleteSuccess => BaseAction(undefined, eTKBChiTietActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): ITKBChiTietDeleteErr => BaseAction(message, eTKBChiTietActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: pl_tkb_chitiet): ITKBChiTietSaveStart => BaseAction(data, eTKBChiTietActionTypeIds.SAVE_START),
    saveSuccess: (): ITKBChiTietSaveSuccess => BaseAction(undefined, eTKBChiTietActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): ITKBChiTietSaveErr => BaseAction(message, eTKBChiTietActionTypeIds.SAVE_ERR),

    loadFromExcelStart: (data: FormData): ITKBChiTietUploadExcelStartAction => BaseAction(data, eTKBChiTietActionTypeIds.UPLOAD_EXCEL_START),
    loadFromExcelSuccess: (respone: ITKBChiTietUploadResponse): ITKBChiTietUploadExcelSuccessAction => BaseAction(respone, eTKBChiTietActionTypeIds.UPLOAD_EXCEL_SUCCESS),
    loadFromExcelError: (message: string): ITKBChiTietUploadExcelErrorAction => BaseAction(message, eTKBChiTietActionTypeIds.UPLOAD_EXCEL_ERROR),
    loadFromExcelClear: (): ITKBChiTietUploadExcelClearAction => BaseAction(undefined, eTKBChiTietActionTypeIds.UPLOAD_EXCEL_CLEAR),

    saveFromExcelStart: (data: ITKBChiTietSaveExcelRequest): ITKBChiTietSaveExcelStartAction => BaseAction(data, eTKBChiTietActionTypeIds.SAVE_EXCEL_START),
    saveFromExcelSuccess: (respone: ITKBChiTietUploadResponse): ITKBChiTietSaveExcelSuccessAction => BaseAction(respone, eTKBChiTietActionTypeIds.SAVE_EXCEL_SUCCESS),
    saveFromExcelError: (message: string): ITKBChiTietSaveExcelErrorAction => BaseAction(message, eTKBChiTietActionTypeIds.SAVE_EXCEL_ERROR),

    duplicateStart: (data: IThoiKhoaBieuDuplicateRequest): ITKBChiTietDuplicateStart => BaseAction(data, eTKBChiTietActionTypeIds.DUPLICATE_START),
    duplicateSuccess: (respone: IThoiKhoaBieuPreviewItem[]): ITKBChiTietDuplicateSuccess => BaseAction(respone, eTKBChiTietActionTypeIds.DUPLICATE_SUCCESS),
    duplicateError: (message: string): ITKBChiTietDuplicateErr => BaseAction(message, eTKBChiTietActionTypeIds.DUPLICATE_ERR),
    duplicateClearErrorStart: (): ITKBChiTietDuplicateClearErrorStart => BaseAction(undefined, eTKBChiTietActionTypeIds.DUPLICATE_CLEAR_ERROR),

    deleteMultipleStart: (data: IThoiKhoaBieuDeleteMultipleRequest): ITKBChiTietDeleteMultipleStart => BaseAction(data, eTKBChiTietActionTypeIds.DELETE_MULTIPLE_START),
    deleteMultipleSuccess: (): ITKBChiTietDeleteMultipleSuccess => BaseAction(undefined, eTKBChiTietActionTypeIds.DELETE_MULTIPLE_SUCCESS),
    deleteMultipleError: (message: string): ITKBChiTietDeleteMultipleErr => BaseAction(message, eTKBChiTietActionTypeIds.DELETE_MULTIPLE_ERR),
}