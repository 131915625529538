import { DangKyMonHocKetQuaUpdateRequest } from "../../../models/request/dangky-monhoc/DangKyMonHocKetQuaUpdateRequest"
import { DangKyMonHocKetQuaHocSinhResponse, sis_pl_dotdangky_ketqua } from "../../../models/response/dangky-monhoc/sis_pl_dotdangky_ketqua"
import { sis_pl_dotdangky_ketqua_chitiet } from "../../../models/response/dangky-monhoc/sis_pl_dotdangky_ketqua_chitiet"

export enum eDotDangKyKetQuaActionTypeIds {
    LOAD_START = "DotDangKyKetQua_LOAD_START",
    LOAD_SUCCESS = "DotDangKyKetQua_LOAD_SUCCESS",
    LOAD_ERROR = "DotDangKyKetQua_LOAD_ERROR",

    LOAD_CHITIET_START = "DotDangKyKetQua_LOADCHITIET_START",
    LOAD_CHITIET_SUCCESS = "DotDangKyKetQua_LOADCHITIET_SUCCESS",
    LOAD_CHITIET_ERROR = "DotDangKyKetQua_LOADCHITIET_ERROR",

    LOAD_HOCSINH_START = "DotDangKyKetQua_LOAD_HOCSINH_START",
    LOAD_HOCSINH_SUCCESS = "DotDangKyKetQua_LOAD_HOCSINH_SUCCESS",
    LOAD_HOCSINH_ERROR = "DotDangKyKetQua_LOAD_HOCSINH_ERROR",

    DANGKY_HOCSINH_START = "DotDangKyKetQua_DANGKY_HOCSINH_START",
    DANGKY_HOCSINH_SUCCESS = "DotDangKyKetQua_DANGKY_HOCSINH_SUCCESS",
    DANGKY_HOCSINH_ERROR = "DotDangKyKetQua_DANGKY_HOCSINH_ERROR",
}

export interface IDotDangKyKetQuaLoadStart {
    type: eDotDangKyKetQuaActionTypeIds.LOAD_START,
    payload: number
}
export interface IDotDangKyKetQuaLoadSuccess {
    type: eDotDangKyKetQuaActionTypeIds.LOAD_SUCCESS,
    payload: sis_pl_dotdangky_ketqua[]
}
export interface IDotDangKyKetQuaLoadErr {
    type: eDotDangKyKetQuaActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IDotDangKyKetQuaLoadChiTietStart {
    type: eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_START,
    payload: number
}
export interface IDotDangKyKetQuaLoadChiTietSuccess {
    type: eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_SUCCESS,
    payload: sis_pl_dotdangky_ketqua_chitiet[]
}
export interface IDotDangKyKetQuaLoadChiTietErr {
    type: eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_ERROR,
    payload: string
}

export interface IDotDangKyKetQuaHocSinhLoadStart {
    type: eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_START,
    payload: number
}
export interface IDotDangKyKetQuaHocSinhLoadSuccess {
    type: eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_SUCCESS,
    payload: DangKyMonHocKetQuaHocSinhResponse[]
}
export interface IDotDangKyKetQuaHocSinhLoadErr {
    type: eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_ERROR,
    payload: string
}

export interface IDotDangKyKetQuaHocSinhDangKyStart {
    type: eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_START,
    payload: DangKyMonHocKetQuaUpdateRequest
}
export interface IDotDangKyKetQuaHocSinhDangKySuccess {
    type: eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_SUCCESS,
}
export interface IDotDangKyKetQuaHocSinhDangKyErr {
    type: eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_ERROR,
    payload: string
}

export type IDotDangKyKetQuaActionTypes = IDotDangKyKetQuaLoadStart | IDotDangKyKetQuaLoadSuccess | IDotDangKyKetQuaLoadErr |
    IDotDangKyKetQuaHocSinhLoadStart | IDotDangKyKetQuaHocSinhLoadSuccess | IDotDangKyKetQuaHocSinhLoadErr |
    IDotDangKyKetQuaLoadChiTietStart | IDotDangKyKetQuaLoadChiTietSuccess | IDotDangKyKetQuaLoadChiTietErr |
    IDotDangKyKetQuaHocSinhDangKyStart | IDotDangKyKetQuaHocSinhDangKySuccess | IDotDangKyKetQuaHocSinhDangKyErr