import { IBaiVietCommentSelectRequest } from "../../../models/request/app-phuhuynh/IBaiVietCommentSelectRequest"
import { IAppBaiVietComment } from "../../../models/response/app-phuhuynh/IAppBaiVietComment"
import { IBaiVietCommentChangeBaiViet, eBaiVietCommentActionTypeIds } from "../../action-types/bai-viet-comment/IBaiVietCommentActionts"
import { baseAction } from "../IActionBase"

export const baiVietCommentActions = {
    changeBaiViet: (baiVietId: number): IBaiVietCommentChangeBaiViet =>
        baseAction(eBaiVietCommentActionTypeIds.CHANGE_BAIVIET, baiVietId),
    loadStart: (request: IBaiVietCommentSelectRequest) =>
        baseAction(eBaiVietCommentActionTypeIds.LOAD_START, request),
    loadSuccess: (data: IAppBaiVietComment[]) =>
        baseAction(eBaiVietCommentActionTypeIds.LOAD_SUCCESS, data),
    loadError: (message: string) =>
        baseAction(eBaiVietCommentActionTypeIds.LOAD_ERROR, message),

    addCommentStart: (request: IAppBaiVietComment) =>
        baseAction(eBaiVietCommentActionTypeIds.ADD_COMMENT_START, request),

    addCommentSuccess: (request: IAppBaiVietComment) =>
        baseAction(eBaiVietCommentActionTypeIds.ADD_COMMENT_SUCCESS, request),

    addCommentError: (message: string) =>
        baseAction(eBaiVietCommentActionTypeIds.ADD_COMMENT_ERROR, message)
}