import { IConvertMoetRequest } from '../../../models/request/diem/IConvertMoetRequest';
import { IDiemHocSinhSelectDiemChuyenDeRequest } from '../../../models/request/diem/IDiemHocSinhSelectDiemChuyenDeRequest';
import IDiemMappingBaiTapRequest from '../../../models/request/diem/IDiemMappingBaiTapRequest';
import { IImportDiemHocSinhRequest } from '../../../models/request/diem/IImportDiemHocSinhRequest';
import { IUpdateDiemHocSinhRequest } from "../../../models/request/diem/IUpdateDiemHocSinhRequest";
import { IDiem } from '../../../models/response/diem/IDiem';
import { ISoDiemDetailRespone } from "../../../models/response/so-diem/ISoDiemDetailRespone";
import { baseAction } from "../IActionBase";
import { NotifyHelper } from './../../../helpers/toast';
import { IDiemHocSinhCalculateRequest } from './../../../models/request/diem/IDiemHocSinhCalculateRequest';
import { IDiemHocSinhSelectRequest } from './../../../models/request/diem/IDiemHocSinhSelectRequest';
import { IUpdateDiemHocSinhRespone } from './../../../models/response/diem/IUpdateDiemHocSinhRespone';
import { pl_lopbomon_hocsinh_viewmodel } from './../../../models/response/lop-bo-mon/pl_lopbomon_hocsinh';
import { sis_sodiem_thanhphandiem } from './../../../models/response/so-diem/sis_sodiem_thanhphandiem';
import { eNhapDiemActionTypeIds, INhapDiemLoadSoDiemInfoSuccess, INhapDiemLoadSoDiemMoetInfoSuccess } from './../../action-types/nhap-diem/INhapDiemActionType';
import { eNhapDiemViewMode, IINhapDiemPagaStateFilter } from './../../page-state-model/nhap-diem/INhapDiemPageState';

export const nhapDiemActions = {
    loadHocSinhStart: (lopBoMonId: number) => baseAction(eNhapDiemActionTypeIds.LOAD_HOCSINH_START, lopBoMonId),
    loadHocSinhSuccess: (data: pl_lopbomon_hocsinh_viewmodel[]) => baseAction(eNhapDiemActionTypeIds.LOAD_HOCSINH_SUCCESS, data),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.LOAD_HOCSINH_ERROR, message)
    },
    loadDiemStart: (request: IDiemHocSinhSelectRequest) => baseAction(eNhapDiemActionTypeIds.LOAD_DIEM_START, request),
    loadDiemSuccess: (data: IDiem[]) => baseAction(eNhapDiemActionTypeIds.LOAD_DIEM_SUCCESS, data),
    loadDiemError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.LOAD_DIEM_ERROR, message)
    },

    loadDiemChuyenDeStart: (request: IDiemHocSinhSelectDiemChuyenDeRequest) => baseAction(eNhapDiemActionTypeIds.LOAD_DIEM_CHUYEN_DE_START, request),
    loadDiemChuyenDeSuccess: (data: IDiem[]) => baseAction(eNhapDiemActionTypeIds.LOAD_DIEM_CHUYEN_DE_SUCCESS, data),
    loadDiemChuyenDeError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.LOAD_DIEM_CHUYEN_DE_ERROR, message)
    },

    loadDiemMoetStart: (request: IDiemHocSinhSelectRequest) => baseAction(eNhapDiemActionTypeIds.LOAD_DIEM_MOET_START, request),
    loadDiemMoetSuccess: (data: IDiem[]) => baseAction(eNhapDiemActionTypeIds.LOAD_DIEM_MOET_SUCCESS, data),
    loadDiemMoetError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.LOAD_DIEM_MOET_ERROR, message)
    },
    changeFilter: (filter: IINhapDiemPagaStateFilter) => baseAction(eNhapDiemActionTypeIds.CHANGE_FILTER, filter),
    showMappingDiemBaiTapModal: (isOpen: boolean) => baseAction(eNhapDiemActionTypeIds.SHOW_MAPPING_DIEMBAITAP_MODAL, isOpen),

    loadSoDiemInfoStart: (lopBoMonId: number) => baseAction(eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_START, lopBoMonId),
    loadSoDiemInfoSuccess: (data?: ISoDiemDetailRespone): INhapDiemLoadSoDiemInfoSuccess => baseAction(eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_SUCCESS, data),
    loadSoDiemInfoError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.LOAD_SODIEM_INFO_ERROR, message)
    },
    loadSoDiemMoetInfoStart: (lopBoMonId: number) => baseAction(eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_START, lopBoMonId),
    loadSoDiemMoetInfoSuccess: (data?: ISoDiemDetailRespone): INhapDiemLoadSoDiemMoetInfoSuccess => baseAction(eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_SUCCESS, data),
    loadSoDiemMoetInfoError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.LOAD_SODIEM_MOET_INFO_ERROR, message)
    },

    updateDiemStart: (request: IUpdateDiemHocSinhRequest) => baseAction(eNhapDiemActionTypeIds.UPDATE_DIEM_START, request),
    updateDiemSuccess: (data: IUpdateDiemHocSinhRespone) => baseAction(eNhapDiemActionTypeIds.UPDATE_DIEM_SUCCESS, data),
    updateDiemError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.UPDATE_DIEM_ERROR, message)
    },

    showCalculatorModal: (thanhPhan: sis_sodiem_thanhphandiem) => baseAction(eNhapDiemActionTypeIds.SHOW_CALCULATOR_MODAL, thanhPhan),
    closeCalculatorModal: () => baseAction(eNhapDiemActionTypeIds.CLOSE_CALCULATOR_MODAL, undefined),

    calculateDiemStart: (request: IDiemHocSinhCalculateRequest) => baseAction(eNhapDiemActionTypeIds.CALCULATE_START, request),
    calculateDiemSuccess: (diemChangeds: IDiem[]) => {
        NotifyHelper.Success("Success")
        return baseAction(eNhapDiemActionTypeIds.CALCULATE_SUCCESS, diemChangeds)
    },
    calculateDiemError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.CALCULATE_ERROR, message)
    },

    mappingStart: (request: IDiemMappingBaiTapRequest) => baseAction(eNhapDiemActionTypeIds.MAPPING_START, request),
    mappingSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eNhapDiemActionTypeIds.MAPPING_SUCCESS, undefined)
    },
    mappingError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.MAPPING_ERROR, message)
    },
    changeViewMode: (viewMode: eNhapDiemViewMode) => baseAction(eNhapDiemActionTypeIds.CHANGE_VIEW_MODE, viewMode),

    showConvertMoetConfirm: () => baseAction(eNhapDiemActionTypeIds.SHOW_CONVERT_MOET_CONFIRM, undefined),
    closeConvertMoetConfirm: () => baseAction(eNhapDiemActionTypeIds.CLOSE_CONVERT_MOET_CONFIRM, undefined),
    convertMoetStart: (request: IConvertMoetRequest) => baseAction(eNhapDiemActionTypeIds.CONVERT_MOET_START, request),
    convertMoetSuccess: () => baseAction(eNhapDiemActionTypeIds.CONVERT_MOET_SUCCESS, undefined),
    convertMoetError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.CONVERT_MOET_ERROR, message)
    },

    validateImportStart: (request: IImportDiemHocSinhRequest) => baseAction(eNhapDiemActionTypeIds.VALIDATE_IMPORT_START, request),
    validateImportSuccess: (data: any) => baseAction(eNhapDiemActionTypeIds.VALIDATE_IMPORT_SUCCESS, data),
    validateImportError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.VALIDATE_IMPORT_ERRROR, message)
    },
    importStart: (request: IImportDiemHocSinhRequest) => baseAction(eNhapDiemActionTypeIds.IMPORT_START, request),
    importSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eNhapDiemActionTypeIds.IMPORT_SUCCESS, undefined)
    },
    importError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.IMPORT_ERRROR, message)
    },

    validateImportLopStart: (request: IImportDiemHocSinhRequest) => baseAction(eNhapDiemActionTypeIds.VALIDATE_IMPORT_LOP_START, request),
    validateImportLopSuccess: (data: any) => baseAction(eNhapDiemActionTypeIds.VALIDATE_IMPORT_LOP_SUCCESS, data),
    validateImportLopError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.VALIDATE_IMPORT_LOP_ERRROR, message)
    },
    importLopStart: (request: IImportDiemHocSinhRequest) => baseAction(eNhapDiemActionTypeIds.IMPORT_LOP_START, request),
    importLopSuccess: () => {
        NotifyHelper.Success("Success")
        return baseAction(eNhapDiemActionTypeIds.IMPORT_LOP_SUCCESS, undefined)
    },
    importLopError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.IMPORT_LOP_ERRROR, message)
    },
    updateDiemThanhPhanAllStart: (request: IUpdateDiemHocSinhRequest) => baseAction(eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_START, request),
    updateDiemThanhPhanAllSuccess: () => baseAction(eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_SUCCESS, undefined),
    updateDiemThanhPhanAllError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eNhapDiemActionTypeIds.UPDATE_DIEM_ALL_THANHPHAN_ERROR, message)
    },
}