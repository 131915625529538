import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_form_khaosat } from "../../../models/response/form-khaosat/sis_form_khaosat";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eFormKhaoSatActionTypeIds {
    LOAD_START = "FORMKHAOSAT_LOAD_START",
    LOAD_SUCCESS = "FORMKHAOSAT_LOAD_SUCCESS",
    LOAD_ERROR = "FORMKHAOSAT_LOAD_ERROR",

    LOAD_BYTRUONG_START = "FORMKHAOSAT_LOAD_BYTRUONG_START",
    LOAD_BYTRUONG_SUCCESS = "FORMKHAOSAT_LOAD_BYTRUONG_SUCCESS",
    LOAD_BYTRUONG_ERROR = "FORMKHAOSAT_LOAD_BYTRUONG_ERROR",

    SHOW_MODAL = "FORMKHAOSAT_SHOW_MODAL",
    CHANGE_DETAIL = "FORMKHAOSAT_CHANGE_DETAIL",

    CHANGE_SELECTED_IDS = "FORMKHAOSAT_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "FORMKHAOSAT_SHOW_CONFIRM",

    DELETE_START = "FORMKHAOSAT_DELETE_START",
    DELETE_SUCCESS = "FORMKHAOSAT_DELETE_SUCCESS",
    DELETE_ERROR = "FORMKHAOSAT_DELETE_ERROR",

    SAVE_START = "FORMKHAOSAT_SAVE_START",
    SAVE_SUCCESS = "FORMKHAOSAT_SAVE_SUCCESS",
    SAVE_ERR = "FORMKHAOSAT_SAVE_ERR",

    CLEAR_STATUS = "FORMKHAOSAT_CLEAR_STATUS",
}
//ORIGIN MODEL
export interface IFormKhaoSatLoadStart extends IActionTypeBase<eFormKhaoSatActionTypeIds.LOAD_START, undefined> { }
export interface IFormKhaoSatLoadSuccess extends IActionTypeBase<eFormKhaoSatActionTypeIds.LOAD_SUCCESS, sis_form_khaosat[]> { }
export interface IFormKhaoSatLoadErr extends IActionTypeBase<eFormKhaoSatActionTypeIds.LOAD_ERROR, string> { }

export interface IFormKhaoSatLoadByTruongStart extends IActionTypeBase<eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_START, { dm_truong_id: number, nam_hoc: string }> { }
export interface IFormKhaoSatLoadByTruongSuccess extends IActionTypeBase<eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_SUCCESS, sis_form_khaosat[]> { }
export interface IFormKhaoSatLoadByTruongErr extends IActionTypeBase<eFormKhaoSatActionTypeIds.LOAD_BYTRUONG_ERROR, string> { }

//DETAIL
export interface IFormKhaoSatChangeDetail extends IActionTypeBase<eFormKhaoSatActionTypeIds.CHANGE_DETAIL, sis_form_khaosat | undefined> { }
export interface IFormKhaoSatChangeSelectedIds extends IActionTypeBase<eFormKhaoSatActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
//CONFIRM
export interface IFormKhaoSatShowModal extends IActionTypeBase<eFormKhaoSatActionTypeIds.SHOW_MODAL, boolean> { }
export interface IFormKhaoSatShowConfirm extends IActionTypeBase<eFormKhaoSatActionTypeIds.SHOW_CONFIRM, boolean> { }
//DELETE
export interface IFormKhaoSatDeleteStart extends IActionTypeBase<eFormKhaoSatActionTypeIds.DELETE_START, IDeleteMultipleBaseRequest> { }
export interface IFormKhaoSatDeleteSuccess extends IActionTypeBase<eFormKhaoSatActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface IFormKhaoSatDeleteErr extends IActionTypeBase<eFormKhaoSatActionTypeIds.DELETE_ERROR, string> { }
//SAVE
export interface IFormKhaoSatSaveStart extends IActionTypeBase<eFormKhaoSatActionTypeIds.SAVE_START, sis_form_khaosat> { }
export interface IFormKhaoSatSaveSuccess extends IActionTypeBase<eFormKhaoSatActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface IFormKhaoSatSaveErr extends IActionTypeBase<eFormKhaoSatActionTypeIds.SAVE_ERR, string> { }

export interface IFormKhaoSatClearStatus extends IActionTypeBase<eFormKhaoSatActionTypeIds.CLEAR_STATUS, undefined> { }

export type IFormKhaoSatActionTypes =
    IFormKhaoSatLoadStart | IFormKhaoSatLoadSuccess | IFormKhaoSatLoadErr |
    IFormKhaoSatChangeDetail |
    IFormKhaoSatChangeSelectedIds | IFormKhaoSatShowModal |
    IFormKhaoSatShowConfirm |
    IFormKhaoSatDeleteStart | IFormKhaoSatDeleteSuccess | IFormKhaoSatDeleteErr |
    IFormKhaoSatSaveStart | IFormKhaoSatSaveSuccess | IFormKhaoSatSaveErr |
    IFormKhaoSatLoadByTruongStart | IFormKhaoSatLoadByTruongSuccess | IFormKhaoSatLoadByTruongErr  |
    IFormKhaoSatClearStatus