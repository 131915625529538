import { pl_loaiphong } from "../models/response/phong-hoc/pl_loaiphong";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_LOAI_PHONG_PATH = "loai-phong"

class LoaiPhongApi extends BaseCRUDApi<pl_loaiphong> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
}
export const loaiPhongApi = new LoaiPhongApi({ path: API_LOAI_PHONG_PATH });