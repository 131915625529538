import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { app_nhombaiviet } from "../../../models/response/app-phuhuynh/app_nhombaiviet"

export enum eAppNhomBaiVietActionTypeIds {
    LOAD_START = "APPNHOMBAIVIET_LOAD_START",
    LOAD_SUCCESS = "APPNHOMBAIVIET_LOAD_SUCCESS",
    LOAD_ERROR = "APPNHOMBAIVIET_LOAD_ERROR",

    LOAD_BY_TRUONG_START = "APPNHOMBAIVIET_LOAD_BY_TRUONG_START",
    LOAD_BY_TRUONG_SUCCESS = "APPNHOMBAIVIET_LOAD_BY_TRUONG_SUCCESS",
    LOAD_BY_TRUONG_ERROR = "APPNHOMBAIVIET_LOAD_BY_TRUONG_ERROR",

    SHOW_DETAIL = "APPNHOMBAIVIET_SHOW_DETAIL",
    CLOSE_DETAIL = "APPNHOMBAIVIET_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "APPNHOMBAIVIET_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "APPNHOMBAIVIET_SHOW_CONFIRM",
    CLOSE_CONFIRM = "APPNHOMBAIVIET_CLOSE_CONFIRM",

    DELETE_START = "APPNHOMBAIVIET_DELETE_START",
    DELETE_SUCCESS = "APPNHOMBAIVIET_DELETE_SUCCESS",
    DELETE_ERROR = "APPNHOMBAIVIET_DELETE_ERROR",

    SAVE_START = "APPNHOMBAIVIET_SAVE_START",
    SAVE_SUCCESS = "APPNHOMBAIVIET_SAVE_SUCCESS",
    SAVE_ERR = "APPNHOMBAIVIET_SAVE_ERR"
}

export interface IAppNhomBaiVietLoadStart {
    type: eAppNhomBaiVietActionTypeIds.LOAD_START,
}
export interface IAppNhomBaiVietLoadSuccess {
    type: eAppNhomBaiVietActionTypeIds.LOAD_SUCCESS,
    payload: app_nhombaiviet[]
}
export interface IAppNhomBaiVietLoadErr {
    type: eAppNhomBaiVietActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IAppNhomBaiVietLoadByTruongStart {
    type: eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_START,
    payload: number
}
export interface IAppNhomBaiVietLoadByTruongSuccess {
    type: eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_SUCCESS,
    payload: app_nhombaiviet[]
}
export interface IAppNhomBaiVietLoadByTruongError {
    type: eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_ERROR,
    payload: string
}

export interface IAppNhomBaiVietShowDetail {
    type: eAppNhomBaiVietActionTypeIds.SHOW_DETAIL,
    payload?: app_nhombaiviet
}
export interface IAppNhomBaiVietCloseDetail {
    type: eAppNhomBaiVietActionTypeIds.CLOSE_DETAIL,
}
export interface IAppNhomBaiVietChangeSelectedIds {
    type: eAppNhomBaiVietActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IAppNhomBaiVietShowConfirm {
    type: eAppNhomBaiVietActionTypeIds.SHOW_CONFIRM
}
export interface IAppNhomBaiVietCloseConfirm {
    type: eAppNhomBaiVietActionTypeIds.CLOSE_CONFIRM
}

export interface IAppNhomBaiVietDeleteStart {
    type: eAppNhomBaiVietActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IAppNhomBaiVietDeleteSuccess {
    type: eAppNhomBaiVietActionTypeIds.DELETE_SUCCESS,
}
export interface IAppNhomBaiVietDeleteErr {
    type: eAppNhomBaiVietActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IAppNhomBaiVietSaveStart {
    type: eAppNhomBaiVietActionTypeIds.SAVE_START,
    payload: app_nhombaiviet
}
export interface IAppNhomBaiVietSaveSuccess {
    type: eAppNhomBaiVietActionTypeIds.SAVE_SUCCESS
}
export interface IAppNhomBaiVietSaveErr {
    type: eAppNhomBaiVietActionTypeIds.SAVE_ERR,
    payload: string
}
export type IAppNhomBaiVietActionTypes = IAppNhomBaiVietLoadStart | IAppNhomBaiVietLoadSuccess | IAppNhomBaiVietLoadErr |
    IAppNhomBaiVietLoadByTruongStart | IAppNhomBaiVietLoadByTruongSuccess | IAppNhomBaiVietLoadByTruongError |
    IAppNhomBaiVietShowDetail | IAppNhomBaiVietCloseDetail |
    IAppNhomBaiVietChangeSelectedIds |
    IAppNhomBaiVietShowConfirm | IAppNhomBaiVietCloseConfirm |
    IAppNhomBaiVietDeleteStart | IAppNhomBaiVietDeleteSuccess | IAppNhomBaiVietDeleteErr |
    IAppNhomBaiVietSaveStart | IAppNhomBaiVietSaveSuccess | IAppNhomBaiVietSaveErr
