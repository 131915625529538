import { NotifyHelper } from "../../../helpers/toast";
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eAppXinNghiActionTypeIds, IAppXinNghiActionTypes } from "../../action-types/app-phuhuynh/IAppXinNghiActionTypes";
import { IAppXinNghiPageState } from "../../page-state-model/app-phuhuynh/IAppXinNghiPageState";

const initialState: IAppXinNghiPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_xinnghis: [],
    app_xinnghi_detail: undefined
}

export const appXinNghiReducer = (state: IAppXinNghiPageState = initialState, action: IAppXinNghiActionTypes): IAppXinNghiPageState => {
    switch (action.type) {
        case eAppXinNghiActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppXinNghiActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_xinnghis: action.payload
            }
        case eAppXinNghiActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_xinnghis: []
            }
        case eAppXinNghiActionTypeIds.LOAD_SELECT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppXinNghiActionTypeIds.LOAD_SELECT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_xinnghis: action.payload
            }
        case eAppXinNghiActionTypeIds.LOAD_SELECT_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_xinnghis: []
            }
        case eAppXinNghiActionTypeIds.LOAD_DETAIL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppXinNghiActionTypeIds.LOAD_DETAIL_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_xinnghi_detail: action.payload
            }
        case eAppXinNghiActionTypeIds.LOAD_DETAIL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_xinnghi_detail: undefined
            }
        case eAppXinNghiActionTypeIds.UPDATE_STATUS_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eAppXinNghiActionTypeIds.UPDATE_STATUS_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eAppXinNghiActionTypeIds.UPDATE_STATUS_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eAppXinNghiActionTypeIds.UPDATE_PHANHOI_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eAppXinNghiActionTypeIds.UPDATE_PHANHOI_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload
            }
        case eAppXinNghiActionTypeIds.UPDATE_PHANHOI_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        default:
            return state;
    }

}