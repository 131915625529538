import { IBaiVietCommentSelectRequest } from "../../../models/request/app-phuhuynh/IBaiVietCommentSelectRequest";
import { IAppBaiVietComment } from "../../../models/response/app-phuhuynh/IAppBaiVietComment";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eBaiVietCommentActionTypeIds {
    CHANGE_BAIVIET = "BAIVIET_COMMENT_CHANGE_BAIVIET",
    LOAD_START = "BAIVIET_COMMENT_LOAD_START",
    LOAD_SUCCESS = "BAIVIET_COMMENT_LOAD_SUCCESS",
    LOAD_ERROR = "BAIVIET_COMMENT_ERROR",

    ADD_COMMENT_START = "BAIVIET_COMMENT_ADD_COMMENT_START",
    ADD_COMMENT_SUCCESS = "BAIVIET_COMMENT_ADD_COMMENT_SUCCESS",
    ADD_COMMENT_ERROR = "BAIVIET_COMMENT_ADD_COMMENT_ERROR",
}
export interface IBaiVietCommentChangeBaiViet extends IActionTypeBase<eBaiVietCommentActionTypeIds.CHANGE_BAIVIET, number> { }
export interface IBaiVietCommentLoadStart extends IActionTypeBase<eBaiVietCommentActionTypeIds.LOAD_START, IBaiVietCommentSelectRequest> { }
export interface IBaiVietCommentLoadSuccess extends IActionTypeBase<eBaiVietCommentActionTypeIds.LOAD_SUCCESS, IAppBaiVietComment[]> { }
export interface IBaiVietCommentLoadError extends IActionTypeBase<eBaiVietCommentActionTypeIds.LOAD_ERROR, string> { }

export interface IBaiVietCommentAddStart extends IActionTypeBase<eBaiVietCommentActionTypeIds.ADD_COMMENT_START, IAppBaiVietComment> { }
export interface IBaiVietCommentAddSuccess extends IActionTypeBase<eBaiVietCommentActionTypeIds.ADD_COMMENT_SUCCESS, IAppBaiVietComment> { }
export interface IBaiVietCommentAddError extends IActionTypeBase<eBaiVietCommentActionTypeIds.ADD_COMMENT_ERROR, string> { }

export type IBaiVietCommentActionTypes = IBaiVietCommentChangeBaiViet |
    IBaiVietCommentLoadStart | IBaiVietCommentLoadSuccess | IBaiVietCommentLoadError |
    IBaiVietCommentAddStart | IBaiVietCommentAddSuccess | IBaiVietCommentAddError