import { IDiemVanMinhKetQua } from './../../../models/response/diemvanminh/IDiemVanMinhKetQua';
import { ILopSelectHocSinhRequest } from "../../../models/request/dm-lop/ILopSelectHocSinhRequest";
import { IHocSinhItemRespone } from "../../../models/response/hoc-sinh/IHocSinhItemRespone";
import { eDiemVanMinhKetQuaActionTypeIds } from "../../action-types/diemvanminh-ketqua/IDiemVanMinhKetQuaActionTypes";
import { baseAction } from "../IActionBase";
import { NotifyHelper } from './../../../helpers/toast';
import { IDiemVanMinhSelectRequest } from '../../../models/request/diemvanminh/IDiemVanMinhSelectRequest';


export const diemVanMinhKetQuaActions = {
    loadHocSinhStart: (request: ILopSelectHocSinhRequest) => baseAction(eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_START, request),
    loadHocSinhSuccess: (data: IHocSinhItemRespone[]) => baseAction(eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_SUCCESS, data),
    loadHocSinhError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_ERROR, message)
    },
    changeFilter: (request: IDiemVanMinhSelectRequest) => baseAction(eDiemVanMinhKetQuaActionTypeIds.CHANGE_FILTER, request),

    showDetailModal: (data?: IDiemVanMinhKetQua) => baseAction(eDiemVanMinhKetQuaActionTypeIds.SHOW_DETAIL_MODAL, data),
    closeDetailModal: () => baseAction(eDiemVanMinhKetQuaActionTypeIds.CLOSE_DETAIL_MODAL, undefined),

    showDeleteConfirm: (data?: IDiemVanMinhKetQua) => baseAction(eDiemVanMinhKetQuaActionTypeIds.SHOW_DELETE_CONFIRM, data),
    closeDeleteConfirm: () => baseAction(eDiemVanMinhKetQuaActionTypeIds.CLOSE_DELETE_CONFIRM, undefined),

    saveStart: (data: IDiemVanMinhKetQua) => baseAction(eDiemVanMinhKetQuaActionTypeIds.SAVE_START, data),
    saveSuccess: (data: IDiemVanMinhKetQua) => {
        NotifyHelper.Success('Success')
        return baseAction(eDiemVanMinhKetQuaActionTypeIds.SAVE_SUCCESS, data)
    },
    saveError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDiemVanMinhKetQuaActionTypeIds.SAVE_ERROR, message)
    },
    loadKetQuaStart: (request: IDiemVanMinhSelectRequest) => baseAction(eDiemVanMinhKetQuaActionTypeIds.LOAD_KETQUA_START, request),
    loadKetQuaSuccess: (data: IDiemVanMinhKetQua[]) => baseAction(eDiemVanMinhKetQuaActionTypeIds.LOAD_KETQUA_SUCCESS, data),
    loadKetQuaError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_ERROR, message)
    },
    deleteStart: (id: number) => baseAction(eDiemVanMinhKetQuaActionTypeIds.DELETE_START, id),
    deleteSuccess: () => {
        NotifyHelper.Success('Success')
        return baseAction(eDiemVanMinhKetQuaActionTypeIds.DELETE_SUCCESS, undefined)
    },
    deleteError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eDiemVanMinhKetQuaActionTypeIds.DELETE_ERROR, message)
    },
}