import { ISoNhanXet } from '../../../models/response/dgdk-dm/ISoNhanXet';
import { ISoNhanXetSelectRequest } from '../../../models/request/so-tieu-hoc/ISoNhanXetSelectRequest';
import { IActionTypeBase } from '../IActionTypeBase';
export enum eSoNhanXetActionTypeIds {
    LOAD_START = "SONHANXET_LOAD_START",
    LOAD_SUCCESS = "SONHANXET_LOAD_SUCCESS",
    LOAD_ERROR = "SONHANXET_LOAD_ERROR",

    SHOW_DETAIL_MODAL = "SONHANXET_SHOW_DETAIL_MODAL",
    CLOSE_DETAIL_MODAL = "SONHANXET_CLOSE_DETAIL_MODAL",

    SHOW_DELETE_CONFIRM = "SONHANXET_SHOW_DELETE_CONFIRM",
    CLOSE_DELETE_CONFIRM = "SONHANXET_CLOSE_DELETE_CONFIRM",

    DELETE_START = "SONHANXET_DELETE_START",
    DELETE_SUCCESS = "SONHANXET_DELETE_SUCCES",
    DELETE_ERROR = "SONHANXET_DELETE_ERROR",

    SAVING_START = "SONHANXET_SAVING_START",
    SAVING_SUCCESS = "SONHANXET_SAVING_SUCCESS",
    SAVING_ERROR = "SONHANXET_SAVING_ERROR",

    CHANGE_SELECTED_DATA = "SONHANXET_CHANGE_SELECTED_DATA"
}

export interface ISoNhanXetLoadStart extends IActionTypeBase<eSoNhanXetActionTypeIds.LOAD_START, ISoNhanXetSelectRequest> { }
export interface ISoNhanXetLoadSuccess extends IActionTypeBase<eSoNhanXetActionTypeIds.LOAD_SUCCESS, ISoNhanXet[]> { }
export interface ISoNhanXetLoadError extends IActionTypeBase<eSoNhanXetActionTypeIds.LOAD_ERROR, string> { }

export interface ISoNhanXetShowDetailModel extends IActionTypeBase<eSoNhanXetActionTypeIds.SHOW_DETAIL_MODAL, ISoNhanXet | undefined> { }
export interface ISoNhanXetCloseDetailModel extends IActionTypeBase<eSoNhanXetActionTypeIds.CLOSE_DETAIL_MODAL, undefined> { }

export interface ISoNhanXetShowDeleteConfirm extends IActionTypeBase<eSoNhanXetActionTypeIds.SHOW_DELETE_CONFIRM, ISoNhanXet | undefined> { }
export interface ISoNhanXetCloseDeleteConfirm extends IActionTypeBase<eSoNhanXetActionTypeIds.CLOSE_DELETE_CONFIRM, undefined> { }

export interface ISoNhanXetDeleteStart extends IActionTypeBase<eSoNhanXetActionTypeIds.DELETE_START, number> { }
export interface ISoNhanXetDeleteSuccess extends IActionTypeBase<eSoNhanXetActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface ISoNhanXetDeleteError extends IActionTypeBase<eSoNhanXetActionTypeIds.DELETE_ERROR, string> { }

export interface ISoNhanXetSavingStart extends IActionTypeBase<eSoNhanXetActionTypeIds.SAVING_START, ISoNhanXet> { }
export interface ISoNhanXetSavingSuccess extends IActionTypeBase<eSoNhanXetActionTypeIds.SAVING_SUCCESS, undefined> { }
export interface ISoNhanXetSavingError extends IActionTypeBase<eSoNhanXetActionTypeIds.SAVING_ERROR, string> { }

export interface IDgdkDmNlpcChangeSelectedData extends IActionTypeBase<eSoNhanXetActionTypeIds.CHANGE_SELECTED_DATA, ISoNhanXet> { }

export type ISoNhanXetActionTypes = ISoNhanXetLoadStart | ISoNhanXetLoadSuccess | ISoNhanXetLoadError |
    ISoNhanXetShowDetailModel | ISoNhanXetCloseDetailModel |
    ISoNhanXetShowDeleteConfirm | ISoNhanXetCloseDeleteConfirm |
    ISoNhanXetDeleteStart | ISoNhanXetDeleteSuccess | ISoNhanXetDeleteError |
    ISoNhanXetSavingStart | ISoNhanXetSavingSuccess | ISoNhanXetSavingError |
    IDgdkDmNlpcChangeSelectedData