import { ITKBPreviewByLopRequest } from "../models/request/thoi-khoa-bieu/ITKBPreviewByLopRequest";
import { pl_tkb } from "../models/response/thoi-khoa-bieu/pl_tkb";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_TKB_PATH = "thoi-khoa-bieu"

class ThoiKhoaBieuApi extends BaseCRUDApi<pl_tkb> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    PreviewByLop(request: ITKBPreviewByLopRequest) {
        // let queryString = request ? Object.keys({ ...request }).map(key => key + '=' + { ...request }[key]).join('&') : undefined;
        return apiClient.post(`${this._path}/preview-by-lop`, request)
    }
}
export const thoiKhoaBieuApi = new ThoiKhoaBieuApi({ path: API_TKB_PATH });

