import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eNhanXetHangNgayActionTypeIds, INhanXetHangNgaySelectLopBoMonStart, INhanXetHangNgayLopBoMonStart, INhanXetHangNgaySelectLopChuNhiemStart, INhanXetHangNgayLopChuNhiemStart, INhanXetHangNgaySelectDetailStart, INhanXetHangNgaySelectCommentsStart, INhanXetHangNgaySaveCommentsStart, INhanXetHangNgayDeleteCommentsStart, INhanXetHangNgaySelectTDHTLopBoMonStart, INhanXetHangNgayTDHTLopBoMonStart, INhanXetHangNgaySelectHoctapStart, INhanXetHangNgaySaveHoctapStart, INhanXetHangNgayPublishMarkLopBoMonStart, INhanXetHangNgayPublishMarkLopChuNhiemStart } from "../../action-types/nhan-xet/INhanXetHangNgayActionTypes";
import { actions } from "../../actions/actionsWrapper";

const nhanXetHangNgayApi = apiWrapper.nhanXetHangNgay;

export function* nhanXetHangNgaySaga(): any {
    yield takeLatest(eNhanXetHangNgayActionTypeIds.SELECT_LOPBOMON_START, selectLopBoMonWorker)
    yield takeLatest(eNhanXetHangNgayActionTypeIds.SELECT_TDHT_LOPBOMON_START, selectTDHTLopBoMonWorker)
    yield takeEvery(eNhanXetHangNgayActionTypeIds.NHANXET_LOPBOMON_START, nhanXetLopBoMonWorker)
    yield takeEvery(eNhanXetHangNgayActionTypeIds.NHANXET_TDHT_LOPBOMON_START, nhanXetTDHTLopBoMonWorker)
    yield takeLatest(eNhanXetHangNgayActionTypeIds.SELECT_LOPCHUNHIEM_START, selectLopChuNhiemWorker)
    yield takeEvery(eNhanXetHangNgayActionTypeIds.NHANXET_LOPCHUNHIEM_START, nhanXetLopChuNhiemWorker)
    yield takeLatest(eNhanXetHangNgayActionTypeIds.SELECT_DETAIL_START, selectDetailWorker)
    yield takeLatest(eNhanXetHangNgayActionTypeIds.SELECT_COMMENTS_START, selectCommentsWorker)
    yield takeEvery(eNhanXetHangNgayActionTypeIds.SAVE_COMMENTS_START, saveCommentsWorker)
    yield takeEvery(eNhanXetHangNgayActionTypeIds.DELETE_COMMENTS_START, deleteCommentsWorker)
    yield takeEvery(eNhanXetHangNgayActionTypeIds.SELECT_HOCTAP_START, selectHoctapWorker)
    yield takeEvery(eNhanXetHangNgayActionTypeIds.SAVE_HOCTAP_START, saveHoctapWorker)
    yield takeLatest(eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPBOMON_START, publishMarkLopBoMonWorker)
    yield takeLatest(eNhanXetHangNgayActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_START, publishMarkLopChuNhiemWorker)
}

function* selectLopBoMonWorker(data: INhanXetHangNgaySelectLopBoMonStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.SelectByLopBoMonAsync], data.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.selectLopBoMonSuccess(res.data))
    } else {
        yield put(actions.nhanXetHangNgay.selectLopBoMonError(res.message || "Không tải được danh sách học sinh"))
    }
}
function* nhanXetLopBoMonWorker(action: INhanXetHangNgayLopBoMonStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.NhanXetLopBoMonAsync], action.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.nhanXetLopBoMonSuccess())
        yield put(actions.nhanXetHangNgay.showNhanXetHangNgayModal(false))
    } else {
        yield put(actions.nhanXetHangNgay.nhanXetLopBoMonError(res.message || "Không lưu được nhận xét"))
        yield put(actions.nhanXetHangNgay.showNhanXetHangNgayModal(false))
    }
}
function* selectLopChuNhiemWorker(data: INhanXetHangNgaySelectLopChuNhiemStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.SelectByLopChuNhiemAsync], data.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.selectLopChuNhiemSuccess(res.data))
    } else {
        yield put(actions.nhanXetHangNgay.selectLopChuNhiemError(res.message || "Không tải được danh sách học sinh"))
    }
}
function* nhanXetLopChuNhiemWorker(action: INhanXetHangNgayLopChuNhiemStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.NhanXetLopChuNhiemAsync], action.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.nhanXetLopChuNhiemSuccess())
        yield put(actions.nhanXetHangNgay.showNhanXetHangNgayModal(false))
    } else {
        yield put(actions.nhanXetHangNgay.nhanXetLopChuNhiemError(res.message || "Không lưu được nhận xét"))
        yield put(actions.nhanXetHangNgay.showNhanXetHangNgayModal(false))
    }
}

function* selectDetailWorker(action: INhanXetHangNgaySelectDetailStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.SelectDetailViewAsync], action.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.selectDetailSuccess(res.data))
        yield put(actions.nhanXetHangNgay.showNhanXetHangNgayModal(false))
    } else {
        yield put(actions.nhanXetHangNgay.selectDetailError(res.message || "Không tìm thấy nội dung nhận xét"))
        yield put(actions.nhanXetHangNgay.showNhanXetHangNgayModal(false))
    }
}
function* selectCommentsWorker(data: INhanXetHangNgaySelectCommentsStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.SelectCommentsAsync], data.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.selectCommentsSuccess(res.data))
    } else {
        yield put(actions.nhanXetHangNgay.selectCommentsError(res.message || "Không tải được danh sách bình luận"))
    }
}

function* saveCommentsWorker(action: INhanXetHangNgaySaveCommentsStart): any {
    let res: IBaseResponeModel;
    if (action.payload.comment_id > 0) {
        res = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.EditCommentsAsync], action.payload);
    } else {
        res = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.AddCommentsAsync], action.payload);
    }
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.saveCommentsSuccess())
        yield put(actions.nhanXetHangNgay.selectCommentsStart(action.payload.sis_nhanxet_hangngay_id))
    } else {
        yield put(actions.nhanXetHangNgay.saveCommentsError(res.message || "Không lưu được bình luận"))
    }
}

function* deleteCommentsWorker(action: INhanXetHangNgayDeleteCommentsStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.DeleteCommentsAsync], action.payload.comment_id)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.deleteCommentsSuccess())
        yield put(actions.nhanXetHangNgay.selectCommentsStart(action.payload.sis_nhanxet_hangngay_id))
    } else {
        yield put(actions.nhanXetHangNgay.deleteCommentsError(res.message || "Không xóa được bình luận"))
    }
}

function* selectTDHTLopBoMonWorker(data: INhanXetHangNgaySelectTDHTLopBoMonStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.SelectThaiDoHocTapByLopBoMonAsync], data.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.selectTDHTLopBoMonSuccess(res.data))
    } else {
        yield put(actions.nhanXetHangNgay.selectTDHTLopBoMonError(res.message || "Không tải được danh sách học sinh"))
    }
}
function* nhanXetTDHTLopBoMonWorker(action: INhanXetHangNgayTDHTLopBoMonStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.NhanXetThaiDoHocTapLopBoMonAsync], action.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.nhanXetTDHTLopBoMonSuccess())
    } else {
        yield put(actions.nhanXetHangNgay.nhanXetTDHTLopBoMonError(res.message || "Không lưu được nhận xét"))
    }
}

function* selectHoctapWorker(data: INhanXetHangNgaySelectHoctapStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.SelectHocTapAsync], data.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.selectHoctapSuccess(res.data))
    } else {
        yield put(actions.nhanXetHangNgay.selectHoctapError(res.message || "Không tải được danh sách bình luận"))
    }
}

function* saveHoctapWorker(action: INhanXetHangNgaySaveHoctapStart): any {
    let res: IBaseResponeModel;
    const dataSave = action.payload;
    if (dataSave.id > 0) {
        res = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.EditHocTapAsync], dataSave);
    } else {
        res = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.AddHocTapAsync], dataSave);
    }
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.saveHoctapSuccess())
        yield put(actions.nhanXetHangNgay.selectHoctapStart({ dm_lop_id: dataSave.dm_lop_id, ngay_nhan_xet: dataSave.ngay_nhan_xet, pl_lopbomon_id: dataSave.pl_lopbomon_id, pl_tkb_chitiet_id: dataSave.pl_tkb_chitiet_id }))
    } else {
        yield put(actions.nhanXetHangNgay.saveHoctapError(res.message || "Không lưu được bình luận"))
    }
}
function* publishMarkLopBoMonWorker(data: INhanXetHangNgayPublishMarkLopBoMonStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.NhanXetLopBoMonPublishMarkAsync], data.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.publishMarkLopBoMonSuccess())
    } else {
        yield put(actions.nhanXetHangNgay.publishMarkLopBoMonError(res.message || "Không tải được danh sách học sinh"))
    }
}
function* publishMarkLopChuNhiemWorker(data: INhanXetHangNgayPublishMarkLopChuNhiemStart): any {
    const res: IBaseResponeModel = yield call([nhanXetHangNgayApi, nhanXetHangNgayApi.NhanXetLopChuNhiemPublishMarkAsync], data.payload)
    if (res.is_success) {
        yield put(actions.nhanXetHangNgay.publishMarkLopChuNhiemSuccess())
    } else {
        yield put(actions.nhanXetHangNgay.publishMarkLopChuNhiemError(res.message || "Không tải được danh sách học sinh"))
    }
}