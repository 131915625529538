import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { soDiemThanhPhanApi } from './../../../api/soDiemThanhPhanApi';
import { eSoDiemConfigActionTypeIds, ISoDiemConfigLoadThanhPhanStart, ISoDiemConfigAddThahPhanStart, ISoDiemConfigRemoveThanhPhanStart, ISoDiemConfigUpdateThanhPhanStart } from './../../action-types/so-diem/ISoDiemConfigActionTypes';
import { actions } from './../../actions/actionsWrapper';



export function* soDiemConfigSaga(): any {
    yield takeLatest(eSoDiemConfigActionTypeIds.LOAD_SODIEM_THANHPHAN_START, loadWorker)
    yield takeEvery(eSoDiemConfigActionTypeIds.ADD_THANHPHAN_START, addThanhPhanWorker)
    yield takeEvery(eSoDiemConfigActionTypeIds.REMOVE_THANHPHAN_START, removeThanhPhanWorker)
    yield takeEvery(eSoDiemConfigActionTypeIds.UPDATE_THANHPHAN_START, updateThanhPhanWorker)
}

function* loadWorker(action: ISoDiemConfigLoadThanhPhanStart): any {
    const res: IBaseResponeModel = yield call([soDiemThanhPhanApi, soDiemThanhPhanApi.SelectBySoDiem], action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemConfig.loadThanhPhanSuccess(res.data))
    } else {
        yield put(actions.soDiem.soDiemConfig.loadThanhPhanError(res.message || "Không tải được sổ điểm"))
    }
}

function* addThanhPhanWorker(action: ISoDiemConfigAddThahPhanStart): any {
    const res: IBaseResponeModel = yield call([soDiemThanhPhanApi, soDiemThanhPhanApi.AddThanhPhan], action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemConfig.addThanhPhanSuccess())
    } else {
        yield put(actions.soDiem.soDiemConfig.addThanhPhanError(res.message || "Không thêm được đầu điểm"))
    }
}

function* removeThanhPhanWorker(action: ISoDiemConfigRemoveThanhPhanStart): any {
    const res: IBaseResponeModel = yield call([soDiemThanhPhanApi, soDiemThanhPhanApi.ReomveThanhPhan], action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemConfig.removeThanhPhanSuccess())
    } else {
        yield put(actions.soDiem.soDiemConfig.removeThanhPhanError(res.message || "Không xóa được đầu điểm"))
    }
}

function* updateThanhPhanWorker(action: ISoDiemConfigUpdateThanhPhanStart): any {
    const res: IBaseResponeModel = yield call([soDiemThanhPhanApi, soDiemThanhPhanApi.UpdateThanhPhan], action.payload)
    if (res.is_success) {
        yield put(actions.soDiem.soDiemConfig.updateThanhPhanSuccess())
    } else {
        yield put(actions.soDiem.soDiemConfig.updateThanhPhanError(res.message || "Không cập nhật được đầu điểm"))
    }
}