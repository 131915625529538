import { sis_diemdanh_status } from "../models/response/diem-danh/sis_diemdanh_status";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_DIEMDANH_STATUS_PATH = "diem-danh-status"

class DiemDanhStatusApi extends BaseCRUDApi<sis_diemdanh_status> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
}
export const diemDanhStatusApi = new DiemDanhStatusApi({ path: API_DIEMDANH_STATUS_PATH });