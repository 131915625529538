import { request } from "http";
import { INhanXetHangNgayLopSelectRequest } from "../models/request/nhan-xet/INhanXetHangNgayLopSelectRequest";
import { INhanXetLopBoMonPublishMarkRequest } from "../models/request/nhan-xet/INhanXetLopBoMonPublishMarkRequest";
import { INhanXetLopChuNhiemPublishMarkRequest } from "../models/request/nhan-xet/INhanXetLopChuNhiemPublishMarkRequest";
import { INhanXetThaiDoHocTapSelectRequest } from "../models/request/nhan-xet/INhanXetThaiDoHocTapSelectRequest";
import { INhanXetLopBoMonRequest, INhanXetLopChuNhiemRequest } from "../models/request/nhan-xet/NhanXetChangeDataRequest";
import { INhanXetHangNgayCommentRequest } from "../models/request/nhan-xet/NhanXetHangNgayCommentRequest";
import { INhanXetLopBoMonSelectRequest, INhanXetLopChuNhiemSelectRequest } from "../models/request/nhan-xet/NhanXetSelectRequest";
import { sis_nhanxet_hangngay_lop } from "../models/response/nhan-xet/sis_nhanxet_hangngay_lop";
import { sis_thaidohoctap_ketqua } from "../models/response/nhan-xet/sis_thaidohoctap_ketqua";
import { apiClient } from "./apiClient";

export const API_NHANXET_HANGNGAY_PATH = "nhan-xet-hang-ngay"
export const nhanXetHangNgayApi = {
    SelectByLopBoMonAsync: (request: INhanXetLopBoMonSelectRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/select-by-lopbomon`, request)
    },
    SelectThaiDoHocTapByLopBoMonAsync: (request: INhanXetThaiDoHocTapSelectRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/select-thaidohoctap-by-lopbomon`, request)
    },
    SelectDetailViewAsync: (id: number) =>  {
        return apiClient.get(`${API_NHANXET_HANGNGAY_PATH}/detail/${id}`)
    },
    SelectByLopChuNhiemAsync: (request: INhanXetLopChuNhiemSelectRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/select-by-lopchunhiem`, request)
    },
    NhanXetLopBoMonAsync: (request: INhanXetLopBoMonRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/nhan-xet-lopbomon`, request)
    },
    NhanXetThaiDoHocTapLopBoMonAsync: (request: sis_thaidohoctap_ketqua) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/nhan-xet-thaidohoctap-lopbomon`, request)
    },
    NhanXetLopChuNhiemAsync: (request: INhanXetLopChuNhiemRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/nhan-xet-lopchunhiem`, request)
    },
    SelectCommentsAsync: (id: number) =>  {
        return apiClient.get(`${API_NHANXET_HANGNGAY_PATH}/comments/${id}`)
    },
    AddCommentsAsync: (request: INhanXetHangNgayCommentRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/comments/add`, request)
    },
    EditCommentsAsync: (request: INhanXetHangNgayCommentRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/comments/edit`, request)
    },
    DeleteCommentsAsync: (id: number) =>  {
        return apiClient.delete(`${API_NHANXET_HANGNGAY_PATH}/comments/delete/${id}`)
    },
    SelectHocTapAsync: (request: INhanXetHangNgayLopSelectRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/hoc-tap/select-by-lop`, request)
    },
    AddHocTapAsync: (request: sis_nhanxet_hangngay_lop) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/hoc-tap/add`, request)
    },
    EditHocTapAsync: (request: sis_nhanxet_hangngay_lop) =>  {
        return apiClient.put(`${API_NHANXET_HANGNGAY_PATH}/hoc-tap/edit`, request)
    },
    NhanXetLopBoMonPublishMarkAsync: (request: INhanXetLopBoMonPublishMarkRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/nhan-xet-lopbomon-publish-mark`, request)
    },
    NhanXetLopChuNhiemPublishMarkAsync: (request: INhanXetLopChuNhiemPublishMarkRequest) =>  {
        return apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/nhan-xet-lopchunhiem-publish-mark`, request)
    },
    SelectDiemTrungBinhAsync: (request:any)=> apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/diem-trung-binh`, request),
    SelectHistoryAsync: (request:any)=> apiClient.post(`${API_NHANXET_HANGNGAY_PATH}/history`, request)
};