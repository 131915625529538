import { call, put, takeLatest } from "redux-saga/effects"
import { apiWrapper } from "../../../api/apiWrapper"
import { IBaseResponeModel } from "../../../models/response/base-response"
import { eThuActionTypeIds } from "../../action-types/danh-muc/IThuActionTypes"
import { actions } from "../../actions/actionsWrapper"

function* loadThuWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.thu.SelectAll)
    if (res.is_success) {
        yield put(actions.danhMucWrapper.thu.loadSuccess(res.data))
    }
}

export function* thuSaga(): any {
    yield takeLatest(eThuActionTypeIds.LOAD_START, loadThuWorker)
}