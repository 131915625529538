import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { IBaseResponeModel } from "../../models/response/base-response";
import { ITrangThaiHocSinhLoadStart, eCategorySourceActionTypeIds } from "../action-types/ICategorySourceActionTypes";
import { actions } from './../actions/actionsWrapper';

export function* categorySourceSaga(): any {
    yield takeLatest(eCategorySourceActionTypeIds.DM_TRUONG_LOAD_START, loadTruongWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_TRANGTHAIHOCSINH_LOAD_START, loadTrangThaiHocSinhWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_NAMHOC_LOAD_START, loadNamHocWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_KHOI_LOAD_START, loadKhoiWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_HE_LOAD_START, loadHeWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_START, loadTruongKhoiHeWorker)

}
function* loadTruongWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.SelectTruongs)
    if (res.is_success) {
        yield put(actions.categorySource.loadDmTruongSuccess(res.data))
    }
}
function* loadNamHocWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.SelectNamHocs)
    if (res.is_success) {
        yield put(actions.categorySource.loadNamHocSuccess(res.data))
    }
}
function* loadKhoiWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.SelectKhois)
    if (res.is_success) {
        yield put(actions.categorySource.loadKhoiSucess(res.data))
    }
}
function* loadTrangThaiHocSinhWorker(action: ITrangThaiHocSinhLoadStart): any {
    
    const res: IBaseResponeModel = yield call([apiWrapper.categorySource, apiWrapper.categorySource.SelectTrangThaiHocSinhs], action.payload)
    // const res: IBaseResponeModel = yield call(apiWrapper.categorySource.SelectTrangThaiHocSinhs, action.payload)
    if (res.is_success) {
        yield put(actions.categorySource.loadTrangThaiHocSinhSucess(res.data))
    }
}
function* loadHeWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.SelectHes)
    if (res.is_success) {
        yield put(actions.categorySource.loadHeSuccess(res.data))
    }
}

function* loadTruongKhoiHeWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.SelectTruongKhoiHes)
    if (res.is_success) {
        yield put(actions.categorySource.loadDmTruongKhoiHeSuccess(res.data))
    }
}
