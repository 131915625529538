import { IKhenThuongKyLuatSelectRequest } from '../../../models/request/khen-thuong-ky-luat/IKhenThuongKyLuatRequest';
import { IKhenThuongKyLuat } from '../../../models/response/khen-thuong-ky-luat/IKhenThuongKyLuat';
import { IKhenThuongKyLuatViewModel } from '../../../models/response/khen-thuong-ky-luat/IKhenThuongKyLuatViewModel';
import { IActionTypeBase } from './../IActionTypeBase';
export enum eKhenThuongKyLuatActionTypeIds {
    LOAD_START = "KHEN_THUONG_KY_LUAT_LOAD_START",
    LOAD_SUCCESS = "KHEN_THUONG_KY_LUAT_LOAD_SUCCESS",
    LOAD_ERROR = "KHEN_THUONG_KY_LUAT_LOAD_ERROR",

    SAVE_START = "KHEN_THUONG_KY_LUAT_SAVE_START",
    SAVE_SUCCESS = "KHEN_THUONG_KY_LUAT_SAVE_SUCCESS",
    SAVE_ERROR = "KHEN_THUONG_KY_LUAT_SAVE_ERROR",


    DELETE_START = "KHEN_THUONG_KY_LUAT_DELETE_START",
    DELETE_SUCCESS = "KHEN_THUONG_KY_LUAT_DELETESTART",
    DELETE_ERROR = "KHEN_THUONG_KY_LUAT_DELETESTART",

    SHOW_DETAIL_MODAL="KHEN_THUONG_KY_LUAT_SHOW_DETAIL_MODAL",
    CLOSE_DETAIL_MODAL="KHEN_THUONG_KY_LUAT_CLOSE_DETAIL_MODAL",

    SHOW_DELETE_CONFIRM="KHEN_THUONG_KY_LUAT_SHOW_DELETE_CONFIRM",
    CLOSE_DELETE_CONFIRM="KHEN_THUONG_KY_LUAT_CLOSE_DELETE_CONFIRM",
}
export interface IKhenThuongKyLuatLoadStart extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.LOAD_START, IKhenThuongKyLuatSelectRequest> { }
export interface IKhenThuongKyLuatLoadSuccess extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.LOAD_SUCCESS, IKhenThuongKyLuatViewModel[]> { }
export interface IKhenThuongKyLuatLoadError extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.LOAD_ERROR, string> { }

export interface IKhenThuongKyLuatSaveStart extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.SAVE_START, IKhenThuongKyLuat> { }
export interface IKhenThuongKyLuatSaveSuccess extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface IKhenThuongKyLuatSaveError extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.SAVE_ERROR, string> { }


export interface IKhenThuongKyLuatDeleteStart extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.DELETE_START, number> { }
export interface IKhenThuongKyLuatDeleteSuccess extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface IKhenThuongKyLuatDeleteError extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.DELETE_ERROR, string> { }

export interface IKhenThuongKyLuatShowDetailModal extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.SHOW_DETAIL_MODAL, IKhenThuongKyLuatViewModel | undefined>{}
export interface IKhenThuongKyLuatCloseDetailModal extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.CLOSE_DETAIL_MODAL, undefined>{}

export interface IKhenThuongKyLuatShowDeleteConfirm extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.SHOW_DELETE_CONFIRM, IKhenThuongKyLuatViewModel>{}
export interface IKhenThuongKyLuatCloseDeleteConfirm extends IActionTypeBase<eKhenThuongKyLuatActionTypeIds.CLOSE_DELETE_CONFIRM, undefined>{}

export type IKhenThuongKyLuatActionTypes = IKhenThuongKyLuatLoadStart | IKhenThuongKyLuatLoadSuccess | IKhenThuongKyLuatLoadError |
    IKhenThuongKyLuatSaveStart | IKhenThuongKyLuatSaveSuccess | IKhenThuongKyLuatSaveError |
    IKhenThuongKyLuatDeleteStart | IKhenThuongKyLuatDeleteSuccess | IKhenThuongKyLuatDeleteError|
    IKhenThuongKyLuatShowDetailModal|IKhenThuongKyLuatCloseDetailModal|
    IKhenThuongKyLuatShowDeleteConfirm|IKhenThuongKyLuatCloseDeleteConfirm
