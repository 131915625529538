import { pl_tiet } from "../models/response/danh-muc/pl_tiet";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_TIET_PATH = "tiet"

class TietApi extends BaseCRUDApi<pl_tiet> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
}
export const tietApi = new TietApi({ path: API_TIET_PATH });

