import { IDiemVanMinhKetQua } from './../../../models/response/diemvanminh/IDiemVanMinhKetQua';
import { ILopSelectHocSinhRequest } from '../../../models/request/dm-lop/ILopSelectHocSinhRequest';
import { IHocSinhItemRespone } from '../../../models/response/hoc-sinh/IHocSinhItemRespone';
import { IActionTypeBase } from './../IActionTypeBase';
import { IDiemVanMinhSelectRequest } from '../../../models/request/diemvanminh/IDiemVanMinhSelectRequest';
export enum eDiemVanMinhKetQuaActionTypeIds {
    LOAD_HOCSINH_START = "DIEM_VAN_MINH_LOAD_HOCSINH_START",
    LOAD_HOCSINH_SUCCESS = "DIEM_VAN_MINH_LOAD_HOCSINH_SUCCESS",
    LOAD_HOCSINH_ERROR = "DIEM_VAN_MINH_LOAD_HOCSINH_ERROR",

    CHANGE_FILTER = "DIEM_VAN_MINH_CHANGE_FILTER",

    SHOW_DETAIL_MODAL = "DIEM_VAN_MINH_SHOW_DETAIL_MODAL",
    CLOSE_DETAIL_MODAL = "DIEM_VAN_MINH_CLOSE_DETAIL_MODAL",

    SAVE_START = "DIEM_VAN_MINH_SAVE_START",
    SAVE_SUCCESS = "DIEM_VAN_MINH_SAVE_SUCCESS",
    SAVE_ERROR = "DIEM_VAN_MINH_SAVE_ERROR",

    LOAD_KETQUA_START = "DIEM_VAN_MINH_LOAD_KETQUA_START",
    LOAD_KETQUA_SUCCESS = "DIEM_VAN_MINH_LOAD_KETQUA_SUCCESS",
    LOAD_KETQUA_ERROR = "DIEM_VAN_MINH_LOAD_KETQUA_ERROR",

    SHOW_DELETE_CONFIRM = "DIEM_VAN_MINH_SHOW_DELETE_CONFIRM",
    CLOSE_DELETE_CONFIRM = "DIEM_VAN_MINH_CLOSE_DELETE_CONFIRM",


    DELETE_START = "DIEM_VAN_MINH_DELETE_START",
    DELETE_SUCCESS = "DIEM_VAN_MINH_DELETE_SUCCESS",
    DELETE_ERROR = "DIEM_VAN_MINH_DELETE_ERROR",
}

export interface IDiemVanMinhLoadHocSinhStart extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_START, ILopSelectHocSinhRequest> { }
export interface IDiemVanMinhLoadHocSinhSuccess extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_SUCCESS, IHocSinhItemRespone[]> { }
export interface IDiemVanMinhLoadHocSinhError extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_ERROR, string> { }

export interface IDiemVanMinhChangeFilter extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.CHANGE_FILTER, IDiemVanMinhSelectRequest> { }

export interface IDiemVanMinhShowDetaiModal extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.SHOW_DETAIL_MODAL, IDiemVanMinhKetQua | undefined> { }
export interface IDiemVanMinhCloseDetaiModal extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.CLOSE_DETAIL_MODAL, undefined> { }

export interface IDiemVanMinhShowDeleteConfirm extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.SHOW_DELETE_CONFIRM, IDiemVanMinhKetQua | undefined> { }
export interface IDiemVanMinhCloseDeleteConfirm extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.CLOSE_DELETE_CONFIRM, undefined> { }

export interface IDiemVanMinhSaveStart extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.SAVE_START, IDiemVanMinhKetQua> { }
export interface IDiemVanMinhSaveSucess extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.SAVE_SUCCESS, IDiemVanMinhKetQua> { }
export interface IDiemVanMinhSaveError extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.SAVE_ERROR, string> { }


export interface IDiemVanMinhLoadKetQuaStart extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.LOAD_KETQUA_START, IDiemVanMinhSelectRequest> { }
export interface IDiemVanMinhLoadKetQuaSuccess extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.LOAD_KETQUA_SUCCESS, IDiemVanMinhKetQua[]> { }
export interface IDiemVanMinhLoadKetQuaError extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.LOAD_KETQUA_ERROR, string> { }

export interface IDiemVanMinhDeleteStart extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.DELETE_START, number> { }
export interface IDiemVanMinhDeleteSucess extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface IDiemVanMinhDeleteError extends IActionTypeBase<eDiemVanMinhKetQuaActionTypeIds.DELETE_ERROR, string> { }


export type IDiemVanMinhKetQuaActionTypes = IDiemVanMinhLoadHocSinhStart | IDiemVanMinhLoadHocSinhSuccess | IDiemVanMinhLoadHocSinhError |
    IDiemVanMinhShowDetaiModal | IDiemVanMinhCloseDetaiModal |
    IDiemVanMinhSaveStart | IDiemVanMinhSaveSucess | IDiemVanMinhSaveError |
    IDiemVanMinhLoadKetQuaStart | IDiemVanMinhLoadKetQuaSuccess | IDiemVanMinhLoadKetQuaError |
    IDiemVanMinhChangeFilter |
    IDiemVanMinhShowDeleteConfirm | IDiemVanMinhCloseDeleteConfirm |
    IDiemVanMinhDeleteStart | IDiemVanMinhDeleteSucess | IDiemVanMinhDeleteError