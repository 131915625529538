import { IHocSinhSelectionChangeFilter, eHocSinhSelectionActionTypeIds, IHocSinhSelectionLoadStart, IHocSinhSelectionLoadError, IHocSinhSelectionLoadSuccess } from './../../action-types/hoc-sinh/IHocSinhSelectionActionTypes';
import { IHocSinhSelectRequest } from "../../../models/request/hoc-sinh/IHocSinhSelectRequest";
import { BaseAction } from '../IBaseActionResult';
import { IHocSinhSelectResponeItem } from '../../../models/response/hoc-sinh/IHocSinhSelectRespone';

export const hocSinhSelectionActions = {
    changeFilter: (filter: IHocSinhSelectRequest): IHocSinhSelectionChangeFilter =>
        BaseAction(filter, eHocSinhSelectionActionTypeIds.CHANGE_FILTER),
    loadStart: (request: IHocSinhSelectRequest): IHocSinhSelectionLoadStart =>
        BaseAction(request, eHocSinhSelectionActionTypeIds.LOAD_START),
    loadSuccess: (respone: IHocSinhSelectResponeItem[]): IHocSinhSelectionLoadSuccess =>
        BaseAction(respone, eHocSinhSelectionActionTypeIds.LOAD_SUCCESS),
    loadErr: (message: string): IHocSinhSelectionLoadError =>
        BaseAction(message, eHocSinhSelectionActionTypeIds.LOAD_ERROR),
    changeSelectedIds: (ids: number) => BaseAction(ids, eHocSinhSelectionActionTypeIds.CHANGE_SELECTED_IDS)
}