import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eSoNhanXetItemActionTypeIds, ISoNhanXetItemDeleteStart, ISoNhanXetItemLoadStart, ISoNhanXetItemSaveStart } from "../../action-types/so-diem-tieu-hoc/ISoNhanXetItemActionTypes";
import { actions } from '../../actions/actionsWrapper';

const soNhanXetItemApi = apiWrapper.soNhanXetItem;

export function* soNhanXetItemSaga(): any {
    yield takeLatest(eSoNhanXetItemActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eSoNhanXetItemActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eSoNhanXetItemActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(action: ISoNhanXetItemLoadStart): any {

    const res: IBaseResponeModel = yield call([soNhanXetItemApi, soNhanXetItemApi.SelectBySet], action.payload)
    if (res.is_success) {
        yield put(actions.soNhanXetItem.loadSoNhanXetItemSuccess(res.data))
    } else {
        yield put(actions.soNhanXetItem.loadSoNhanXetItemError(res.message || "Không tải được đánh giá phẩm chất"))
    }
}
function* deleteWorker(action: ISoNhanXetItemDeleteStart): any {
    const res: IBaseResponeModel = yield call([soNhanXetItemApi, soNhanXetItemApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.soNhanXetItem.deleteSoNhanXetItemSuccess())
    } else {
        yield put(actions.soNhanXetItem.deleteSoNhanXetItemErr(res.message || "Không tải được đánh giá phẩm chất"))
    }
}
function* saveWorker(action: ISoNhanXetItemSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([soNhanXetItemApi, soNhanXetItemApi.Update], action.payload);
    } else {
        res = yield call([soNhanXetItemApi, soNhanXetItemApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.soNhanXetItem.saveSoNhanXetItemSuccess())
    } else {
        yield put(actions.soNhanXetItem.saveSoNhanXetItemError(res.message || "Không lưu được đánh giá phẩm chất"))
    }
}
