
import { IDiemDanhAnConfigSelectRequest } from "../../../models/request/diem-danh-an/IDiemDanhAnConfigSelectRequest";
import { IDiemDanhAnLopChuNhiemChangeRequest } from "../../../models/request/diem-danh-an/IDiemDanhAnLopChuNhiemChangeRequest";
import { IDiemDanhAnLopChuNhiemSelectRequest } from "../../../models/request/diem-danh-an/IDiemDanhAnLopChuNhiemSelectRequest";
import { DiemDanhAnResponse } from "../../../models/response/diem-danh-an/DiemDanhAnResponse";
import { ILopDiemDanhAnResponse } from "../../../models/response/diem-danh-an/LopDiemDanhAnResponse";
import { eDiemDanhAnActionTypeIds, IDiemDanhAnChangeDanhSachDiemDanh, IDiemDanhAnChangeSelectedIds, 
    IDiemDanhAnLopChuNhiemErr, IDiemDanhAnLopChuNhiemStart, IDiemDanhAnLopChuNhiemSuccess, 
    IDiemDanhAnSelectConfigErr, IDiemDanhAnSelectConfigStart, IDiemDanhAnSelectConfigSuccess, 
    IDiemDanhAnSelectLopChuNhiemErr, IDiemDanhAnSelectLopChuNhiemStart, IDiemDanhAnSelectLopChuNhiemSuccess, IDiemDanhAnShowModal 
} from "../../action-types/diem-danh-an/IDiemDanhAnActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const diemDanhAnActions = {
    selectConfigStart: (request: IDiemDanhAnConfigSelectRequest): IDiemDanhAnSelectConfigStart => BaseAction(request, eDiemDanhAnActionTypeIds.SELECT_CONFIG_START),
    selectConfigSuccess: (respone: ILopDiemDanhAnResponse[]): IDiemDanhAnSelectConfigSuccess => BaseAction(respone, eDiemDanhAnActionTypeIds.SELECT_CONFIG_SUCCESS),
    selectConfigError: (messege: string): IDiemDanhAnSelectConfigErr => BaseAction(messege, eDiemDanhAnActionTypeIds.SELECT_CONFIG_ERROR),
 
    selectLopChuNhiemStart: (request: IDiemDanhAnLopChuNhiemSelectRequest): IDiemDanhAnSelectLopChuNhiemStart => BaseAction(request, eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_START),
    selectLopChuNhiemSuccess: (respone: DiemDanhAnResponse[]): IDiemDanhAnSelectLopChuNhiemSuccess => BaseAction(respone, eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS),
    selectLopChuNhiemError: (messege: string): IDiemDanhAnSelectLopChuNhiemErr => BaseAction(messege, eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_ERROR),
    
    showDiemDanhModal: (respone: boolean): IDiemDanhAnShowModal => BaseAction(respone, eDiemDanhAnActionTypeIds.SHOW_DIEMDANH_AN_MODAL),
    changeDanhSachDiemDanh: (respone: DiemDanhAnResponse[]): IDiemDanhAnChangeDanhSachDiemDanh => BaseAction(respone, eDiemDanhAnActionTypeIds.CHANGE_DANHSACH_DIEMDANH),
 
    diemDanhLopChuNhiemStart: (data: IDiemDanhAnLopChuNhiemChangeRequest): IDiemDanhAnLopChuNhiemStart => BaseAction(data, eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_START),
    diemDanhLopChuNhiemSuccess: (): IDiemDanhAnLopChuNhiemSuccess => BaseAction(undefined, eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_SUCCESS),
    diemDanhLopChuNhiemError: (message: string): IDiemDanhAnLopChuNhiemErr =>  BaseAction(message, eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_ERROR),

    changeSelectedIds: (ids: number[]): IDiemDanhAnChangeSelectedIds => BaseAction(ids, eDiemDanhAnActionTypeIds.CHANGE_SELECTED_IDS),
}