import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_monhoc } from "../../../models/response/mon-hoc/sis_monhoc";
import { eMonHocActionTypeIds, IMonHocChangeSelectedIds, IMonHocCloseConfirm, IMonHocCloseDetail, IMonHocDeleteErr, IMonHocDeleteStart, IMonHocDeleteSuccess, IMonHocLoadErr, IMonHocLoadStart, IMonHocLoadSuccess, IMonHocShowConfirm, IMonHocShowDetail } from "../../action-types/IMonHocActionTypes";

export const monHocActions = {
    loadMonHocStart: (truongId: number): IMonHocLoadStart => {
        return {
            type: eMonHocActionTypeIds.LOAD_START,
            payload: truongId
        }
    },

    loadMonHocSuccess: (data: sis_monhoc[]): IMonHocLoadSuccess => {
        console.log(data);
        return {
            type: eMonHocActionTypeIds.LOAD_SUCCESS,
            payload: data
        }
    },

    loadMonHocError: (messege: string): IMonHocLoadErr => {
        return {
            type: eMonHocActionTypeIds.LOAD_ERROR,
            payload: messege
        }
    },
    showMonHocDetail: (data?: sis_monhoc): IMonHocShowDetail => {
        return {
            type: eMonHocActionTypeIds.SHOW_DETAIL,
            payload: data
        }
    },
    closeMonHocDetail: (): IMonHocCloseDetail => {
        return {
            type: eMonHocActionTypeIds.CLOSE_DETAIL,

        }
    },
    showMonHocConfirm: (): IMonHocShowConfirm => {
        return {
            type: eMonHocActionTypeIds.SHOW_CONFIRM,
        }
    },
    closeMonHocConfirm: (): IMonHocCloseConfirm => {
        return {
            type: eMonHocActionTypeIds.CLOSE_CONFIRM,
        }
    },
    changeMonHocSelectedIds: (ids: number[]): IMonHocChangeSelectedIds => {
        return {
            type: eMonHocActionTypeIds.CHANGE_SELECTED_IDS,
            payload: ids
        }
    },
    deleteMonHocStart: (ids: IDeleteMultipleBaseRequest): IMonHocDeleteStart => {
        return {
            type: eMonHocActionTypeIds.DELETE_START,
            payload: ids
        }
    },
    deleteMonHocSuccess: (): IMonHocDeleteSuccess => {
        return {
            type: eMonHocActionTypeIds.DELETE_SUCCESS,
        }
    },
    deleteMonHocErr: (message: string): IMonHocDeleteErr => {
        return {
            type: eMonHocActionTypeIds.DELETE_ERROR,
            payload: message
        }
    },
    saveMonHocStart: (data: sis_monhoc) => {
        return {
            type: eMonHocActionTypeIds.SAVE_START,
            payload: data
        }
    },
    saveMonHocSuccess: () => {
        return {
            type: eMonHocActionTypeIds.SAVE_SUCCESS,
        }
    },
    saveMonHocError: (message: string) => {
        return {
            type: eMonHocActionTypeIds.SAVE_ERR,
            payload: message
        }
    }
}


