import { IXepHangHocSinhSelectRequest } from '../../../models/request/xep-hang-hoc-sinh/IXepHangHocSinhRequest';
import { IHocSinhSelectRequest } from '../../../models/request/hoc-sinh/IHocSinhSelectRequest';
import { IXepHangHocSinhRequest } from '../../../models/request/xep-hang-hoc-sinh/IXepHangHocSinhSelectRequest';
import { IHocSinhSelectResponeItem } from '../../../models/response/hoc-sinh/IHocSinhSelectRespone';
import { ILopMonHocItemRespone } from '../../../models/response/lop-mon-hoc/ILopMonHocItemRespone';
import { IXepHangHocSinh } from '../../../models/response/xep-hang-hoc-sinh/IXepHangHocSinh';
import { IXepHangHocSinhPageFilter } from '../../page-state-model/xep-hang-hoc-sinh/IXepHangHocSinhPageState';
import { IActionTypeBase } from '../IActionTypeBase';
import { IDiem } from '../../../models/response/diem/IDiem';
import { ITongHopDinhKyFilter } from '../../page-state-model/tong-hop/ITongHopDinhKyPageState';
import { ITongHopDinhKySelectRequest } from '../../../models/request/tong-hop/ITongHopDinhKyRequest';
import { ILopMonHocSoDinhKyReponse } from '../../../models/response/tong-hop/ILopMonHocSoDinhKyReponse';
import { IKetQuaDinhKyRespone } from '../../../models/response/tong-hop/IKetQuaDinhKyRespone';
export enum eTongHopDinhKyActionTypeIds {
    CHANGE_FILTER = "TONGHOP_DINHKY_CHANGE_FILTER",

    HOCSINH_LOAD_START = "TONGHOP_DINHKY_HOCSINH_LOAD_START",
    HOCSINH_LOAD_SUCCESS = "TONGHOP_DINHKY_HOCSINH_LOAD_SUCCESS",
    HOCSINH_LOAD_ERROR = "TONGHOP_DINHKY_HOCSINH_LOAD_ERROR",

    MONHOC_LOAD_START = "TONGHOP_DINHKY_MONHOC_LOAD_START",
    MONHOC_LOAD_SUCCESS = "TONGHOP_DINHKY_MONHOC_LOAD_SUCCESS",
    MONHOC_LOAD_ERROR = "TONGHOP_DINHKY_MONHOC_LOAD_ERROR",

    KETQUA_DANHGIA_LOAD_START = "TONGHOP_DINHKY_KETQUA_LOAD_START",
    KETQUA_DANHGIA_LOAD_SUCCESS = "TONGHOP_DINHKY_KETQUA_LOAD_SUCCESS",
    KETQUA_DANHGIA_LOAD_ERROR = "TONGHOP_DINHKY_KETQUA_LOAD_ERROR",


}

export interface ITongHopDinhKyChangeFilter extends IActionTypeBase<eTongHopDinhKyActionTypeIds.CHANGE_FILTER, ITongHopDinhKyFilter> { }

export interface ITongHopDinhKyHocSinhLoadStart extends IActionTypeBase<eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_START, IHocSinhSelectRequest> { }
export interface ITongHopDinhKyHocSinhLoadSuccess extends IActionTypeBase<eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_SUCCESS, IHocSinhSelectResponeItem[]> { }
export interface ITongHopDinhKyHocSinhLoadError extends IActionTypeBase<eTongHopDinhKyActionTypeIds.HOCSINH_LOAD_ERROR, string> { }

export interface ITongHopDinhKyMonHocLoadStart extends IActionTypeBase<eTongHopDinhKyActionTypeIds.MONHOC_LOAD_START, number> { }
export interface ITongHopDinhKyMonHocLoadSuccess extends IActionTypeBase<eTongHopDinhKyActionTypeIds.MONHOC_LOAD_SUCCESS, ILopMonHocSoDinhKyReponse> { }
export interface ITongHopDinhKyMonHocLoadError extends IActionTypeBase<eTongHopDinhKyActionTypeIds.MONHOC_LOAD_ERROR, string> { }


export interface ITongHopDinhKyKetQuaLoadStart extends IActionTypeBase<eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_START, ITongHopDinhKySelectRequest> { }
export interface ITongHopDinhKyKetQuaLoadSuccess extends IActionTypeBase<eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_SUCCESS, IKetQuaDinhKyRespone> { }
export interface ITongHopDinhKyKetQuaLoadError extends IActionTypeBase<eTongHopDinhKyActionTypeIds.KETQUA_DANHGIA_LOAD_ERROR, string> { }


export type ITongHopDinhKyActionTypes = ITongHopDinhKyChangeFilter |
    ITongHopDinhKyHocSinhLoadStart | ITongHopDinhKyHocSinhLoadSuccess | ITongHopDinhKyHocSinhLoadError |
    ITongHopDinhKyMonHocLoadStart | ITongHopDinhKyMonHocLoadSuccess | ITongHopDinhKyMonHocLoadError |
    ITongHopDinhKyKetQuaLoadStart | ITongHopDinhKyKetQuaLoadSuccess | ITongHopDinhKyKetQuaLoadError
