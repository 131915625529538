import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { IBaiVietPageState } from "../../page-state-model/bai-viet/IBaiVietPageState";
import { NotifyHelper } from './../../../helpers/toast';
import { IBaiVietActionTypes, eBaiVietActionTypeIds } from './../../action-types/bai-viet/IBaiVietActionTypes';

const initialState: IBaiVietPageState = {
    status: ePageBaseStatus.is_not_initialization,
    app_baiviets: [],
    app_baiviet_selected_ids: [],
    is_show_delete_confirm: false,
    waiting_approve_count: 0
}

export const baiVietReducer = (state: IBaiVietPageState = initialState, action: IBaiVietActionTypes): IBaiVietPageState => {
    switch (action.type) {
        case eBaiVietActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiVietActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviets: action.payload
            }
        case eBaiVietActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviets: []
            }
        case eBaiVietActionTypeIds.LOAD_APPROVED_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiVietActionTypeIds.LOAD_APPROVED_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviets: action.payload
            }
        case eBaiVietActionTypeIds.LOAD_APPROVED_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviets: []
            }
        case eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviets: action.payload
            }
        case eBaiVietActionTypeIds.LOAD_WAITING_APPROVE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviets: []
            }
        case eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                waiting_approve_count: action.payload
            }
        case eBaiVietActionTypeIds.COUNT_WAITING_APPROVE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                app_baiviets: []
            }
        case eBaiVietActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                app_baiviet_selected_ids: action.payload
            }
        case eBaiVietActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eBaiVietActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }

        case eBaiVietActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eBaiVietActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Xoá dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case eBaiVietActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        default:
            return state;
    }

}