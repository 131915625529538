import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { sis_thongtu } from "../../../models/response/danh-muc/sis_thongtu"

export enum eThongTuActionTypeIds {
    LOAD_START = "ThongTu_LOAD_START",
    LOAD_SUCCESS = "ThongTu_LOAD_SUCCESS",
    LOAD_ERROR = "ThongTu_LOAD_ERROR",

    SHOW_DETAIL = "ThongTu_SHOW_DETAIL",
    CLOSE_DETAIL = "ThongTu_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "ThongTu_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "ThongTu_SHOW_CONFIRM",
    CLOSE_CONFIRM = "ThongTu_CLOSE_CONFIRM",

    DELETE_START = "ThongTu_DELETE_START",
    DELETE_SUCCESS = "ThongTu_DELETE_SUCCESS",
    DELETE_ERROR = "ThongTu_DELETE_ERROR",

    SAVE_START = "ThongTu_SAVE_START",
    SAVE_SUCCESS = "ThongTu_SAVE_SUCCESS",
    SAVE_ERR = "ThongTu_SAVE_ERR"
}

export interface IThongTuLoadStart {
    type: eThongTuActionTypeIds.LOAD_START,
}
export interface IThongTuLoadSuccess {
    type: eThongTuActionTypeIds.LOAD_SUCCESS,
    payload: sis_thongtu[]
}
export interface IThongTuLoadErr {
    type: eThongTuActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IThongTuShowDetail {
    type: eThongTuActionTypeIds.SHOW_DETAIL,
    payload?: sis_thongtu
}
export interface IThongTuCloseDetail {
    type: eThongTuActionTypeIds.CLOSE_DETAIL,
}
export interface IThongTuChangeSelectedIds {
    type: eThongTuActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IThongTuShowConfirm {
    type: eThongTuActionTypeIds.SHOW_CONFIRM
}
export interface IThongTuCloseConfirm {
    type: eThongTuActionTypeIds.CLOSE_CONFIRM
}

export interface IThongTuDeleteStart {
    type: eThongTuActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IThongTuDeleteSuccess {
    type: eThongTuActionTypeIds.DELETE_SUCCESS,
}
export interface IThongTuDeleteErr {
    type: eThongTuActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IThongTuSaveStart {
    type: eThongTuActionTypeIds.SAVE_START,
    payload: sis_thongtu
}
export interface IThongTuSaveSuccess {
    type: eThongTuActionTypeIds.SAVE_SUCCESS
}
export interface IThongTuSaveErr {
    type: eThongTuActionTypeIds.SAVE_ERR,
    payload: string
}
export type IThongTuActionTypes = IThongTuLoadStart | IThongTuLoadSuccess | IThongTuLoadErr |
    IThongTuShowDetail | IThongTuCloseDetail |
    IThongTuChangeSelectedIds |
    IThongTuShowConfirm | IThongTuCloseConfirm |
    IThongTuDeleteStart | IThongTuDeleteSuccess | IThongTuDeleteErr |
    IThongTuSaveStart | IThongTuSaveSuccess | IThongTuSaveErr