import { IDotDangKySelectRequest } from '../../../models/request/dangky-monhoc/IDotDangKySelectRequest';
import { sis_pl_dotdangky } from '../../../models/response/dangky-monhoc/sis_pl_dotdangky';
export enum eDotDangKyActionTypeIds {
    LOAD_START = "DOTDANGKY_LOAD_START",
    LOAD_SUCCESS = "DOTDANGKY_LOAD_SUCCESS",
    LOAD_ERROR = "DOTDANGKY_LOAD_ERROR",
    CHANGE_EDITING_DATA = "DOTDANGKY_CHANGE_EDITING_DATA",
    SHOW_DELETE_CONFIRM = "DOTDANGKY_SHOW_CONFIRM",

    DELETE_START = "DOTDANGKY_DELETE_START",
    DELETE_SUCCESS = "DOTDANGKY_DELETE_SUCCESS",
    DELETE_ERROR = "DOTDANGKY_DELETE_ERROR",

    SHOW_DETAIL_MODAL = "DOTDANGKY_SHOW_DETAIL_MODAL",

    SAVE_START = "DOTDANGKY_SAVE_START",
    SAVE_SUCCESS = "DOTDANGKY_SAVE_SUCCESS",
    SAVE_ERROR = "DOTDANGKY_SAVE_ERROR"

}
export interface IDotDangKyLoadStart {
    type: eDotDangKyActionTypeIds.LOAD_START,
    payload: IDotDangKySelectRequest
}
export interface IDotDangKyLoadSuccess {
    type: eDotDangKyActionTypeIds.LOAD_SUCCESS,
    payload: sis_pl_dotdangky[]
}
export interface IDotDangKyLoadError {
    type: eDotDangKyActionTypeIds.LOAD_ERROR,
    payload: string
}
export interface IDotDangKyChangeEditingData {
    type: eDotDangKyActionTypeIds.CHANGE_EDITING_DATA,
    payload?: sis_pl_dotdangky
}
export interface IDotDangKyShowDeleteConfirm {
    type: eDotDangKyActionTypeIds.SHOW_DELETE_CONFIRM,
    payload: boolean
}
export interface IDotDangKyDeleteStart {
    type: eDotDangKyActionTypeIds.DELETE_START,
    payload: number
}
export interface IDotDangKyDeleteSuccess {
    type: eDotDangKyActionTypeIds.DELETE_SUCCESS
}

export interface IDotDangKyDeleteError {
    type: eDotDangKyActionTypeIds.DELETE_ERROR,
    payload: string
}

export interface IDotDangKyShowDetailModal {
    type: eDotDangKyActionTypeIds.SHOW_DETAIL_MODAL,
    payload: boolean
}

export interface IDotDangKySaveStart {
    type: eDotDangKyActionTypeIds.SAVE_START,
    payload: sis_pl_dotdangky
}
export interface IDotDangKySaveSuccess {
    type: eDotDangKyActionTypeIds.SAVE_SUCCESS
}
export interface IDotDangKySaveError {
    type: eDotDangKyActionTypeIds.SAVE_ERROR,
    payload: string
}

export type IDotDangKyActionTypes = IDotDangKyLoadStart | IDotDangKyLoadSuccess | IDotDangKyLoadError |
    IDotDangKyChangeEditingData | IDotDangKyShowDeleteConfirm |
    IDotDangKyDeleteStart | IDotDangKyDeleteSuccess | IDotDangKyDeleteError |
    IDotDangKyShowDetailModal |
    IDotDangKySaveStart | IDotDangKySaveSuccess | IDotDangKySaveError