import { ePageBaseStatus } from "../../../../models/ePageBaseStatus";
import { IDgtxKetQua } from "../../../../models/response/danh-gia/thuong-xuyen/IDgtxKetQua";
import { eDgtxKetQuaActionTypeIds, IDgtxKetQuaActionTypes } from "../../../action-types/danh-gia/thuong-xuyen/IDgtxKetQuaActionType";
import { IDgtxKetQuaPageState } from '../../../page-state-model/danh-gia/thuong-xuyen/IDgtxKetQuaPageState';
const iniState: IDgtxKetQuaPageState = {
    filter: {
        dm_khoi_id: 0,
        dm_lop_id: 0,
        dm_truong_id: 0,
        nam_hoc: '',
        thang: 0,
        hoc_ky: 0
    },
    loading: {
        hocSinh: "",
        soNhanXetItems: "",
        ketQuas: "",
        baoCaoThuongXuyens: ""
    },
    status: ePageBaseStatus.is_not_initialization,
    hocSinhs: [],
    soNhanXetItems: [],
    ketQuas: [],
    baoCaoThuongXuyens: [],
    importData: []
}
const mergeKetQuas = (ketQuas: IDgtxKetQua[], newKetQuas: IDgtxKetQua[]): IDgtxKetQua[] => {
    let result = ketQuas.map(x => {
        var _newDiem = newKetQuas.find(y => x.id == y.id);
        if (_newDiem) return _newDiem;
        return x;
    })
    newKetQuas.map(y => {
        var _existedDiem = ketQuas.find(x => x.id == y.id)
        if (!_existedDiem) {
            result.push(y);
        }
    })
    return result;
}
export const dgtxKetQuaReducer = (state: IDgtxKetQuaPageState = iniState, action: IDgtxKetQuaActionTypes): IDgtxKetQuaPageState => {
    switch (action.type) {
        case eDgtxKetQuaActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: "is_loading"
                }
            }
        case eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: ""
                },
                hocSinhs: action.payload
            }
        case eDgtxKetQuaActionTypeIds.HOCSINH_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinh: ""
                },
                hocSinhs: []
            }
        case eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soNhanXetItems: "is_loading"
                },
            }
        case eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soNhanXetItems: ""
                },
                soNhanXetItems: action.payload
            }
        case eDgtxKetQuaActionTypeIds.SONHANXET_ITEM_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    soNhanXetItems: ""
                },
                soNhanXetItems: []
            }
        case eDgtxKetQuaActionTypeIds.KETQUA_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: "is_loading"
                },
            }
        case eDgtxKetQuaActionTypeIds.KETQUA_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: action.payload
            }
        case eDgtxKetQuaActionTypeIds.KETQUA_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: []
            }
        case eDgtxKetQuaActionTypeIds.KETQUA_SAVE_SUCCESS:
            return {
                ...state,
                ketQuas: mergeKetQuas(state.ketQuas, [action.payload])
            }
        case eDgtxKetQuaActionTypeIds.BCTX_LOAD_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    baoCaoThuongXuyens: "is_loading"
                },
            }
        case eDgtxKetQuaActionTypeIds.BCTX_LOAD_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    baoCaoThuongXuyens: ""
                },
                baoCaoThuongXuyens: action.payload
            }
        case eDgtxKetQuaActionTypeIds.BCTX_LOAD_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    baoCaoThuongXuyens: ""
                },
                baoCaoThuongXuyens: []
            }
        case eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: action.payload
            }
        case eDgtxKetQuaActionTypeIds.VALIDATE_IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                importData: []
            }
        case eDgtxKetQuaActionTypeIds.IMPORT_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDgtxKetQuaActionTypeIds.IMPORT_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eDgtxKetQuaActionTypeIds.IMPORT_ERRROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return {
                ...state
            }

    }

}