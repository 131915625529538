import { eDiemDanhStatusActionTypeIds, IDiemDanhStatusActionTypes } from "../../action-types/diem-danh/IDiemDanhStatusActionTypes";
import { IDiemDanhStatusState } from "../../page-state-model/diem-danh/IDiemDanhStatusState";


const initialState: IDiemDanhStatusState = {
    sis_diemdanh_status: []
}

export const diemDanhStatusReducer = (state: IDiemDanhStatusState = initialState, action: IDiemDanhStatusActionTypes): IDiemDanhStatusState => {
    switch (action.type) {
        case eDiemDanhStatusActionTypeIds.LOAD_START:
            return {
                ...state
            }
        case eDiemDanhStatusActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                sis_diemdanh_status: action.payload
            }
        case eDiemDanhStatusActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                sis_diemdanh_status: []
            }
        default:
            return state;
    }
}