import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiWrapper } from '../../../api/apiWrapper';
import { IBaseResponeModel } from './../../../models/response/base-response';
import { eLocalizedResourceActionTypeIds, ILocalizedResourceLoadStart } from './../../action-types/localized-resource/ILocalizedResourceActions';
import { localizedResourceAction } from './../../actions/localized-resource/localizedResourceAction';
export function* localizedResourceSaga() {
    yield takeLatest(eLocalizedResourceActionTypeIds.LOAD_START, loadWorker)
}
function* loadWorker(action: ILocalizedResourceLoadStart) {
    try {
        const res: AxiosResponse = yield call(apiWrapper.localizedResource.loadAll, action.payload);
        const resData: IBaseResponeModel = res.data;
        if (resData.is_success) {
            yield put(localizedResourceAction.loadSuccess(resData.data))
        } else {
            yield put(localizedResourceAction.loadError(resData.message ?? "Error"))
        }
    } catch (error: any) {
        yield put(localizedResourceAction.loadError(error?.response?.data?.message ?? "Error"))
    }
}