import { call, put, takeEvery } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eUploadActionTypeIds, IReadExcelStartAction, IUploadExcelStartAction, IUploadImageStartAction, IUploadSvgStartAction } from "../../action-types/upload-file/IUploadActionTypes";
import { actions } from "../../actions/actionsWrapper";


function* uploadSaga(): any {
    yield takeEvery(eUploadActionTypeIds.UPLOAD_EXCEL_START, uploadExcelWorker)
    yield takeEvery(eUploadActionTypeIds.UPLOAD_SVG_START, uploadSvgWorker)
    yield takeEvery(eUploadActionTypeIds.UPLOAD_IMAGE_START, uploadImageWorker)
    yield takeEvery(eUploadActionTypeIds.READ_EXCEL_START, readExcelWorker)
}

function* uploadExcelWorker(data: IUploadExcelStartAction) {
    try {
        const res: IBaseResponeModel = yield call(apiWrapper.uploadFile.uploadExcel, data.payload);
        if (res.is_success) {
            yield put(actions.uploadFile.uploadExcelSuccess(res.data))
        } else {
            yield put(actions.uploadFile.uploadExcelError(res.message || "Không upload được file excel"))
        }
    } catch (error) {
        yield put(actions.uploadFile.uploadExcelError("Upload file excel có lỗi"))
    }
}

function* uploadSvgWorker(data: IUploadSvgStartAction) {
    try {
        const res: IBaseResponeModel = yield call(apiWrapper.uploadFile.uploadSvg, data.payload);
        if (res.is_success) {
            yield put(actions.uploadFile.uploadSvgSuccess(res.data))
        } else {
            yield put(actions.uploadFile.uploadSvgError(res.message || "Không upload được file svg"))
        }
    } catch (error) {
        yield put(actions.uploadFile.uploadSvgError("Upload file svg có lỗi"))
    }
}

function* uploadImageWorker(data: IUploadImageStartAction) {
    try {
        const res: IBaseResponeModel = yield call(apiWrapper.uploadFile.uploadImage, data.payload);
        if (res.is_success) {
            yield put(actions.uploadFile.uploadImageSuccess(res.data))
        } else {
            yield put(actions.uploadFile.uploadImageError(res.message || "Không upload được file ảnh"))
        }
    } catch (error) {
        yield put(actions.uploadFile.uploadImageError("Upload file ảnh có lỗi"))
    }
}

function* readExcelWorker(data: IReadExcelStartAction) {
    try {
        const res: IBaseResponeModel = yield call(apiWrapper.uploadFile.readExcel, data.payload);
        if (res.is_success) {
            yield put(actions.uploadFile.readExcelSuccess({ data: res.data }))
        } else {
            yield put(actions.uploadFile.readExcelError(res.message || "Không đọc được file excel"))
        }
    } catch (error) {
        yield put(actions.uploadFile.readExcelError("Đọc file excel có lỗi"))
    }
}

export { uploadSaga };