import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { ChamTietCaNhanSelectRequest } from "../../../models/request/cham-tiet/ChamTietCaNhanSelectRequest";
import { ChamTietCaNhanUpdateRequest } from "../../../models/request/cham-tiet/ChamTietCaNhanUpdateRequest";
import { ChamTietDuyetRequest } from "../../../models/request/cham-tiet/ChamTietDuyetRequest";
import { ChamTietQuanLySelectRequest } from "../../../models/request/cham-tiet/ChamTietQuanLySelectRequest";
import { ChamTietItemResponse, sis_chamtiet } from "../../../models/response/cham-tiet/sis_chamtiet";
import { sis_chamtiet_trangthai_duyet } from "../../../models/response/cham-tiet/sis_chamtiet_trangthai_duyet";
import { eChamTietActionTypeIds } from "../../action-types/cham-tiet/IChamTietActionTypes";
import { BaseAction } from "../IBaseActionResult";

export const chamTietActions = {
    selectTrangthaiduyetStart: () => BaseAction(undefined, eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_START),
    selectTrangthaiduyetSuccess: (data: sis_chamtiet_trangthai_duyet[]) => BaseAction(undefined, eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_SUCCESS),
    selectTrangthaiduyetError: (error: string) => BaseAction(error, eChamTietActionTypeIds.SELECT_TRANGTHAIDUYET_ERROR),

    selectCanhanStart: (request: ChamTietCaNhanSelectRequest) => BaseAction(request, eChamTietActionTypeIds.SELECT_CANHAN_START),
    selectCanhanSuccess: (data: ChamTietItemResponse[]) => BaseAction(data, eChamTietActionTypeIds.SELECT_CANHAN_SUCCESS),
    selectCanhanError: (error: string) => BaseAction(error, eChamTietActionTypeIds.SELECT_CANHAN_ERROR),

    selectDuyetStart: (request: ChamTietQuanLySelectRequest) => BaseAction(request, eChamTietActionTypeIds.SELECT_DUYET_START),
    selectDuyetSuccess: (data: ChamTietItemResponse[]) => BaseAction(data, eChamTietActionTypeIds.SELECT_DUYET_SUCCESS),
    selectDuyetError: (error: string) => BaseAction(error, eChamTietActionTypeIds.SELECT_DUYET_ERROR),

    selectQuanlyStart: (request: ChamTietQuanLySelectRequest) => BaseAction(request, eChamTietActionTypeIds.SELECT_QUANLY_START),
    selectQuanlySuccess: (data: ChamTietItemResponse[]) => BaseAction(data, eChamTietActionTypeIds.SELECT_QUANLY_SUCCESS),
    selectQuanlyError: (error: string) => BaseAction(error, eChamTietActionTypeIds.SELECT_QUANLY_ERROR),

    changeSelectedIds: (ids: number[]) => BaseAction(ids, eChamTietActionTypeIds.CHANGE_SELECTED_IDS),
    changeEditingData: (data?: sis_chamtiet) => BaseAction(data, eChamTietActionTypeIds.CHANGE_EDITING_DATA),
    showDeleteConfirm: (isShow: boolean) => BaseAction(isShow, eChamTietActionTypeIds.SHOW_DELETE_CONFIRM),

    deleteStart: (request: IDeleteMultipleBaseRequest) => BaseAction(request, eChamTietActionTypeIds.DELETE_START),
    deleteSuccess: () => BaseAction(undefined, eChamTietActionTypeIds.DELETE_SUCCESS),
    deleteError: (error: string) => BaseAction(error, eChamTietActionTypeIds.DELETE_ERROR),

    showDetailModal: (isShow: boolean) => BaseAction(isShow, eChamTietActionTypeIds.SHOW_DETAIL_MODAL),

    saveStart: (data: sis_chamtiet) => BaseAction(data, eChamTietActionTypeIds.SAVE_START),
    saveSuccess: () => BaseAction(undefined, eChamTietActionTypeIds.SAVE_SUCCESS),
    saveError: (error: string) => BaseAction(error, eChamTietActionTypeIds.SAVE_ERROR),

    changeTrangThaiStart: (data: ChamTietCaNhanUpdateRequest) => BaseAction(data, eChamTietActionTypeIds.CHANGE_TRANGTHAI_START),
    changeTrangThaiSuccess: () => BaseAction(undefined, eChamTietActionTypeIds.CHANGE_TRANGTHAI_SUCCESS),
    changeTrangThaiError: (error: string) => BaseAction(error, eChamTietActionTypeIds.CHANGE_TRANGTHAI_ERROR),

    changeChamLaiStart: (data: string | null) => BaseAction(data, eChamTietActionTypeIds.CHAMLAI_START),
    changeChamLaiSuccess: () => BaseAction(undefined, eChamTietActionTypeIds.CHAMLAI_SUCCESS),
    changeChamLaiError: (error: string) => BaseAction(error, eChamTietActionTypeIds.CHAMLAI_ERROR),

    duyetStart: (data: ChamTietDuyetRequest) => BaseAction(data, eChamTietActionTypeIds.DUYET_START),
    duyetSuccess: () => BaseAction(undefined, eChamTietActionTypeIds.DUYET_SUCCESS),
    duyetError: (error: string) => BaseAction(error, eChamTietActionTypeIds.DUYET_ERROR),
}