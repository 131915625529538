import { sis_giaidoan } from './../../../models/response/so-diem/sis_giaidoan';
export enum eGiaiDoanActionTypeIds {
    LOAD_START = "GIAIDOAN_LOAD_START",
    LOAD_SUCCESS = "GIAIDOAN_LOAD_SUCCESS",
    LOAD_ERROR = "GIAIDOAN_LOAD_ERROR",
}
export interface IGiaiDoanLoadStart {
    type: eGiaiDoanActionTypeIds.LOAD_START
}
export interface IGiaiDoanLoadSuccess {
    type: eGiaiDoanActionTypeIds.LOAD_SUCCESS,
    payload: sis_giaidoan[]
}
export interface IGiaiDoanLoadError {
    type: eGiaiDoanActionTypeIds.LOAD_ERROR,
    payload: string
}
export type IGiaiDoanActionTypes = IGiaiDoanLoadStart | IGiaiDoanLoadSuccess | IGiaiDoanLoadError
