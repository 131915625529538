import { NotifyHelper } from './../../../helpers/toast';
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { IDotDangKyState } from '../../page-state-model/dangky-monhoc/IDotDangKyState';
import { IDotDangKyActionTypes, eDotDangKyActionTypeIds } from '../../action-types/dangky-monhoc/IDotDangKyActionTypes';

const iniState: IDotDangKyState = {
    status: ePageBaseStatus.is_not_initialization,
    dotDangKys: [],
    isShowDeleteConfirm: false,
    dotDangKyEditing: undefined,
    isShowDetailModal: false
}

export const dotDangKyReducer = (state: IDotDangKyState = iniState, action: IDotDangKyActionTypes): IDotDangKyState => {
    switch (action.type) {
        case eDotDangKyActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDotDangKyActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                dotDangKys: action.payload
            }
        case eDotDangKyActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eDotDangKyActionTypeIds.CHANGE_EDITING_DATA:
            return {
                ...state,
                dotDangKyEditing: action.payload
            }
        case eDotDangKyActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                isShowDeleteConfirm: action.payload
            }
        case eDotDangKyActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eDotDangKyActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success("Đã xóa đợt đăng ký")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDeleteConfirm: false
            }
        case eDotDangKyActionTypeIds.DELETE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eDotDangKyActionTypeIds.SHOW_DETAIL_MODAL:
            return {
                ...state,
                isShowDetailModal: action.payload
            }
        case eDotDangKyActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDotDangKyActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Cập nhật thành công")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowDetailModal: false
            }
        case eDotDangKyActionTypeIds.SAVE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        default:
            return {
                ...state
            }
    }
}