import { IDiemDanhLopBoMonSelectRequest } from "../../../models/request/diem-danh/IDiemDanhLopBoMonSelectRequest"
import { IDiemDanhLopBoMonChangeRequest } from "../../../models/request/diem-danh/IDiemDanhLopBoMonChangeRequest"
import { DiemDanhResponse } from "../../../models/response/diem-danh/DiemDanhResponse"
import { IDiemDanhConfigSelectRequest } from "../../../models/request/diem-danh/IDiemDanhConfigSelectRequest"
import { IDiemDanhLopChuNhiemSelectRequest } from "../../../models/request/diem-danh/IDiemDanhLopChuNhiemSelectRequest"
import { IDiemDanhLopChuNhiemChangeRequest } from "../../../models/request/diem-danh/IDiemDanhLopChuNhiemChangeRequest"
import { IDiemDanhConfigResponse } from "../../../models/response/diem-danh/DiemDanhConfigResponse"
import { ILopDiemDanhResponse } from "../../../models/response/diem-danh/LopDiemDanhResponse"
import { IActionTypeBase } from "../IActionTypeBase"
import { IDiemDanhLopBoMonPublishMarkRequest } from "../../../models/request/diem-danh/IDiemDanhLopBoMonPublishMarkRequest"
import { IDiemDanhLopChuNhiemPublishMarkRequest } from "../../../models/request/diem-danh/IDiemDanhLopChuNhiemPublishMarkRequest"

export enum eDiemDanhActionTypeIds {
    SELECT_CONFIG_START = "DIEMDANH_CONFIG_LOPCHUNHIEM_START",
    SELECT_CONFIG_SUCCESS = "DIEMDANH_CONFIG_LOPCHUNHIEM_SUCCESS",
    SELECT_CONFIG_ERROR = "DIEMDANH_CONFIG_LOPCHUNHIEM_ERROR",

    SELECT_LOPBOMON_START = "DIEMDANH_SELECT_LOPBOMON_START",
    SELECT_LOPBOMON_SUCCESS = "DIEMDANH_SELECT_LOPBOMON_SUCCESS",
    SELECT_LOPBOMON_ERROR = "DIEMDANH_SELECT_LOPBOMON_ERROR",

    SELECT_LOPCHUNHIEM_START = "DIEMDANH_SELECT_LOPCHUNHIEM_START",
    SELECT_LOPCHUNHIEM_SUCCESS = "DIEMDANH_SELECT_LOPCHUNHIEM_SUCCESS",
    SELECT_LOPCHUNHIEM_ERROR = "DIEMDANH_SELECT_LOPCHUNHIEM_ERROR",

    CHANGE_DANHSACH_DIEMDANH = "CHANGE_DANHSACH_DIEMDANH",
    SHOW_DIEMDANH_MODAL = "SHOW_DIEMDANH_MODAL",

    DIEMDANH_LOPBOMON_START = "DIEMDANH_LOPBOMON_START",
    DIEMDANH_LOPBOMON_SUCCESS = "DIEMDANH_LOPBOMON_SUCCESS",
    DIEMDANH_LOPBOMON_ERROR = "DIEMDANH_LOPBOMON_ERROR",

    DIEMDANH_LOPCHUNHIEM_START = "DIEMDANH_LOPCHUNHIEM_START",
    DIEMDANH_LOPCHUNHIEM_SUCCESS = "DIEMDANH_LOPCHUNHIEM_SUCCESS",
    DIEMDANH_LOPCHUNHIEM_ERROR = "DIEMDANH_LOPCHUNHIEM_ERROR",

    PUBLISHMARK_LOPBOMON_START = "DIEMDANH_PUBLISHMARK_LOPBOMON_START",
    PUBLISHMARK_LOPBOMON_SUCCESS = "DIEMDANH_PUBLISHMARK_LOPBOMON_SUCCESS",
    PUBLISHMARK_LOPBOMON_ERROR = "DIEMDANH_PUBLISHMARK_LOPBOMON_ERROR",

    PUBLISHMARK_LOPCHUNHIEM_START = "DIEMDANH_PUBLISHMARK_LOPCHUNHIEM_START",
    PUBLISHMARK_LOPCHUNHIEM_SUCCESS = "DIEMDANH_PUBLISHMARK_LOPCHUNHIEM_SUCCESS",
    PUBLISHMARK_LOPCHUNHIEM_ERROR = "DIEMDANH_PUBLISHMARK_LOPCHUNHIEM_ERROR",

    CHANGE_SELECTED_IDS = "DIEMDANH_CHANGE_SELECTED_IDS",
}
export interface IDiemDanhSelectConfigStart extends IActionTypeBase<eDiemDanhActionTypeIds.SELECT_CONFIG_START, IDiemDanhConfigSelectRequest> { }
export interface IDiemDanhSelectConfigSuccess extends IActionTypeBase<eDiemDanhActionTypeIds.SELECT_CONFIG_SUCCESS, ILopDiemDanhResponse[]> { }
export interface IDiemDanhSelectConfigErr extends IActionTypeBase<eDiemDanhActionTypeIds.SELECT_CONFIG_ERROR, string> { }

export interface IDiemDanhSelectLopBoMonStart extends IActionTypeBase<eDiemDanhActionTypeIds.SELECT_LOPBOMON_START, IDiemDanhLopBoMonSelectRequest> { }
export interface IDiemDanhSelectLopBoMonSuccess extends IActionTypeBase<eDiemDanhActionTypeIds.SELECT_LOPBOMON_SUCCESS, DiemDanhResponse[]> { }
export interface IDiemDanhSelectLopBoMonErr extends IActionTypeBase<eDiemDanhActionTypeIds.SELECT_LOPBOMON_ERROR, string> { }

export interface IDiemDanhSelectLopChuNhiemStart extends IActionTypeBase<eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_START, IDiemDanhLopChuNhiemSelectRequest> { }
export interface IDiemDanhSelectLopChuNhiemSuccess extends IActionTypeBase<eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_SUCCESS, DiemDanhResponse[]> { }
export interface IDiemDanhSelectLopChuNhiemErr extends IActionTypeBase<eDiemDanhActionTypeIds.SELECT_LOPCHUNHIEM_ERROR, string> { }

export interface IDiemDanhShowModal extends IActionTypeBase<eDiemDanhActionTypeIds.SHOW_DIEMDANH_MODAL, boolean> { }
export interface IDiemDanhChangeDanhSachDiemDanh extends IActionTypeBase<eDiemDanhActionTypeIds.CHANGE_DANHSACH_DIEMDANH, DiemDanhResponse[]> { }

export interface IDiemDanhLopBoMonStart extends IActionTypeBase<eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_START, IDiemDanhLopBoMonChangeRequest> { }
export interface IDiemDanhLopBoMonSuccess extends IActionTypeBase<eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_SUCCESS, undefined> { }
export interface IDiemDanhLopBoMonErr extends IActionTypeBase<eDiemDanhActionTypeIds.DIEMDANH_LOPBOMON_ERROR, string> { }

export interface IDiemDanhLopChuNhiemStart extends IActionTypeBase<eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_START, IDiemDanhLopChuNhiemChangeRequest> { }
export interface IDiemDanhLopChuNhiemSuccess extends IActionTypeBase<eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_SUCCESS, undefined> { }
export interface IDiemDanhLopChuNhiemErr extends IActionTypeBase<eDiemDanhActionTypeIds.DIEMDANH_LOPCHUNHIEM_ERROR, string> { }

export interface IDiemDanhPublishMarkLopBoMonStart extends IActionTypeBase<eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_START, IDiemDanhLopBoMonPublishMarkRequest> { }
export interface IDiemDanhPublishMarkLopBoMonSuccess extends IActionTypeBase<eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_SUCCESS, undefined> { }
export interface IDiemDanhPublishMarkLopBoMonErr extends IActionTypeBase<eDiemDanhActionTypeIds.PUBLISHMARK_LOPBOMON_ERROR, string> { }

export interface IDiemDanhPublishMarkLopChuNhiemStart extends IActionTypeBase<eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_START, IDiemDanhLopChuNhiemPublishMarkRequest> { }
export interface IDiemDanhPublishMarkLopChuNhiemSuccess extends IActionTypeBase<eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_SUCCESS, undefined> { }
export interface IDiemDanhPublishMarkLopChuNhiemErr extends IActionTypeBase<eDiemDanhActionTypeIds.PUBLISHMARK_LOPCHUNHIEM_ERROR, string> { }

export interface IDiemDanhChangeSelectedIds extends IActionTypeBase<eDiemDanhActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }

export type IDiemDanhActionTypes = IDiemDanhSelectConfigStart | IDiemDanhSelectConfigSuccess | IDiemDanhSelectConfigErr |
    IDiemDanhSelectLopBoMonStart | IDiemDanhSelectLopBoMonSuccess | IDiemDanhSelectLopBoMonErr |
    IDiemDanhSelectLopChuNhiemStart | IDiemDanhSelectLopChuNhiemSuccess | IDiemDanhSelectLopChuNhiemErr |
    IDiemDanhShowModal | IDiemDanhChangeDanhSachDiemDanh |
    IDiemDanhLopBoMonStart | IDiemDanhLopBoMonSuccess | IDiemDanhLopBoMonErr |
    IDiemDanhLopChuNhiemStart | IDiemDanhLopChuNhiemSuccess | IDiemDanhLopChuNhiemErr |
    IDiemDanhPublishMarkLopBoMonStart | IDiemDanhPublishMarkLopBoMonSuccess | IDiemDanhPublishMarkLopBoMonErr |
    IDiemDanhPublishMarkLopChuNhiemStart | IDiemDanhPublishMarkLopChuNhiemSuccess | IDiemDanhPublishMarkLopChuNhiemErr |
    IDiemDanhChangeSelectedIds