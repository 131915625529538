import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";

import { actions } from "../../actions/actionsWrapper";
import { IDotDangKyKetQuaHocSinhDangKyStart, IDotDangKyKetQuaHocSinhLoadStart, IDotDangKyKetQuaLoadChiTietStart, IDotDangKyKetQuaLoadStart, eDotDangKyKetQuaActionTypeIds } from "../../action-types/dangky-monhoc/IDotDangKyKetQuaActionTypes";

const ketQuaApi = apiWrapper.dangKyMonHoc.dotDangKyKetQua;
const ketQuaChiTietApi = apiWrapper.dangKyMonHoc.dotDangKyKetQuaChiTiet;

export function* dotDangKyKetQuaSaga(): any {
    yield takeLatest(eDotDangKyKetQuaActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_START, loadHocSinhWorker)
    yield takeLatest(eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_START, loadChiTietWorker)
    yield takeEvery(eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_START, dangKyHocSinhWorker)
}

function* loadWorker(action: IDotDangKyKetQuaLoadStart): any {

    const res: IBaseResponeModel = yield call([ketQuaApi, ketQuaApi.SelectByDotDangKyAsync], action.payload)
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyKetQua.loadSuccess(res.data))
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyKetQua.loadError(res.message || "Không tải được kết quả đăng ký"))
    }
}

function* loadChiTietWorker(action: IDotDangKyKetQuaLoadChiTietStart): any {

    const res: IBaseResponeModel = yield call([ketQuaChiTietApi, ketQuaChiTietApi.SelectByDotDangKyAsync], action.payload)
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyKetQua.loadchitietSuccess(res.data))
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyKetQua.loadchitietError(res.message || "Không tải được kết quả đăng ký của học sinh"))
    }
}

function* loadHocSinhWorker(action: IDotDangKyKetQuaHocSinhLoadStart): any {

    const res: IBaseResponeModel = yield call([ketQuaApi, ketQuaApi.SelectHocSinhByDotDangKyAsync], action.payload)
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyKetQua.loadHocSinhSuccess(res.data))
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyKetQua.loadHocSinhError(res.message || "Không tải được kết quả đăng ký của học sinh"))
    }
}

function* dangKyHocSinhWorker(action: IDotDangKyKetQuaHocSinhDangKyStart): any {
    let res: IBaseResponeModel = yield call([ketQuaApi, ketQuaApi.PostDangKyMonHocAsync], action.payload);
    if (res.is_success) {
        yield put(actions.dangKyMonHoc.dotDangKyKetQua.dangkyHocSinhSuccess())
    } else {
        yield put(actions.dangKyMonHoc.dotDangKyKetQua.dangkyHocSinhError(res.message || "Không lưu được đăng ký môn học cho học sinh"))
    }
}