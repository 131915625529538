import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eLopBoMonSoDiemActionTypeIds, ILopBoMonSoDiemActionTypes } from './../../action-types/lop-bo-mon/ILopBoMonSoDiemActionTypes';
import { ILopBoMonSoDiemPageState } from './../../page-state-model/lop-bo-mon/ILopBoMonSoDiemPageState';
const iniState: ILopBoMonSoDiemPageState = {
    isShowSoDiemSelectionModal: false,
    isShowSoNhanXetDinhKySelectionModal: false,
    isShowSoNhanXetThuongXuyenSelectionModal: false,
    lopBoMons: [],
    lopBoMonSelectedIds: [],
    status: ePageBaseStatus.is_not_initialization
}
export const lopBoMonSoDiemReducer = (state: ILopBoMonSoDiemPageState = iniState, action: ILopBoMonSoDiemActionTypes): ILopBoMonSoDiemPageState => {
    switch (action.type) {
        case eLopBoMonSoDiemActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eLopBoMonSoDiemActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lopBoMons: action.payload
            }
        case eLopBoMonSoDiemActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eLopBoMonSoDiemActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                lopBoMonSelectedIds: action.payload
            }
        // so diem
        case eLopBoMonSoDiemActionTypeIds.SHOW_SODIEM_SELECTION_MODAL:
            return {
                ...state,
                isShowSoDiemSelectionModal: action.payload
            }
        case eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowSoDiemSelectionModal: false
            }
        case eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }

        // dinh ky
        case eLopBoMonSoDiemActionTypeIds.SHOW_DGDK_HOCTAP_SELECTION_MODAL:
            return {
                ...state,
                isShowSoNhanXetDinhKySelectionModal: action.payload
            }
        case eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowSoNhanXetDinhKySelectionModal: false
            }
        case eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        // thuong xuyen
        case eLopBoMonSoDiemActionTypeIds.SHOW_DGTX_HOCTAP_SELECTION_MODAL:
            return {
                ...state,
                isShowSoNhanXetThuongXuyenSelectionModal: action.payload
            }
        case eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                isShowSoNhanXetThuongXuyenSelectionModal: false
            }
        case eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }

        default:
            return {
                ...state
            }
    }
}