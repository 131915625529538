import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { sis_dgtx_dm_noidung, sis_dgtx_dm_noidung_treeview } from "../../../models/response/dgtx-dm/sis_dgtx_dm_noidung"

export enum eDgtxDmHocTapActionTypeIds {
    LOAD_START = "DGTX_DM_HOCTAP_LOAD_START",
    LOAD_SUCCESS = "DGTX_DM_HOCTAP_LOAD_SUCCESS",
    LOAD_ERROR = "DGTX_DM_HOCTAP_LOAD_ERROR",

    SHOW_DETAIL = "DGTX_DM_HOCTAP_SHOW_DETAIL",
    CLOSE_DETAIL = "DGTX_DM_HOCTAP_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "DGTX_DM_HOCTAP_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "DGTX_DM_HOCTAP_SHOW_CONFIRM",
    CLOSE_CONFIRM = "DGTX_DM_HOCTAP_CLOSE_CONFIRM",

    DELETE_START = "DGTX_DM_HOCTAP_DELETE_START",
    DELETE_SUCCESS = "DGTX_DM_HOCTAP_DELETE_SUCCESS",
    DELETE_ERROR = "DGTX_DM_HOCTAP_DELETE_ERROR",

    SAVE_START = "DGTX_DM_HOCTAP_SAVE_START",
    SAVE_SUCCESS = "DGTX_DM_HOCTAP_SAVE_SUCCESS",
    SAVE_ERR = "DGTX_DM_HOCTAP_SAVE_ERR"
}
//ORIGIN MODEL
export interface IDgtxDmHocTapLoadStart {
    type: eDgtxDmHocTapActionTypeIds.LOAD_START,
}
export interface IDgtxDmHocTapLoadSuccess {
    type: eDgtxDmHocTapActionTypeIds.LOAD_SUCCESS,
    payload: sis_dgtx_dm_noidung_treeview[]
}
export interface IDgtxDmHocTapLoadErr {
    type: eDgtxDmHocTapActionTypeIds.LOAD_ERROR,
    payload: string
}
//DETAIL
export interface IDgtxDmHocTapShowDetail {
    type: eDgtxDmHocTapActionTypeIds.SHOW_DETAIL,
    payload?: sis_dgtx_dm_noidung_treeview
}
export interface IDgtxDmHocTapCloseDetail {
    type: eDgtxDmHocTapActionTypeIds.CLOSE_DETAIL,
}
export interface IDgtxDmHocTapChangeSelectedIds {
    type: eDgtxDmHocTapActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
//CONFIRM
export interface IDgtxDmHocTapShowConfirm {
    type: eDgtxDmHocTapActionTypeIds.SHOW_CONFIRM
}
export interface IDgtxDmHocTapCloseConfirm {
    type: eDgtxDmHocTapActionTypeIds.CLOSE_CONFIRM
}
//DELETE
export interface IDgtxDmHocTapDeleteStart {
    type: eDgtxDmHocTapActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IDgtxDmHocTapDeleteSuccess {
    type: eDgtxDmHocTapActionTypeIds.DELETE_SUCCESS,
}
export interface IDgtxDmHocTapDeleteErr {
    type: eDgtxDmHocTapActionTypeIds.DELETE_ERROR,
    payload: string
}
//SAVE
export interface IDgtxDmHocTapSaveStart {
    type: eDgtxDmHocTapActionTypeIds.SAVE_START,
    payload: sis_dgtx_dm_noidung
}
export interface IDgtxDmHocTapSaveSuccess {
    type: eDgtxDmHocTapActionTypeIds.SAVE_SUCCESS
}
export interface IDgtxDmHocTapSaveErr {
    type: eDgtxDmHocTapActionTypeIds.SAVE_ERR,
    payload: string
}

export type IDgtxDmHocTapActionTypes = 
    IDgtxDmHocTapLoadStart | IDgtxDmHocTapLoadSuccess | IDgtxDmHocTapLoadErr |
    IDgtxDmHocTapShowDetail | IDgtxDmHocTapCloseDetail |
    IDgtxDmHocTapChangeSelectedIds |
    IDgtxDmHocTapShowConfirm | IDgtxDmHocTapCloseConfirm |
    IDgtxDmHocTapDeleteStart | IDgtxDmHocTapDeleteSuccess | IDgtxDmHocTapDeleteErr |
    IDgtxDmHocTapSaveStart | IDgtxDmHocTapSaveSuccess | IDgtxDmHocTapSaveErr