import { INamHocTruongFilter } from "../../../models/INamHocTruongFilter";
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { IDiemVanMinhTieuChi } from "../../../models/response/diemvanminh/IDiemVanMinhTieuChi";
import {
    eDiemVanMinhTieuChiActionTypeIds, IDiemVanMinhTieuChiChangeSelectedIds, IDiemVanMinhTieuChiCloseConfirm,
    IDiemVanMinhTieuChiCloseDetail, IDiemVanMinhTieuChiDeleteErr, IDiemVanMinhTieuChiDeleteStart, IDiemVanMinhTieuChiDeleteSuccess,
    IDiemVanMinhTieuChiLoadErr, IDiemVanMinhTieuChiLoadStart, IDiemVanMinhTieuChiLoadSuccess, IDiemVanMinhTieuChiSaveErr, IDiemVanMinhTieuChiSaveStart,
    IDiemVanMinhTieuChiSaveSuccess, IDiemVanMinhTieuChiShowConfirm, IDiemVanMinhTieuChiShowDetail
} from "../../action-types/diemvanminh-tieuchi/IDiemVanMinhTieuChiActionTypes";
import { baseAction } from "../IActionBase";
import { BaseAction } from "../IBaseActionResult";

export const DiemVanMinhTieuChiActions = {
    loadStart: (filter: INamHocTruongFilter): IDiemVanMinhTieuChiLoadStart => BaseAction(filter, eDiemVanMinhTieuChiActionTypeIds.LOAD_START),
    loadSuccess: (respone: IDiemVanMinhTieuChi[]): IDiemVanMinhTieuChiLoadSuccess => BaseAction(respone, eDiemVanMinhTieuChiActionTypeIds.LOAD_SUCCESS),
    loadError: (messege: string): IDiemVanMinhTieuChiLoadErr => BaseAction(messege, eDiemVanMinhTieuChiActionTypeIds.LOAD_ERROR),

    showDetail: (data?: IDiemVanMinhTieuChi): IDiemVanMinhTieuChiShowDetail => BaseAction(data, eDiemVanMinhTieuChiActionTypeIds.SHOW_DETAIL_MODAL),
    closeDetail: (): IDiemVanMinhTieuChiCloseDetail => BaseAction(undefined, eDiemVanMinhTieuChiActionTypeIds.CLOSE_DETAIL_MODAL),
    showConfirm: (): IDiemVanMinhTieuChiShowConfirm => BaseAction(undefined, eDiemVanMinhTieuChiActionTypeIds.SHOW_DELETE_CONFIRM),
    closeConfirm: (): IDiemVanMinhTieuChiCloseConfirm => BaseAction(undefined, eDiemVanMinhTieuChiActionTypeIds.CLOSE_DELETE_CONFIRM),
    changeSelectedIds: (ids: number[]): IDiemVanMinhTieuChiChangeSelectedIds => BaseAction(ids, eDiemVanMinhTieuChiActionTypeIds.CHANGE_SELECTED),
    deleteStart: (ids: IDeleteMultipleBaseRequest): IDiemVanMinhTieuChiDeleteStart => BaseAction(ids, eDiemVanMinhTieuChiActionTypeIds.DELETE_START),
    deleteSuccess: (): IDiemVanMinhTieuChiDeleteSuccess => BaseAction(undefined, eDiemVanMinhTieuChiActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string): IDiemVanMinhTieuChiDeleteErr => BaseAction(message, eDiemVanMinhTieuChiActionTypeIds.DELETE_ERROR),
    //SAVE
    saveStart: (data: IDiemVanMinhTieuChi): IDiemVanMinhTieuChiSaveStart => BaseAction(data, eDiemVanMinhTieuChiActionTypeIds.SAVE_START),
    saveSuccess: (): IDiemVanMinhTieuChiSaveSuccess => BaseAction(undefined, eDiemVanMinhTieuChiActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string): IDiemVanMinhTieuChiSaveErr => BaseAction(message, eDiemVanMinhTieuChiActionTypeIds.SAVE_ERROR),

    changeFilter: (filter: INamHocTruongFilter) => baseAction(eDiemVanMinhTieuChiActionTypeIds.CHANGE_FILTER, filter)
}