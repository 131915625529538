import { log } from 'console';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eDiemVanMinhKetQuaActionTypeIds, IDiemVanMinhKetQuaActionTypes } from "../../action-types/diemvanminh-ketqua/IDiemVanMinhKetQuaActionTypes";
import { IDiemVanMinhKetQuaPageState } from "../../page-state-model/diemvanminh-ketqua/IDiemVanMinhKetQuaState";
import { IDiemVanMinhKetQua } from './../../../models/response/diemvanminh/IDiemVanMinhKetQua';

const initialState: IDiemVanMinhKetQuaPageState = {
    status: ePageBaseStatus.is_not_initialization,
    is_show_delete_confirm: false,
    is_show_detail_modal: false,
    hocSinhs: [],
    ketQuas: [],
    filter: {
        thang: new Date().getMonth() + 1,
        dm_lop_ids: [],
        dm_truong_id: 0,
        nam_hoc: "",
        hoc_ky: 0
    },
    loading: {
        hocSinhs: "",
        ketQuas: ""
    }
}
const mergeData = (ketQuas: IDiemVanMinhKetQua[], item: IDiemVanMinhKetQua) => {
    let result = ketQuas.map(x => {
        if (x.id == item.id) return item;
        return x;
    })
    if (!ketQuas.find(x => x.id == item.id)) {
        result.push(item)
    }
    return result;
}

export const diemVanMinhKetQuaReducer = (state: IDiemVanMinhKetQuaPageState = initialState, action: IDiemVanMinhKetQuaActionTypes): IDiemVanMinhKetQuaPageState => {
    switch (action.type) {
        case eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinhs: "isLoading"
                }
            }
        case eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinhs: ""
                },
                hocSinhs: action.payload
            }
        case eDiemVanMinhKetQuaActionTypeIds.LOAD_HOCSINH_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    hocSinhs: ""
                },
                hocSinhs: []
            }

        case eDiemVanMinhKetQuaActionTypeIds.CHANGE_FILTER:
            // console.log({
            //     filter: action.payload
            // });

            return {
                ...state,
                filter: action.payload
            }
        case eDiemVanMinhKetQuaActionTypeIds.SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                ketQuaEditing: action.payload
            }
        case eDiemVanMinhKetQuaActionTypeIds.CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false
            }
        case eDiemVanMinhKetQuaActionTypeIds.SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true,
                ketQuaEditing: action.payload
            }
        case eDiemVanMinhKetQuaActionTypeIds.CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case eDiemVanMinhKetQuaActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDiemVanMinhKetQuaActionTypeIds.SAVE_SUCCESS:

            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_detail_modal: false,
                ketQuas: mergeData(state.ketQuas, action.payload)
            }
        case eDiemVanMinhKetQuaActionTypeIds.SAVE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eDiemVanMinhKetQuaActionTypeIds.LOAD_KETQUA_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: "isLoading"
                }
            }
        case eDiemVanMinhKetQuaActionTypeIds.LOAD_KETQUA_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: action.payload
            }
        case eDiemVanMinhKetQuaActionTypeIds.LOAD_KETQUA_ERROR:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ketQuas: ""
                },
                ketQuas: []
            }
        case eDiemVanMinhKetQuaActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eDiemVanMinhKetQuaActionTypeIds.DELETE_SUCCESS:

            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_delete_confirm: false,
            }
        case eDiemVanMinhKetQuaActionTypeIds.DELETE_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        default:
            return state;
    }

}