import { NotifyHelper, showNotify } from '../../../helpers/toast';
import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { eFormKhaoSatEditorActionTypeIds, IFormKhaoSatEditorActionTypes } from "../../action-types/form-khaosat/IFormKhaoSatEditorActionTypes";
import { IFormKhaoSatEditorPageStateModel } from "../../page-state-model/form-khaosat/IFormKhaoSatEditorState";

const initialState: IFormKhaoSatEditorPageStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    sis_form_khaosat_editors: [],
    sis_form_khaosat_editor_selected_ids: [],
    sis_form_khaosat_editor_editing: undefined,
    is_show_confirm: false,
    is_show_modal: false,
    sis_form_khaosat_editor_types: []
}
export const formKhaoSatEditorReducer = (state: IFormKhaoSatEditorPageStateModel = initialState, action: IFormKhaoSatEditorActionTypes): IFormKhaoSatEditorPageStateModel => {
    switch (action.type) {
        case eFormKhaoSatEditorActionTypeIds.LOAD_START:
        case eFormKhaoSatEditorActionTypeIds.LOAD_BYFORM_START:
        case eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eFormKhaoSatEditorActionTypeIds.LOAD_SUCCESS:
        case eFormKhaoSatEditorActionTypeIds.LOAD_BYFORM_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosat_editors: action.payload
            }
        case eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosat_editor_types: action.payload
            }
        case eFormKhaoSatEditorActionTypeIds.LOAD_ERROR:
        case eFormKhaoSatEditorActionTypeIds.LOAD_BYFORM_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosat_editors: []
            }
        case eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                sis_form_khaosat_editor_types: []
            }
        case eFormKhaoSatEditorActionTypeIds.CHANGE_DETAIL:
            return {
                ...state,
                sis_form_khaosat_editor_editing: action.payload
            }
        case eFormKhaoSatEditorActionTypeIds.SHOW_MODAL:
            return {
                ...state,
                is_show_modal: action.payload,
                sis_form_khaosat_editor_selected_ids: []
            }
        case eFormKhaoSatEditorActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_form_khaosat_editor_selected_ids: action.payload
            }
        case eFormKhaoSatEditorActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_confirm: true
            }
        case eFormKhaoSatEditorActionTypeIds.DELETE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_deleting
            }
        case eFormKhaoSatEditorActionTypeIds.DELETE_SUCCESS:
            showNotify({ type: "success", message: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_confirm: false
            }
        case eFormKhaoSatEditorActionTypeIds.DELETE_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_confirm: false
            }


        case eFormKhaoSatEditorActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eFormKhaoSatEditorActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case eFormKhaoSatEditorActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_modal: false
            }

            case eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_saving
                }
            case eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_SUCCESS:
                return {
                    ...state,
                    status: ePageBaseStatus.is_need_reload,
                    is_show_modal: false
                }
            case eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_ERR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_modal: false
                }

        case eFormKhaoSatEditorActionTypeIds.CLEAR_STATUS:
            return {
                ...initialState
            }
        default:
            return state;
    }

}
