import { ePageBaseStatus } from '../../../models/ePageBaseStatus';
import { eBaiTapKetQuaLopTypeIds, IBaiTapKetQuaLopActionTypes } from './../../action-types/bai-tap/IBaiTapKetQuaLop';
import { IBaiTapKetQuaLopPageState } from './../../page-state-model/bai-tap/IBaiTapKetQuaLopPageState';
const iniState: IBaiTapKetQuaLopPageState = {
    baiTapKetQuas: [],
    lopBoMonHocSinhs: [],
    status: ePageBaseStatus.is_not_initialization
}
export const baiTapKetQuaLopReducer = (state: IBaiTapKetQuaLopPageState = iniState, action: IBaiTapKetQuaLopActionTypes): IBaiTapKetQuaLopPageState => {
    switch (action.type) {
        case eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                lopBoMonHocSinhs: action.payload
            }
        case eBaiTapKetQuaLopTypeIds.LOAD_HOCSINH_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                baiTapKetQuas: action.payload
            }
        case eBaiTapKetQuaLopTypeIds.LOAD_KETQUA_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        case eBaiTapKetQuaLopTypeIds.SYNC_LMS_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eBaiTapKetQuaLopTypeIds.SYNC_LMS_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eBaiTapKetQuaLopTypeIds.SYNC_LMS_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }

        default:
            return { ...state };
    }
}