import { IAppXinNghiChangePhanHoiRequest } from "../../../models/request/app-phuhuynh/AppXinNghiChangePhanHoiRequest"
import { IAppXinNghiChangeStatusRequest } from "../../../models/request/app-phuhuynh/AppXinNghiChangeStatusRequest"
import { IAppXinNghiSelectRequest } from "../../../models/request/app-phuhuynh/AppXinNghiSelectRequest"
import { app_xinnghi_view } from "../../../models/response/app-phuhuynh/app_xinnghi"

export enum eAppXinNghiActionTypeIds {
    LOAD_START = "APP_XINNGHI_LOAD_START",
    LOAD_SUCCESS = "APP_XINNGHI_LOAD_SUCCESS",
    LOAD_ERROR = "APP_XINNGHI_LOAD_ERROR",

    LOAD_SELECT_START = "APP_XINNGHI_LOAD_SELECT_START",
    LOAD_SELECT_SUCCESS = "APP_XINNGHI_LOAD_SELECT_SUCCESS",
    LOAD_SELECT_ERROR = "APP_XINNGHI_LOAD_SELECT_ERROR",

    LOAD_DETAIL_START = "APP_XINNGHI_LOAD_DETAIL_START",
    LOAD_DETAIL_SUCCESS = "APP_XINNGHI_LOAD_DETAIL_SUCCESS",
    LOAD_DETAIL_ERROR = "APP_XINNGHI_LOAD_DETAIL_ERROR",

    UPDATE_STATUS_START = "APP_XINNGHI_UPDATE_STATUS_START",
    UPDATE_STATUS_SUCCESS = "APP_XINNGHI_UPDATE_STATUS_SUCCESS",
    UPDATE_STATUS_ERROR = "APP_XINNGHI_UPDATE_STATUS_ERROR",

    UPDATE_PHANHOI_START = "APP_XINNGHI_UPDATE_PHANHOI_START",
    UPDATE_PHANHOI_SUCCESS = "APP_XINNGHI_UPDATE_PHANHOI_SUCCESS",
    UPDATE_PHANHOI_ERROR = "APP_XINNGHI_UPDATE_PHANHOI_ERROR",
}

export interface IAppXinNghiLoadStart {
    type: eAppXinNghiActionTypeIds.LOAD_START,
}
export interface IAppXinNghiLoadSuccess {
    type: eAppXinNghiActionTypeIds.LOAD_SUCCESS,
    payload: app_xinnghi_view[]
}
export interface IAppXinNghiLoadErr {
    type: eAppXinNghiActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IAppXinNghiLoadDetailStart {
    type: eAppXinNghiActionTypeIds.LOAD_DETAIL_START,
    payload: number
}
export interface IAppXinNghiLoadDetailSuccess {
    type: eAppXinNghiActionTypeIds.LOAD_DETAIL_SUCCESS,
    payload: app_xinnghi_view
}
export interface IAppXinNghiLoadDetailErr {
    type: eAppXinNghiActionTypeIds.LOAD_DETAIL_ERROR,
    payload: string
}

export interface IAppXinNghiLoadSelectStart {
    type: eAppXinNghiActionTypeIds.LOAD_SELECT_START,
    payload: IAppXinNghiSelectRequest
}
export interface IAppXinNghiLoadSelectSuccess {
    type: eAppXinNghiActionTypeIds.LOAD_SELECT_SUCCESS,
    payload: app_xinnghi_view[]
}
export interface IAppXinNghiLoadSelectErr {
    type: eAppXinNghiActionTypeIds.LOAD_SELECT_ERROR,
    payload: string
}

export interface IAppXinNghiUpdateStatusStart {
    type: eAppXinNghiActionTypeIds.UPDATE_STATUS_START,
    payload: IAppXinNghiChangeStatusRequest
}
export interface IAppXinNghiUpdateStatusSuccess {
    type: eAppXinNghiActionTypeIds.UPDATE_STATUS_SUCCESS,
}
export interface IAppXinNghiUpdateStatusErr {
    type: eAppXinNghiActionTypeIds.UPDATE_STATUS_ERROR,
    payload: string
}

export interface IAppXinNghiUpdatePhanHoiStart {
    type: eAppXinNghiActionTypeIds.UPDATE_PHANHOI_START,
    payload: IAppXinNghiChangePhanHoiRequest
}
export interface IAppXinNghiUpdatePhanHoiSuccess {
    type: eAppXinNghiActionTypeIds.UPDATE_PHANHOI_SUCCESS,
}
export interface IAppXinNghiUpdatePhanHoiErr {
    type: eAppXinNghiActionTypeIds.UPDATE_PHANHOI_ERROR,
    payload: string
}

export type IAppXinNghiActionTypes = IAppXinNghiLoadStart | IAppXinNghiLoadSuccess | IAppXinNghiLoadErr |
    IAppXinNghiLoadSelectStart | IAppXinNghiLoadSelectSuccess | IAppXinNghiLoadSelectErr |
    IAppXinNghiLoadDetailStart | IAppXinNghiLoadDetailSuccess | IAppXinNghiLoadDetailErr |
    IAppXinNghiUpdateStatusStart | IAppXinNghiUpdateStatusSuccess | IAppXinNghiUpdateStatusErr |
    IAppXinNghiUpdatePhanHoiStart | IAppXinNghiUpdatePhanHoiSuccess | IAppXinNghiUpdatePhanHoiErr 