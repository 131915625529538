import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { IBaseResponeModel } from "../../models/response/base-response";
import { eMealActionTypeIds, IMealDeleteStart, IMealLoadStart, IMealSaveStart } from "../action-types/IMealActionTypes";
import { actions } from './../actions/actionsWrapper';

const mealApi = apiWrapper.meal;

export function* mealSaga(): any {
    yield takeLatest(eMealActionTypeIds.LOAD_START, loadMealWorker)
    yield takeEvery(eMealActionTypeIds.DELETE_START, deleteMealWorker)
    yield takeEvery(eMealActionTypeIds.SAVE_START, saveMealWorker)
}
function* loadMealWorker(action: IMealLoadStart): any {

    const res: IBaseResponeModel = yield call([mealApi, mealApi.SelectByTruong], action.payload)
    if (res.is_success) {
        yield put(actions.meal.loadMealSuccess(res.data))
    } else {
        yield put(actions.meal.loadMealError(res.message || "Không tải được bữa ăn"))
    }
}
function* deleteMealWorker(action: IMealDeleteStart): any {
    const res: IBaseResponeModel = yield call([mealApi, mealApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.meal.deleteMealSuccess())
    } else {
        yield put(actions.meal.deleteMealErr(res.message || "Không tải được bữa ăn"))
    }
}
function* saveMealWorker(action: IMealSaveStart): any {
    let res: IBaseResponeModel;
    console.log(action);
    if (action.payload.id > 0) {
        res = yield call([mealApi, mealApi.Update], action.payload);
    } else {
        res = yield call([mealApi, mealApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.meal.saveMealSuccess())
    } else {
        yield put(actions.meal.loadMealError(res.message || "Không lưu được bữa ăn"))
    }
}
