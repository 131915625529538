import { sis_sodiem } from './../../../models/response/so-diem/sis_sodiem';
import { eSoDiemListActionTypeIds } from "../../action-types/so-diem/ISoDiemListAcitonTypes";
import { BaseAction } from "../IBaseActionResult";

export const soDiemListActions = {
    loadStart: (truongId: number) => BaseAction(truongId, eSoDiemListActionTypeIds.LOAD_START),
    loadSuccess: (respone: sis_sodiem[]) => BaseAction(respone, eSoDiemListActionTypeIds.LOAD_SUCCESS),
    loadError: (message: string) => BaseAction(message, eSoDiemListActionTypeIds.LOAD_ERROR),
    showDeleteConfirm: (isShow: boolean) => BaseAction(isShow, eSoDiemListActionTypeIds.SHOW_DELETE_CONFIRM),
    changeEditingData: (data?: sis_sodiem) => BaseAction(data, eSoDiemListActionTypeIds.CHANGE_EDITING_DATA),
    deleteStart: (id: number) => BaseAction(id, eSoDiemListActionTypeIds.DELETE_START),
    deleteSuccess: () => BaseAction(undefined, eSoDiemListActionTypeIds.DELETE_SUCCESS),
    deleteError: (message: string) => BaseAction(message, eSoDiemListActionTypeIds.DELETE_ERROR),
    showDetailModal: (isShow: boolean) => BaseAction(isShow, eSoDiemListActionTypeIds.SHOW_DETAIL_MODAL),
    saveStart: (request: sis_sodiem) => BaseAction(request, eSoDiemListActionTypeIds.SAVE_START),
    saveSuccess: () => BaseAction(undefined, eSoDiemListActionTypeIds.SAVE_SUCCESS),
    saveError: (message: string) => BaseAction(message, eSoDiemListActionTypeIds.SAVE_ERROR),
}