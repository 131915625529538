import { AppYeuCauHoTroSelectRequest } from "../../../models/request/app-phuhuynh/AppYeuCauHoTroSelectRequest";
import { AppYeuCauHoTroUpdateRequest } from "../../../models/request/app-phuhuynh/AppYeuCauHoTroUpdateRequest";
import { AppYeuCauHoTroGroupAddOrUpdateRequest } from "../../../models/request/app-phuhuynh/AppYeuCauHoTroGroupAddOrUpdateRequest";
import { app_yeu_cau_ho_tro } from "../../../models/response/app-phuhuynh/app_yeu_cau_ho_tro";
import { app_yeu_cau_ho_tro_group } from "../../../models/response/app-phuhuynh/app_yeu_cau_ho_tro_group";
import { IAppYeuCauHoTroLoadDetailErr, IAppYeuCauHoTroLoadDetailStart, IAppYeuCauHoTroLoadDetailSuccess, 
    IAppYeuCauHoTroLoadSelectErr, IAppYeuCauHoTroLoadSelectStart, IAppYeuCauHoTroLoadSelectSuccess, 
    IAppYeuCauHoTroUpdatePhanHoiErr, IAppYeuCauHoTroUpdatePhanHoiStart, IAppYeuCauHoTroUpdatePhanHoiSuccess, 
    IAppYeuCauHoTroLoadGroupStart, IAppYeuCauHoTroLoadGroupSuccess, IAppYeuCauHoTroLoadGroupErr, 
    IAppYeuCauHoTroLoadGroupDetailStart, IAppYeuCauHoTroLoadGroupDetailSuccess, IAppYeuCauHoTroLoadGroupDetailErr,
    IAppYeuCauHoTroDeleteGroupStart , IAppYeuCauHoTroDeleteGroupSuccess , IAppYeuCauHoTroDeleteGroupErr , 
    IAppYeuCauHoTroShowGroupDetail, IAppYeuCauHoTroGroupCloseDetail , IAppYeuCauHoTroGroupChangeSelectedIds , 
    IAppYeuCauHoTroGroupShowConfirm , IAppYeuCauHoTroGroupCloseConfirm,
    IAppYeuCauHoTroSaveGroupStart, IAppYeuCauHoTroSaveGroupSuccess, IAppYeuCauHoTroSaveGroupErr,
    eAppYeuCauHoTroActionTypeIds } from "../../action-types/app-phuhuynh/IAppYeuCauHoTroActionTypes";
import { BaseAction } from "../IBaseActionResult";
import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"

export const appYeuCauHoTroActions = {
    loadSelectStart: (request: AppYeuCauHoTroSelectRequest): IAppYeuCauHoTroLoadSelectStart => BaseAction(request, eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_START),
    loadSelectSuccess: (respone: app_yeu_cau_ho_tro[]): IAppYeuCauHoTroLoadSelectSuccess => BaseAction(respone, eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_SUCCESS),
    loadSelectError: (messege: string): IAppYeuCauHoTroLoadSelectErr => BaseAction(messege, eAppYeuCauHoTroActionTypeIds.LOAD_SELECT_ERROR),

    loadDetailStart: (id: number): IAppYeuCauHoTroLoadDetailStart => BaseAction(id, eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_START),
    loadDetailSuccess: (respone: app_yeu_cau_ho_tro): IAppYeuCauHoTroLoadDetailSuccess => BaseAction(respone, eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_SUCCESS),
    loadDetailError: (messege: string): IAppYeuCauHoTroLoadDetailErr => BaseAction(messege, eAppYeuCauHoTroActionTypeIds.LOAD_DETAIL_ERROR),

    updatePhanHoiStart: (data: AppYeuCauHoTroUpdateRequest): IAppYeuCauHoTroUpdatePhanHoiStart => BaseAction(data, eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_START),
    updatePhanHoiSuccess: (): IAppYeuCauHoTroUpdatePhanHoiSuccess => BaseAction(undefined, eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_SUCCESS),
    updatePhanHoiError: (message: string): IAppYeuCauHoTroUpdatePhanHoiErr => BaseAction(message, eAppYeuCauHoTroActionTypeIds.UPDATE_PHANHOI_ERROR),

    loadGroupStart: (dm_truong_id: number): IAppYeuCauHoTroLoadGroupStart => BaseAction(dm_truong_id, eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_START),
    loadGroupSuccess: (respone: app_yeu_cau_ho_tro_group[]): IAppYeuCauHoTroLoadGroupSuccess => BaseAction(respone, eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_SUCCESS),
    loadGroupError: (messege: string): IAppYeuCauHoTroLoadGroupErr => BaseAction(messege, eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_ERROR),

    loadGroupDetailStart: (id: number): IAppYeuCauHoTroLoadGroupDetailStart => BaseAction(id, eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_DETAIL_START),
    loadGroupDetailSuccess: (respone: app_yeu_cau_ho_tro_group): IAppYeuCauHoTroLoadGroupDetailSuccess => BaseAction(respone, eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_DETAIL_SUCCESS),
    loadGroupDetailError: (messege: string): IAppYeuCauHoTroLoadGroupDetailErr => BaseAction(messege, eAppYeuCauHoTroActionTypeIds.LOAD_GROUP_DETAIL_ERROR),

    saveGroupStart: (request: AppYeuCauHoTroGroupAddOrUpdateRequest): IAppYeuCauHoTroSaveGroupStart => BaseAction(request, eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_START),
    saveGroupSuccess: (respone: app_yeu_cau_ho_tro_group): IAppYeuCauHoTroSaveGroupSuccess => BaseAction(respone, eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_SUCCESS),
    saveGroupError: (messege: string): IAppYeuCauHoTroSaveGroupErr => BaseAction(messege, eAppYeuCauHoTroActionTypeIds.SAVE_GROUP_ERROR),
    
    showGroupDetail: (data?: app_yeu_cau_ho_tro_group): IAppYeuCauHoTroShowGroupDetail => BaseAction(data, eAppYeuCauHoTroActionTypeIds.SHOW_GROUP_DETAIL),
    closeGroupDetail: (): IAppYeuCauHoTroGroupCloseDetail => BaseAction(undefined, eAppYeuCauHoTroActionTypeIds.CLOSE_GROUP_DETAIL),
    showGroupConfirm: (): IAppYeuCauHoTroGroupShowConfirm => BaseAction(undefined, eAppYeuCauHoTroActionTypeIds.SHOW_GROUP_CONFIRM),
    closeGroupConfirm: (): IAppYeuCauHoTroGroupCloseConfirm => BaseAction(undefined, eAppYeuCauHoTroActionTypeIds.CLOSE_GROUP_CONFIRM),
    changeGroupSelectedIds: (ids: number[]): IAppYeuCauHoTroGroupChangeSelectedIds => BaseAction(ids, eAppYeuCauHoTroActionTypeIds.CHANGE_GROUP_SELECTED_IDS),
    deleteGroupStart: (ids: IDeleteMultipleBaseRequest): IAppYeuCauHoTroDeleteGroupStart => BaseAction(ids, eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_START),
    deleteGroupSuccess: (): IAppYeuCauHoTroDeleteGroupSuccess => BaseAction(undefined, eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_SUCCESS),
    deleteGroupError: (message: string): IAppYeuCauHoTroDeleteGroupErr => BaseAction(message, eAppYeuCauHoTroActionTypeIds.DELETE_GROUP_ERROR),
    
    //SAVE
}