import { sis_tokhoi } from "../models/response/to-khoi/sis_tokhoi";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_TO_KHOI_PATH = "to-khoi"

class ToKhoiApi extends BaseCRUDApi<sis_tokhoi> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectAllView() {
        return apiClient.get(`${this._path}/select-all-view`)
    }
}
export const toKhoiApi = new ToKhoiApi({ path: API_TO_KHOI_PATH });

