import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eAppNhomBaiVietActionTypeIds, IAppNhomBaiVietDeleteStart, IAppNhomBaiVietLoadByTruongStart, IAppNhomBaiVietSaveStart } from "../../action-types/bai-viet/IAppNhomBaiVietActionTypes";
import { actions } from "../../actions/actionsWrapper";

const appNhomBaiVietApi = apiWrapper.appNhomBaiViet;

export function* appNhomBaiVietSaga(): any {
    yield takeLatest(eAppNhomBaiVietActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eAppNhomBaiVietActionTypeIds.LOAD_BY_TRUONG_START, loadByTruongWorker)
    yield takeEvery(eAppNhomBaiVietActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eAppNhomBaiVietActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([appNhomBaiVietApi, appNhomBaiVietApi.SelectAll])
    if (res.is_success) {
        yield put(actions.baiVietNhom.loadSuccess(res.data))
    } else {
        yield put(actions.baiVietNhom.loadError(res.message || "Không tải được nhóm bài viết"))
    }
}
function* loadByTruongWorker(action: IAppNhomBaiVietLoadByTruongStart): any {

    const res: IBaseResponeModel = yield call([appNhomBaiVietApi, appNhomBaiVietApi.SelectByTruongAsync], action.payload)
    if (res.is_success) {
        yield put(actions.baiVietNhom.loadByTruongSuccess(res.data))
    } else {
        yield put(actions.baiVietNhom.loadByTruongError(res.message || "Không tải được nhóm bài viết"))
    }
}
function* deleteWorker(action: IAppNhomBaiVietDeleteStart): any {
    const res: IBaseResponeModel = yield call([appNhomBaiVietApi, appNhomBaiVietApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.baiVietNhom.deleteSuccess())
    } else {
        yield put(actions.baiVietNhom.deleteError(res.message || "Không xóa được nhóm bài viết"))
    }
}
function* saveWorker(action: IAppNhomBaiVietSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([appNhomBaiVietApi, appNhomBaiVietApi.Update], action.payload);
    } else {
        res = yield call([appNhomBaiVietApi, appNhomBaiVietApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.baiVietNhom.saveSuccess())
    } else {
        yield put(actions.baiVietNhom.saveError(res.message || "Không lưu được nhóm bài viết"))
    }
}