import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest"
import { IMonHocDangKyUpdateRequest } from "../../../models/request/dangky-monhoc/IDotDangKySelectRequest";
import { sis_pl_dotdangky_monhoc } from "../../../models/response/dangky-monhoc/sis_pl_dotdangky_monhoc"

export enum eDotDangKyMonHocActionTypeIds {
    LOAD_START = "DotDangKyMonHoc_LOAD_START",
    LOAD_SUCCESS = "DotDangKyMonHoc_LOAD_SUCCESS",
    LOAD_ERROR = "DotDangKyMonHoc_LOAD_ERROR",

    SHOW_DETAIL = "DotDangKyMonHoc_SHOW_DETAIL",
    CLOSE_DETAIL = "DotDangKyMonHoc_CLOSE_DETAIL",

    CHANGE_SELECTED_IDS = "DotDangKyMonHoc_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "DotDangKyMonHoc_SHOW_CONFIRM",
    CLOSE_CONFIRM = "DotDangKyMonHoc_CLOSE_CONFIRM",

    DELETE_START = "DotDangKyMonHoc_DELETE_START",
    DELETE_SUCCESS = "DotDangKyMonHoc_DELETE_SUCCESS",
    DELETE_ERROR = "DotDangKyMonHoc_DELETE_ERROR",

    SAVE_START = "DotDangKyMonHoc_SAVE_START",
    SAVE_SUCCESS = "DotDangKyMonHoc_SAVE_SUCCESS",
    SAVE_ERR = "DotDangKyMonHoc_SAVE_ERR",

    ADDMONHOC_START = "DotDangKyMonHoc_ADDMONHOC_START",
    ADDMONHOC_SUCCESS = "DotDangKyMonHoc_ADDMONHOC_SUCCESS",
    ADDMONHOC_ERR = "DotDangKyMonHoc_ADDMONHOC_ERR"
}

export interface IDotDangKyMonHocLoadStart {
    type: eDotDangKyMonHocActionTypeIds.LOAD_START,
    payload: number;
}
export interface IDotDangKyMonHocLoadSuccess {
    type: eDotDangKyMonHocActionTypeIds.LOAD_SUCCESS,
    payload: sis_pl_dotdangky_monhoc[]
}
export interface IDotDangKyMonHocLoadErr {
    type: eDotDangKyMonHocActionTypeIds.LOAD_ERROR,
    payload: string
}

export interface IDotDangKyMonHocShowDetail {
    type: eDotDangKyMonHocActionTypeIds.SHOW_DETAIL,
    payload?: sis_pl_dotdangky_monhoc
}
export interface IDotDangKyMonHocCloseDetail {
    type: eDotDangKyMonHocActionTypeIds.CLOSE_DETAIL,
}
export interface IDotDangKyMonHocChangeSelectedIds {
    type: eDotDangKyMonHocActionTypeIds.CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface IDotDangKyMonHocShowConfirm {
    type: eDotDangKyMonHocActionTypeIds.SHOW_CONFIRM
}
export interface IDotDangKyMonHocCloseConfirm {
    type: eDotDangKyMonHocActionTypeIds.CLOSE_CONFIRM
}

export interface IDotDangKyMonHocDeleteStart {
    type: eDotDangKyMonHocActionTypeIds.DELETE_START,
    payload: IDeleteMultipleBaseRequest
}
export interface IDotDangKyMonHocDeleteSuccess {
    type: eDotDangKyMonHocActionTypeIds.DELETE_SUCCESS,
}
export interface IDotDangKyMonHocDeleteErr {
    type: eDotDangKyMonHocActionTypeIds.DELETE_ERROR,
    payload: string
}
export interface IDotDangKyMonHocSaveStart {
    type: eDotDangKyMonHocActionTypeIds.SAVE_START,
    payload: sis_pl_dotdangky_monhoc
}
export interface IDotDangKyMonHocSaveSuccess {
    type: eDotDangKyMonHocActionTypeIds.SAVE_SUCCESS
}
export interface IDotDangKyMonHocSaveErr {
    type: eDotDangKyMonHocActionTypeIds.SAVE_ERR,
    payload: string
}
export interface IDotDangKyMonHocADDMONHOCStart {
    type: eDotDangKyMonHocActionTypeIds.ADDMONHOC_START,
    payload: IMonHocDangKyUpdateRequest
}
export interface IDotDangKyMonHocADDMONHOCSuccess {
    type: eDotDangKyMonHocActionTypeIds.ADDMONHOC_SUCCESS
}
export interface IDotDangKyMonHocADDMONHOCErr {
    type: eDotDangKyMonHocActionTypeIds.ADDMONHOC_ERR,
    payload: string
}
export type IDotDangKyMonHocActionTypes = IDotDangKyMonHocLoadStart | IDotDangKyMonHocLoadSuccess | IDotDangKyMonHocLoadErr |
    IDotDangKyMonHocShowDetail | IDotDangKyMonHocCloseDetail |
    IDotDangKyMonHocChangeSelectedIds |
    IDotDangKyMonHocShowConfirm | IDotDangKyMonHocCloseConfirm |
    IDotDangKyMonHocDeleteStart | IDotDangKyMonHocDeleteSuccess | IDotDangKyMonHocDeleteErr |
    IDotDangKyMonHocSaveStart | IDotDangKyMonHocSaveSuccess | IDotDangKyMonHocSaveErr |
    IDotDangKyMonHocADDMONHOCStart | IDotDangKyMonHocADDMONHOCSuccess | IDotDangKyMonHocADDMONHOCErr