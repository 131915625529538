import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { actions } from '../../actions/actionsWrapper';
import { apiWrapper } from './../../../api/apiWrapper';
import { eLopBoMonSoDiemActionTypeIds, ILopBoMonSoDiemLoadStart, ILopBoMonUpdateSoNhanXetDinhKyStart, ILopBoMonUpdateSoDiemStart, ILopBoMonUpdateSoNhanXetThuongXuyenStart } from './../../action-types/lop-bo-mon/ILopBoMonSoDiemActionTypes';

export function* lopBoMonSoDiemSaga(): any {
    yield takeLatest(eLopBoMonSoDiemActionTypeIds.LOAD_START, loadWorker)
    yield takeEvery(eLopBoMonSoDiemActionTypeIds.UPDATE_SODIEM_START, saveWorker)
    yield takeEvery(eLopBoMonSoDiemActionTypeIds.UPDATE_DGDK_HOCTAP_START, saveSoNhanXetDinhKyWorker)
    yield takeEvery(eLopBoMonSoDiemActionTypeIds.UPDATE_DGTX_HOCTAP_START, saveSoNhanXetThuongXuyenWorker)

}

function* loadWorker(data: ILopBoMonSoDiemLoadStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.Select], data.payload)
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMoSoDiem.loadSuccess(res.data))
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMoSoDiem.loadError(res.message ?? ""))
    }
}


function* saveWorker(data: ILopBoMonUpdateSoDiemStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.UpdateSoDiem], data.payload)
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMoSoDiem.updateSoDiemSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMoSoDiem.updateSoDiemError(res.message ?? ""))
    }
}

function* saveSoNhanXetDinhKyWorker(data: ILopBoMonUpdateSoNhanXetDinhKyStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.UpdateSoNhanXetDinhKy], data.payload)
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMoSoDiem.updateSoNhanXetDinhKySuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMoSoDiem.updateSoNhanXetDinhKyError(res.message ?? ""))
    }
}

function* saveSoNhanXetThuongXuyenWorker(data: ILopBoMonUpdateSoNhanXetThuongXuyenStart): any {
    const res: IBaseResponeModel = yield call([apiWrapper.lopBoMon, apiWrapper.lopBoMon.UpdateSoNhanXetThuongXuyen], data.payload)
    if (res.is_success) {
        yield put(actions.lopBoMonWrapper.lopBoMoSoDiem.updateSoNhanXetThuongXuyenSuccess())
    } else {
        yield put(actions.lopBoMonWrapper.lopBoMoSoDiem.updateSoNhanXetThuongXuyenError(res.message ?? ""))
    }
}