import { ILoginSSORequestModel } from "../../models/request/account";
import { eAccountActionTypeIds, ILoginErrorAction, ILoginStartAction, ILoginSuccessAction, ILogOutAction } from "../action-types/IAccountActionTypes";
export const accountActions = {
    loginStart: (data: ILoginSSORequestModel): ILoginStartAction => {
        return {
            type: eAccountActionTypeIds.LOGIN_START,
            payload: data
        }
    },
    loginSuccess: (): ILoginSuccessAction => {
        return {
            type: eAccountActionTypeIds.LOGIN_SUCCESS
        }
    },
    loginError: (data?: string): ILoginErrorAction => {
        return {
            type: eAccountActionTypeIds.LOGIN_ERROR,
            payload: data
        }
    },
    logOut: (): ILogOutAction => {
        return {
            type: eAccountActionTypeIds.LOG_OUT
        }
    }
}

