import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eThoiKhoaBieuActionTypeIds, IThoiKhoaBieuDeleteStart, IThoiKhoaBieuLoadPreviewByLopErr, IThoiKhoaBieuLoadPreviewByLopStart, IThoiKhoaBieuLoadPreviewByLopSuccess, IThoiKhoaBieuSaveStart } from "../../action-types/thoi-khoa-bieu/IThoiKhoaBieuActionTypes";
import { actions } from "../../actions/actionsWrapper";

const thoiKhoaBieuApi = apiWrapper.thoiKhoaBieu;

export function* thoiKhoaBieuSaga(): any {
    yield takeLatest(eThoiKhoaBieuActionTypeIds.LOAD_START, loadWorker)
    yield takeLatest(eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_START, loadPreviewByLopWorker)
    yield takeEvery(eThoiKhoaBieuActionTypeIds.DELETE_START, deleteWorker)
    yield takeEvery(eThoiKhoaBieuActionTypeIds.SAVE_START, saveWorker)
}
function* loadWorker(): any {

    const res: IBaseResponeModel = yield call([thoiKhoaBieuApi, thoiKhoaBieuApi.SelectAll])
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.loadSuccess(res.data))
    } else {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.loadError(res.message || "Không tải được thời khóa biểu"))
    }
}
function* loadPreviewByLopWorker(data: IThoiKhoaBieuLoadPreviewByLopStart): any {
    const res: IBaseResponeModel = yield call([thoiKhoaBieuApi, thoiKhoaBieuApi.PreviewByLop], data.payload)
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.loadPreviewByLopSuccess(res.data))
    } else {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.loadPreviewByLopError(res.message || "Không tải được thời khóa biểu"))
    }
}
function* deleteWorker(action: IThoiKhoaBieuDeleteStart): any {
    // console.log('deleteWorker', action);
    const res: IBaseResponeModel = yield call([thoiKhoaBieuApi, thoiKhoaBieuApi.DeleteMultiple], action.payload)
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.deleteSuccess())
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.loadStart())
    } else {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.deleteError(res.message || "Không xóa được thời khóa biểu"))
    }
}
function* saveWorker(action: IThoiKhoaBieuSaveStart): any {
    let res: IBaseResponeModel;
    if (action.payload.id > 0) {
        res = yield call([thoiKhoaBieuApi, thoiKhoaBieuApi.Update], action.payload);
    } else {
        res = yield call([thoiKhoaBieuApi, thoiKhoaBieuApi.Insert], action.payload);
    }
    if (res.is_success) {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.saveSuccess())
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.loadStart())
    } else {
        yield put(actions.thoiKhoaBieuWrapper.thoiKhoaBieu.saveError(res.message || "Không lưu được thời khóa biểu"))
    }
}