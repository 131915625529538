import { sis_thangdiem_option } from '../models/response/so-diem/sis_thangdiem_option';
import { sis_thangdiem } from './../models/response/so-diem/sis_thangdiem';
import { apiClient } from './apiClient';
import { BaseCRUDApi } from "./baseCRUDApi";

export const THANG_DIEM_API = "thang-diem"

class ThangDiemApi extends BaseCRUDApi<sis_thangdiem>{
    constructor(path: string) {
        super(path)
    }
    LoadOptions(request: number) {
        return apiClient.get(`thang-diem/${request}/option`)
    }
    AddOption(request: sis_thangdiem_option) {
        return apiClient.post(`thang-diem-option`,request)
    }
    UpdateOption(request: sis_thangdiem_option) {
        return apiClient.put(`thang-diem-option`,request)
    }
    DeleteOption(request: number) {
        return apiClient.delete(`thang-diem-option/${request}`)
    }
}
export const thangDiemApi = new ThangDiemApi('thang-diem')