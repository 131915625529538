import { GiaoVienSelectByToKhoiRequest } from "../models/request/category/giaoVienSelectByToKhoiRequest";
import { GiaoVienTruongModifyRequest } from "../models/request/giao-vien/GiaoVienTruongModifyRequest";
import { IGiaoVienImportDsRequest } from "../models/request/giao-vien/GiaoVienImportDsRequest";
import { sis_giaovien } from "../models/response/giao-vien/sis_giaovien";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_GIAO_VIEN_PATH = "giao-vien"

class GiaoVienApi extends BaseCRUDApi<sis_giaovien> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    SelectByToKhoi(request: GiaoVienSelectByToKhoiRequest) {
        return apiClient.post(`${this._path}/select`, request)
    }
    SelectAllView() {
        return apiClient.get(`${this._path}/selectallview`)
    }
    SelectByTruongAsync(dm_truong_id: number) {
        return apiClient.get(`${this._path}/select-by-truong/${dm_truong_id}`)
    }
    LoadFromExcel(request: FormData) {
        return apiClient.upload(`${this._path}/import/load-from-excel`, request)
    }
    SaveDataExcel(request: IGiaoVienImportDsRequest) {
        return apiClient.post(`${this._path}/import/save-data-excel`, request)
    }
}
export const giaoVienApi = new GiaoVienApi({ path: API_GIAO_VIEN_PATH });

