import { NotifyHelper } from './../../../helpers/toast';
import { eThanhPhanDiemActionTypeIds, IThanhPhanDiemActionTypes } from './../../action-types/so-diem/IThanhPhanDiemAction';
import { IThanhPhanDiemStateModel } from "../../page-state-model/so-diem/IThanhPhanDiemStateModel";
import { ePageBaseStatus } from '../../../models/ePageBaseStatus';

const iniState: IThanhPhanDiemStateModel = {
    status: ePageBaseStatus.is_not_initialization,
    thanhPhanDiems: []
}

export const thanhPhanDiemReducer =
    (state: IThanhPhanDiemStateModel = iniState, action: IThanhPhanDiemActionTypes): IThanhPhanDiemStateModel => {
        switch (action.type) {
            case eThanhPhanDiemActionTypeIds.LOAD_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_loading
                }
            case eThanhPhanDiemActionTypeIds.LOAD_SUCCESS:
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    thanhPhanDiems: action.payload
                }
            case eThanhPhanDiemActionTypeIds.LOAD_ERRROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed
                }

            default:
                return {
                    ...state
                }
        }
    }