import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { IDotDangKyKetQuaActionTypes, eDotDangKyKetQuaActionTypeIds } from "../../action-types/dangky-monhoc/IDotDangKyKetQuaActionTypes"
import { IDotDangKyKetQuaState } from "../../page-state-model/dangky-monhoc/IDotDangKyKetQuaState"

const initialState: IDotDangKyKetQuaState = {
    status: ePageBaseStatus.is_not_initialization,
    hocsinh_ketquas: [],
    ketquas: [],
    ketqua_chitiets: []
}

export const dotDangKyKetQuaReducer = (state: IDotDangKyKetQuaState = initialState, action: IDotDangKyKetQuaActionTypes): IDotDangKyKetQuaState => {
    switch (action.type) {
        case eDotDangKyKetQuaActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDotDangKyKetQuaActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ketquas: action.payload
            }
        case eDotDangKyKetQuaActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ketquas: []
            }
        case eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                hocsinh_ketquas: action.payload
            }
        case eDotDangKyKetQuaActionTypeIds.LOAD_HOCSINH_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                hocsinh_ketquas: []
            }
        case eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ketqua_chitiets: action.payload
            }
        case eDotDangKyKetQuaActionTypeIds.LOAD_CHITIET_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                ketqua_chitiets: []
            }
        case eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_SUCCESS:
            NotifyHelper.Success("Đăng ký môn học giúp học sinh thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_need_reload,
            }
        case eDotDangKyKetQuaActionTypeIds.DANGKY_HOCSINH_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return state;
    }

}