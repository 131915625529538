import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eThoiKhoaBieuActionTypeIds, IThoiKhoaBieuActionTypes } from "../../action-types/thoi-khoa-bieu/IThoiKhoaBieuActionTypes"
import { IThoiKhoaBieuPageState } from "../../page-state-model/thoi-khoa-bieu/IThoiKhoaBieuPageState"

const initialState: IThoiKhoaBieuPageState = {
    status: ePageBaseStatus.is_not_initialization,
    pl_tkbs: [],
    pl_tkb_selected_ids: [],
    pl_tkb_editing: undefined,
    is_show_delete_confirm: false,
    is_show_detail_modal: false,
    previewListItems: []
}

export const thoiKhoaBieuReducer = (state: IThoiKhoaBieuPageState = initialState, action: IThoiKhoaBieuActionTypes): IThoiKhoaBieuPageState => {
    switch (action.type) {
        case eThoiKhoaBieuActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eThoiKhoaBieuActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_tkbs: action.payload
            }
        case eThoiKhoaBieuActionTypeIds.LOAD_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                pl_tkbs: []
            }
        case eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                previewListItems: action.payload
            }
        case eThoiKhoaBieuActionTypeIds.LOAD_PREVIEW_BYLOP_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                previewListItems: []
            }
        case eThoiKhoaBieuActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                is_show_detail_modal: true,
                pl_tkb_editing: action.payload
            }
        case eThoiKhoaBieuActionTypeIds.CLOSE_DETAIL:
            return {
                ...state,
                is_show_detail_modal: false,
                pl_tkb_editing: undefined
            }
        case eThoiKhoaBieuActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                pl_tkb_selected_ids: action.payload
            }
        case eThoiKhoaBieuActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case eThoiKhoaBieuActionTypeIds.CLOSE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        
            case eThoiKhoaBieuActionTypeIds.DELETE_START:
                return {
                    ...state,
                    status: ePageBaseStatus.is_deleting
                }
            case eThoiKhoaBieuActionTypeIds.DELETE_SUCCESS:
                NotifyHelper.Success("Xoá dữ liệu thành công.")
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }
            case eThoiKhoaBieuActionTypeIds.DELETE_ERROR:
                NotifyHelper.Error(action.payload)
                return {
                    ...state,
                    status: ePageBaseStatus.is_completed,
                    is_show_delete_confirm:false
                }

        
            case eThoiKhoaBieuActionTypeIds.SAVE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_saving
            }
        case eThoiKhoaBieuActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success("Thay đổi dữ liệu thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }
        case eThoiKhoaBieuActionTypeIds.SAVE_ERR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                is_show_detail_modal:false
            }

        default:
            return state;
    }

}