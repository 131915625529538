import { IDeleteMultipleBaseRequest } from "../../../models/request/base/deleteRequest";
import { sis_form_khaosat_editor } from "../../../models/response/form-khaosat/sis_form_khaosat_editor";
import { sis_form_khaosat_editor_type } from "../../../models/response/form-khaosat/sis_form_khaosat_editor_type";
import { IActionTypeBase } from "../IActionTypeBase";

export enum eFormKhaoSatEditorActionTypeIds {
    LOAD_START = "FORMKHAOSATEDITOR_LOAD_START",
    LOAD_SUCCESS = "FORMKHAOSATEDITOR_LOAD_SUCCESS",
    LOAD_ERROR = "FORMKHAOSATEDITOR_LOAD_ERROR",

    LOAD_BYFORM_START = "FORMKHAOSATEDITOR_LOAD_BYFORM_START",
    LOAD_BYFORM_SUCCESS = "FORMKHAOSATEDITOR_LOAD_BYFORM_SUCCESS",
    LOAD_BYFORM_ERROR = "FORMKHAOSATEDITOR_LOAD_BYFORM_ERROR",

    LOAD_EDITORTYPE_START = "FORMKHAOSATEDITOR_LOAD_EDITORTYPE_START",
    LOAD_EDITORTYPE_SUCCESS = "FORMKHAOSATEDITOR_LOAD_EDITORTYPE_SUCCESS",
    LOAD_EDITORTYPE_ERROR = "FORMKHAOSATEDITOR_LOAD_EDITORTYPE_ERROR",

    SHOW_MODAL = "FORMKHAOSATEDITOR_SHOW_MODAL",
    CHANGE_DETAIL = "FORMKHAOSATEDITOR_CHANGE_DETAIL",

    CHANGE_SELECTED_IDS = "FORMKHAOSATEDITOR_CHANGE_SELECTED_IDS",

    SHOW_CONFIRM = "FORMKHAOSATEDITOR_SHOW_CONFIRM",

    DELETE_START = "FORMKHAOSATEDITOR_DELETE_START",
    DELETE_SUCCESS = "FORMKHAOSATEDITOR_DELETE_SUCCESS",
    DELETE_ERROR = "FORMKHAOSATEDITOR_DELETE_ERROR",

    SAVE_START = "FORMKHAOSATEDITOR_SAVE_START",
    SAVE_SUCCESS = "FORMKHAOSATEDITOR_SAVE_SUCCESS",
    SAVE_ERR = "FORMKHAOSATEDITOR_SAVE_ERR",

    UPDATE_SELECT_OPTIONS_START = "FORMKHAOSATEDITOR_UPDATE_SELECT_OPTIONS_START",
    UPDATE_SELECT_OPTIONS_SUCCESS = "FORMKHAOSATEDITOR_UPDATE_SELECT_OPTIONS_SUCCESS",
    UPDATE_SELECT_OPTIONS_ERR = "FORMKHAOSATEDITOR_UPDATE_SELECT_OPTIONS_ERR",

    CLEAR_STATUS = "FORMKHAOSATEDITOR_CLEAR_STATUS",
}
//ORIGIN MODEL
export interface IFormKhaoSatEditorLoadStart extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.LOAD_START, undefined> { }
export interface IFormKhaoSatEditorLoadSuccess extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.LOAD_SUCCESS, sis_form_khaosat_editor[]> { }
export interface IFormKhaoSatEditorLoadErr extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.LOAD_ERROR, string> { }

export interface IFormKhaoSatEditorLoadByFormStart extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.LOAD_BYFORM_START, { dm_truong_id: number }> { }
export interface IFormKhaoSatEditorLoadByFormSuccess extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.LOAD_BYFORM_SUCCESS, sis_form_khaosat_editor[]> { }
export interface IFormKhaoSatEditorLoadByFormErr extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.LOAD_BYFORM_ERROR, string> { }

export interface IFormKhaoSatEditorLoadEditorTypeStart extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_START, undefined> { }
export interface IFormKhaoSatEditorLoadEditorTypeSuccess extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_SUCCESS, sis_form_khaosat_editor_type[]> { }
export interface IFormKhaoSatEditorLoadEditorTypeErr extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.LOAD_EDITORTYPE_ERROR, string> { }

//DETAIL
export interface IFormKhaoSatEditorChangeDetail extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.CHANGE_DETAIL, sis_form_khaosat_editor | undefined> { }
export interface IFormKhaoSatEditorChangeSelectedIds extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
//CONFIRM
export interface IFormKhaoSatEditorShowModal extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.SHOW_MODAL, boolean> { }
export interface IFormKhaoSatEditorShowConfirm extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.SHOW_CONFIRM, boolean> { }
//DELETE
export interface IFormKhaoSatEditorDeleteStart extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.DELETE_START, IDeleteMultipleBaseRequest> { }
export interface IFormKhaoSatEditorDeleteSuccess extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.DELETE_SUCCESS, undefined> { }
export interface IFormKhaoSatEditorDeleteErr extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.DELETE_ERROR, string> { }
//SAVE
export interface IFormKhaoSatEditorSaveStart extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.SAVE_START, sis_form_khaosat_editor> { }
export interface IFormKhaoSatEditorSaveSuccess extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.SAVE_SUCCESS, undefined> { }
export interface IFormKhaoSatEditorSaveErr extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.SAVE_ERR, string> { }

export interface IFormKhaoSatEditorUpdateSelectOptionsStart extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_START, { sis_form_khaosat_editor_id: number, select_options: string }> { }
export interface IFormKhaoSatEditorUpdateSelectOptionsSuccess extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_SUCCESS, undefined> { }
export interface IFormKhaoSatEditorUpdateSelectOptionsErr extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.UPDATE_SELECT_OPTIONS_ERR, string> { }

export interface IFormKhaoSatEditorClearStatus extends IActionTypeBase<eFormKhaoSatEditorActionTypeIds.CLEAR_STATUS, undefined> { }

export type IFormKhaoSatEditorActionTypes =
    IFormKhaoSatEditorLoadStart | IFormKhaoSatEditorLoadSuccess | IFormKhaoSatEditorLoadErr |
    IFormKhaoSatEditorChangeDetail |
    IFormKhaoSatEditorChangeSelectedIds | IFormKhaoSatEditorShowModal |
    IFormKhaoSatEditorShowConfirm |
    IFormKhaoSatEditorDeleteStart | IFormKhaoSatEditorDeleteSuccess | IFormKhaoSatEditorDeleteErr |
    IFormKhaoSatEditorSaveStart | IFormKhaoSatEditorSaveSuccess | IFormKhaoSatEditorSaveErr |
    IFormKhaoSatEditorUpdateSelectOptionsStart | IFormKhaoSatEditorUpdateSelectOptionsSuccess | IFormKhaoSatEditorUpdateSelectOptionsErr |
    IFormKhaoSatEditorLoadByFormStart | IFormKhaoSatEditorLoadByFormSuccess | IFormKhaoSatEditorLoadByFormErr  |
    IFormKhaoSatEditorClearStatus | 
    IFormKhaoSatEditorLoadEditorTypeStart |IFormKhaoSatEditorLoadEditorTypeSuccess |IFormKhaoSatEditorLoadEditorTypeErr 