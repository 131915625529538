import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../../api/apiWrapper";
import { IBaseResponeModel } from "../../../models/response/base-response";
import { eDiemDanhAnActionTypeIds, IDiemDanhAnLopChuNhiemStart, IDiemDanhAnSelectConfigStart, IDiemDanhAnSelectLopChuNhiemStart 
} from "../../action-types/diem-danh-an/IDiemDanhAnActionTypes";

import { actions } from "../../actions/actionsWrapper";

const diemDanhAnApi = apiWrapper.diemDanhAn;

export function* diemDanhAnSaga(): any {
    yield takeLatest(eDiemDanhAnActionTypeIds.SELECT_LOPCHUNHIEM_START, selectAnLopChuNhiemWorker)
    yield takeEvery(eDiemDanhAnActionTypeIds.DIEMDANH_AN_LOPCHUNHIEM_START, diemDanhLopChuNhiemWorker)
    yield takeLatest(eDiemDanhAnActionTypeIds.SELECT_CONFIG_START, selectConfifWorker)
}


function* selectAnLopChuNhiemWorker(data: IDiemDanhAnSelectLopChuNhiemStart): any {
    const res: IBaseResponeModel = yield call([diemDanhAnApi, diemDanhAnApi.SelectByLopChuNhiemAsync], data.payload)
    if (res.is_success) {
        yield put(actions.diemDanhAn.selectLopChuNhiemSuccess(res.data))
    } else {
        yield put(actions.diemDanhAn.selectLopChuNhiemError(res.message || "Không tải được danh sách học sinh"))
    }
}
function* diemDanhLopChuNhiemWorker(action: IDiemDanhAnLopChuNhiemStart): any {
    console.log(action);
    const res: IBaseResponeModel = yield call([diemDanhAnApi, diemDanhAnApi.DiemDanhLopChuNhiemAsync], action.payload)
    if (res.is_success) {
        yield put(actions.diemDanhAn.diemDanhLopChuNhiemSuccess())
        yield put(actions.diemDanhAn.showDiemDanhModal(false))
    } else {
        yield put(actions.diemDanhAn.diemDanhLopChuNhiemError(res.message || "Không lưu được điểm danh"))
        yield put(actions.diemDanhAn.showDiemDanhModal(false))
    }
}

function* selectConfifWorker(data: IDiemDanhAnSelectConfigStart): any {
    const res: IBaseResponeModel = yield call([diemDanhAnApi, diemDanhAnApi.SelectLopDiemDanhAsync], data.payload)
    if (res && res.is_success) {
        yield put(actions.diemDanhAn.selectConfigSuccess(res.data))
    }else {
        yield put(actions.diemDanhAn.selectConfigError(res.message || "Không tìm thấy lớp điểm danh"))
    }
}