// console.log(process.env)

interface IAppInfo {
    baseApiURL: string
    homeURL: string,
    importTKBFileUrl: string,
    subSytemId: number,
    versionDataGridStorageKey?: string,
}

const appInfo: IAppInfo = {
    baseApiURL: process.env.REACT_APP_API_BASE_URL?.toString() || "",// 'https://phenikaa-finance.3s.edu.vn/api',
    homeURL: process.env.REACT_APP_PORTAL_URL?.toString() || "", //'https://portal.3s.edu.vn',
    importTKBFileUrl: "https://docs.google.com/spreadsheets/d/1PcwgHeAg96axlc2vAsXFMJyuyDSgk4Iu/edit?usp=sharing&ouid=111169258709356213204&rtpof=true&sd=true",
    subSytemId: 11,
    versionDataGridStorageKey: process.env.DATA_GRID_STOREGE_VERSION?.toString() || "",
};
export { appInfo }

