import { NotifyHelper } from './../../../helpers/toast';
import { IBaiTapType } from './../../../models/response/bai-tap/IBaiTapType';
import { eBaiTapTypeActionIds } from '../../action-types/bai-tap/IBaiTapTypeActionType';
import { baseAction } from "../IActionBase";

export const baiTapTypeActions = {
    loadStart: () => baseAction(eBaiTapTypeActionIds.LOAD_START, undefined),
    loadSuccess: (data: IBaiTapType[]) => baseAction(eBaiTapTypeActionIds.LOAD_SUCCESS, data),
    loadError: (message: string) => {
        NotifyHelper.Error(message)
        return baseAction(eBaiTapTypeActionIds.LOAD_ERROR, message)
    }
}