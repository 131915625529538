import { NotifyHelper } from "../../../helpers/toast"
import { ePageBaseStatus } from "../../../models/ePageBaseStatus"
import { eUploadActionTypeIds, IUploadActionTypes } from "../../action-types/upload-file/IUploadActionTypes"
import { IUploadResultState } from "../../page-state-model/upload-file/IUploadResultState"

const initialState: IUploadResultState = {
    status: ePageBaseStatus.is_not_initialization,
    readExcelResult: undefined,
    uploadExcelResult: undefined,
    uploadImageResult: undefined
}
const uploadReducer = (state: IUploadResultState = initialState, action: IUploadActionTypes): IUploadResultState => {
    switch (action.type) {
        case eUploadActionTypeIds.UPLOAD_EXCEL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
            }
        case eUploadActionTypeIds.UPLOAD_EXCEL_SUCCESS:
            NotifyHelper.Success("Upload thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                uploadExcelResult: action.payload
            }
        case eUploadActionTypeIds.UPLOAD_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eUploadActionTypeIds.UPLOAD_SVG_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
            }
        case eUploadActionTypeIds.UPLOAD_SVG_SUCCESS:
            NotifyHelper.Success("Upload thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                uploadImageResult: action.payload
            }
        case eUploadActionTypeIds.UPLOAD_SVG_COMPLETE:
            return {
                ...state,
                status: ePageBaseStatus.is_not_initialization,
                uploadImageResult: undefined
            }
        case eUploadActionTypeIds.UPLOAD_SVG_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eUploadActionTypeIds.UPLOAD_IMAGE_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
            }
        case eUploadActionTypeIds.UPLOAD_IMAGE_SUCCESS:
            NotifyHelper.Success("Upload ảnh thành công.")
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                uploadImageResult: action.payload
            }
        case eUploadActionTypeIds.UPLOAD_IMAGE_COMPLETE:
            return {
                ...state,
                status: ePageBaseStatus.is_not_initialization,
                uploadImageResult: undefined
            }
        case eUploadActionTypeIds.UPLOAD_IMAGE_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        case eUploadActionTypeIds.READ_EXCEL_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading,
            }
        case eUploadActionTypeIds.READ_EXCEL_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                readExcelResult: action.payload
            }
        case eUploadActionTypeIds.READ_EXCEL_ERROR:
            NotifyHelper.Error(action.payload)
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
            }
        default:
            return state;
    }
}
export { uploadReducer }