import { IMonHocSoDiemActionTypes } from '../../action-types/so-diem/IMonHocSoDiem';
import { ePageBaseStatus } from './../../../models/ePageBaseStatus';
import { eMonHocSoDiemActionIds } from './../../action-types/so-diem/IMonHocSoDiem';
import { IMonHocSoDiemPagteState } from './../../page-state-model/so-diem/IMonHocSoDiemPageState';
const iniState: IMonHocSoDiemPagteState = {
    monHocSoDiems: [],
    status: ePageBaseStatus.is_not_initialization
}
export const monHocSoDiemReducer = (state: IMonHocSoDiemPagteState = iniState, action: IMonHocSoDiemActionTypes): IMonHocSoDiemPagteState => {
    switch (action.type) {
        case eMonHocSoDiemActionIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eMonHocSoDiemActionIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                monHocSoDiems: action.payload
            }
        case eMonHocSoDiemActionIds.LOAD_ERROR:
            return {
                ...state,
                monHocSoDiems: [],
                status: ePageBaseStatus.is_completed
            }

        default:
            return { ...state };
    }
}